<template>
  <b-container
    fluid
    class="main-landing-back pr-0 pl-0"
    style="min-height: 500px; position: relative"
    @click="search_toggle.city = false"
  >
    <!-- <testTopNav /> -->
    <b-container class="mb-5">
      <h4 class="text-white">
        BROWSE THROUGH THE LATEST ANIMALS UPLOADED <br />
        BY HUNDREDS OF SELLERS DAILY
      </h4>
    </b-container>
    <b-container fluid>
      <b-card>
        <div class="row">
          <div class="col-md-3">
            <!-- <b-card> -->
            <!-- <b-tabs pills content-class="mt-3">
                <b-tab class="post-tab"> -->
            <b-card style="box-shadow: 0px 3px 6px #00000029" class="mb-2">
              <template v-slot:header>
                <h4 class="pt-3"><b>Browse Categories</b></h4>
              </template>
              <b-list-group style="cursor: pointer">
                <b-list-group-item
                  @click="type = 0"
                  href="#"
                  :class="type == 0 ? active_class : 'deps'"
                >
                  <div class="d-inline">
                    <img
                      class="float-left"
                      :src="
                        type == 0
                          ? 'https://firebasestorage.googleapis.com/v0/b/qurbaniapp-24a10.appspot.com/o/posts%2Ffeatured-white.png?alt=media&token=1415369d-3f4e-4ff9-a154-cc278157d1a1'
                          : 'https://firebasestorage.googleapis.com/v0/b/qurbaniapp-24a10.appspot.com/o/posts%2Ffeature.png?alt=media&token=4c94eaaa-4b98-4997-a4d4-2004e63fb4ca'
                      "
                      width="20"
                      height="20"
                      alt=""
                    />
                    <span
                      class="float-left"
                      style="text-decoration: none; margin-left: 0.45rem"
                      :class="type == 0 ? 'text-white' : 'text-dark'"
                      >Featured</span
                    >
                  </div>
                </b-list-group-item>
                <b-list-group-item
                  :class="type == 1 ? active_class : 'opds'"
                  @click="type = 1"
                >
                  <div class="d-inline">
                    <img
                      class="float-left"
                      :src="
                        type == 1
                          ? 'https://firebasestorage.googleapis.com/v0/b/qurbaniapp-24a10.appspot.com/o/posts%2Fnew-aals-white.png?alt=media&token=87926c90-5fc8-4043-8755-85b15f533186'
                          : 'https://firebasestorage.googleapis.com/v0/b/qurbaniapp-24a10.appspot.com/o/posts%2Fnew-aals.png?alt=media&token=9f998a23-488a-4a51-af48-1c6036fe0627'
                      "
                      width="20"
                      height="20"
                      alt=""
                    />
                    <span
                      style="text-decoration: none; margin-left: 0.45rem"
                      :class="[type == 1 ? 'text-white' : '', 'float-left']"
                    >
                      New Arrivals</span
                    >
                  </div>
                </b-list-group-item>
                <b-list-group-item
                  :class="type == 2 ? active_class : 'opds'"
                  @click="
                    type = 2;
                    search_obj.category = 'Cow';
                  "
                >
                  <div class="d-inline">
                    <img
                      class="float-left"
                      :src="
                        type == 2
                          ? 'https://firebasestorage.googleapis.com/v0/b/qurbaniapp-24a10.appspot.com/o/posts%2Fcow-white.png?alt=media&token=40a2d1db-bc78-43bf-bfd1-ed08c1fe6e6e'
                          : 'https://firebasestorage.googleapis.com/v0/b/qurbaniapp-24a10.appspot.com/o/posts%2Fcow_ico_web.png?alt=media&token=7e78741a-796b-4ece-a013-ceb2ea18caec'
                      "
                      width="20"
                      height="20"
                      alt=""
                    />
                    <span
                      style="text-decoration: none; margin-left: 0.45rem"
                      :class="[type == 2 ? 'text-white' : '', 'float-left']"
                      >Cows</span
                    >
                  </div>
                </b-list-group-item>
                <b-list-group-item
                  :class="type == 3 ? active_class : 'opds'"
                  @click="
                    type = 3;
                    search_obj.category = 'Goat';
                  "
                >
                  <div class="d-inline">
                    <img
                      class="float-left"
                      :src="
                        type == 3
                          ? 'https://firebasestorage.googleapis.com/v0/b/qurbaniapp-24a10.appspot.com/o/posts%2Fgoat-white.png?alt=media&token=ad34234d-b263-4045-aeaf-2d71b13d201d'
                          : 'https://firebasestorage.googleapis.com/v0/b/qurbaniapp-24a10.appspot.com/o/posts%2Fgoat_io_web.png?alt=media&token=9068beb1-6cef-4a3a-8f80-f5cbfc11ec06'
                      "
                      width="20"
                      height="20"
                      alt=""
                    />
                    <span
                      style="text-decoration: none; margin-left: 0.45rem"
                      :class="[type == 3 ? 'text-white' : '', 'float-left']"
                      >Goats</span
                    >
                  </div>
                </b-list-group-item>
                <b-list-group-item
                  :class="type == 4 ? active_class : 'opds'"
                  @click="
                    type = 4;
                    search_obj.category = 'Sheep';
                  "
                >
                  <div class="d-inline">
                    <img
                      class="float-left"
                      :src="
                        type == 4
                          ? 'https://firebasestorage.googleapis.com/v0/b/qurbaniapp-24a10.appspot.com/o/posts%2Fsheep-white.png?alt=media&token=91bc2c08-530a-42f7-a943-2a753c6df29b'
                          : 'https://firebasestorage.googleapis.com/v0/b/qurbaniapp-24a10.appspot.com/o/posts%2Fsheep_ico_web.png?alt=media&token=6af8b1db-37be-436b-abed-4106ac4edfc7'
                      "
                      width="20"
                      height="20"
                      alt=""
                    />
                    <span
                      :class="[type == 4 ? 'text-white' : '', 'float-left']"
                      style="text-decoration: none; margin-left: 0.45rem"
                      >Sheep</span
                    >
                  </div>
                </b-list-group-item>
                <!-- <b-list-group-item
                        :class="type == 5 ? active_class : 'opds'"
                        @click="type = 5"
                      >
                        <div class="d-inline">
                          <img
                            class="float-left"
                            :src="
                              type == 5
                                ? 'img/posts_page/store-white.png'
                                : 'img/posts_page/home-black.png'
                            "
                            width="20"
                            height="20"
                            alt=""
                          />
                          <span
                            class="ml-2"
                            :class="[
                              type == 5 ? 'text-white' : '',
                              'float-left',
                            ]"
                            style="text-decoration: none; margin-left: 0.45rem"
                            >Our Stock</span
                          >
                        </div>
                      </b-list-group-item> -->
                <b-list-group-item
                  :class="[type == 6 ? active_class : 'opds', 'pr-1 ']"
                  @click="type = 6"
                >
                  <div class="d-inline">
                    <img
                      class="float-left"
                      :src="
                        type == 6
                          ? 'https://firebasestorage.googleapis.com/v0/b/qurbaniapp-24a10.appspot.com/o/posts%2Fcertified_white.png?alt=media&token=be040542-22cf-4239-8b36-6e501096338c'
                          : 'https://firebasestorage.googleapis.com/v0/b/qurbaniapp-24a10.appspot.com/o/posts%2Fcertified_black.png?alt=media&token=3a45da39-13c7-404a-b6b8-27bfcee76347'
                      "
                      width="20"
                      height="20"
                      alt=""
                    />
                    <span
                      :class="[type == 6 ? 'text-white' : '', 'float-left']"
                      style="text-decoration: none; margin-left: 0.45rem"
                      >Sadqah Stock</span
                    >
                  </div>
                </b-list-group-item>
              </b-list-group>
            </b-card>
            <!-- </b-tab>
              </b-tabs>
            </b-card> -->

            <!-- <b-card>
              <b-tabs pills content-class="mt-3">
                <b-tab class="post-tab" title="Search"> -->
            <b-card style="box-shadow: 0px 3px 6px #00000029">
              <h4>Filter {{ search_obj.category }}</h4>
              <!-- <div>
                  <b-button
                    @click="search_obj.sold_status = 's'"
                    :variant="
                      search_obj.sold_status == 's' ? 'success' : 'white'
                    "
                    :style="
                      search_obj.sold_status == 's'
                        ? 'border:1px solid #dcdcdc;border-bottom-left-radius: 1rem;border-top-left-radius: 1rem;'
                        : 'border:1px solid #dcdcdc;border-bottom-left-radius: 1rem;border-top-left-radius: 1rem;'
                    "
                    >Sold</b-button
                  >
                  <b-button
                    @click="search_obj.sold_status = 'n'"
                    :variant="
                      search_obj.sold_status == 'n' ? 'success' : 'white'
                    "
                    :style="
                      search_obj.sold_status == 'n'
                        ? 'border:1px solid #dcdcdc;border-bottom-right-radius: 1rem;border-top-right-radius: 1rem;'
                        : 'border:1px solid #dcdcdc;border-bottom-right-radius: 1rem;border-top-right-radius: 1rem;'
                    "
                    >Unsold</b-button
                  >
                </div> -->
              <div class="mt-3">
                <h6 class="text-left">Description</h6>
                <b-input
                  class="rounded-0"
                  v-model="search_obj.search"
                  style="background-clip: unset !important"
                  placeholder="Search"
                  @focus="search_toggle.city = false"
                >
                </b-input>
                <div
                  v-if="search_toggle.desc == true"
                  style="min-height: 100px; max-height: 120px; overflow-y: auto"
                  class="p-2 bg-white"
                >
                  <h6
                    v-for="(item, i) in descriptions"
                    :key="i"
                    @click="descriptionResult(item)"
                    class="text-success text-left cursor-pointer"
                  >
                    {{
                      item.split(" ").length > 3
                        ? `${item.split(" ")[0]} ${item.split(" ")[1]}...`
                        : item
                    }}
                  </h6>
                </div>
                <!-- <b-input-group> -->
                <!-- <template v-slot:prepend>
                        <b-button
                          style="
                            border-left: 1px solid #dcdcdc;
                            border-top: 1px solid #dcdcdc;
                            border-bottom: 1px solid #dcdcdc;
                          "
                          variant="white"
                          ><i class="fa fa-text-width" aria-hidden="true"></i>
                        </b-button>
                      </template>
                      <input
                        v-model="search_obj.search"
                        class="form-control"
                        style="border-left: none !important"
                        placeholder="Description"
                      />
                    </b-input-group> -->
              </div>

              <div class="mt-3">
                <h6 class="text-left">City</h6>
                <b-input
                  class="rounded-0"
                  style="background-clip: unset !important"
                  placeholder="City"
                  v-model="search_obj.city"
                  @focus="search_toggle.desc = false"
                >
                </b-input>
                <div
                  v-if="search_toggle.city == true"
                  style="min-height: 100px; max-height: 120px; overflow-y: auto"
                  class="p-2 bg-white"
                >
                  <h6
                    v-for="(item, i) in cities"
                    :key="i"
                    @click="cityResult(item)"
                    class="text-success text-left cursor-pointer"
                  >
                    {{
                      item.split(" ").length > 3
                        ? `${item.split(" ")[0]} ${item.split(" ")[1]}...`
                        : item
                    }}
                  </h6>
                </div>

                <!-- <b-input-group>
                      <template v-slot:prepend>
                        <b-button
                          style="
                            border-left: 1px solid #dcdcdc;
                            border-top: 1px solid #dcdcdc;
                            border-bottom: 1px solid #dcdcdc;
                          "
                          variant="white"
                          ><span class="fa fa-map-marker"></span
                        ></b-button>
                      </template>
                      <input
                        v-model="search_obj.city"
                        class="form-control"
                        style="border-left: none !important"
                        placeholder="Location"
                      /> -->
                <!-- </b-input-group> -->
              </div>
              <div class="mt-3">
                <h6 class="text-left">By Weight</h6>

                <div
                  @click="search_toggle.weight = !search_toggle.weight"
                  style="background-clip: unset !important"
                  class="text-center rounded-0 form-control"
                >
                  <span>{{
                    loweight != "" && highweight != ""
                      ? `${loweight}-${highweight} kg`
                      : "Weight"
                  }}</span>
                </div>
                <div
                  v-if="search_toggle.weight == true"
                  style="min-height: 100px"
                  class="p-2 bg-white"
                >
                  <b-row>
                    <b-col md="6">
                      <b-input
                        v-model="loweight"
                        type="number"
                        placeholder="min"
                        max="900"
                      ></b-input>
                    </b-col>
                    <b-col md="6">
                      <b-input
                        v-model="highweight"
                        type="number"
                        placeholder="max"
                        max="900"
                      ></b-input>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col v-if="loweight == ''">
                      <p
                        v-for="(item, i) in [
                          0, 40, 160, 300, 500, 700, 1000, 1300,
                        ]"
                        :key="i"
                        @click="loweight = item"
                        class="fs-14 mb-0 text-left search-drop mt-1"
                      >
                        {{ item }}
                      </p>
                    </b-col>
                    <b-col v-else>
                      <p
                        @click="
                          highweight = item;
                          search_toggle.weight = false;
                        "
                        v-for="(item, i) in [
                          0, 40, 160, 300, 500, 700, 1000, 1300,
                        ].filter((its) => its > loweight)"
                        :key="i"
                        class="fs-14 mb-0 text-right search-drop mt-1"
                      >
                        {{ item }}
                      </p>
                    </b-col>
                  </b-row>
                </div>

                <!-- <b-input-group>
                      <template v-slot:prepend>
                        <b-button
                          style="
                            border-left: 1px solid #dcdcdc;
                            border-top: 1px solid #dcdcdc;
                            border-bottom: 1px solid #dcdcdc;
                          "
                          variant="white"
                          ><span class="fa fa-shopping-bag"></span
                        ></b-button>
                      </template>
                      <select
                        class="form-control"
                        v-model="search_obj.weight"
                        name=""
                        id=""
                      >
                        <option value="no" selected disabled>
                          Select weight
                        </option>
                        <option value="0-40">0-40 kg</option>
                        <option value="40-80">40-80 kg</option>
                        <option value="80-120">80-120 kg</option>
                        <option value="120-160">120-160 kg</option>
                        <option value="160-200">160-200 kg</option>
                      </select>
                    </b-input-group> -->
              </div>
              <div class="mt-3">
                <h6 class="text-left">By Price</h6>
                <div
                  @click="search_toggle.price = !search_toggle.price"
                  style="background-clip: unset !important"
                  class="text-center rounded-0 form-control pr-0 pl-0"
                >
                  <span>{{
                    lowprice != "" && highprice != ""
                      ? `${lowprice}-${
                          highprice == 10000000 ? "1000000+" : highprice
                        }  Pkr`
                      : "Price"
                  }}</span>
                </div>
                <div
                  v-if="search_toggle.price == true"
                  style="min-height: 100px"
                  class="pt-2 pb-2 bg-white"
                >
                  <b-row>
                    <b-col md="6">
                      <b-input
                        v-model="lowprice"
                        type="number"
                        placeholder="min"
                      ></b-input>
                    </b-col>
                    <b-col md="6">
                      <b-input
                        v-model="highprice"
                        type="number"
                        placeholder="max "
                      ></b-input>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col v-if="lowprice == ''">
                      <p
                        v-for="(item, i) in [
                          20000, 60000, 100000, 200000, 300000, 1000000,
                        ]"
                        :key="i"
                        @click="lowprice = item"
                        class="fs-14 mb-0 text-left search-drop mt-1"
                      >
                        {{ item }}
                      </p>
                    </b-col>
                    <b-col v-else>
                      <p
                        @click="
                          highprice = item;
                          search_toggle.price = false;
                        "
                        v-for="(item, i) in [
                          20000, 60000, 100000, 200000, 300000, 1000000,
                          10000000,
                        ].filter((its) => its > lowprice)"
                        :key="i"
                        class="fs-14 mb-0 text-right search-drop mt-1"
                      >
                        {{ item == 10000000 ? "1000000+" : item }}
                      </p>
                    </b-col>
                  </b-row>
                </div>
                <!-- <b-input-group>
                      <template v-slot:prepend>
                        <b-button
                          style="
                            border-left: 1px solid #dcdcdc;
                            border-top: 1px solid #dcdcdc;
                            border-bottom: 1px solid #dcdcdc;
                          "
                          variant="white"
                        >
                          <i class="fa fa-money" aria-hidden="true"></i>
                        </b-button>
                      </template>
                      <select
                        v-model="search_obj.price"
                        style="background-clip: unset !important"
                        class="rounded-0 form-control"
                      >
                        <option value="no" selected disabled>
                          Select price
                        </option>
                        <option value="20000 40000">20,000-40,000</option>
                        <option value="60000-80000">60,000-80,000</option>
                        <option value="100000-150000">100,000-150,000</option>
                        <option value="200000-300000">200,000 250,000</option>
                        <option value=" 300000-500000">300,000 500,000</option>
                        <option value="1000000-9000000">
                          1,000,000 and above
                        </option>
                      </select> -->
                <!-- </b-input-group> -->
                <!-- <b-input-group class="mt-2">
                      <template v-slot:prepend>
                        <b-button
                          style="
                            border-left: 1px solid #dcdcdc;
                            border-top: 1px solid #dcdcdc;
                            border-bottom: 1px solid #dcdcdc;
                          "
                          variant="white"
                        ></b-button>
                      </template>
                    </b-input-group> -->
              </div>
              <div class="mt-3">
                <b-button
                  variant="success"
                  class="pr-5 pl-5"
                  @click="searchPost(1)"
                  >Apply</b-button
                >
              </div>
            </b-card>
            <!-- </b-tab>
              </b-tabs>
            </b-card> -->
          </div>
          <div class="col-md-9">
            <FeaturedPosts v-if="type == 0" @chat-show="hideDialog" />
            <RecentPosts v-if="type == 1" @chat-show="hideDialog" />
            <CowPosts v-if="type == 2" @chat-show="hideDialog" />
            <SheepPosts v-if="type == 4" @chat-show="hideDialog" />
            <GoatPosts v-if="type == 3" @chat-show="hideDialog" />
            <OurStock v-if="type == 5" @chat-show="hideDialog" />
            <CSadqahStock v-if="type == 6" @order-item="orderItem" />
            <CSadqahOrder
              :AllPostFlag="true"
              :order="order"
              class="mt-5"
              v-if="type == 7"
            />
            <SearchResultPosts
              :search_results="search_results"
              :search_params="search_params"
              :data="tempdata"
              v-if="type == 8"
            />
          </div>
          <b-modal
            hide-footer
            id="modal-link"
            ref="modal-link"
            size="lg"
            :title="selected_post.category"
          >
            <DialogPost
              :selected_post="selected_post"
              @chat-show="hideDialog"
            />
          </b-modal>
          <b-modal
            id="first-chat"
            title="Send Message"
            @ok="lc_sendMessage()"
            ok-title="Send"
          >
            <b-form-group id="fieldset-1" label="Write your message">
              <b-form-textarea
                id="textarea"
                v-model="new_chat_obj.message"
                placeholder="Type here..."
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
          </b-modal>
        </div>
      </b-card>
    </b-container>
  </b-container>
</template>
<script>
import FeaturedPosts from "../components/FeaturedPosts.vue";
import CowPosts from "../components/CowPosts.vue";
import SheepPosts from "../components/SheepPosts.vue";
import GoatPosts from "../components/GoatPosts.vue";
import RecentPosts from "../components/RecentPosts.vue";
import CSadqahStock from "../components/CSadqahStock.vue";
import CSadqahOrder from "../components/CSadqahOrder.vue";
import SearchResultPosts from "../components/SearchResultPosts.vue";
import testTopNav from "../components/testTopNav.vue";
import DialogPost from "../components/DialogPost.vue";

import OurStock from "../components/OurStock.vue";
import { mapGetters } from "vuex";
import { RepositoryFactory } from "../Repository/RepositoryFactory";
const UserRepository = RepositoryFactory.get("user_repository");
const SearchRepository = RepositoryFactory.get("search_repository");

import globalfuns from "../mixins/globalfuns";
import unixTime from "unix-time";
import PostsRepository from "../Repository/PostsRepository";

export default {
  name: "AllPosts",
  props: ["types"],
  mixins: [globalfuns],
  components: {
    FeaturedPosts,
    CowPosts,
    SheepPosts,
    testTopNav,
    GoatPosts,
    DialogPost,
    RecentPosts,
    OurStock,
    CSadqahStock,
    CSadqahOrder,
    SearchResultPosts,
  },
  data() {
    return {
      linkenable: false,
      search_params: {},
      tempdata: 0,
      search_results: [],
      descriptions: [],
      cities: [],
      current: 1,
      highprice: "",
      lowprice: "",
      highweight: "",
      loweight: "",
      search_toggle: { price: false, weight: false, city: false, desc: false },

      search_obj: {
        price: "2000-100000",
        weight: "no",
        city: "",
        search: "",
        // filter: "yes",
        // category: "Cow",
        // min_price: "",
        // max_price: "",
        // price: "2000-100000",
        // weight: "no",
        // city: "",
        // sold_status: "n",
      },
      order: "",

      selected_post: "",
      type: 0,
      msg_notif_obj: {
        sender_id: "",
        post_owner_id: "",
        post_image: "",
        channel: "",
      },
    };
  },
  computed: {
    ...mapGetters(["loggedUser", "chatUser"]),
    search() {
      return this.search_obj.search;
    },
    search_city() {
      return this.search_obj.city;
    },
    active_class() {
      return "active";
    },
  },
  mounted() {
    localStorage.setItem("route", "AllPosts");
    // if (this.linkenable == true) {
    //   this.$bvModal("modal-link").show();
    // }
  },
  async created() {
    window.scrollTo(0, 0);
    console.log(this.$route.params.id != null);
    if (this.$route.params.id != null) {
      let { data } = await PostsRepository.get_sigle_post({
        id: this.$route.params.id,
      });
      console.log(data);
      this.selected_post = data;
      this.$bvModal.show("modal-link");
      // this.linkenable = true;
    }
    localStorage.setItem("route", "AllPosts");
    if (this.types != null) {
      this.type = this.types;
      let odr = localStorage.getItem("order");
      if (this.type == 6 && odr != null) {
        this.orderItem(JSON.parse(odr));
        localStorage.removeItem("order");
      }
    } else {
      console.log(this.$route.params.id);
      if (this.$route.params.id == "cow") {
        this.type = 2;
      } else if (this.$route.params.id == "sheep") {
        this.type = 4;
      } else if (this.$route.params.id == "goat") {
        this.type = 3;
      }
    }
  },
  watch: {
    search(value) {
      console.log("dsadasd");
      // console.log(value);
      if (value.length > 1) {
        this.searchResults();
      } else {
        this.search_toggle.desc = false;
      }
      // or generate/simulate a native events (not sure how, but its outside Vue's realm I think
    },
    search_city(value) {
      console.log(value);
      if (value.length > 3) {
        this.searchCity();
      } else {
        this.search_toggle.city = false;
      }
      // or generate/simulate a native events (not sure how, but its outside Vue's realm I think
    },
  },
  methods: {
    cityResult(item) {
      this.search_obj.city =
        item.split(" ").length > 3
          ? `${item.split(" ")[0]} ${item.split(" ")[1]}...`
          : item;
      console.log(this.search);
      this.search_toggle.city = false;
    },
    descriptionResult(item) {
      this.search_obj.search =
        item.split(" ").length > 3
          ? `${item.split(" ")[0]} ${item.split(" ")[1]}...`
          : item;
      console.log(this.search);
      this.search_toggle.desc = false;
    },
    async searchResults() {
      console.log(this.search);
      let { data } = await SearchRepository.search_description({
        search_field: this.search,
      });
      console.log(data);
      this.descriptions = data;
      this.search_toggle.desc = true;
      console.log(this.search_toggle);
    },
    async searchCity() {
      console.log(this.search);
      let { data } = await SearchRepository.search_city({
        city: this.search_city,
      });
      console.log(data);
      this.cities = data;
      this.search_toggle.city = true;
    },
    async searchPost(page) {
      console.log("Dsada");
      // if (
      //   this.search_obj.max_price >= 2000 &&
      //   this.search_obj.min_price >= 2000 &&
      //   this.search_obj.max_price <= 10000000
      // ) {
      // this.search_obj.price =
      //   this.search_obj.min_price + "-" + this.search_obj.max_price;
      // console.log(this.search_obj);
      // let objs = { ...this.search_obj };
      // if (objs.city == "") {
      //   objs.city = "no";
      // }
      // delete objs.min_price;
      // delete objs.max_price;
      let { data } = await SearchRepository.search_results({
        search_field: this.search == "" ? "no" : this.search,
        city: this.search_city == "" ? "no" : this.search_city,
        price:
          this.lowprice != "" && this.highprice != ""
            ? this.lowprice + "-" + this.highprice
            : "no",
        weight:
          this.loweight != "" && this.highweight != ""
            ? this.loweight + "-" + this.highweight
            : "no",
      });
      console.log(data);
      this.search_results = data.posts;
      this.search_params = {
        search_field: this.search == "" ? "no" : this.search,
        city: this.search_city == "" ? "no" : this.search_city,
        price:
          this.lowprice != "" && this.highprice != ""
            ? this.lowprice + "-" + this.highprice
            : "no",
        weight:
          this.loweight != "" && this.highweight != ""
            ? this.loweight + "-" + this.highweight
            : "no",
      };
      this.tempdata = data;
      //   if (data.length > 0) {
      //     this.search_results = data;
      this.type = 8;
      //   } else {
      //     this.$store.commit("setNotifications", {
      //       message: "No search results on this criteria",
      //       type: "error",
      //     });
      //   }
      // } else {
      //   this.$store.commit("setNotifications", {
      //     message: "Min Price is 2000 and Maxium price is 10000000",
      //     type: "error",
      //   });
      // }
    },
    orderItem(item) {
      console.log("Cames");
      this.type = 7;
      this.order = item;
    },
    hideDialog(item) {
      console.log(item);
      this.$bvModal.hide("modal-lg");
      this.$bvModal.show("first-chat");
      this.selected_post = item;
    },
    async lc_sendMessage() {
      if (this.loggedUser != null) {
        let isNew = true;

        // let senderphone = this.FB !=null ? this.loggedUser.id : this.loggedUser.phoneno
        let sendername = this.loggedUser.nickname;
        this.new_chat_obj.post_id = this.selected_post.id;
        this.new_chat_obj.sender = this.loggedUser.id;
        this.new_chat_obj.timestamp = unixTime(new Date());
        this.new_chat_obj.image_key = this.selected_post.imagekey;

        this.new_user_obj = {
          last_date: this.getTodaysDate(),
          last_time: this.getCurrentTime(),
        };

        let { data } = await UserRepository.getuser({
          user_id: this.selected_post.user_id,
        });
        let receivername = data[0].nickname;
        let checks;
        if (this.chatUser.chat_groups != null) {
          checks = Object.keys(this.chatUser.chat_groups).find(
            (item) => this.chatUser.chat_groups[item].receiver_id == data[0].id
          );
        }
        let chatgroup_id;
        if (checks != null) {
          chatgroup_id = this.chatUser.chat_groups[checks].id;
        }
        isNew = checks != null ? false : true;

        let contacts = {
          sender_name: sendername,
          receiver_name: receivername,
          sender_id: this.loggedUser.id,
          receiver_id: data[0].id,
        };

        this.msg_notif_obj.sender_id = this.loggedUser.id;
        this.msg_notif_obj.post_owner_id = this.selected_post.user_id;
        this.msg_notif_obj.channel = this.loggedUser.id + "_" + data[0].id;
        this.msg_notif_obj.post_image = this.selected_post.imagekey;

        let resps = await UserRepository.sendmsgnotification(
          this.msg_notif_obj
        );

        // console.log(chatgroup_id)
        // console.log(isNew)
        // console.log(contacts)
        // console.log(this.new_user_obj)
        // console.log(this.new_chat_obj)

        this.$store.dispatch("initialMessage", {
          chatgroup_id: chatgroup_id,
          isNew: isNew,
          contacts: contacts,
          new_user_obj: this.new_user_obj,
          new_chat_obj: this.new_chat_obj,
        });
        this.$bvModal.hide("first-chat");
        this.$router.push({ name: "Dashboard", params: { active_prop: 9 } });
      } else {
        this.$store.commit("setNotifications", {
          message: "Login before messaging",
          type: "error",
        });
      }
    },
  },
};
</script>
<style>
.btn-primary {
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}
</style>
<style scoped>
button:focus {
  outline: none !important;
  box-shadow: none !important;
  border-left: 1px solid #dcdcdc !important;
  border-top: 1px solid #dcdcdc !important;
  border-bottom: 1px solid #dcdcdc !important;
}
select {
  border-left: none !important;
}
input:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #dcdcdc !important;
}
.form-control:focus {
  /* border-color: green !important; */
  outline: none !important;
  border: 1px solid #dcdcdc !important;
  box-shadow: none !important;
}
.list-group-item:first-child {
  /* border-top-left-radius: 0.25rem; */
  border-top: none;
  border-left: none !important;
  border-right: none !important;
}
.list-group-item:first-child:hover {
  /* border-top-left-radius: 0.25rem; */
  text-decoration: none !important;
  border-top: none;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
}
.active {
  background-color: #28a745 !important;
  color: black !important;
  /* border-top:1px solid #0A0101 !important; */
  border-left: none;
  border-right: none;
  /* border-bottom: none !important */
}

.opds {
  /* border-top:1px solid #0A0101 !important; */
  border-left: none;
  border-right: none;
  /* border-bottom: none !important */
}
.card-header {
  background-color: white !important;
}
</style>
<style>
.nav-item .nav-link.active {
  background-color: #28a745 !important;
  color:white !important;
  color:white !important
}
.nav-item .nav-link.active:hover {
  background-color: #28a745 !important;
  color: white !important;
}
</style>