<template>
 
    <b-navbar toggleable="lg" class="p-1 main-landing-black" :sticky="true" type="dark">
      <b-navbar-brand to="/"
        ><img style="width: 5rem" src="img/ops.png" alt=""
      /></b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto mr-auto">
          <b-nav-item to="/" class="mr-1">Home</b-nav-item>
          <b-nav-item class="ml-md-4" to="/all_posts">Livestock</b-nav-item>
          <!-- <b-nav-item class="ml-md-4" to="/ijtmae-qurbani"
            >
                      

            Ijtamae Qurbani <br/>
            <b-badge variant="success">Order Now</b-badge>  
           
            </b-nav-item
          > -->
          <b-nav-item class="ml-md-4" to="/sadqah-and-aqiqa"
            >Sadqah Aqiqa</b-nav-item
          >

            <b-nav-item-dropdown class="ml-md-4 top-nav-drop"  text="Services"  right>
                <b-dropdown-item href="/farms">Farms <b-badge variant="warning">Beta</b-badge></b-dropdown-item>
                <b-dropdown-item href="/forums">Discussion Forum <b-badge variant="warning">Beta</b-badge></b-dropdown-item>
                <b-dropdown-item href="/videos">Featured Videos</b-dropdown-item>
                <b-dropdown-item href="/informational-videos">Informational Videos</b-dropdown-item>
            </b-nav-item-dropdown>
          
        

          <!-- <b-nav-item class="ml-md-4" to="/videos"
            >Videos</b-nav-item
          > -->
          <!-- <b-nav-item class="ml-md-4" to="/contact-us">Contact Us</b-nav-item> -->

          <!-- <b-nav-item class="mr-md-4 ml-md-4" to="/terms-and-conditions"
            >Terms and Conditions</b-nav-item
          > -->
          <!-- <b-nav-item class="mr-md-4" to="/diseases">Diseases</b-nav-item> -->
          <b-nav-item class="mr-md-4" v-if="loggedUser == null" to="/login"
            >Login</b-nav-item
          >
          <b-nav-item class="mr-md-4 ml-md-4" to="/dashboard" v-if="loggedUser != null"
            >Dashboard</b-nav-item
          >
          <b-nav-item class="mr-md-4" @click="logout" v-if="loggedUser != null"
            >Logout</b-nav-item
          >
          <!-- <b-button
            variant="success"
            v-if="loggedUser == null"
            @click="redirect(self, 'register')"
            ><i class="fa fa-user" aria-hidden="true"></i> Register</b-button
          > -->
          <b-button
            class="ml-1"
            style="box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px"
            variant="success"
            @click="showModal()"
            ><i class="fa fa-camera" aria-hidden="true"></i> Sell</b-button
          >
        </b-navbar-nav>
      </b-collapse>

         <b-modal id="land-sell-dial" hide-footer="" centered>
      <template v-slot:modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h5>Upload your Ads</h5>
        <b-button size="sm" variant="outline-success" @click="close()">
          Cancel
        </b-button>
      </template>
      <div style="display: flex; cursor: pointer" @click="routeit('AUDIO',false)">
        <img src="img/sellmodal/dslr-camera.png" width="60" alt="" />
        <h5 class="mt-3 ml-3">Upload Image</h5>
      </div>
      <div
        style="display: flex; cursor: pointer"
        @click="routeit('VIDEO',false)"
        class="mb-2"
      >
        <img src="img/sellmodal/video-camera.png" width="60" alt="" />
        <h5 class="mt-3 ml-3">Upload Video</h5>
      </div>
       <div style="display: flex; cursor: pointer" @click="routeit('AUDIO',true)">
        <img src="img/dashboard/cow.png" width="60" alt="" />
        <h5 class="mt-3 ml-3">Upload Animal to your farm</h5>
      </div>
    </b-modal>
 
 
    </b-navbar>
 
</template>

<script>
import globalfuns from "../mixins/globalfuns";
import { mapGetters } from "vuex";
export default {
  name: "TopNav",
  mixins: [globalfuns],
  computed: {
    ...mapGetters(["loggedUser", "FB"]),
  },
  created() {
    this.self = this;
  },
  data() {
    return {
      type: "image",
      self: "",
    };
  },
  methods: {
    routeit(arg,bools) {
      this.type = arg;
      console.log(bools)
      this.$bvModal.hide("land-sell-dial");
      this.$router.push({ name: "NewPost", params: { type: arg,farm:bools } });
    },
    logout() {
      this.$store.dispatch("updateOnlineStatus", {
        id: this.loggedUser.id,
        status: false,
      });

      this.$store.commit("logout");
      this.FB.logout();

      this.$router.push({ path: "/" });
    },
    showModal() {
      if (this.loggedUser != null) {
        this.$bvModal.show("land-sell-dial");
      } else {
        this.$store.commit("setNotifications", {
          message: "Login before creating Ads",
          type: "error",
        });
      }
    },
  },
};
</script>

<style>
#land-sell-dial___BV_modal_content_ {
  border-radius: 22px !important;
}
.top-nav-drop  ul li .dropdown-item{
  color:#28a745 !important
}
</style>
