<template>
  <b-container fluid class="pl-0 pr-0">
    <div class="wrapper-top text-center">
      <b-row>
        <b-col cols="12" md="2" class="text-md-left text-center">
          <b-navbar-brand to="/"
            ><img style="width: 5rem" src="img/ops.png" alt=""
          /></b-navbar-brand>
        </b-col>
        <b-col cols="12" md="8">
          <div>
            <a
              href="https://www.facebook.com/qurbaniapp8"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b-avatar class="bgd-green">
                <i class="fab fa-facebook-f"></i>
              </b-avatar>
            </a>
            <a
              href="https://twitter.com/qurbaniapp?lang=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b-avatar class="ml-1 bgd-green"
                ><i class="fab fa-twitter"></i
              ></b-avatar>
            </a>
            <a
              href="https://www.instagram.com/qurbaniapp8/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b-avatar class="ml-2 mr-1 bgd-green"
                ><i class="fab fa-instagram"></i
              ></b-avatar>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.plan9.qurbaniapps.qurbani&hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b-avatar class="ml-1 mr-1 bgd-green"
                ><i class="fab fa-google-play"></i
              ></b-avatar>
            </a>
            <a
              href="https://apps.apple.com/pk/app/qurbani-app/id1520463934"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b-avatar class="bgd-green"
                ><i class="fab fa-app-store-ios"></i
              ></b-avatar>
            </a>
          </div>
          <div class="mt-3">
            <span>About Us</span>
            |
            <router-link to="/diseases"
              ><span class="text-white">Diseases</span></router-link
            >
            |
            <router-link to="/terms-and-conditions">
              <span class="text-white">Terms and Conditions</span>
            </router-link>
            |

            <router-link to="/contact-us">
              <span class="text-white">Contact Us</span>
            </router-link>

            <p class="mt-3">Bakra@qurbaniapp.com</p>
          </div>
        </b-col>
        <b-col md="2" cols="12" class="text-md-right text-center">
          <i class="far fa-arrow-alt-circle-up fa-3x"></i>
        </b-col>
      </b-row>
    </div>
    <div class="wrapper text-center" style="background: #1f693d">
      <p class="mb-0"><b>Qurbani App Private Limited</b></p>
      <p>Office 1206,National Science & Technology Park,H-12, Islamabad</p>
      <!-- <div class="row">
        <div class="col-md-3">
          <div>
            <h5 class="text-white"><b>Qurbani App Pvt Limited</b></h5>
          </div>
          <div class="mt-2">Contact Us</div>

          <div class="mt-2">
            <span class="fa fa-map-marker"> </span>
            <span style="font-size: 14px"
              >Address : Office 1206, <br />
              National Science & Technology Park, <br />
              H-12, Islamabad</span
            >
          </div>
          <div class="mt-2">
            <span class="fa fa-envelope"> </span>
            <span style="font-size: 14px">Email : Bakra@qurbaniapp.com </span>
          </div>
          <div class="mt-2">
            <span class="fa fa-phone"> </span>
            <span style="font-size: 14px">Phone : +92 306 5401886 </span>
          </div>
        </div>
        <div class="col-md-3">
          <div>
            <h5 class="text-white"><b>Top Categories</b></h5>
          </div>
          <router-link
            class="footer-links"
            :to="{ name: 'AllPosts', params: { types: 0 } }"
          >
            <div class="mt-2">
              <span style="font-size: 14px; color: white !important">
                Featured Posts</span
              >
            </div>
          </router-link>
          <router-link
            class="footer-links"
            :to="{ name: 'AllPosts', params: { types: 1 } }"
          >
            <div class="mt-2">
              <span style="font-size: 14px; color: white !important">
                New Arrivals</span
              >
            </div>
          </router-link>
          <router-link
            class="footer-links"
            :to="{ name: 'AllPosts', params: { types: 2 } }"
          >
            <div class="mt-2">
              <span style="font-size: 14px; color: white !important"> Cow</span>
            </div>
          </router-link>

          <router-link :to="{ name: 'AllPosts', params: { types: 3 } }">
            <div class="mt-2">
              <span style="font-size: 14px; color: white !important">
                Goat
              </span>
            </div>
          </router-link>

          <router-link :to="{ name: 'AllPosts', params: { types: 4 } }">
            <div class="mt-2">
              <span style="font-size: 14px; color: white !important">
                Sheep
              </span>
            </div>
          </router-link>
        </div>

        <div class="col-md-3">
          <router-link class="footer-links" :to="{ path: '/' }">
            <div class="mt-2">
              <span style="font-size: 14px; color: white !important">
                About Us</span
              >
            </div>
          </router-link>

          <router-link :to="{ path: '/terms-and-conditions' }">
            <div class="mt-2">
              <span style="font-size: 14px; color: white !important">
                Terms and Conditions
              </span>
            </div>
          </router-link>

          <router-link :to="{ path: '/contact-us' }">
            <div class="mt-2">
              <span style="font-size: 14px; color: white !important">
                Contact
              </span>
            </div>
          </router-link>
        </div>
        <div :class="['col-md-3', aligners]">
          <div>
            <span style="font-size: 20px" class="mr-5">Follow us now</span>
          </div>
          <div style="margin-right: 3rem">
            <a href="https://www.facebook.com/qurbaniapp8/" target="_blank"
              ><img
                class="ml-2 mr-3 mt-3"
                src="img/facebook-white.png"
                height="23"
                width="23"
                alt=""
                srcset=""
            /></a>
            <a
              href="https://instagram.com/qurbaniapp8?igshid=1dqsslzajj5ym"
              target="_blank"
              ><img
                class="ml-2 mr-3 mt-3"
                src="img/instagram-white.png"
                height="23"
                width="23"
                alt=""
                srcset=""
            /></a>
            <a href="https://twitter.com/QurbaniApp" target="_blank"
              ><img
                class="ml-2 mr-3 mt-3"
                src="img/twitter-white.png"
                height="23"
                width="23"
                alt=""
                srcset=""
            /></a>
          </div>
          <div class="mr-3 mt-2">
            <span style="font-size: 20px">Access our app now</span>
          </div>
          <div class="mr-4 mt-1">
            <a
              href="https://apps.apple.com/pk/app/qurbani-app/id1520463934"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="img/appstore.png"
                height="70"
                width="70"
                alt=""
                srcset=""
            /></a>
            <a
              href="https://play.google.com/store/apps/details?id=com.plan9.qurbaniapps.qurbani&hl=en"
              target="_blank"
            >
              <img
                class="ml-2"
                src="img/playstore.png"
                height="70"
                width="70"
                alt=""
                srcset=""
              />
            </a>
          </div>
        </div>
      </div> -->
    </div>
  </b-container>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    aligners() {
      if (this.isMobile == false) return "right-aligned";
      else return "";
    },
  },
};
</script>

<style scoped>
@media (max-width: 425px) {
  .wrapper {
    text-align: center !important;
  }
  .wrapper .col-md-3 {
    margin-bottom: 20px !important;
  }
}
.wrapper-top {
  color: white;
  padding: 10px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  background: #000000;
  border-top: 0.5px solid rgba(189, 189, 189, 0.637);
  /* margin-bottom: 0px; */
  padding-top: 30px;
}
.wrapper {
  color: white;
  padding: 10px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  background: #28a745;
  border-top: 0.5px solid rgba(189, 189, 189, 0.637);
  /* margin-top: 100px; */
  /* margin-bottom: 0px; */
  padding-top: 30px;
}
.contact,
.copy,
.copy a {
  font-weight: 600;
  font-size: 12px;
  color: #6a707a !important;
}
.heading {
  font-weight: 500;
  color: white !important;
  margin-bottom: 20px;
}
ul {
  list-style-type: none;
  padding: 0px;
}
ul li a {
  font-size: 12px;
  font-weight: 600;
  color: white !important;
  margin-top: 30px;
}

div .footerlinks {
  color: white !important;
}
</style>

