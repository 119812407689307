import Repository from './Repository';
const farms_resource = 'users/get_all_farms';
const farms_farm_resource = 'posts/all_farm_posts';
const create_user_farm = 'users/create_user_farm';
const get_user_farms = 'users/get_user_farm';
const delete_farm = 'users/delete_farm';
const edit_Farm = 'users/edit_user_farm';


export default {
  getAllFarms() {
    return Repository.get(`${farms_resource}`);
  },
  getFarmPosts(payload) {
    return Repository.post(`${farms_farm_resource}`,payload);
  },
  createFarm(payload) {
    return Repository.post(`${create_user_farm}`,payload);
  },
  getUserFarms(payload){
    return Repository.post(`${get_user_farms}`,payload);
  },
  deleteFarm(payload){
    return Repository.post(`${delete_farm}`,payload);
  },
  editFarm(payload){
    return Repository.post(`${edit_Farm}`,payload);
  }

  
  // new_order(payload) {
  //   return Repository.post(`${order_resource}`,payload);
  // },
  // edit_order(payload) {
  //   return Repository.post(`${edit_order_resource}`,payload);
  // },
  
  // delete_order(payload) {
  //   return Repository.post(`${delete_order_resource}`,payload);
  // },
  // get_admin_sadqa_orders(payload) {
  //   return Repository.get(`${admin_order_resource}`);
  // }
};
