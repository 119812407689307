<template>
  <div>
    <b-container v-if="$route.name == 'Dashboard'" class="pr-0 pl-0">
      <div
        style="
          border-bottom-right-radius: 1rem;
          border-bottom-left-radius: 1rem;
          min-height: 200px;
          background: linear-gradient(180deg, #000000 0%, #309f5d 100%) 0% 0%
            no-repeat padding-box;
        "
        class="p-1"
      >
        <b-row>
          <b-col md="8" style="align-self: center">
            <!-- <h2 class="text-white">Healthy and Fresh Sadqah and Aqiqa Stock</h2> -->
          </b-col>
          <b-col md="4">
            <img
              class="float-md-left"
              style="width: 100px"
              src="img/myprofile/sheep.png"
              alt=""
            />
          </b-col>
        </b-row>
      </div>
    </b-container>
    <div>
      <b-row>
        <b-col>
          <b-card>
            <b-row>
              <b-col md="12" cols="12">
                <p>Convert to view in your currency</p>
                <b-spinner
                  v-if="currLoad == true"
                  variant="success"
                  label="Spinning"
                ></b-spinner>

                <br />
                <b-button
                  v-if="currLoad == false"
                  pill
                  :variant="
                    order_currency == 'PKR' ? 'success' : 'outline-success'
                  "
                  @click="
                    order_currency = 'PKR';
                    currencyConversion();
                  "
                  >PKR</b-button
                >
                <b-button
                  class="ml-2"
                  v-if="currLoad == false"
                  pill
                  :variant="
                    order_currency == 'USD' ? 'success' : 'outline-success'
                  "
                  @click="
                    order_currency = 'USD';
                    currencyConversion();
                  "
                  >USD</b-button
                >
                <b-button
                  class="ml-2"
                  v-if="currLoad == false"
                  pill
                  :variant="
                    order_currency == 'GBP' ? 'success' : 'outline-success'
                  "
                  @click="
                    order_currency = 'GBP';
                    currencyConversion();
                  "
                  >GBP</b-button
                >
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <h3 v-if="$route.name != 'Dashboard'" class="left-aligned text-success">
      <!-- <b> Healthy and Fresh Sadqah and Aqiqa Stocks </b> -->
    </h3>
    <div v-if="postLoad == false" class="row">
      <div class="col-md-4" v-for="(post_item, i) in viewable_posts" :key="i">
        <PostCard
          :landing="`sadqahs`"
          :converter="converter"
          :currency="order_currency"
          :post_item="post_item"
          @order-item="
            $emit('order-item', {
              ...post_item,
              converter: converter,
              currency: order_currency,
            })
          "
        />
      </div>
    </div>
    <div v-else class="text-center">
      <b-spinner variant="success" label="Spinning"></b-spinner>
    </div>
    <a-pagination
      class="pages"
      :current="current"
      @change="onChange"
      :total="rows"
    />
    <!-- <a-pagination :current="current" @change="onChange" :total="rows" /> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PostCard from "../components/PostCard.vue";
import { RepositoryFactory } from "../Repository/RepositoryFactory";
import axios from "axios";
const PostsRepository = RepositoryFactory.get("posts_repository");
export default {
  components: {
    PostCard,
  },
  watch: {
    posts() {
      if (this.posts.length > 0) {
        this.end_index = this.per_page;
      }
    },
  },
  computed: {
    ...mapGetters(["posts"]),
    rows() {
      if (this.posts.length > 0) {
        let lngth_posts = this.total_posts;
        let temp_perpage = 12;
        let dividedresult = lngth_posts / temp_perpage;
        let parsed = parseInt(dividedresult);
        if (parsed * temp_perpage < lngth_posts) {
          parsed++;
          let sringer = parsed.toString() + "0";
          let parsedback = parseInt(sringer);
          return parsedback;
        } else {
          let sringer = parsed.toString() + "0";
          let parsedback = parseInt(sringer);
          return parsedback;
        }
      }
    },
    spliced_posts() {
      let arr2 = [];
      if (this.posts.length > 0) {
        arr2 = this.posts[6].slice(this.start_index, this.end_index);
        return arr2;
      } else {
        return [];
      }
    },
  },
  data() {
    return {
      postLoad: false,
      order_currency: "PKR",
      converter: 0,

      viewable_posts: [],
      total_posts: 0,
      start_index: 0,
      end_index: 12,
      current: 1,
      per_page: 12,
      selected_post: "",

      currLoad: false,
    };
  },
  methods: {
    currencyConversion() {
      this.currLoad = true;
      if (this.order_currency != "PKR") {
        // this.packages=[15000,18000,30000]

        axios
          .get(
            `https://free.currconv.com/api/v7/convert?apiKey=93b58cb071fcca371f8d&q=${this.order_currency}_PKR&compact=y`
          )
          .then((response) => {
            if (this.order_currency == "USD") {
              console.log(response.data.USD_PKR.val);
              this.converter = Math.round(response.data.USD_PKR.val);
            } else {
              this.converter = Math.round(response.data.GBP_PKR.val);
            }
            this.currLoad = false;
          });
      } else {
        this.order_currency = "PKR";
        this.converter = 0;
        this.currLoad = false;
      }
    },
    async getSadqahPosts(page) {
      this.postLoad = true;
      let { data } = await PostsRepository.other_pagination_posts({
        page: page,
        featured: false,
        qurbani: false,
        sadqa: true,
      });
      console.log(data);
      if (page == 1) {
        this.total_posts = data.total_posts;
      }
      this.viewable_posts = data.posts;
      this.postLoad = false;
      window.scrollTo(50, 50);
    },
    onChange(current, pkd) {
      this.current = current;
      this.getSadqahPosts(current);
      // this.end_index=this.per_page*current
      // this.current = current;
      // this.start_index=(this.current-1)*this.per_page
    },
  },
  created() {
    console.log(this.$route.path);
    localStorage.setItem("route", "Sadqah");
    this.getSadqahPosts(1);
  },
  mounted() {
    localStorage.setItem("route", "Sadqah");
  },
};
</script>

<style></style>
