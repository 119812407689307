import Repository from './Repository';
const order_resource = 'posts/create_sadqa_order';
const admin_order_resource = 'posts/all_sadqa_orders';
const edit_order_resource = 'posts/edit_sadqa_order_admin';
const delete_order_resource = 'posts/delete_sadqa_order';
const new_ijtmaer_order_resource = 'posts/create_ijtimai_order';
const edit_ijtmae_order_resource = 'posts/edit_ijtimai_order_admin';

export default {
  edit_ijtmae_order(payload) {
    return Repository.post(`${edit_ijtmae_order_resource}`,payload);
  },
  new_ijtmae_order(payload) {
    return Repository.post(`${new_ijtmaer_order_resource}`,payload);
  },
  new_order(payload) {
    return Repository.post(`${order_resource}`,payload);
  },
  edit_order(payload) {
    return Repository.post(`${edit_order_resource}`,payload);
  },
  
  delete_order(payload) {
    return Repository.post(`${delete_order_resource}`,payload);
  },
  get_admin_sadqa_orders(payload) {
    return Repository.get(`${admin_order_resource}`);
  }
};
