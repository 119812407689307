<template>
  <div>
    <!-- <b-container
      fluid
      class="main-landing-black pr-0 pl-0"
      style="min-height: 100px; position: relative"
    > -->
      <!-- <testTopNav /> -->
    <!-- </b-container> -->
    <b-container
      class="d-none d-md-block d-lg-block pr-0 pl-0"
      fluid
      style="position: relative"
    >
      <div>
        <video autoplay muted loop id="myVideo">
          <source
            src="https://firebasestorage.googleapis.com/v0/b/qurbaniapp-24a10.appspot.com/o/web-video-finalised.mp4?alt=media&token=6ddd7a44-370d-4531-a4a3-fed19e423a61"
          />
          Your browser does not support HTML5 video.
        </video>
      </div>
      <div
        class="opd"
        style="min-height: 500px !important;background:-webkit-linear-gradient(left , rgb(0, 0, 0, 0.4) , rgb(0, 0, 0, 0.4) 100%);!important;"
      >
        <b-container fluid class="pr-0 pl-0" style="margin-top: 6%">
          <b-container class="mt-md-5 mt-0 temp-cont">
            <div class="p-4 ml-auto mr-auto" style="max-width: 600px">
              <h4 class="d-md-block mb-3 d-lg-block d-none text-white">
                BUY AND SELL LIVESTOCK ANYWHERE IN <br />
                PAKISTAN FOR FREE WITH QURBANI APP
              </h4>
              <h6 class="d-block d-md-none d-lg-none text-white">
                BUY AND SELL LIVESTOCK ANYWHERE IN <br />
                PAKISTAN FOR FREE WITH QURBANI APP
              </h6>
              <router-link to="/all_posts">
                <b-button
                  @mouseover="buy = true"
                  @mouseleave="buy = false"
                  :variant="buy == false ? 'outline-light' : 'success'"
                  class="pr-5 pl-5"
                  >Buy</b-button
                >
              </router-link>
              <b-button
                @mouseover="sell = true"
                @mouseleave="sell = false"
                :variant="sell == false ? 'outline-light' : 'success'"
                class="ml-2 mb pr-5 pl-5"
                @click="showModal()"
                >Sell</b-button
              >
              <div class="mt-3">
                <a
                  href="https://apps.apple.com/pk/app/qurbani-app/id1520463934"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <b-img
                    src="./img/landing/appstore.svg"
                    style="border-radius: 0.5em"
                  ></b-img>

                  <!-- <button
                    type="button"
                    style="background-color: black"
                    class="
                      btn btn-xs btn-wide
                      transition-3d-hover
                      text-left
                      mb-2
                      mr-1
                    "
                  >
                    <span class="media align-items-center">
                      <span class="fab fa-apple fa-2x mr-3 text-white"></span>
                      <span class="media-body">
                        <span class="d-block text-white">Download on the</span>
                        <strong class="font-size-1 text-white"
                          >App Store</strong
                        >
                      </span>
                    </span>
                  </button> -->
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.plan9.qurbaniapps.qurbani"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <b-img
                    src="./img/landing/google play.svg"
                    style="border-radius: 0.5em"
                    class="ml-1"
                  ></b-img>

                  <!-- <button
                    type="button"
                    style="background-color: black"
                    class="
                      btn btn-xs btn-wide
                      transition-3d-hover
                      text-left
                      mb-2
                      mr-1
                    "
                  >
                    <span class="media align-items-center">
                      <span
                        class="fab fa-google-play fa-2x mr-3 text-white"
                      ></span>
                      <span class="media-body">
                        <span class="d-block text-white">Get it on</span>
                        <strong class="font-size-1 text-white"
                          >Google Play</strong
                        >
                      </span>
                    </span>
                  </button> -->
                </a>
              </div>
            </div>
            <b-row>
              <b-col @mouseleave="search_toggle.desc = false" class="pr-0 pl-0">
                <!-- @focus="search_toggle.desc = true" -->
                <b-input
                  class="rounded-0"
                  v-model="search"
                  style="background-clip: unset !important"
                  placeholder="Search"
                  @focus="search_toggle.city = false"
                >
                </b-input>
                <div
                  v-if="search_toggle.desc == true"
                  style="min-height: 100px; max-height: 120px; overflow-y: auto"
                  class="p-2 bg-white"
                >
                  <h6
                    v-for="(item, i) in descriptions"
                    :key="i"
                    @click="descriptionResult(item)"
                    class="text-left cursor-pointer"
                  >
                    {{
                      item.split(" ").length > 3
                        ? `${item.split(" ")[0]} ${item.split(" ")[1]}...`
                        : item
                    }}
                  </h6>
                </div>
              </b-col>

              <b-col class="pr-0 pl-0">
                <!-- @focus="search_toggle.city = true" -->

                <b-input
                  class="rounded-0"
                  style="background-clip: unset !important"
                  placeholder="City"
                  v-model="search_city"
                  @focus="search_toggle.desc = false"
                >
                </b-input>
                <div
                  v-if="search_toggle.city == true"
                  style="min-height: 100px; max-height: 120px; overflow-y: auto"
                  class="p-2 bg-white"
                >
                  <h6
                    v-for="(item, i) in cities"
                    :key="i"
                    @click="cityResult(item)"
                    class="text-left cursor-pointer"
                  >
                    {{
                      item.split(" ").length > 3
                        ? `${item.split(" ")[0]} ${item.split(" ")[1]}...`
                        : item
                    }}
                  </h6>
                </div>
              </b-col>
              <b-col @mouseleave="search_toggle.city = false" class="pr-0 pl-0">
                <!-- @focus="search_toggle.desc = true" -->
                <b-input
                  class="rounded-0"
                  v-model="username"
                  style="background-clip: unset !important"
                  placeholder="User"
                  @focus="search_toggle.city = false"
                >
                </b-input>
                <div
                  v-if="search_toggle.username == true"
                  style="min-height: 100px; max-height: 120px; overflow-y: auto"
                  class="p-2 bg-white"
                >
                  <h6
                    v-for="(item, i) in users"
                    :key="i"
                    @click="usernameResult(item)"
                    class="text-left cursor-pointer"
                  >
                    {{
                      item.split(" ").length > 2
                        ? `${item.split(" ")[0]} ${item.split(" ")[1]}...`
                        : item
                    }}
                  </h6>
                </div>
              </b-col>
              <b-col class="pr-0 pl-0">
                <div
                  @click="
                    search_toggle.username = false;
                    search_toggle.weight = !search_toggle.weight;
                    search_toggle.price = false;
                  "
                  style="background-clip: unset !important; cursor: pointer"
                  class="text-center rounded-0 form-control"
                >
                  <span>{{
                    loweight != "" && highweight != ""
                      ? `${loweight}-${highweight} kg`
                      : "Weight (kg)"
                  }}</span>
                </div>
                <div
                  v-if="search_toggle.weight == true"
                  style="
                    max-height: 120px;
                    overflow-x: hidden;
                    overflow-y: auto;
                  "
                  class="p-2 bg-white"
                >
                  <b-row>
                    <b-col md="6" cols="6">
                      <b-input
                        v-model="loweight"
                        type="number"
                        placeholder="min"
                        max="900"
                      ></b-input>
                    </b-col>
                    <b-col md="6" cols="6">
                      <b-input
                        v-model="highweight"
                        type="number"
                        placeholder="max"
                        max="900"
                      ></b-input>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col v-if="loweight == ''">
                      <p
                        v-for="(item, i) in [
                          0, 40, 160, 300, 500, 700, 1000, 1300,
                        ]"
                        :key="i"
                        @click="loweight = item"
                        class="fs-14 mb-0 text-left mt-1 search-drop"
                      >
                        {{ item }}
                      </p>
                    </b-col>
                    <b-col v-else>
                      <p
                        @click="
                          highweight = item;
                          search_toggle.weight = false;
                        "
                        v-for="(item, i) in [
                          0, 40, 160, 300, 500, 700, 1000, 1300,
                        ].filter((its) => its > loweight)"
                        :key="i"
                        class="fs-14 mb-0 mt-1 text-right search-drop"
                      >
                        {{ item }}
                      </p>
                    </b-col>
                  </b-row>
                </div>
                <!-- <select
                  v-model="weight"
                  style="background-clip: unset !important"
                  class="rounded-0 form-control text-success"
                >
                  <option value="no" selected disabled>Weight</option>
                  <option value="0-40">0 to 40 KG</option>
                  <option value="40-80">40 to 80 KG</option>
                  <option value="120-160">120 to 160 KG</option>
                </select> -->
              </b-col>
              <b-col class="pr-0 pl-0">
                <!-- <select
                  v-model="price"
                  style="background-clip: unset !important"
                  class="rounded-0 form-control text-success"
                >
                  <option value="no" selected disabled>Price</option>
                  <option value="20000 40000">20,000-40,000</option>
                  <option value="60000-80000">60,000-80,000</option>
                  <option value="100000-150000">100,000-150,000</option>
                  <option value="200000-300000">200,000 250,000</option>
                  <option value=" 300000-500000">300,000 500,000</option>
                  <option value="1000000-9000000">1,000,000 and above</option>
                </select> -->

                <div
                  @click="
                    search_toggle.price = !search_toggle.price;
                    search_toggle.weight = false;
                  "
                  style="background-clip: unset !important; cursor: pointer"
                  class="text-center rounded-0 form-control"
                >
                  <span>{{
                    lowprice != "" && highprice != ""
                      ? `${lowprice}-${
                          highprice == 10000000 ? "1000000+" : highprice
                        }  Pkr`
                      : "Price (pkr)"
                  }}</span>
                </div>
                <div
                  v-if="search_toggle.price == true"
                  style="
                    max-height: 120px;
                    overflow-x: hidden;
                    overflow-y: auto;
                  "
                  class="p-2 bg-white"
                >
                  <b-row>
                    <b-col md="6">
                      <b-input
                        v-model="lowprice"
                        type="number"
                        placeholder="min"
                      ></b-input>
                    </b-col>
                    <b-col md="6">
                      <b-input
                        v-model="highprice"
                        type="number"
                        placeholder="max "
                      ></b-input>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col v-if="lowprice == ''">
                      <p
                        @click="lowprice = 20000"
                        v-for="(item, i) in [
                          20000, 60000, 100000, 200000, 300000, 1000000,
                        ].filter((its) => its > lowprice)"
                        :key="i"
                        class="search-drop mt-1 fs-14 mb-0 text-left"
                      >
                        {{ item }}
                      </p>
                      <!-- <p
                        @click="lowprice = 60000"
                        class="search-drop fs-14 mb-0 mt-0 text-left"
                      >
                        60000
                      </p>
                      <p
                        @click="lowprice = 100000"
                        class="fs-14 mb-0 mt-0 text-left"
                      >
                        100000
                      </p>
                      <p
                        @click="lowprice = 200000"
                        class="fs-14 mb-0 mt-0 text-left"
                      >
                        200000
                      </p>
                      <p
                        @click="lowprice = 300000"
                        class="fs-14 mb-0 mt-0 text-left"
                      >
                        300000
                      </p>
                      <p
                        @click="lowprice = 1000000"
                        class="fs-14 mb-0 mt-0 text-left"
                      >
                        1000000
                      </p> -->
                    </b-col>
                    <b-col v-else>
                      <p
                        @click="
                          () => {
                            highprice = item;
                            search_toggle.price = false;
                          }
                        "
                        v-for="(item, i) in [
                          20000, 60000, 100000, 200000, 300000, 1000000,
                          10000000,
                        ].filter((its) => its > lowprice)"
                        :key="i"
                        class="fs-14 mb-0 mt-1 text-right search-drop"
                      >
                        {{ item == 10000000 ? "1000000+" : item }}
                      </p>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
              <b-col class="pr-0 pl-0">
                <!-- <b-input type="date" v-model="start_date"></b-input> -->
                <a-range-picker @change="changeDate" />
              </b-col>
              <b-col class="pr-0 pl-0 text-left">
                <b-button @click="finalSearch" variant="success" block>
                  <span class="fa fa-search"></span>
                  Search
                </b-button>
              </b-col>
            </b-row>

            <b-modal id="sell-dialog" hide-footer="" centered>
              <template v-slot:modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5>Upload your Ad</h5>
                <b-button size="sm" variant="outline-success" @click="close()">
                  Cancel
                </b-button>
              </template>
              <div
                style="display: flex; cursor: pointer"
                @click="routeit('AUDIO')"
              >
                <img src="img/sellmodal/dslr-camera.png" width="60" alt="" />
                <h5 class="mt-3 ml-3">Upload Image</h5>
              </div>
              <div
                style="display: flex; cursor: pointer"
                @click="routeit('VIDEO')"
                class="mb-4"
              >
                <img src="img/sellmodal/video-camera.png" width="60" alt="" />
                <h5 class="mt-3 ml-3">Upload Video</h5>
              </div>
            </b-modal>
          </b-container>
          <!-- <h4>
             Hi there the work
           </h4>

           <h6>So the work</h6> -->
        </b-container>
      </div>
    </b-container>

    <b-container
      class="d-block d-md-none d-lg-none pr-0 pl-0"
      fluid
      style="position: relative"
    >
      <div>
        <video style="min-height: 273px" autoplay muted loop id="myVideo">
          <source
            src="https://firebasestorage.googleapis.com/v0/b/qurbaniapp-24a10.appspot.com/o/Web-Video-another.mp4?alt=media&token=d1df983f-a324-4f01-ba97-054f378dfbaf"
          />
          Your browser does not support HTML5 video.
        </video>
      </div>
      <div
        class="opd"
        style="background:-webkit-linear-gradient(left , rgb(0, 0, 0, 0.4) , rgb(0, 0, 0, 0.4) 100%);!important;"
      >
        <b-container fluid class="pr-0 pl-0">
          <b-container class="mt-md-5 mt-0">
            <div class="p-4 ml-auto mr-auto" style="max-width: 600px">
              <p class="d-block d-md-none d-lg-none text-white">
                BUY AND SELL LIVESTOCK ANYWHERE IN PAKISTAN FOR FREE WITH
                QURBANI APP
              </p>
              <router-link to="/all_posts">
                <b-button
                  @mouseover="buy = true"
                  @mouseleave="buy = false"
                  :variant="buy == false ? 'outline-light' : 'success'"
                  size="sm"
                  >Buy</b-button
                >
              </router-link>
              <b-button
                size="sm"
                @mouseover="sell = true"
                @mouseleave="sell = false"
                :variant="sell == false ? 'outline-light' : 'success'"
                class="ml-2 mb"
                @click="showModal()"
                >Sell</b-button
              >
            </div>
            <div class="mt-1 d-flex justify-content-around">
              <a
                href="https://apps.apple.com/pk/app/qurbani-app/id1520463934"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  type="button"
                  style="background-color: black"
                  class="
                    btn btn-xs btn-wide
                    transition-3d-hover
                    text-left
                    mb-2
                    mr-1
                  "
                >
                  <span class="media align-items-center">
                    <span class="fab fa-apple fa-2x mr-3 text-white"></span>
                    <span class="media-body">
                      <span class="d-block text-white" style="font-size: 10px"
                        >Download on
                      </span>
                      <strong
                        class="font-size-1 text-white"
                        style="font-size: 10px"
                        >App Store</strong
                      >
                    </span>
                  </span>
                </button>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.plan9.qurbaniapps.qurbani"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  type="button"
                  style="background-color: black"
                  class="
                    btn btn-xs btn-wide
                    transition-3d-hover
                    text-left
                    mb-2
                    mr-1
                  "
                >
                  <span class="media align-items-center">
                    <span
                      class="fab fa-google-play fa-2x mr-3 text-white"
                    ></span>
                    <span class="media-body">
                      <span class="d-block text-white" style="font-size: 10px"
                        >Download on
                      </span>
                      <strong
                        class="font-size-1 text-white"
                        style="font-size: 10px"
                        >Google Play</strong
                      >
                    </span>
                  </span>
                </button>
              </a>
              <!-- <a
                href="https://play.google.com/store/apps/details?id=com.plan9.qurbaniapps.qurbani"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  type="button"
                  style="background-color: black"
                  class="btn btn-xs btn-wide transition-3d-hover text-left mb-2 mr-1"
                >
                  <span class="media align-items-center">
                    <span
                      class="fab fa-google-play fa-2x mr-3 text-white"
                    ></span>
                    <span class="media-body">
                      <span class="d-block text-white">Get it on</span>
                      <strong class="font-size-1 text-white"
                        >Google Play</strong
                      >
                    </span>
                  </span>
                </button>
              </a> -->
            </div>

            <!-- <b-modal id="sell-dialog" hide-footer="">
              <template v-slot:modal-header="{ close }">
                <h5>Upload your Ad</h5>
                <b-button size="sm" variant="outline-success" @click="close()">
                  Cancel
                </b-button>
              </template>
              <div
                style="display: flex; cursor: pointer"
                @click="routeit('AUDIO')"
              >
                <img src="img/sellmodal/dslr-camera.png" width="60" alt="" />
                <h5 class="mt-3 ml-3">Upload Image</h5>
              </div>
              <div
                style="display: flex; cursor: pointer"
                @click="routeit('VIDEO')"
                class="mb-4"
              >
                <img src="img/sellmodal/video-camera.png" width="60" alt="" />
                <h5 class="mt-3 ml-3">Upload Video</h5>
              </div>
            </b-modal> -->
          </b-container>
        </b-container>
      </div>
    </b-container>

    <b-container fluid class="qurbani-cards">
      <b-card>
        <b-row class="d-flex d-md-none d-lg-none">
          <b-col
            cols="12"
            @mouseleave="search_toggle.desc = false"
            class="pr-0 pl-0"
          >
            <!-- @focus="search_toggle.desc = true" -->
            <b-input
              class="rounded-0 text-center mb-2"
              v-model="search"
              style="background-clip: unset !important"
              placeholder="Search"
              @focus="search_toggle.city = false"
            >
            </b-input>
            <div
              v-if="search_toggle.desc == true"
              style="min-height: 100px; max-height: 120px; overflow-y: auto"
              class="p-2 bg-white"
            >
              <h6
                v-for="(item, i) in descriptions"
                :key="i"
                @click="descriptionResult(item)"
                class="text-left cursor-pointer"
              >
                {{
                  item.split(" ").length > 3
                    ? `${item.split(" ")[0]} ${item.split(" ")[1]}...`
                    : item
                }}
              </h6>
            </div>
          </b-col>
          <b-col cols="12" class="pr-0 pl-0 mb-2">
            <!-- @focus="search_toggle.city = true" -->

            <b-input
              class="rounded-0 text-center"
              style="background-clip: unset !important"
              placeholder="City"
              v-model="search_city"
              @focus="search_toggle.desc = false"
            >
            </b-input>
            <div
              v-if="search_toggle.city == true"
              style="min-height: 100px; max-height: 120px; overflow-y: auto"
              class="p-2 bg-white"
            >
              <h6
                v-for="(item, i) in cities"
                :key="i"
                @click="cityResult(item)"
                class="text-left cursor-pointer"
              >
                {{
                  item.split(" ").length > 3
                    ? `${item.split(" ")[0]} ${item.split(" ")[1]}...`
                    : item
                }}
              </h6>
            </div>
          </b-col>
          <b-col cols="12" class="pr-0 pl-0 text-center mb-2">
            <div
              @click="search_toggle.weight = !search_toggle.weight"
              style="background-clip: unset !important; cursor: pointer"
              class="text-center rounded-0 form-control"
            >
              <span>{{
                loweight != "" && highweight != ""
                  ? `${loweight}-${highweight} kg`
                  : "Weight (kg)"
              }}</span>
            </div>
            <div
              v-if="search_toggle.weight == true"
              style="max-height: 120px; overflow-x: hidden; overflow-y: auto"
              class="p-2 bg-white"
            >
              <b-row>
                <b-col md="6" cols="6">
                  <b-input
                    v-model="loweight"
                    type="number"
                    placeholder="min"
                    max="900"
                  ></b-input>
                </b-col>
                <b-col md="6" cols="6">
                  <b-input
                    v-model="highweight"
                    type="number"
                    placeholder="max"
                    max="900"
                  ></b-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col v-if="loweight == ''">
                  <p
                    v-for="(item, i) in [0, 40, 160, 300, 500, 700, 1000, 1300]"
                    :key="i"
                    @click="loweight = item"
                    class="fs-14 mb-0 text-left"
                  >
                    {{ item }}
                  </p>
                </b-col>
                <b-col v-else>
                  <p
                    @click="
                      highweight = item;
                      search_toggle.weight = false;
                    "
                    v-for="(item, i) in [
                      0, 40, 160, 300, 500, 700, 1000, 1300,
                    ].filter((its) => its > loweight)"
                    :key="i"
                    class="fs-14 mb-0 text-right"
                  >
                    {{ item }}
                  </p>
                </b-col>
              </b-row>
            </div>
            <!-- <select
              v-model="weight"
              style="background-clip: unset !important"
              class="rounded-0 form-control text-success"
            >
              <option value="no" selected disabled>Weight</option>
              <option value="0-40">0 to 40 KG</option>
              <option value="40-80">40 to 80 KG</option>
              <option value="120-160">120 to 160 KG</option>
            </select> -->
          </b-col>
          <b-col cols="12" class="pr-0 pl-0 mb-2">
            <b-input
              class="rounded-0 text-center"
              v-model="username"
              style="background-clip: unset !important"
              placeholder="User"
              @focus="search_toggle.city = false"
            >
            </b-input>
            <div
              v-if="search_toggle.username == true"
              style="min-height: 100px; max-height: 120px; overflow-y: auto"
              class="p-2 bg-white"
            >
              <h6
                v-for="(item, i) in users"
                :key="i"
                @click="usernameResult(item)"
                class="text-left cursor-pointer"
              >
                {{
                  item.split(" ").length > 2
                    ? `${item.split(" ")[0]} ${item.split(" ")[1]}...`
                    : item
                }}
              </h6>
            </div>
          </b-col>
          <b-col cols="12" class="pr-0 pl-0 mb-2">
            <a-range-picker @change="changeDate" />
          </b-col>
          <b-col cols="12" class="pr-0 pl-0 mb-2">
            <div
              @click="search_toggle.price = !search_toggle.price"
              style="background-clip: unset !important; cursor: pointer"
              class="text-center rounded-0 form-control"
            >
              <span>{{
                lowprice != "" && highprice != ""
                  ? `${lowprice}-${
                      highprice == 10000000 ? "1000000+" : highprice
                    }  Pkr`
                  : "Price (pkr)"
              }}</span>
            </div>
            <div
              v-if="search_toggle.price == true"
              style="max-height: 120px; overflow-x: hidden; overflow-y: auto"
              class="p-2 bg-white"
            >
              <b-row>
                <b-col md="6" cols="6">
                  <b-input
                    v-model="lowprice"
                    type="number"
                    placeholder="min"
                  ></b-input>
                </b-col>
                <b-col md="6" cols="6">
                  <b-input
                    v-model="highprice"
                    type="number"
                    placeholder="max "
                  ></b-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col v-if="lowprice == ''">
                  <p @click="lowprice = 20000" class="fs-14 mb-0 text-left">
                    20000
                  </p>
                  <p
                    @click="lowprice = 60000"
                    class="fs-14 mb-0 mt-0 text-left"
                  >
                    60000
                  </p>
                  <p
                    @click="lowprice = 100000"
                    class="fs-14 mb-0 mt-0 text-left"
                  >
                    100000
                  </p>
                  <p
                    @click="lowprice = 200000"
                    class="fs-14 mb-0 mt-0 text-left"
                  >
                    200000
                  </p>
                  <p
                    @click="lowprice = 300000"
                    class="fs-14 mb-0 mt-0 text-left"
                  >
                    300000
                  </p>
                  <p
                    @click="lowprice = 1000000"
                    class="fs-14 mb-0 mt-0 text-left"
                  >
                    1000000
                  </p>
                </b-col>
                <b-col v-else>
                  <p
                    @click="
                      () => {
                        highprice = item;
                        search_toggle.price = false;
                      }
                    "
                    v-for="(item, i) in [
                      20000, 60000, 100000, 200000, 300000, 1000000, 10000000,
                    ].filter((its) => its > lowprice)"
                    :key="i"
                    class="fs-14 mb-0 text-right"
                  >
                    {{ item == 10000000 ? "1000000+" : item }}
                  </p>
                </b-col>
              </b-row>
            </div>
            <!-- <select
              v-model="price"
              style="background-clip: unset !important"
              class="rounded-0 form-control text-success"
            >
              <option value="no" selected disabled>Price</option>
              <option value="20000 40000">20,000-40,000</option>
              <option value="60000-80000">60,000-80,000</option>
              <option value="100000-150000">100,000-150,000</option>
              <option value="200000-300000">200,000 250,000</option>
              <option value=" 300000-500000">300,000 500,000</option>
              <option value="1000000-9000000">1,000,000 and above</option>
            </select> -->

            <!-- Maxmum will start from     


                <div
                  @click="search_toggle.price = !search_toggle.price"
                  style="background-clip: unset !important"
                  class="text-center rounded-0 form-control"
                >
                  <span class="text-success">Price</span>
                </div>
                <div
                  v-if="search_toggle.price == true"
                  style="min-height: 100px"
                  class="p-2 bg-white"
                >
                  <b-input
                    v-model="lowprice"
                    type="number"
                    placeholder="Min price"
                  ></b-input>

                  <b-input
                    v-model="highprice"
                    type="number"
                    placeholder="Max price"
                  ></b-input> -->
            <!-- </div> -->
          </b-col>
          <b-col class="pr-0 pl-0 text-left">
            <b-button @click="finalSearch" variant="success" block>
              <span class="fa fa-search"></span>
              Search
            </b-button>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col cols="12" md="4">
          <b-card>
            <b-img src="./img/landing/post.png"></b-img>
            <!-- <b-avatar style="background: black" size="4rem"> -->
            <!-- <i class="fas fa-clipboard-list fa-2x"></i> -->
            <!-- </b-avatar> -->

            <h6 class="text-dark mt-2">
              <b>Post Your Animals On Qurbani App</b>
            </h6>
            <div class="text-left fs-14">
              <i class="fas fa-check"></i> Upload your animal within 10 secs
            </div>
            <div class="text-left fs-14">
              <i class="fas fa-check"></i> Get genuine offers from verified
              buyers
            </div>
            <div class="text-left fs-14">
              <i class="fas fa-check"></i> Sell your animals at the best market
              price
            </div>
          </b-card>
        </b-col>
        <b-col cols="12" md="4">
          <b-card>
            <b-img src="./img/landing/sell.png"></b-img>

            <!-- <b-avatar style="background: black" size="4rem">
              <i class="fas fa-clipboard-list fa-2x"></i>
            </b-avatar> -->

            <h6 class="text-dark mt-2">
              <b>Qurbani App Sell It For Me</b>
            </h6>
            <div class="text-left fs-14">
              <i class="fas fa-check"></i> Dedicated expert to sell your animals
            </div>
            <div class="text-left fs-14">
              <i class="fas fa-check"></i> We guarantee safe and secure
              transactions
            </div>
            <div class="text-left fs-14">
              <i class="fas fa-check"></i> We ensure safe and timely
              transportation
            </div>
          </b-card>
        </b-col>
        <b-col cols="12" md="4">
          <b-card>
            <b-img src="./img/landing/browse.png"></b-img>

            <!-- <b-avatar style="background: black" size="4rem">
              <i class="fas fa-clipboard-list fa-2x"></i>
            </b-avatar> -->

            <h6 class="text-dark mt-2"><b>Browse Animals from Anywhere</b></h6>
            <div class="text-left fs-14">
              <i class="fas fa-check"></i> Browse animals from anywhere, anytime
            </div>
            <div class="text-left fs-14">
              <i class="fas fa-check"></i> Access beautiful animals 24/7
            </div>
            <div class="text-left fs-14">
              <i class="fas fa-check"></i> Best animals at market prices
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <!-- <Slider style="margin-top: 4rem" /> -->
    <testSection />
    <!-- <b-modal id="modal-video" hide-header hide-footer  > -->
         <!-- <video style="width:100%" height="240"  muted ref="opd"> -->
            <!-- <img style="width:100%" height="240" src="/qurbani.gif" /> -->
         <!-- </video> -->
      <!-- </b-modal> -->
  </div>
</template>
<script>
import Slider from "../components/Slider.vue";
import testSection from "../components/test_Section.vue";
import testTopNav from "../components/testTopNav.vue";
import { RepositoryFactory } from "../Repository/RepositoryFactory";
const SearchRepository = RepositoryFactory.get("search_repository");
import { mapGetters } from "vuex";
// import TermsAndConditions from './TermsAndConditions.vue'
export default {
  components: {
    Slider,
    testSection,
    testTopNav,
  },

  data() {
    return {
      descriptions: [],
      users: [],
      cities: [],
      tempdate: "",
      search_toggle: {
        price: false,
        username: false,
        weight: false,
        city: false,
        desc: false,
      },
      type: "image",
      buy: true,
      sell: false,
      search: "",
      search_city: "",
      lowprice: "",
      highprice: "",
      loweight: "",
      highweight: "",
      username: "",
      weight: "no",
      price: "no",
      start_date: "no",
      end_date: "no",
    };
  },
  watch: {
    username(value) {
      console.log(value);
      if (value.length > 2) {
        this.usernameResults();
      } else {
        this.search_toggle.username = false;
      }
      // or generate/simulate a native events (not sure how, but its outside Vue's realm I think
    },
    search(value) {
      console.log(value);
      if (value.length > 2) {
        this.searchResults();
      } else {
        this.search_toggle.desc = false;
      }
      // or generate/simulate a native events (not sure how, but its outside Vue's realm I think
    },
    search_city(value) {
      console.log(value);
      if (value.length > 3) {
        this.searchCity();
      } else {
        this.search_toggle.city = false;
      }
      // or generate/simulate a native events (not sure how, but its outside Vue's realm I think
    },
  },
  computed: {
    ...mapGetters(["loggedUser"]),
  },
  methods: {
    changeDate(date, dateString) {
      this.tempdate = date;
      this.start_date = dateString[0];
      this.end_date = dateString[1];
    },
    async finalSearch() {
      let { data } = await SearchRepository.search_results({
        page: "1",
        search_field: this.search == "" ? "no" : this.search,
        city: this.search_city == "" ? "no" : this.search_city,
        start_date: this.start_date,
        end_date: this.end_date,
        user_name: this.username == "" ? "no" : this.username,
        price:
          this.lowprice != "" && this.highprice != ""
            ? this.lowprice + "-" + this.highprice
            : "no",
        weight:
          this.loweight != "" && this.highweight != ""
            ? this.loweight + "-" + this.highweight
            : "no",
      });
      console.log(data);
      this.$router.push({
        name: "SearchResults",
        params: {
          data: data,
          search_params: {
            search_field: this.search == "" ? "no" : this.search,
            city: this.search_city == "" ? "no" : this.search_city,
            username: this.username == "" ? "no" : this.username,
            tempdate: this.tempdate,
            start_date: this.start_date,
            end_date: this.end_date,
            price:
              this.lowprice != "" && this.highprice != ""
                ? this.lowprice + "-" + this.highprice
                : "no",
            weight:
              this.loweight != "" && this.highweight != ""
                ? this.loweight + "-" + this.highweight
                : "no",
          },
        },
      });
      // this.descriptions = data;
      // this.search_toggle.desc = true;
    },
    descriptionResult(item) {
      this.search =
        item.split(" ").length > 3
          ? `${item.split(" ")[0]} ${item.split(" ")[1]}...`
          : item;
      console.log(this.search);
      this.search_toggle.desc = false;
    },
    usernameResult(item) {
      this.username =
        item.split(" ").length > 3
          ? `${item.split(" ")[0]} ${item.split(" ")[1]}...`
          : item;
      console.log(this.search);
      this.search_toggle.username = false;
    },
    cityResult(item) {
      this.search_city =
        item.split(" ").length > 3
          ? `${item.split(" ")[0]} ${item.split(" ")[1]}...`
          : item;
      console.log(this.search);
      this.search_toggle.city = false;
    },
    async searchCity() {
      console.log(this.search);
      let { data } = await SearchRepository.search_city({
        city: this.search_city,
      });
      console.log(data);
      this.cities = data;
      this.search_toggle.city = true;
    },
    async searchResults() {
      console.log(this.search);
      let { data } = await SearchRepository.search_description({
        search_field: this.search,
      });
      console.log(data);
      this.descriptions = data;
      this.search_toggle.desc = true;
    },
    async usernameResults() {
      console.log(this.search);
      let { data } = await SearchRepository.search_user({
        search_field: this.username,
      });
      console.log(data);
      this.users = data;
      this.search_toggle.username = true;
    },
    showModal() {
      console.log(this.loggedUser);
      if (this.loggedUser != null) {
        this.$bvModal.show("sell-dialog");
      } else {
        this.$store.commit("setNotifications", {
          message: "Login before creating Ads",
          type: "error",
        });
      }
    },
    routeit(arg) {
      this.type = arg;
      this.$bvModal.hide("sell-dialog");
      this.$router.push({ name: "NewPost", params: { type: arg } });
    },
  },
  mounted() {
    // setTimeout(() => {
    // this.$bvModal.show('modal-video')
      
    // }, 3500);
    // setTimeout(() => {
    //    console.log("plays")
    //    this.$refs['opd'].play()
    // }, 5500);
    // setTimeout(() => {
    //  this.$bvModal.hide('modal-video')
    // }, 8500);
  },
  async created() {
    // let { data } = await SearchRepository.search_city({
    //   city: "karachi",
    // });
    // let resp = await SearchRepository.search_description({
    //   search_field: "cow",
    // });
    // console.log(data);
    // console.log(resp);
    //  this.$bvModal.show('modal-video')
  },
};
</script>



<style >
#modal-video .modal-dialog .modal-content .modal-body {
  padding: 0px !important;
}
.main-landing-back {
  background: transparent
    linear-gradient(180deg, #000000 0%, #000000 21%, #1b5934 65%, #309f5d 100%)
    0% 0% no-repeat padding-box;
}
.main-landing-black {
  background: #000000;
}
.top-notification-bar {
  border-bottom: 1px solid #a9aaa9;
}
.opd {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
#myVideo {
  /* position: absolute; */
  /* right: 0;
  bottom: 0; */
  width: 100% !important;
  max-height: 500px !important;
  object-fit: fill;

  /* min-height: 20%; */
}
.ant-calendar-range-picker-separator {
  margin-top: 10px !important;
}
</style>