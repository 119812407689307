<template>
  <div class="ml-auto mr-auto col-md-8 mb-5">
    <h3 class="left-aligned text-success">
      <!-- <b> Healthy and Fresh Sadqah and Aqiqa Stock </b> -->
      <p v-if="loggedUser == null" class="text-danger">
        You will be redirect in a bit to login
      </p>
    </h3>
    <div class="row">
      <div class="col-md-12">
        <img
          :src="
            order.imagekey != ''
              ? 'https://s3.amazonaws.com/qurbaniimages/' +
                replacer(order.imagekey)
              : 'img/section1.png'
          "
          alt=""
        />
      </div>
      <div class="col-md-12">
        <h3>{{ order.nickname }}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        Availibility :
        <span
          :class="order.sold_status == 's' ? 'text-danger' : 'text-success'"
          >{{ order.sold_status == "s" ? "Out of Stock" : "In Stock " }}</span
        >
      </div>
      <div class="col-md-6">
        <b>
          {{
            order.converter == 0 || order.converter == null
              ? ` Rs: ${order.price}`
              : `${Math.round(order.price / order.converter)} ${order.currency}`
          }}
        </b>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">Ad Id {{ order.id }}</div>
      <div class="col-md-6">
        Weight {{ order.weight }} {{ order.weight_unit }}
      </div>

      <div class="col-md-6">{{ order.category }}</div>
    </div>
    <div class="row">
      <div class="col-md-12 left-aligned">
        <span>Would you Like animal to be delivered ?</span> <br />
        <b-button
          pill
          :variant="obj.order_delivered == true ? success : outline_success"
          @click="obj.order_delivered = true"
          >Yes</b-button
        >
        <b-button
          class="ml-2"
          pill
          :variant="obj.order_delivered == false ? success : outline_success"
          @click="obj.order_delivered = false"
          >No</b-button
        >
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-12 left-aligned">
        <span>What type of order would you like ?</span> <br />
        <b-button
          pill
          :variant="obj.sadqa_aqiqa == 'sadqah' ? success : outline_success"
          @click="obj.sadqa_aqiqa = 'sadqah'"
          >Sadqah</b-button
        >
        <b-button
          class="ml-2"
          pill
          :variant="obj.sadqa_aqiqa == 'aqiqa' ? success : outline_success"
          @click="obj.sadqa_aqiqa = 'aqiqa'"
          >Aqiqa</b-button
        >
      </div>
    </div>
    <div class="row mt-2" v-if="obj.order_delivered == true">
      <div class="col-md-12 left-aligned">
        <span style="font-size: 12px">Delievery Charges: </span
        ><span><b>1000</b></span>
      </div>
    </div>
    <form @submit.prevent="placeOrder">
      <div class="row mt-2">
        <div class="col-md-12 left-aligned">
          <span>Enter your details </span>
        </div>
        <div class="col-md-12 mt-3 left-aligned">
          <b-input
            placeholder="Enter your name"
            v-model="obj.user_name"
            type="text"
            required
            style="background: #f5f5f5"
          ></b-input>
        </div>
        <div class="col-md-12 mt-3 left-aligned">
          <b-input
            placeholder="Enter your Phone Number"
            v-model="obj.user_phone"
            type="text"
            required
            style="background: #f5f5f5"
          ></b-input>
        </div>
        <div class="col-md-12 mt-3 left-aligned">
          <b-input
            placeholder="Enter your email address"
            v-model="obj.user_address"
            required
            type="text"
            style="background: #f5f5f5"
          ></b-input>
        </div>

        <!-- <div class="col-md-12 mt-3 left-aligned">
          <b-input
            placeholder="Enter your promo code"
            v-model="promo_code"
            required
            type="text"
            style="background: #f5f5f5"
          ></b-input>
        </div> -->
        <!-- <p
          class="mt-3"
          v-if="returnValidPromo != '' && returnValidPromo != 'NA'"
        >
          {{ returnValidPromo }} your discounted order price
        </p> -->
      </div>
      <div class="row mt-2">
        <div class="col-md-12">
          <b-button variant="success" type="submit">Order</b-button>
        </div>
      </div>
    </form>

    <div class="row mt-2 bg-success p-3">
      <div class="col-md-12 left-aligned">
        <span class="text-white"> Qurbani App Promises</span> <br /><br />
        <span class="text-white"
          >1) Affordable Rate and High Quality Animal </span
        ><br /><br />
        <span class="text-white">2) Hygiene Cautious</span>
      </div>
    </div>
    <b-modal hideHeader hideFooter id="order-confirmed" title="BootstrapVue">
      <!-- <p class="my-4">Hello from modal!</p> -->
      <div class="p-5">
        <h4 class="text-center">THANKYOU</h4>
        <h5 class="text-center">
          We have received your order ! Your order will be processed soon …
        </h5>
        <h5 class="text-center mt-4">
          You can track progress of your order in "My Order" section
        </h5>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PostCard from "../components/PostCard.vue";
import { RepositoryFactory } from "../Repository/RepositoryFactory";
const OrderRepository = RepositoryFactory.get("order_repository");
const UserRepository = RepositoryFactory.get("user_repository");
const PostsRepository = RepositoryFactory.get("posts_repository");
const PromoRepository = RepositoryFactory.get("promo_repository");

export default {
  props: ["AllPostFlag", "order"],
  components: {
    PostCard,
  },
  data() {
    return {
      outline_success: "outline-success",
      success: "success",
      promo_code: "",
      obj: {
        post_id: "",
        user_id: "",
        total_price: "",
        user_name: "",
        user_phone: "",
        user_address: "",
        delivery: "",
        order_video: "",
        order_distributed: "",
        order_delivered: false,
        sadqa_aqiqa: "sadqah",
      },
    };
  },
  methods: {
    async placeOrder() {
      if (this.promo_code != "") {
        if (this.promo_code == this.promo.promo_code) {
          if (this.promo.promo_type == "Flat") {
            this.obj.total_price = this.order.price - this.promo.promo_value;
          } else {
            this.obj.total_price =
              this.order.price -
              (this.promo.promo_value / 100) * this.order.price;
          }
        } else {
          this.$store.commit("setNotifications", {
            message: "Use valid promo code",
            type: "error",
          });
        }
      }

      if (this.order.sold_status != "s") {
        if (this.loggedUser != null) {
          this.obj.post_id = this.order.id;
          this.obj.user_id = this.loggedUser.id;

          let { data } = await OrderRepository.new_order(this.obj);

          if (data != -1) {
            this.changeSale(this.obj.post_id);
            this.$store.commit("setNotifications", {
              message: "Order created successfully",
              type: "success",
            });
            this.$bvModal.show("order-confirmed");
            //
            localStorage.removeItem("user_resources");
            let { data } = await UserRepository.get_user_resources({
              user_id: this.loggedUser.id,
            });
            this.$store.commit("setUserResources", data);
            if (this.AllPostFlag == true) {
              this.$router.push({
                name: "Dashboard",
                params: { active_prop: 4 },
              });
            } else {
              setInterval(() => {
                this.$emit("return-order", 1);
              }, 5000);
            }
          } else {
            this.$store.commit("setNotifications", {
              message: "Problems in creating New order",
              type: "error",
            });
          }
        } else {
          this.$store.commit("setNotifications", {
            message: "Before placing order login",
            type: "error",
          });
        }
      } else {
        this.$store.commit("setNotifications", {
          message: "Already sold",
          type: "error",
        });
      }
    },
    async changeSale(id) {
      let resp = await PostsRepository.change_sale_status({
        post_id: id,
        sold_status: "s",
      });
      localStorage.removeItem("user_resources");
      var { data } = await UserRepository.get_user_resources({
        user_id: this.loggedUser.id,
      });
      this.$store.commit("setUserResources", data);
    },
    replacer(item) {
      if (item.includes("cropped")) {
        let res = item.split(",");
        if (res.length == 1) {
          return res[0].replace("+", "%252B");
        } else if (res.length > 1) {
          return res[1].replace("+", "%252B");
        }
      } else if (item.includes("png")) {
        let res = item + ".png";
        return res;
      } else if (item.includes("jpg")) {
        let res = item + ".jpeg";
        return res;
      } else if (item.split(",").length > 0) {
        let res = item.split(",");
        if (res.length > 1) {
          let updater = res[1].replace("+", "%252B");
          return updater;
        } else {
          let updater = res[0].replace("+", "%252B");
          return updater;
        }
      } else {
        let updater = item.replace("+", "%252B");
        return updater;
      }
    },
  },
  computed: {
    ...mapGetters(["posts", "loggedUser", "promo"]),
    returnValidPromo() {
      if (this.promo_code != "") {
        if (this.promo_code == this.promo.promo_code) {
          if (this.promo.promo_type == "Flat") {
            return (this.obj.total_price =
              this.order.price - this.promo.promo_value);
          } else {
            return (this.obj.total_price =
              this.order.price -
              (this.promo.promo_value / 100) * this.order.price);
          }
        } else {
          return "NA";
        }
      } else {
        return "NA";
      }
    },
  },
  created() {
    setTimeout(() => {
      if (this.loggedUser == null) {
        localStorage.setItem("order", JSON.stringify(this.order));
        this.$router.push("/login");
      }
    }, 2000);
    localStorage.setItem("route", "Sadqah");
  },
  mounted() {
    localStorage.setItem("route", "Sadqah");
  },
};
</script>

<style></style>
