<template>
  <div>
    <b-container class="pr-0 pl-0 m-0">
      <div
        style="
          border-bottom-right-radius: 1rem;
          border-bottom-left-radius: 1rem;
          min-height: 200px;
          background: linear-gradient(180deg, #000000 0%, #309f5d 100%) 0% 0%
            no-repeat padding-box;
        "
        class="p-1"
      >
        <b-row>
          <b-col md="8" style="align-self: center">
            <h2 class="text-white">Ijtmae Qurbani via Qurbani App</h2>
          </b-col>
          <b-col md="4">
            <img
              class="float-md-left"
              style="width: 100px"
              src="img/myprofile/sheep.png"
              alt=""
            />
          </b-col>
        </b-row>
      </div>
    </b-container>
    <b-container fluid style="background: #f5f5f5">
      <div v-if="step == 0">
        <h3 class="pt-4 text-center text-success">
          IJTAMAE QURBANI BY QURBANI APP
        </h3>
        <h5>
          Qurbani App is providing Ijtamae Qurbani this Eid. We have some of the
          best breeds from around the World. Our Ijtamai Qurbani Service will
          deliver meat to the most underprivileged and deserving communities of
          twin cities. Other than this this year COVID-19 pandemic has hit
          country badly and there are numbers of people who are jobless and
          hungry. This is your chance to help them, play your part for the well
          being of your people to the most underprivileged and deserving
          communities in twin cities. Covid-19 pandemic this year has severely
          affected the livelihoods of thousands of families in disadvantaged
          areas like Dhak Dalal, Dhok Hassu, Fauji Colony and Girja Road. We are
          working with a number of local social welfare organisation to maintain
          a database of widows, divorcees, orphans, daily wage earners, families
          living on rent and families of more than five with a single wage
          earner. Your Qurbani this year can be a source of nutritious meat for
          these communities. Help us, Help them!
        </h5>
        <b-button variant="success" @click="step = 1">Next</b-button>
      </div>
      <div v-else-if="step == 1">
        <b-container>
          <b-row>
            <b-col>
              <b-card>
                <b-row>
                  <b-col md="12" cols="12">
                    <span>Convert to view in your currency</span> <br />
                    <b-button
                      pill
                      :variant="
                        newOrder.order_currency == 'PKR'
                          ? 'success'
                          : 'outline-success'
                      "
                      @click="
                        newOrder.order_currency = 'PKR';
                        currencyConversion();
                      "
                      >PKR</b-button
                    >
                    <b-button
                      class="ml-2"
                      pill
                      :variant="
                        newOrder.order_currency == 'USD'
                          ? 'success'
                          : 'outline-success'
                      "
                      @click="
                        newOrder.order_currency = 'USD';
                        currencyConversion();
                      "
                      >USD</b-button
                    >
                    <b-button
                      class="ml-2"
                      pill
                      :variant="
                        newOrder.order_currency == 'GBP'
                          ? 'success'
                          : 'outline-success'
                      "
                      @click="
                        newOrder.order_currency = 'GBP';
                        currencyConversion();
                      "
                      >GBP</b-button
                    >
                  </b-col>
                </b-row>
                <div>
                  <h3 class="mt-3 text-center text-success">SELECT CATEGORY</h3>
                </div>
                <b-container class="pr-0 pl-0" fluid>
                  <b-row>
                    <b-col md="4" cols="12">
                      <b-card
                        @click="newOrder.total_price = packages[0]"
                        tag="article"
                        :class="[
                          'points shadow mt-5 pb-4 c col-md-12 mb-2 ml-auto mr-auto p-0',
                          newOrder.total_price == this.packages[0]
                            ? 'selected_price'
                            : '',
                        ]"
                      >
                        <template v-slot:header>
                          <div
                            class="col-md-12 col-11 ml-auto p-2 mr-auto"
                            style="
                              border-top-right-radius: 1rem;
                              border-top-left-radius: 1rem;
                            "
                          >
                            <div class="p-2">
                              <b-img
                                src="img/ijtmae/cow.png"
                                width="80"
                              ></b-img>
                              <p
                                style="font-size: 15px"
                                class="text-success bolds"
                              >
                                Ijtamae Hissa With Meat Distributed To Poor
                                People
                              </p>
                            </div>
                          </div>
                        </template>

                        <div class="text-left text-success">
                          <div v-if="currLoad == true" class="text-center">
                            <b-spinner variant="success"></b-spinner>
                          </div>
                          <h3 v-else class="text-success text-center">
                            {{ this.newOrder.order_currency }}
                            {{ this.packages[0] }}
                          </h3>

                          1) Animal slaughtered within the first two days of
                          Eid.
                          <br />
                          2) Meat distributed within the first three days.
                          <br />
                          3) Receipt of Order confirmation. <br />
                          4) Live updates on WhatsApp number <br />
                          5) Images and videos of slaughtered animal with
                          takbeer of your name
                        </div>
                      </b-card>
                    </b-col>
                    <b-col md="4" cols="12">
                      <b-card
                        @click="newOrder.total_price = packages[1]"
                        tag="article"
                        :class="[
                          'points shadow mt-5 pb-4 col-md-12 mb-2 ml-auto mr-auto p-0',
                          newOrder.total_price == this.packages[1]
                            ? 'selected_price'
                            : '',
                        ]"
                      >
                        <template v-slot:header>
                          <div
                            class="col-md-12 col-11 ml-auto p-2 mr-auto"
                            style="
                              border-top-right-radius: 1rem;
                              border-top-left-radius: 1rem;
                            "
                          >
                            <div class="p-2">
                              <b-img
                                src="img/ijtmae/cow_1.png"
                                width="80"
                              ></b-img>
                              <p
                                style="font-size: 15px"
                                class="text-success bolds"
                              >
                                Ijtamae Hissa With Meat Delivery To Home
                              </p>
                            </div>
                          </div>
                        </template>
                        <div class="text-left text-success">
                          <div v-if="currLoad == true" class="text-center">
                            <b-spinner variant="success"></b-spinner>
                          </div>
                          <h3 v-else class="text-success text-center">
                            {{ this.newOrder.order_currency }}
                            {{ this.packages[1] }}
                          </h3>
                          1) Animal slaughtered within the first two days of
                          Eid.
                          <br />
                          2) Meat distributed within the first three days.
                          <br />
                          3) Receipt of Order confirmation. <br />
                          4) Live updates on WhatsApp number <br />
                          5) Images and videos of slaughtered animal with
                          takbeer of your name
                        </div>
                      </b-card>
                    </b-col>
                    <b-col md="4" cols="12">
                      <b-card
                        @click="newOrder.total_price = packages[2]"
                        tag="article"
                        :class="[
                          'points shadow mt-5 pb-4 col-md-12 mb-2 ml-auto mr-auto p-0',
                          newOrder.total_price == this.packages[2]
                            ? 'selected_price'
                            : '',
                        ]"
                      >
                        <template v-slot:header>
                          <div
                            class="col-md-12 col-11 ml-auto p-2 mr-auto"
                            style="
                              border-top-right-radius: 1rem;
                              border-top-left-radius: 1rem;
                            "
                          >
                            <div class="p-2">
                              <b-img
                                src="img/ijtmae/goat.png"
                                width="80"
                              ></b-img>
                              <p
                                style="font-size: 15px"
                                class="text-success bolds"
                              >
                                Qurbani Bakra With Or Without Delivery
                              </p>
                            </div>
                          </div>
                        </template>
                        <div class="text-left text-success">
                          <div v-if="currLoad == true" class="text-center">
                            <b-spinner variant="success"></b-spinner>
                          </div>
                          <h3 v-else class="text-success text-center">
                            {{ this.newOrder.order_currency }}
                            {{ this.packages[2] }}
                          </h3>
                          1) Animal slaughtered within the first two days of
                          Eid.
                          <br />
                          2) Meat distributed within the first three days.
                          <br />
                          3) Receipt of Order confirmation. <br />
                          4) Live updates on WhatsApp number <br />
                          5) Images and videos of slaughtered animal with
                          takbeer of your name
                        </div>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-container>
                <b-button @click="step = 2" variant="success">Next</b-button>
              </b-card>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div v-else-if="step == 2">
        <b-container>
          <b-row>
            <b-col class="dropbackground"> </b-col>
            <b-col>
              <b-card>
                <h3 class="text-center text-success">
                  PLEASE FILL OUT THE FORM BELOW TO ORDER
                </h3>
                <form action="" @submit.prevent="orderNow()">
                  <b-card>
                    <b-container>
                      <b-row>
                        <b-col md="6" cols="12">
                          <b-input
                            required
                            v-model="newOrder.user_name"
                            class="backs"
                            placeholder="Enter Name"
                          ></b-input>
                        </b-col>
                        <b-col md="6" cols="12 mt-md-0 mt-1">
                          <b-input
                            required
                            v-model="newOrder.user_cnic"
                            class="backs"
                            placeholder="Enter CNIC"
                          ></b-input>
                        </b-col>
                      </b-row>
                      <b-row class="mt-0 mt-md-4">
                        <b-col md="6" class="mt-md-0 mt-1" cols="12">
                          <b-input
                            required
                            v-model="newOrder.user_phone"
                            class="backs"
                            placeholder="Enter Phone Number"
                          ></b-input>
                        </b-col>
                        <b-col md="6" cols="12" class="mt-md-0 mt-1">
                          <b-input
                            required
                            v-model="newOrder.user_address"
                            class="backs"
                            placeholder="Enter Address"
                          ></b-input>
                        </b-col>
                      </b-row>
                      <b-row class="mt-0 mt-md-4">
                        <b-col md="6" class="mt-md-0 mt-1" cols="12">
                          <b-input
                            required
                            v-model="newOrder.user_city"
                            class="backs"
                            placeholder="Enter City"
                          ></b-input>
                        </b-col>
                        <b-col md="6" cols="12" class="mt-md-0 mt-1">
                          <b-input
                            required
                            v-model="newOrder.user_country"
                            class="backs"
                            placeholder="Country name"
                          ></b-input>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="12" cols="12">
                          <span>Would you Like animal to be delivered ? </span>
                          <br />
                          <b-button
                            pill
                            :variant="
                              newOrder.delivery == true
                                ? 'success'
                                : 'outline-success'
                            "
                            @click="newOrder.delivery = true"
                            >Yes</b-button
                          >
                          <b-button
                            class="ml-2"
                            pill
                            :variant="
                              newOrder.delivery == false
                                ? 'success'
                                : 'outline-success'
                            "
                            @click="newOrder.delivery = false"
                            >No</b-button
                          >
                        </b-col>
                        <b-col v-if="newOrder.delivery == true">
                          <sub
                            ><b
                              >*1000 Delivery charges will be added in to your
                              total order amount</b
                            >
                          </sub>
                        </b-col>
                      </b-row>
                      <b-button variant="success" @click="step = 3"
                        >Next</b-button
                      >
                    </b-container>
                  </b-card>
                </form>
              </b-card>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div v-else-if="step == 3">
        <h3 class="mt-3 mb-4 text-center text-success">
          PAYMENT OPTIONS FOR YOU
        </h3>

        <b-container class="">
          <b-row class="ml-md-auto mr-md-auto">
            <b-col md="6" cols="12">
              <div
                class="card pl-3 pr-3 pt-1 pb-1"
                style="height: 100%; background: #feea63"
              >
                <b-img
                  class="ml-auto mr-auto"
                  src="img/easy.png"
                  width="200"
                ></b-img>
                <div class="text-success">
                  You can transfer your payment through easypaisa. Just go to
                  any easypaisa shop nearby and ask retailer to transfer
                  specific amount to following number
                </div>
                <div class="text-success" style="margin-top: 2.25rem">
                  <b> Number Details 0306 5401886</b>
                </div>
              </div>
            </b-col>
            <b-col md="6" cols="12">
              <div
                class="card pl-3 pr-3 pt-1 pb-1"
                style="background: #81c784; height: 100%"
              >
                <b-img
                  class="ml-auto mr-auto"
                  src="img/bank.png"
                  width="100"
                ></b-img>
                <div>
                  <h3 class="d-md-block d-lg-block d-none text-white">
                    Bank Transfer
                  </h3>
                  <h4 class="d-md-none d-lg-none d-block text-white">
                    Bank Transfer
                  </h4>
                </div>

                <!-- <div>
                                            <p> Account Number# 12787900142903</p>
                                        </div> -->
                <div class="text-white" style="margin-bottom: 2.25rem">
                  Account Number# 12787900142903 <br />
                  IBAN Number# PK08 HABB <br />
                  0012787900142903
                </div>
              </div>
            </b-col>
          </b-row>
          <b-spinner v-if="loading == true" variant="success"></b-spinner>
          <b-button
            v-else
            size="lg"
            variant="success"
            class="mt-4 mb-5"
            type="submit"
            @click="orderNow"
            >Order Now</b-button
          >
        </b-container>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { RepositoryFactory } from "../Repository/RepositoryFactory";
const OrderRepository = RepositoryFactory.get("order_repository");
const UserRepository = RepositoryFactory.get("user_repository");
import "../assets/css/app.css";

import axios from "axios";
export default {
  name: "IjtmaeQurbaniOrder",
  computed: {
    ...mapGetters(["loggedUser"]),
  },
  created() {},
  data() {
    return {
      step: 0,
      loading: false,
      showTop: false,
      show: true,
      order: { flag: false, text: "Order Now" },
      message: "+923065401886",
      newOrder: {
        user_id: "",
        user_name: "",
        user_phone: "",
        user_address: "",
        user_city: "",
        user_country: "",
        user_cnic: "",
        ijtimai_category: "Ijtimai Category",
        total_price: "15000",
        delivery: true,
        order_currency: "PKR",
      },
      //  currency:'PKR',
      currLoad: false,
      packages: [15000, 18000, 30000],
      values: [15000, 18000, 30000],
    };
  },
  methods: {
    currencyConversion() {
      this.currLoad = true;
      if (this.newOrder.order_currency != "PKR") {
        // this.packages=[15000,18000,30000]

        axios
          .get(
            `https://free.currconv.com/api/v7/convert?apiKey=93b58cb071fcca371f8d&q=${this.newOrder.order_currency}_PKR&compact=y`
          )
          .then((response) => {
            console.log(response.data);
            // packages
            console.log(response.data);
            if (this.newOrder.order_currency == "USD") {
              // this.packages[0]=Math.round(this.packages[0]/response.data.USD_PKR.val)
              this.$set(
                this.packages,
                0,
                Math.round(15000 / response.data.USD_PKR.val)
              );
              this.$set(
                this.packages,
                1,
                Math.round(18000 / response.data.USD_PKR.val)
              );
              this.$set(
                this.packages,
                2,
                Math.round(30000 / response.data.USD_PKR.val)
              );

              // this.packages[1]=Math.round(this.packages[1]/response.data.USD_PKR.val)
              //                     this.packages[2]=Math.round(this.packages[2]/response.data.USD_PKR.val)
            } else {
              this.$set(
                this.packages,
                0,
                Math.round(15000 / response.data.GBP_PKR.val)
              );
              this.$set(
                this.packages,
                1,
                Math.round(18000 / response.data.GBP_PKR.val)
              );
              this.$set(
                this.packages,
                2,
                Math.round(30000 / response.data.GBP_PKR.val)
              );
            }
            this.newOrder.total_price = this.packages[0];

            this.currLoad = false;
          });
      } else {
        this.packages = this.values;
        this.currLoad = false;
        this.newOrder.total_price = this.packages[0];
      }
    },
    orderNowNext() {
      this.step = 3;
    },
    async orderNow() {
      this.loading = true;
      this.newOrder.user_id = this.loggedUser.id;
      let { data } = await OrderRepository.new_ijtmae_order(this.newOrder);
      this.$store.commit("setNotifications", {
        message: "Order Created successfully",
        type: "success",
      });
      localStorage.removeItem("user_resources");
      let resp = await UserRepository.get_user_resources({
        user_id: this.loggedUser.id,
      });
      this.$store.commit("setUserResources", resp.data);
      setInterval(() => {
        this.$emit("return-order", 4);
      }, 3000);
    },
  },
};
</script>

<style scoped>
.card-header {
  background: White !important;
}

input[type="email"]::placeholder {
  /* Firefox, Chrome, Opera */
  text-align: center;
}
input[type="text"]::placeholder {
  /* Firefox, Chrome, Opera */
  text-align: center;
}
input[type="tel"]::placeholder {
  /* Firefox, Chrome, Opera */
  text-align: center;
}
input[type="email"]:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  text-align: center;
}
input[type="email"]::-ms-input-placeholder {
  /* Microsoft Edge */
  text-align: center;
}
</style>

<style>
.backs {
  background: #f5f5f5;
  height: calc(1.9em + 0.75rem + 2px) !important;
}

.selected_price .card-header {
  background: #f5f5f5 !important;
}
.selected_price .card-body {
  background: #f5f5f5 !important;
}
.selected_price {
  background: #f5f5f5 !important;
}

.points {
  cursor: pointer;
}
</style>
