<template>
  <b-container>
    <h3 class="mt-4">Sheeps Category</h3>
    <div v-if="postLoad == false" class="mt-5 row">
      <div v-for="(post_item, i) in viewable_posts" :key="i" class="col-md-4">
        <PostCard :post_item="post_item" @toggle-item="setPost" />
      </div>
    </div>
    <div v-else class="text-center">
      <b-spinner variant="success" label="Spinning"></b-spinner>
    </div>
    <!-- <a-pagination :current="current" @change="onChange"  :total="rows" />  -->
    <a-pagination
      class="pages"
      :current="current"
      @change="onChange"
      :total="rows"
    />
    <b-modal
      hide-footer
      id="sheep-lg"
      ref="sheep-lg"
      size="lg"
      :title="selected_post.category"
    >
      <DialogPost
        :selected_post="selected_post"
        @chat-show="$emit('chat-show', selected_post)"
      />
    </b-modal>
  </b-container>
</template>
<script>
import PostCard from "../components/PostCard.vue";
import DialogPost from "../components/DialogPost.vue";
import { RepositoryFactory } from "../Repository/RepositoryFactory";
const PostsRepository = RepositoryFactory.get("posts_repository");

import { mapGetters } from "vuex";
export default {
  name: "SheepPosts",
  components: {
    PostCard,
    DialogPost,
  },
  computed: {
    ...mapGetters(["posts"]),
    rows() {
      if (this.posts.length > 0) {
        console.log(this.posts);
        let lngth_posts = this.total_posts;
        let temp_perpage = 12;
        let dividedresult = lngth_posts / temp_perpage;
        let parsed = parseInt(dividedresult);
        if (parsed * temp_perpage < lngth_posts) {
          parsed++;
          let sringer = parsed.toString() + "0";
          console.log(sringer);
          let parsedback = parseInt(sringer);
          console.log(parsedback);
          return parsedback;
        } else {
          let sringer = parsed.toString() + "0";
          console.log(sringer);
          let parsedback = parseInt(sringer);
          console.log(parsedback);
          return parsedback;
        }
      }
      //  if(this.posts.length>0){

      //         console.log(this.posts)
      //         let a=this.posts[4].length
      //         let b=6
      //         let c=a/b
      //         return parseInt(c)+100
      //  }
      //  else{
      //      return 0
      //  }
    },
    spliced_posts() {
      let arr2 = [];
      if (this.posts.length > 0) {
        arr2 = this.posts[4].slice(this.start_index, this.end_index);
        return arr2;
      } else {
        return [];
      }
    },
  },
  watch: {
    posts() {
      if (this.posts.length > 0) {
        // let temp=this.rows/10
        // let temp1=temp+1
        // let perpage=parseInt(temp1)
        // this.end_index=parseInt(this.posts[4].length/perpage)
        // this.per_page=parseInt(this.posts[4].length/perpage)
        // this.per_page=this.per_page+1
        this.end_index = this.per_page;
      }
    },
  },
  mounted() {
    localStorage.setItem("route", "AllPosts");
  },
  created() {
    localStorage.setItem("route", "AllPosts");

    if (this.posts.length > 0) {
      // let temp=this.rows/10
      // let temp1=temp+1
      // let perpage=parseInt(temp1)
      // this.end_index=parseInt(this.posts[4].length/perpage)
      // this.per_page=parseInt(this.posts[4].length/perpage)
      // this.per_page=this.per_page+1
      this.end_index = this.per_page;
    }
    this.getSheepPosts(1);
  },
  methods: {
    async getSheepPosts(page) {
      this.postLoad = true;
      let { data } = await PostsRepository.category_pagination_posts({
        page: page,
        category: "Sheep",
      });
      console.log(data);
      if (page == 1) {
        this.total_posts = data.total_posts;
      }
      this.viewable_posts = data.posts;
      this.postLoad = false;
      window.scrollTo(50, 50);
    },
    setPost(post) {
      console.log(post);
      this.selected_post = post;
      this.$bvModal.show("sheep-lg");
    },
    onChange(current, pkd) {
      this.current = current;
      this.getSheepPosts(current);
      //    if(current<this.current){
      //     this.end_index=this.per_page*current
      //     this.current = current;
      //     this.start_index=(this.current-1)*this.per_page
      //     }
      //     else{
      //     this.end_index=this.per_page*current
      //     this.current = current;
      //     this.start_index=(this.current-1)*this.per_page
      //     }
    },
  },
  data() {
    return {
      postLoad: false,
      viewable_posts: [],
      total_posts: 0,
      start_index: 0,
      end_index: 0,
      current: 1,
      per_page: 12,
      selected_post: "",
    };
  },
};
</script>