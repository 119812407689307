<template>
  <b-container
    fluid
    class="main-landing-back pr-0 pl-0"
    style="min-height: 500px; position: relative"
  >
    <!-- <testTopNav /> -->
    <b-container class="mb-5">
      <h4 class="text-white">IJTAMAE QURBANI BY QURBANI APP</h4>
    </b-container>

    <b-container>
      <b-card>
        <p>
          Qurbani App is providing Ijtamae Qurbani this Eid. We have some of the
          best breeds from around the World. Our Ijtamai Qurbani Service will
          deliver meat to the most underprivileged and deserving communities of
          twin cities. Other than this this year COVID-19 pandemic has hit
          country badly and there are numbers of people who are jobless and
          hungry. This is your chance to help them, play your part for the well
          being of your people to the most underprivileged and deserving
          communities in twin cities. Covid-19 pandemic this year has severely
          affected the livelihoods of thousands of families in disadvantaged
          areas like Dhak Dalal, Dhok Hassu, Fauji Colony and Girja Road. We are
          working with a number of local social welfare organisation to maintain
          a database of widows, divorcees, orphans, daily wage earners, families
          living on rent and families of more than five with a single wage
          earner. Your Qurbani this year can be a source of nutritious meat for
          these communities. Help us, Help them ORDER NOW! Call Now
          +44-7403228448 / +92-3065401886
        </p>
        <h3 class="text-success">Check Out Our Packages</h3>
        <b-container>
          <b-row>
            <b-col md="12" cols="12">
              <span>Convert to view in your currency</span> <br />
              <b-button
                pill
                :variant="
                  newOrder.order_currency == 'PKR'
                    ? 'success'
                    : 'outline-success'
                "
                @click="
                  newOrder.order_currency = 'PKR';
                  currencyConversion();
                "
                >PKR</b-button
              >
              <b-button
                class="ml-2"
                pill
                :variant="
                  newOrder.order_currency == 'USD'
                    ? 'success'
                    : 'outline-success'
                "
                @click="
                  newOrder.order_currency = 'USD';
                  currencyConversion();
                "
                >USD</b-button
              >
              <b-button
                class="ml-2"
                pill
                :variant="
                  newOrder.order_currency == 'GBP'
                    ? 'success'
                    : 'outline-success'
                "
                @click="
                  newOrder.order_currency = 'GBP';
                  currencyConversion();
                "
                >GBP</b-button
              >
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" lg="4">
              <b-card
                @click="newOrder.total_price = packages[0]"
                tag="article"
                :class="[
                  'points shadow mt-5 pb-4 col-md-12 mb-2 ml-auto mr-auto p-0',
                  newOrder.total_price == this.packages[0]
                    ? 'selected_price'
                    : '',
                ]"
              >
                <template v-slot:header>
                  <div
                    class="col-md-12 col-11 ml-auto p-2 mr-auto bg-success"
                    style="
                      border-top-right-radius: 1rem;
                      border-top-left-radius: 1rem;
                    "
                  >
                    <div class="p-2">
                      <b-img src="img/ijtmae/cow.png" width="80"></b-img>
                      <p style="font-size: 15px" class="text-white bolds">
                        Ijtamai Hissa With Meat Distributed To Poor People
                      </p>
                    </div>
                  </div>
                </template>
                <div class="text-left text-success">
                  <div v-if="currLoad == true" class="text-center">
                    <b-spinner variant="success"></b-spinner>
                  </div>
                  <h3 v-else class="text-success text-center">
                    {{ this.newOrder.order_currency }} {{ this.packages[0] }}
                  </h3>
                  1) Animal slaughtered within the first two days of Eid. <br />
                  2) Meat distributed within the first three days. <br />
                  3) Receipt of Order confirmation. <br />
                  4) Live updates on WhatsApp number <br />
                  5) Images and videos of slaughtered animal with takbeer of
                  your name
                </div>
              </b-card>
            </b-col>
            <b-col cols="12" lg="4">
              <b-card
                @click="newOrder.total_price = packages[1]"
                tag="article"
                :class="[
                  'points shadow mt-5 pb-4 col-md-12 mb-2 ml-auto mr-auto p-0',
                  newOrder.total_price == this.packages[1]
                    ? 'selected_price'
                    : '',
                ]"
              >
                <template v-slot:header>
                  <div
                    class="col-md-12 col-11 ml-auto p-2 mr-auto bg-success"
                    style="
                      border-top-right-radius: 1rem;
                      border-top-left-radius: 1rem;
                    "
                  >
                    <div class="p-2">
                      <b-img src="img/ijtmae/cow_1.png" width="80"></b-img>
                      <p style="font-size: 15px" class="text-white bolds">
                        Ijtamae Hissa With Meat Delivery To Home
                      </p>
                    </div>
                  </div>
                </template>
                <div class="text-left text-success">
                  <div v-if="currLoad == true" class="text-center">
                    <b-spinner variant="success"></b-spinner>
                  </div>
                  <h3 v-else class="text-success text-center">
                    {{ this.newOrder.order_currency }} {{ this.packages[1] }}
                  </h3>
                  1) Animal slaughtered within the first two days of Eid. <br />
                  2) Meat distributed within the first three days. <br />
                  3) Receipt of Order confirmation. <br />
                  4) Live updates on WhatsApp number <br />
                  5) Images and videos of slaughtered animal with takbeer of
                  your name
                </div>
              </b-card>
            </b-col>
            <b-col cols="12" lg="4">
              <!-- ,newOrder.total_price==this.packages[2] ? 'selected_price':'' -->
              <b-card
                @click="newOrder.total_price = packages[2]"
                tag="article"
                :class="[
                  'points shadow mt-5 pb-4 col-md-12 mb-2 ml-auto mr-auto p-0',
                  newOrder.total_price == this.packages[2]
                    ? 'selected_price'
                    : '',
                ]"
              >
                <template v-slot:header>
                  <div
                    class="col-md-12 col-11 ml-auto p-2 mr-auto bg-success"
                    style="
                      border-top-right-radius: 1rem;
                      border-top-left-radius: 1rem;
                    "
                  >
                    <div class="p-2">
                      <b-img src="img/ijtmae/goat.png" width="80"></b-img>
                      <p style="font-size: 15px" class="text-white bolds">
                        Qurbani Bakra With Or Without Delivery
                      </p>
                    </div>
                  </div>
                </template>
                <div class="text-left text-success">
                  <div v-if="currLoad == true" class="text-center">
                    <b-spinner variant="success"></b-spinner>
                  </div>
                  <h3 v-else class="text-success text-center">
                    {{ this.newOrder.order_currency }} {{ this.packages[2] }}
                  </h3>
                  1) Animal slaughtered within the first two days of Eid. <br />
                  2) Meat distributed within the first three days. <br />
                  3) Receipt of Order confirmation. <br />
                  4) Live updates on WhatsApp number <br />
                  5) Images and videos of slaughtered animal with takbeer of
                  your name
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-container fluid>
            <b-row>
              <b-col>
                <b-card class="ijtcard">
                  <h3 class="text-center ">
                    <span class="text-success">PLEASE </span> LOGIN
                    <span class="text-success"
                      >TO FILL OUT THE FORM BELOW TO ORDER</span
                    >
                  </h3>
                  <form @submit.prevent="orderNow()">
                    <!-- <b-card> -->
                    <b-container>
                      <b-row>
                        <b-col md="6" cols="12">
                          <b-input
                            required
                            v-model="newOrder.user_name"
                            class="backs"
                            placeholder="Enter Name"
                          ></b-input>
                        </b-col>
                        <b-col md="6" cols="12 mt-md-0 mt-1">
                          <b-input
                            required
                            v-model="newOrder.user_cnic"
                            class="backs"
                            type="number"
                            placeholder="Enter CNIC"
                          ></b-input>
                        </b-col>
                      </b-row>
                      <b-row class="mt-0 mt-md-4">
                        <b-col md="6" class="mt-md-0 mt-1" cols="12">
                          <b-input
                            required
                            v-model="newOrder.user_phone"
                            class="backs"
                            type="number"
                            placeholder="Enter Phone Number"
                          ></b-input>
                        </b-col>
                        <b-col md="6" cols="12" class="mt-md-0 mt-1">
                          <b-input
                            required
                            v-model="newOrder.user_address"
                            class="backs"
                            placeholder="Enter Address"
                          ></b-input>
                        </b-col>
                      </b-row>
                      <b-row class="mt-0 mt-md-4">
                        <b-col md="6" class="mt-md-0 mt-1" cols="12">
                          <b-input
                            required
                            v-model="newOrder.user_city"
                            class="backs"
                            placeholder="Enter City"
                          ></b-input>
                        </b-col>
                        <b-col md="6" cols="12" class="mt-md-0 mt-1">
                          <b-input
                            required
                            v-model="newOrder.user_country"
                            class="backs"
                            placeholder="Country name"
                          ></b-input>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="12" cols="12" class="mt-3">
                          <span>Would you Like animal to be delivered ? </span>
                          <br />
                          <b-button
                            pill
                            :variant="
                              newOrder.delivery == true
                                ? 'success'
                                : 'outline-success'
                            "
                            @click="newOrder.delivery = true"
                            >Yes</b-button
                          >
                          <b-button
                            class="ml-2"
                            pill
                            :variant="
                              newOrder.delivery == false
                                ? 'success'
                                : 'outline-success'
                            "
                            @click="newOrder.delivery = false"
                            >No</b-button
                          >
                        </b-col>
                        <b-col v-if="newOrder.delivery == true" class="mt-3">
                          <sub
                            ><b
                              >*{{ delviery_charges
                              }}{{ newOrder.order_currency }} Delivery charges
                              will be added in to your total order amount</b
                            >
                          </sub>
                        </b-col>
                      </b-row>
                      <h3 class="text-success mt-4">Payment Options For you</h3>
                      <b-container class="mb-5">
                        <b-row>
                          <b-col md="6">
                            <div
                              class="card p-md-5 p-2"
                              style="background: #feea63"
                            >
                              <b-img
                                class="ml-auto mr-auto"
                                src="img/easy.png"
                                width="200"
                              ></b-img>
                              <div class="text-success">
                                You can transfer your payment through easypaisa.
                                Just go to any easypaisa shop nearby and ask
                                retailer to transfer specific amount to
                                following number
                              </div>
                              <div
                                class="text-success"
                                style="margin-top: 2.25rem"
                              >
                                <b> Number Details 0306 5401886</b>
                              </div>
                            </div>
                          </b-col>
                          <b-col md="6">
                            <div
                              class="card p-md-5 p-2"
                              style="background: #81c784"
                            >
                              <b-img
                                class="ml-auto mr-auto"
                                src="img/bank.png"
                                width="100"
                              ></b-img>
                              <div>
                                <h3 class="text-white">Bank Transfer</h3>
                              </div>
                              <div class="text-white">
                                Account Number# 12787900142903 <br />
                                IBAN Number# PK08 HABB <br />
                                0012787900142903
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                      </b-container>
                      <div>
                        <b-button
                          v-if="isload == false"
                          variant="success"
                          type="submit"
                          class="mt-3"
                          >Order Now</b-button
                        >
                        <b-spinner v-else variant="success"> </b-spinner>
                      </div>
                    </b-container>
                    <!-- </b-card> -->
                  </form>
                </b-card>
              </b-col>
            </b-row>
          </b-container>
        </b-container>

        <h3 class="text-success mt-4">
          "WE PROMISE TO DELIVER YOU HIGH FIVE SERVICE"
        </h3>
        <b-container>
          <b-img width="800" src="img/featured_icon.png"> </b-img>
        </b-container>
        <h5 class="mt-5">Didn't find what you were looking for?</h5>
        <button
          class="col-md-4 btn btn-success"
          v-clipboard:copy="message"
          v-clipboard:success="onCopy"
          @click="show = true"
        >
          {{ show == false ? "Qurbani Helpline" : "+923065401886 Helpline" }}
        </button>
        <b-alert
          v-model="showTop"
          class="position-fixed fixed-top m-0 rounded-0"
          style="z-index: 2000"
          variant="success"
          dismissible
        >
          Help Line phone number copied !
        </b-alert>
        <b-img class="mt-3" src="/we-banner.jpg" fluid-grow></b-img>
      </b-card>
    </b-container>
  </b-container>
</template>

<script>
import axios from "axios";
import testTopNav from "../components/testTopNav.vue";
import { RepositoryFactory } from "../Repository/RepositoryFactory";
const OrderRepository = RepositoryFactory.get("order_repository");
const UserRepository = RepositoryFactory.get("user_repository");
import { mapGetters } from "vuex";
export default {
  name: "IjtmaeQurbani",
  components: {
    testTopNav,
  },
  computed: {
    ...mapGetters(["loggedUser"]),
  },
  data() {
    return {
      message: "+923065401886",
      showTop: false,
      delviery_charges: 1000,
      isload: false,
      show: false,
      // total_price: "15000",
      // order_currency: "PKR",
      currLoad: false,
      packages: [15000, 18000, 30000],
      values: [15000, 18000, 30000],
      newOrder: {
        user_id: 48289,
        user_name: "",
        user_phone: "",
        user_address: "",
        user_city: "",
        user_country: "",
        user_cnic: "",
        ijtimai_category: "Ijtimai Category",
        total_price: "15000",
        delivery: true,
        order_currency: "PKR",
      },
    };
  },
  methods: {
    async orderNow() {
      this.isload = true;
      if (this.newOrder.delivery) {
        let newpr =
          parseInt(this.newOrder.total_price) + parseInt(this.delviery_charges);
        this.newOrder.total_price = newpr;
      }
      // this.newOrder.user_id = this.loggedUser.id;

      let { data } = await OrderRepository.new_ijtmae_order(this.newOrder);
      this.$store.commit("setNotifications", {
        message: "Order Created successfully",
        type: "success",
      });
      // localStorage.removeItem("user_resources");
      // alert("Order has been placed")
      // SERIOUS CRIME
      // let resp = await UserRepository.get_user_resources({
      //   user_id: this.loggedUser.id,
      // });
      // this.$store.commit("setUserResources", resp.data);

      this.isload = false;
      setTimeout(() => {
        this.$router.push("/");
      }, 2000);
    },

    onCopy: function(e) {
      this.showTop = !this.showTop;
    },

    currencyConversion() {
      this.currLoad = true;
      if (this.newOrder.order_currency != "PKR") {
        // this.packages=[15000,18000,30000]

        axios
          .get(
            `https://free.currconv.com/api/v7/convert?apiKey=93b58cb071fcca371f8d&q=${this.newOrder.order_currency}_PKR&compact=y`
          )
          .then((response) => {
            console.log(response.data);
            // packages
            console.log(response.data);
            if (this.newOrder.order_currency == "USD") {
              // this.packages[0]=Math.round(this.packages[0]/response.data.USD_PKR.val)
              this.$set(
                this.packages,
                0,
                Math.round(15000 / response.data.USD_PKR.val)
              );
              this.$set(
                this.packages,
                1,
                Math.round(18000 / response.data.USD_PKR.val)
              );
              this.$set(
                this.packages,
                2,
                Math.round(30000 / response.data.USD_PKR.val)
              );
              this.newOrder.total_price = Math.round(
                15000 / response.data.USD_PKR.val
              );
              this.delviery_charges = Math.round(
                1000 / response.data.USD_PKR.val
              );

              // this.packages[1]=Math.round(this.packages[1]/response.data.USD_PKR.val)
              //                     this.packages[2]=Math.round(this.packages[2]/response.data.USD_PKR.val)
            } else {
              this.$set(
                this.packages,
                0,
                Math.round(15000 / response.data.GBP_PKR.val)
              );
              this.$set(
                this.packages,
                1,
                Math.round(18000 / response.data.GBP_PKR.val)
              );
              this.$set(
                this.packages,
                2,
                Math.round(30000 / response.data.GBP_PKR.val)
              );
              this.newOrder.total_price = Math.round(
                15000 / response.data.GBP_PKR.val
              );
              this.delviery_charges = Math.round(
                1000 / response.data.GBP_PKR.val
              );
              // this.packages[0]=Math.round(this.https://www.google.com/search?q=samsun+s7+tab+price+pakistan&oq=samsun+s7+tab+price+pakistan&aqs=chrome..69i57j0i13j0i13i457j0i13l7.4425j0j4&sourceid=chrome&ie=UTF-8packages[0]/response.data.GBP_PKR.val)
              // this.packages[1]=Math.round(this.packages[1]/response.data.GBP_PKR.val)
              // this.packages[2]=Math.round(this.packages[2]/response.data.GBP_PKR.val)
            }
            this.currLoad = false;

            // console.log(response.status);
            // console.log(response.statusText);
            // console.log(response.headers);
            // console.log(response.config);
          });
      } else {
        this.packages = this.values;
        this.currLoad = false;
        this.newOrder.total_price = 15000;
        this.delviery_charges = 1000;
      }
    },
  },
};
</script>

<style scoped>
.card .card-header {
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
}
.ijtcard .card-body {
  padding: 0px !important;
}

.selected_price .card-header {
  background: #f5f5f5 !important;
}
.selected_price .card-body {
  background: #f5f5f5 !important;
}
.selected_price {
  background: #f5f5f5 !important;
}
</style>
