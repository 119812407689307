<template>
  <div>
    <b-card
      @click="$emit('order-item', post_item)"
      tag="article"
      style="cursor: pointer; max-width: 20rem"
      class="post shadow mt-1 mb-2"
    >
      <template v-slot:header>
        <span
        v-if="post_item.featured == true && $route.name!='sadqah-and-aqiqa' && post_item.category!='Aqiqa'"
          @click="themits(post_item)"
          class="mt-2"
          
          >Featured
        </span>
        <span v-else-if="$route.name=='sadqah-and-aqiqa' && post_item.category=='Aqiqa'">Eligibile for Aqiqa</span>
        <img
          @click="themits(post_item)"
          style="height: 182px !important"
          class="card-img"
          v-if="post_item.post_type == 'AUDIO'"
          @error="imgUrlAlt"
          :src="
            post_item.imagekey != ''
              ? 'https://qurbaniimages.s3.amazonaws.com/' +
                replacer(post_item.imagekey)
              : 'img/section1.png'
          "
          alt=""
        />
        <img
          @error="imgUrlAlt"
          @click="themits(post_item)"
          style="height: 182px !important"
          v-if="post_item.post_type == 'VIDEO'"
          class="card-img"
          :src="
            post_item.thumbnail != ''
              ? 'https://qurbaniimages.s3.amazonaws.com/' +
                replacer(post_item.thumbnail)
              : 'img/section1.png'
          "
          alt=""
        />
        <i
          @click="themits(post_item)"
          v-if="post_item.post_type == 'VIDEO'"
          class="fa fa-play-circle fa-5x"
          style="color: #28a745; position: absolute; right: 36%; top: 8%"
          aria-hidden="true"
        ></i>
        <img
          @click="themits(post_item)"
          v-if="post_item.sold_status == 's' && landing == true"
          src="img/extras/sold-out.png"
          style="position: absolute; top: 8%; right: 14%"
          alt=""
        />
        <!-- For My posts -->
        <img
          @click="themits(post_item)"
          v-if="
            post_item.sold_status == 's' &&
            landing == null &&
            active_route == 'Dashboard'
          "
          src="img/extras/sold-out.png"
          style="position: absolute; top: 11%; right: 10%"
          alt=""
        />
        <!-- For All posts -->
        <img
          @click="themits(post_item)"
          v-if="
            post_item.sold_status == 's' &&
            landing == null &&
            active_route == 'AllPosts'
          "
          src="img/extras/sold-out.png"
          style="position: absolute; top: 11%; right: 3%"
          alt=""
        />
        <img
          @click="themits(post_item)"
          v-if="post_item.sold_status == 's' && landing == 'sadqahs'"
          src="img/extras/sold-out.png"
          style="position: absolute; top: 11%; right: 10%"
          alt=""
        />
        <b-button
          class="buy-class"
          v-if="post_item.sold_status != 's' && active_route == 'Sadqah'"
          pill
          variant="success"
          v-on:click="$emit('order-item', post_item)"
          >Buy</b-button
        >
        <b-button
          class="sold-class"
          @click="changeSale(post_item, 'n')"
          v-if="active_route == 'Dashboard' && loggedUser.id == 21"
          pill
          :variant="post_item.sold_status == 's' ? `success` : `outline-light`"
          v-on:click="$emit('order-item', post_item)"
          >Sold</b-button
        >
        <b-button
          class="unsold-class"
          @click="changeSale(post_item, 's')"
          v-if="active_route == 'Dashboard' && loggedUser.id == 21"
          pill
          :variant="post_item.sold_status == 'n' ? `danger` : `outline-light`"
          v-on:click="$emit('order-item', post_item)"
          >Unsold</b-button
        >
      </template>
      <div class="row">
        <div class="col-md-2 pr-0" @click="themits(post_item)">
          <img
            class="img-fluid rounded-circle"
            width="30"
            @error="imgUrlAlt2"
            :src="post_item.userimage != null ? post_item.userimage : img_src"
          />
        </div>
        <div class="col-md-7 pl-0 pr-0" @click="themits(post_item)">
          <h5>
            {{
              post_item.nickname != "" ? post_item.nickname : "Not Applicable"
            }}
          </h5>
          <!-- <h6>Price {{ post_item.price != "" ? post_item.price : "NA" }}</h6> -->
        </div>
        <div class="col-md-2 pr-0 pl-0">
          <span
            @click="likePost(post_item.id)"
            :class="
              analyzeLikes(post_item.id) == true
                ? 'fa fa-heart'
                : 'fa fa-heart-o'
            "
            style="color: #a83f39"
          >
            <!-- {{ post_item.likescount == null ? 0 : post_item.likescount }} -->
            {{ temp_post.likes }}
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-12">
          <h6 class="text-center">
            {{
              post_item.price != ""
                ? `Price ${
                    converter == 0 || converter == null
                      ? post_item.price
                      : `${Math.round(post_item.price / converter)} ${currency}`
                  }`
                : "Call for Price"
            }}

            <!-- Price {{ post_item.price != "" ? post_item.price : "NA" }} -->
          </h6>
          <!-- {{findFarm(post_item.farm_name).image==""}} -->
          <h6 v-if="post_item.farm_name!=null && post_item.farm_name!=''">  
               <b-avatar
              v-if="post_item.farm_name!=''"
            :src="findFarm(post_item.farm_name).image!='' ?
            'https://s3.amazonaws.com/qurbaniimages/' +
              trimValue(replacer(findFarm(post_item.farm_name).image))
              :
              './img/farms/cow.png'
            "
            size="2rem"
          >
          </b-avatar>
             {{findFarm(post_item.farm_name)!=null  ? `Farm ${findFarm(post_item.farm_name).name}` :''}}</h6>
        </div>
        <!-- <div class="col-md-3 pr-0 pl-0 col-12">
          <span class="mr-1 text-success">{{ post_item.views }}</span>
          <i class="far fa-eye text-success text-left"></i>
        </div> -->
      </div>
      <hr />
      <div class="row" @click="themits(post_item)">
        <div class="col-md-6 pl-0 pr-0" style="font-size: 0.7rem">
          <span class="text-muted"
            >{{ post_item.category }} | {{ post_item.city }}</span
          >
        </div>
        <div class="col-md-6 right-aligned">
          <span class="text-muted pull-right" style="font-size: 0.7rem">
            {{ dateSplitter(post_item.created_at) }}
          </span>
          <b-dropdown
            v-if="active_route == 'Dashboard'"
            size="sm"
            variant="link"
            dropup
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <i class="fa fa-ellipsis-v text-success" aria-hidden="true"></i>
            </template>
            <b-dropdown-item href="#"
              ><span @click="updatePost(post_item)" class="text-success">
                Update
                <i
                  class="fa fa-refresh text-success"
                  aria-hidden="true"
                ></i></span
            ></b-dropdown-item>
            <b-dropdown-item href="#">
              <span @click="removePost(post_item)" class="text-success">
                Delete
                <i class="fa fa-trash text-success" aria-hidden="true"></i
              ></span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <!-- <h4>{{post_item.contactno !=""?post_item.contactno :'NA'}}</h4>
    <h5>Price {{post_item.price!="" ? post_item.price :'NA'}}</h5> -->
    </b-card>
  </div>
</template>
<script>
import { RepositoryFactory } from "../Repository/RepositoryFactory";
const PostsRepository = RepositoryFactory.get("posts_repository");
const UserRepository = RepositoryFactory.get("user_repository");
const FarmsRepository = RepositoryFactory.get("farms_repository");

import globalfuns from "../mixins/globalfuns";
import { mapGetters } from "vuex";
export default {
  props: ["post_item", "landing", "converter", "currency"],
  mixins: [globalfuns],
  data() {
    return {
      active_route: "",
      temp_post: { likes: 0 },
      img_src: require("../assets/img/default-profile.png"),
    };
  },
  created() {
    if(this.farms.length<1){
      this.getFarms()
    }
    this.active_route = localStorage.getItem("route");
    this.temp_post = { ...this.post_item, likes: 0 };
    this.fetchComments(this.post_item);
  },
  mounted() {
    this.active_route = localStorage.getItem("route");
  },
  computed: {
    ...mapGetters(["loggedUser", "farms","user_resources"]),
  },
  methods: {
    findFarm(arg){
      return  this.farms.find(item=>item.name==arg)
    },
    async getFarms() {
      let { data } = await FarmsRepository.getAllFarms();
      this.$store.commit("setFarms", data);
      console.log(data);
    },
    async fetchComments(post_item) {
      let { data } = await PostsRepository.post_comments({
        post_id: post_item.id,
      });
      this.likes = data[1];
      this.temp_post.likes = this.likes.length;
    },
    async changeSale(post_item, type) {
      if (type == post_item.sold_status) {
        let resp = await PostsRepository.change_sale_status({
          post_id: post_item.id,
          sold_status: post_item.sold_status == "s" ? "n" : "s",
        });
        localStorage.removeItem("user_resources");
        var { data } = await UserRepository.get_user_resources({
          user_id: this.loggedUser.id,
        });
        this.$store.commit("setUserResources", data);
      } else {
        this.$store.commit("setNotifications", {
          message: `Already ${type == "s" ? "Unsold" : " Sold"}`,
          type: "error",
        });
      }
    },
    updatePost(post) {
      this.$router.push({ name: "UpdatePost", params: { post: post } });
    },
    async removePost(post) {
      let { data } = await PostsRepository.remove_post({ post_id: post.id });
      if (data == 1) {
        this.$store.commit("setNotifications", {
          message: "Post deleted successfully",
          type: "success",
        });
        this.$store.dispatch("removeAct", post);
      } else {
        this.$store.commit("setNotifications", {
          message: "Error in deleting the post",
          type: "error",
        });
      }
    },
    analyzeLikes(id) {
      if (this.user_resources.length > 0) {
        let obj = this.user_resources[2].find(
          (user_res_item) => user_res_item.post_id == id
        );
        if (obj != null) {
          // this.post_item.likescount = this.post_item.likescount + 1;
          return true;
        } else {
          // this.post_item.likescount = this.post_item.likescount - 1;
          return false;
        }
      } else {
        return false;
      }
    },
    async likePost(id) {
      if (this.loggedUser != null) {
        if (this.analyzeLikes(id) == false) {
          let like_obj = {
            post_id: this.post_item.id,
            user_id: this.loggedUser.id,
          };
          let { data } = await PostsRepository.new_likes(like_obj);
          this.$store.commit("setNewUserLikes", data);
          this.fetchComments(this.post_item);
        } else {
          let like_obj = {
            post_id: this.post_item.id,
            user_id: this.loggedUser.id,
          };
          let { data } = await PostsRepository.remove_likes(like_obj);
          this.fetchComments(this.post_item);

          this.$store.commit("removeOldLikes", like_obj);
        }
      } else {
        this.$store.commit("setNotifications", {
          message: "Log in before liking a post",
          type: "error",
        });
      }
    },
    dateSplitter(dateTime) {
            let date = dateTime.split("T")[0];

      let months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const year = date.split("-")[0];
      const month = date.split("-")[1];
      const day = date.split("-")[2];
      const getMonth = months[month - 1];

      const dateString = day + " " + getMonth  + "," + year;
      
      return dateString;

      // var res = dateTime.split("T");
      // return res[0];
    },
    imgUrlAlt(event) {
      event.target.src = "img/section1.png";
    },
    imgUrlAlt2(event) {
      event.target.src = this.img_src; //"img/default-profile.png";
    },
    themits(post) {
      this.$emit("toggle-item", post);
    },
  },
};
</script>

<style>
/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  .w-md-50 {
    width: 50%;
  }

  .buy-class {
    position: absolute;
    top: 42%;
    left: 40%;
  }
  .sold-class {
    position: absolute;
    top: 42%;
    left: 25%;
  }
  .unsold-class {
    position: absolute;
    top: 42%;
    left: 52%;
  }
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  .w-md-50 {
    width: 50%;
  }
  .buy-class {
    position: absolute;
    top: 32%;
    left: 40%;
  }
  .sold-class {
    position: absolute;
    top: 42%;
    left: 25%;
  }
  .unsold-class {
    position: absolute;
    top: 42%;
    left: 52%;
  }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .w-xs-100 {
    width: 100%;
  }
  .buy-class {
    position: absolute;
    top: 32%;
    left: 40%;
  }
  .sold-class {
    position: absolute;
    top: 32%;
    left: 25%;
  }
  .unsold-class {
    position: absolute;
    top: 32%;
    left: 52%;
  }
}
</style>
