<template>
  <b-container fluid class="bg-warning p-2">
    <h6 class="mb-0">
      Get PKR {{ promo.promo_value }}
      {{ promo.promo_type == "Flat" ? "" : "%" }} off on Sadqah and Aqiqa order.
      Use Code
      <b>{{ promo.promo_code }}</b>
    </h6>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Discounts",
  computed: {
    ...mapGetters(["promo"]),
  },
};
</script>

<style>
</style>