<template>
  <div class="mb-5">
    <div
      style="
        border-bottom-right-radius: 1rem;
        border-bottom-left-radius: 1rem;
        min-height: 100px;
        background: linear-gradient(180deg, #000000 0%, #309f5d 100%) 0% 0%
          no-repeat padding-box;
      "
    >
      <b-row>
        <b-col md="8" style="align-self: center">
          <h2 class="text-white">Manage your orders</h2>
        </b-col>
        <b-col md="4">
          <img
            class="float-md-left"
            style="width: 100px"
            src="img/myprofile/sheep.png"
            alt=""
          />
        </b-col>
      </b-row>
    </div>
    <div v-if="loading == false">
      <div v-for="(item, i) in temporders" :key="i" class="row mt-4">
        <div class="col-md-12">
          <b-card class="col-md-12" style="box-shadow: 0px 3px 6px #00000029">
            <div class="row">
              <div class="col-md-6 left-aligned" @click="returnItem(item, i)">
                <span style="font-size: 10px">Ad Id {{ item.id }}</span>
              </div>
              <div class="col-md-6 right-aligned">
                <span style="font-size: 10px">{{
                  item.created_at.split("T")[0]
                }}</span>
                <span
                  class="ml-1 text-danger fa fa-trash"
                  v-if="loggedUser.id == 21"
                  @click="removeOrder(item.id)"
                ></span>
              </div>
            </div>
            <div class="row" @click="returnItem(item, i)">
              <div class="col-md-4 left-aligned">
                <span class="bolds">
                  {{
                    item.sadqa_aqiqa == "Ijtimai"
                      ? "Ijtmai Order"
                      : "Sadqah Order"
                  }}
                  {{ item.id }}</span
                >
              </div>
              <div class="col-md-4">
                <span class="bolds"
                  >{{
                    item.order_currency != null ? item.order_currency : "PKR"
                  }}
                  {{ item.total_price }}</span
                >
              </div>
              <div class="col-md-4 right-aligned">
                <span
                  :class="
                    item.order_delivered == true
                      ? `text-success`
                      : `text-danger`
                  "
                  >{{
                    item.order_delivered == true ? `COMPLETED` : `IN PROCESS`
                  }}</span
                >
              </div>
            </div>
          </b-card>
        </div>
      </div>
    </div>
    <div v-else>
      <b-spinner variant="success"></b-spinner>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PostCard from "../components/PostCard.vue";
import { RepositoryFactory } from "../Repository/RepositoryFactory";
const OrderRepository = RepositoryFactory.get("order_repository");
export default {
  components: {
    PostCard,
  },
  data() {
    return {
      temporders: "",
      loading: false,
    };
  },
  watch: {
    admin_orders() {
      this.temporders = this.admin_orders;
      // this.$forceUpdate()
    },
  },
  created() {
    if (this.loggedUser.id == 21) {
      this.temporders = this.admin_orders;
    } else {
      this.temporders = this.user_resources[3];
    }
  },
  methods: {
    returnItem(item, i) {
      if (item.sadqa_aqiqa == "Ijtimai") {
        let obj = { item: { ...item, no: i }, id: 12 };
        this.$emit("return-order", obj);
      } else {
        let obj = { item: { ...item, no: i }, id: 7 };
        this.$emit("return-order", obj);
      }
    },
    async removeOrder(id) {
      this.loading = true;
      let resp = await OrderRepository.delete_order({ sadqa_order_id: id });
      var { data } = await OrderRepository.get_admin_sadqa_orders();
      this.$store.commit("setAdminResources", data[0]);
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters(["posts", "loggedUser", "user_resources", "admin_orders"]),
  },
  mounted() {},
};
</script>

<style>
</style>