<template>
  <b-container style="background: #f5f5f5; height: 90vh">
    <div class="p-2">
      <form @submit.prevent="createPromo">
        <b-form-input
          v-model="promoObj.promo_code"
          required
          placeholder="Enter Discount Code"
        ></b-form-input>

        <b-input-group class="mt-3">
          <b-form-input
            v-model="promoObj.promo_value"
            type="number"
            required
            min="1"
            placeholder="Enter Discount "
          ></b-form-input>
          <template v-slot:append>
            <b-dropdown :text="promoObj.promo_type" variant="success">
              <b-dropdown-item @click="promoObj.promo_type = 'Flat'"
                >Flat</b-dropdown-item
              >
              <b-dropdown-item @click="promoObj.promo_type = 'Percentage'"
                >Percentage</b-dropdown-item
              >
            </b-dropdown>
          </template>
        </b-input-group>

        <p class="text-left mt-4 mb-0">Expiry Date</p>

        <b-row class="text-left">
          <b-col md="3" cols="12">
            <!-- <p class="mb-0">Ex</p> -->

            <b-form-input
              type="date"
              required
              :min="moment().format('YYYY-MM-DD')"
              v-model="promoObj.expire_date"
            ></b-form-input>
          </b-col>
          <!-- <b-col md="2" cols="12">
          <p class="mb-0">To</p>
          <b-form-input type="date"></b-form-input>
        </b-col> -->
        </b-row>
        <b-button variant="success" type="submit" pill>Apply</b-button>
      </form>
    </div>
  </b-container>
</template>

<script>
import moment from "moment";

import { RepositoryFactory } from "../Repository/RepositoryFactory";
const PromoRepository = RepositoryFactory.get("promo_repository");
export default {
  name: "CDIscounts",
  data() {
    return {
      moment: moment,

      promoObj: {
        promo_code: "",
        promo_value: "",
        promo_type: "Flat",
        expire_date: "",
      },
    };
  },
  methods: {
    async createPromo() {
      console.log(this.promoObj);
      let resp = await PromoRepository.new_promo_code(this.promoObj);
      this.$store.commit("setNotifications", {
        message: "Promo created successfully",
        type: "success",
      });
      this.promoObj = {
        promo_code: "",
        promo_value: "",
        promo_type: "Flat",
        expire_date: "",
      };
      let { data } = await PromoRepository.get_current_promo();
      this.$store.commit("setPromo", data[0]);
    },
  },
};
</script>

<style>
</style>