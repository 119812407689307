<template>
  <b-container>
    <h3 class="mt-4 text-success text-left">{{selectedFarm.name}}</h3>
    <b-spinner v-if="postLoad == true" variant="success"></b-spinner>

    <div v-if="viewable_posts.length > 0 && postLoad == false" class="mt-5 row">
      <div v-for="(post_item, i) in viewable_posts" :key="i" class="col-md-4">
        <PostCard :post_item="post_item" @toggle-item="setPost" />
      </div>
    </div>
    <div v-else>
      <h6>No posts available for this farm</h6>

    </div>
    <!-- <a-pagination
      class="pages"
      :current="current"
      :default-current="5"
      @change="onChange"
      :total="rows"
    /> -->
    <!-- <b-pagination
      v-model="current"
      :total-rows="rows"
      @change="onChange"
      per-page="12"
      aria-controls="my-table"
    ></b-pagination> -->
    <b-modal id="abc"  ref="ab"  size="lg"
      :title="selected_post.category">
 <!-- <DialogPost
        :selected_post="selected_post"
        @chat-show="$emit('chat-show', selected_post)"
      /> -->
    </b-modal>
    <b-modal
      hide-footer
      id="modal-lg"
      ref="modal-lg"
      size="lg"
      :title="selected_post.category"
    >
      <DialogPost
        :selected_post="selected_post"
        @chat-show="$emit('chat-show', selected_post)"
      />
    </b-modal>
  </b-container>
</template>
<script>
import PostCard from "./PostCard.vue";
import DialogPost from "./DialogPost.vue";
import { RepositoryFactory } from "../Repository/RepositoryFactory";
const FarmsRepository = RepositoryFactory.get("farms_repository");

import { mapGetters } from "vuex";
export default {
  name: "FarmDetails",
  props:['selectedFarm'],
  components: {
    PostCard,
    DialogPost,
  },
  computed: {
    rows() {
      if (this.farm_posts.length > 0) {
        let lngth_posts = this.total_posts; //this.farm_posts[2].length
        let temp_perpage = 12;
        let dividedresult = lngth_posts / temp_perpage;
        let parsed = parseInt(dividedresult);
        if (parsed * temp_perpage < lngth_posts) {
          parsed++;
          let sringer = parsed.toString() + "0";
          let parsedback = parseInt(sringer);
          return parsedback;
        } else {
          let sringer = parsed.toString() + "0";
          let parsedback = parseInt(sringer);
          return parsedback;
        }
      }
    },
    spliced_posts() {
      let arr2 = [];
      if (this.farm_posts.length > 0) {
        arr2 = this.farm_posts[2].slice(this.start_index, this.end_index);
        return arr2;
      } else {
        return [];
      }
    },
  },
  watch: {
    farm_posts() {
      if (this.farm_posts.length > 0) {
        this.end_index = this.per_page;
      }
    },
  },
  
  created() {
    
    this.getFarmPosts(1);
  },
  methods: {
    async getFarmPosts(page) {
      this.postLoad = true;
      let { data } = await FarmsRepository.getFarmPosts({
        farm_name: this.selectedFarm.name,
        page: page,
      });
      console.log(data);
      if (page == 1) {
        this.total_posts = data.total_posts;
      }
      this.viewable_posts = data.posts;
      this.postLoad = false;
      window.scrollTo(50, 50);
    },
    setPost(post) {
      // console.log("asdas")
      this.selected_post = post;
      this.$bvModal.show("modal-lg");
      // setTimeout(() => {
      // this.$bvModal.hide("dicks");
        
      // }, 3000);
      
    },
    onChange(current, pkd) {
      console.log(current);
      // if(current<this.current){
      // this.end_index=this.per_page*current
      this.current = current;
      this.getFarmPosts(current);
      // this.start_index=(this.current-1)*this.per_page
      // }
      // else{
      // this.end_index=this.per_page*current
      // this.current = current;
      // this.start_index=(this.current-1)*this.per_page
      // }
    },
  },
  data() {
    return {
      postLoad: false,
      viewable_posts: [],
      total_posts: 0,
      farm_posts:[],
      start_index: 0,
      end_index: 0,
      current: 1,
      per_page: 12,
      selected_post: "",
    };
  },
};
</script>