<template>
  <div id="op">
    <div class="content pl-0 pr-0">
      <div class="container-fluid" style="height: 100vh">
        <div class="row mt-5">
          <div class="col-md-12">
            <b-card
              tag="article"
              class="post shadow mt-5 pb-4 col-md-4 mb-2 ml-auto mr-auto p-0"
              style="max-width: 28rem"
            >
              <template v-slot:header>
                <div
                  class="col-md-4 col-11 ml-auto p-2 mr-auto bg-success"
                  style="
                    border-top-right-radius: 1rem;
                    border-top-left-radius: 1rem;
                  "
                >
                  <h4 class="text-white bolds">LOGIN</h4>
                </div>
              </template>
              <h4 class="text-white bolds">LOGIN</h4>
              <img src="img/head.png" style="width: 4rem" alt="" />

              <b-card-body>
                <b-input-group class="mt-3">
                  <b-input-group-prepend>
                    <b-button
                      style="border: 1px solid #dcdcdc"
                      variant="outline-white"
                    >
                      <img
                        width="25"
                        src="img/landing/mobile-phone.png"
                        alt=""
                      />
                      <!-- <i class="fa fa-mobile" aria-hidden="true"></i> -->
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input
                    id="input-1"
                    style="padding: 1.3rem"
                    v-model="login.phoneno"
                    trim
                  >
                  </b-form-input>
                </b-input-group>
                <b-input-group class="mt-3 mb-3">
                  <b-input-group-prepend>
                    <b-button
                      style="border: 1px solid #dcdcdc"
                      variant="outline-white"
                    >
                      <!-- <i class="fa fa-lock" aria-hidden="true"></i> -->
                      <img width="25" src="img/landing/password.png" alt="" />
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input
                    id="input-2"
                    style="padding: 1.3rem"
                    v-model="login.password"
                    maxlength="15"
                    type="password"
                    trim
                  ></b-form-input>
                </b-input-group>
                <div class="row">
                  <div class="col-md-12 mb-3">
                    <button
                      v-on:click="validate_auth"
                      class="text-center pr-5 pt-2 pb-2 pl-5 btn btn-outline-success"
                    >
                      LOGIN
                    </button>
                  </div>
                  <div class="col-md-12 text-center">
                    <facebook-login
                      class="button"
                      appId="1759104297729821"
                      @login="onLogin"
                      @logout="onLogout"
                      logoutLabel="Logout"
                      @get-initial-status="getUserData"
                      @sdk-loaded="sdkLoaded"
                    >
                    </facebook-login>
                    <!-- <div v-if="isConnected" class="information">
                                    <h1>My Facebook Information</h1>
                                    <div class="well">
                                      <div class="list-item">
                                        <img :src="picture">
                                      </div>
                                      <div class="list-item">
                                        <i>{{name}}</i>
                                      </div>
                                      <div class="list-item">
                                        <i>{{email}}</i>
                                      </div>
                                      <div class="list-item">
                                        <i>{{personalID}}</i>
                                      </div>
                                    </div>
                                  </div> -->
                  </div>
                </div>
                <!-- <div class="row">
                                <div class="col-md-12">
                                  <b-button  variant="info"> <i class="fab fa-facebook-f"></i> Login using facebook</b-button>
                                </div>

                              </div> -->
                <div class="row">
                  <div class="col-md-12">
                    <router-link to="/forget-password">
                      <span class="text-danger" style="font-size: 15px"
                        >Forgot Password ?</span
                      >
                    </router-link>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <span style="font-size: 15px"
                      >Dont have account ?
                      <router-link to="/register"
                        ><span class="text-success">Sign up </span></router-link
                      >
                    </span>
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import facebookLogin from "facebook-login-vuejs";
import { RepositoryFactory } from "../Repository/RepositoryFactory";
const UserRepository = RepositoryFactory.get("user_repository");
export default {
  components: {
    facebookLogin,
  },
  data() {
    return {
      isConnected: false,
      name: "",
      email: "",
      personalID: "",
      picture: "",
      FB: undefined,
      errors: {
        email_error: false,
        password_error: false,
      },
      login: {
        phoneno: "",
        password: "",
        // phoneno: '03235400786',
        // password:'12345678'
        // phoneno: '03058597460',
        // password:'demopass'
      },
    };
  },
  watch: {
    name() {
      if (this.name != "") {
        // console.log({nickname:this.name,email:this.email,imgUri:this.picture})
        this.createFBuser();
      }
    },
  },
  methods: {
    async createFBuser() {
      this.$store.commit("setFB", this.FB);
      var { data } = await UserRepository.register({
        phoneno: this.email,
        nickname: this.name,
        fb_id: this.personalID,
        imgUri: this.picture,
        password: "",
      });
      console.log(data);
      // this.$store.dispatch("findConversations",data[0].id)

      this.$store.commit("setLoggedUser", {
        phoneno: this.email,
        id: data[0].id,
        nickname: this.name,
        email: this.email,
        image: this.picture,
        imgUri: this.picture,
        isFb: true,
      });
    },
    getUserData() {
      this.FB.api("/me", "GET", { fields: "id,name,email,picture" }, (user) => {
        if (user.error != null) {
          this.$store.commit("setNotifications", {
            message: user.error.message,
            type: "error",
          });
        } else {
          this.personalID = user.id;
          this.email = user.email;
          this.name = user.name;
          this.picture = user.picture.data.url;
        }
      });
    },
    sdkLoaded(payload) {
      this.isConnected = payload.isConnected;
      this.FB = payload.FB;
      if (this.isConnected == true) {
        this.getUserData();
      }
    },
    onLogin() {
      this.isConnected = true;
      this.getUserData();
      // console.log({nickname:this.name,email:this.email,imgUri:this.picture})
      // this.$router.push({path:'/dashboard'})
    },
    onLogout() {
      this.isConnected = false;
    },
    async fetchUserNotifications(id) {
      // user.id
      var { data } = await UserRepository.get_user_notifications({
        user_id: id,
      });
      console.log(data);
      this.$store.commit("setUserNotifications", data[0]);
    },
    async validate_auth() {
      if (this.login.phoneno != "" && this.login.password != "") {
        var { data } = await UserRepository.authenticatelogin(this.login);
        // console.log(data)
        if (data != 0) {
          this.getUserResources(data.id);

          // if(data.id!=21){
          this.fetchUserNotifications(data.id);

          // }
          this.$store.commit("setLoggedUser", { ...data, isFb: false });
        } else {
          this.$store.commit("setNotifications", {
            message: "Make sure to provide valid credentials",
            type: "error",
          });
        }
      } else {
        this.$store.commit("setNotifications", {
          message: "Make sure to fill in the fields",
          type: "error",
        });
      }
    },
    async getUserResources(id) {
      var { data } = await UserRepository.get_user_resources({ user_id: id });
      this.$store.commit("setUserResources", data);
    },
  },
};
</script>

<style  scoped>
.card-header {
  border-top-right-radius: 1rem !important;
  border-top-left-radius: 1rem !important;
}
</style>

<style>
.information {
  margin-top: 100px;
  margin: auto;
  display: flex;
  flex-direction: column;
}
.well {
  background-color: rgb(191, 238, 229);
  margin: auto;
  padding: 50px 50px;
  border-radius: 20px;
  /* display:inline-block; */
}
.login {
  width: 200px;
  margin: auto;
}
.list-item:first-child {
  margin: 0;
}
.list-item {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.button {
  margin: auto;
}
</style>

