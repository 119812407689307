<template>
  <div>
      <b-img src="img/landing/cover Qurbani1.jpg" fluid alt="Responsive image"></b-img>

    <!-- <b-carousel :interval="3000" v-model="slide" indicators >
      <b-carousel-slide
        img-src="img/cover1.png"
      />
      <b-carousel-slide
        img-src="img/cover3.png"
      />
      <b-carousel-slide
        img-src="img/cover4.png"
      />
    </b-carousel> -->
  </div>
</template>

<script>
export default {
  name: 'Slider',
  data() {
   return {
   slide: 0,
   sliding: null,

   };
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    }
  }
  
}
</script>

<style scoped>

</style>
