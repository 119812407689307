<template>
  <b-container
    fluid
    class="pr-0 pl-0"
    style="min-height: 500px; position: relative"
  >
    <b-container fluid class="mt-2 text-right">
      <!-- <h4 class="text-white">
        WE PROVIDE SADQAH AND AQIQA SERVICES <br />
        TO THOUSANDS OF USERS DAILY
      </h4> -->
      Change Page to
      <select v-model="lang">
        <option value="english">English</option>
        <option value="urdu">Urdu</option>
      </select>
      <b-button variant="success" class="ml-3" @click="checkFarm"
        >Register your Cattle Farm</b-button
      >
    </b-container>

    <!-- -->
    <b-card>
      <b-img v-if="lang == 'english'" src="./img/farms/fbe.png"></b-img>
      <b-img v-if="lang == 'english'" src="./img/farms/fae.png"></b-img>

      <b-img v-if="lang == 'urdu'" src="./img/farms/fbu.png"></b-img>
      <b-img v-if="lang == 'urdu'" src="./img/farms/fau.png"></b-img>
      <b-button
        v-if="selected_farm != null"
        variant="success"
        class="float-right"
        @click="selected_farm = null"
      >
        Back</b-button
      >

      <Farms
        v-if="selected_farm == null"
        :mine="false"
        @order-item="selectedFarm"
      />
      <FarmDetails v-else :selectedFarm="selected_farm" />
    </b-card>

    <b-modal
      hide-header
      id="modal-1"
      hide-footer
      title="Register Your Cattle Farm"
      centered
    >
      <div class="text-left">
        <h4 class="text-success text-center">Register Your Cattle Farm</h4>
        <form @submit.prevent="createFarms">
          <b-avatar
            variant="light"
            style="box-shadow: 0px 3px 6px #00000029"
            class="p-2"
            @click="onPickFile"
            size="6rem"
          >
            <b-icon
              @click="onPickFile"
              v-if="showurl == ''"
              font-scale="3"
              icon="camera-fill"
              variant="success"
            ></b-icon>
            <b-img @click="onPickFile" v-else :src="showurl"></b-img>
          </b-avatar>
          <input
            type="file"
            ref="FileInput"
            style="display: none"
            @change="onFilePicked"
            
          />
          <b-input
            style="background: #f7f7f8"
            v-model="newFarm.name"
            required
            class="mt-2"
            placeholder="Enter farm name"
          ></b-input>
          <b-input
            style="background: #f7f7f8"
            class="mt-2"
            v-model="newFarm.city"
            required
            placeholder="Enter City"
          ></b-input>
          <b-textarea
            style="background: #f7f7f8"
            class="mt-2"
            required
            v-model="newFarm.address"
            placeholder="Farm Address"
          ></b-textarea>
          <b-button v-if="isload==false" type="submit"  class="mt-2 float-right" variant="success">Save</b-button>
          <b-spinner v-else variant="success" class="mt-2 float-right" ></b-spinner>
        </form>
      </div>
    </b-modal>
  </b-container>
</template>
<script>
import FarmDetails from "../components/FarmDetails.vue";
import Farms from "../components/Farms";
import CSadqahOrder from "../components/CSadqahOrder";
import testTopNav from "../components/testTopNav.vue";
import { RepositoryFactory } from "../Repository/RepositoryFactory";
const FarmsRepository = RepositoryFactory.get("farms_repository");
import { mapGetters } from "vuex";
import { uuid } from "vue-uuid"; // uuid object is also exported to things

import S3 from "aws-s3";
const config = {
  bucketName: "qurbaniimages",
  region: "us-east-1",
  accessKeyId: "AKIA44SH2DQ6PVOA366C",
  secretAccessKey: "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",
  s3Url: "https://qurbaniimages.s3.us-east-1.amazonaws.com/" /* optional */,
  /* optional */
};
const S3Client = new S3(config);
export default {
  props:['fromdashboard'],
  components: {
    FarmDetails,
    Farms,
    CSadqahOrder,
    testTopNav,
  },
  computed: {
    ...mapGetters(["farms", "loggedUser"]),
  },
  data() {
    return {
      lang: "english",
      selected_farm: null,
      isload:false,
      uuid: uuid.v1(),
      file: "",
      showurl: "",
      newFarm: {
        name: "",
        city: "",
        address: "",
        image: "",
      },
    };
  },
  created() {
    console.log(this.fromdashboard)
    if(this.fromdashboard==true){
      setTimeout(() => {
       this.$bvModal.show("modal-1");        
      }, 2000);

    }
    // if (this.farms.length < 1) {
    //   this.getFarms();
    // }
  },
  methods: { 
    checkFarm() {
      if (this.loggedUser == null) {
        this.$store.commit("setNotifications", {
          message: "Login first to create farm",
          type: "error",
        });
        return;
      }
      this.$bvModal.show("modal-1");
    },
    onPickFile() {
      this.$refs.FileInput.click();
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async onFilePicked(event) {
      this.file = event.target.files[0];
      var opd = await this.getBase64(event.target.files[0]);
      this.showurl = opd;
    },
    selectedFarm(obj) {
      this.selected_farm = obj;
    },
    async createFarms() {
      this.isload=true;
      let newkey = uuid.v1();
      // let tem_img_key = newkey;
      // let tem_img_key = "," + newkey + "." + this.file.name.split(".")[1];
      if(this.showurl != ''){

        let self = this;
      S3Client.uploadFile(this.file, newkey)
        .then(async (da) => {
          console.log(da);
          console.log(self.newFarm);
          let { data } = await FarmsRepository.createFarm({
            ...self.newFarm,
            image: da.key,
            user_id: this.loggedUser.id,
          });
          console.log(data);
          self.isload=false;
          self.$bvModal.hide('modal-1')
          self.getFarms()
        })
        .catch((err) => console.error(err));

      }
      else{
           let { data } = await FarmsRepository.createFarm({
            ...this.newFarm,
            user_id: this.loggedUser.id,
          });
          console.log(data)
           this.isload=false;
          this.$bvModal.hide('modal-1')
          this.getFarms()
      }
      
    },
    async getFarms() {
      let { data } = await FarmsRepository.getAllFarms();
      this.$store.commit("setFarms", data);
      console.log(data);
    },
  },
};
</script>

