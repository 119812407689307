<template>
   <b-container class="mt-5">
      <h3 style="font-weight:bold;">FAQs Sadqah & Aqiqah Service</h3>
      <h6>VIEW COMPLETE DETAILS ON HOW WE DELIEVER SADQAH & AQIQAH SERVICES</h6>
      <ul style="list-style:none;" class=" mt-3 left-aligned">
         <li class="bolds">Q:How do I know your service is authentic?</li>
         <li>A: We have taken a number of measures to make sure that all our Sadqa service users in Pakistan and abroad are fully satisfied. Here are some some of those measures:</li>
         <li>1) You will receive a video of your Bakra being weighed, to calculate its final price</li>
         <li>2) You will receive a video of your Bakra being sacrficed with your nametag around its neck clearly visible while being slaughtered</li>
         <li>3) Depending on the service you choose, you will receive a video of your meat being distributed or a call when it is about to be delivered</li>
         <li class="bolds mt-3">Q: How much time does it take for an order to be processed?</li>
         <li>A: For all the three services stated above, we complete them within 48 hours of receiving the payment</li>
         <li class="bolds mt-3">Q: Are there any extra charges on top of the Bakra price?</li>
         <li>A: Ans: Yes, there is Rs 1000 Serivce charge for all the three services mentioned above. This covers the cost of acrificing the Bakra. There is an additional Rs 1000 Delivery and Distribution Charge, this covers the cost of delivery or distribution in the three services above.</li> 
         <li class="bolds mt-3">Q: If I want the meat to be distributed, who will receive it and how do I know that its reaching the derserving people?</li>
         <li>A: We partner with a welfare organisation named <a href="http://renaissancepakistan.com/" target="_black">Renaissance</a>  to distribute meat among orphans and widows. On days when we have more than hundred Sadqa Bakras, we distribute their meat in villages around Rawat. You will receive real-time updtaes with pictures and videos of your Sadqa meat being distributed among the under-privileged.</li>
         <li class="bolds mt-3">Q: What do you do with Bakra's skin, head and feet if we get meat delivered?</li>
         <li>We distribute the head and feet among families living below poverty line. We sell the skin and its proceeds are donated to <a href="http://renaissancepakistan.com/" target="_black">Renaissance</a> , a charity providing quality eduaction in disadvantaged communities</li>
      </ul>
      <h5 class="mt-5">Didn't find what you were looking for?</h5>
      <button class="col-md-4 btn btn-success"   
      v-clipboard:copy="message"
      v-clipboard:success="onCopy" @click="show=true">{{show==false? 'Qurbani Helpline':'+923065401886 Helpline'}}</button>
      <b-alert
      v-model="showTop"
      class="position-fixed fixed-top m-0 rounded-0"
      style="z-index: 2000;"
      variant="success"
      dismissible>
      Help Line phone number copied !
    </b-alert>
   </b-container>
   
      
</template>
<script>
  export default {
   data(){
      return{
         message: '+923065401886',
         showTop: false,
         show:false,
      }
    },
   methods: {
    onCopy: function (e) {
      this.showTop = !this.showTop
    },
    onError: function (e) {
      alert('Failed to copy texts')
    }
  }
   
  }
</script>

