<template>
  <div>
    <b-container class="pr-0 pl-0">
      <div
        style="
          border-bottom-right-radius: 1rem;
          border-bottom-left-radius: 1rem;
          min-height: 200px;
          background: linear-gradient(180deg, #000000 0%, #309f5d 100%) 0% 0%
            no-repeat padding-box;
        "
        class="p-1"
      >
        <b-row>
          <b-col md="8" style="align-self: center">
            <h2 class="text-white">Welcome to your Profile Section</h2>
          </b-col>
          <b-col md="4">
            <img
              class="float-md-left"
              style="width: 100px"
              src="img/myprofile/sheep.png"
              alt=""
            />
          </b-col>
        </b-row>
      </div>
      <div class="col-md-2" style="margin-top: -30px">
        <div class="card shadow p-3 text-center" style="border-radius: 1rem">
          <b-spinner
            v-if="imageLoad == true"
            class="ml-auto mr-auto"
            variant="success"
            label="Spinning"
          ></b-spinner>

          <img
            v-else
            :src="
              loggedUser.image != ''
                ? `https://qurbaniimages.s3.amazonaws.com/${replacer(
                    loggedUser.image
                  )}`
                : `img/dashboard/avatar.png`
            "
            class="rounded-circle"
            style="
              display: block;
              margin-left: auto;
              margin-right: auto;
              width: 70%;
              height: 75px;
            "
            alt=""
            srcset=""
          />
        </div>
        <!-- src="img/dashboard/avatar.png" -->
      </div>
      <div class="pl-md-4">
        <b-row class="mt-2">
          <b-col md="1">
            <span @click="onPickFile" style="cursor: pointer"> Change</span>
            <input
              style="display: none"
              ref="FileInput"
              @change="onFilePicked"
              type="file"
            />
          </b-col>
          <b-col md="1" class="pl-0">
            <span @click="deleteImage()" style="cursor: pointer">Delete</span>
          </b-col>
        </b-row>
      </div>
      <div class="pl-md-4">
        <b-row class="mt-2">
          <b-col md="4" cols="4" class="text-left">
            <b>Name</b>
          </b-col>
          <b-col md="4" cols="4" class="text-left">
            <b v-if="editName == false">{{ loggedUser.nickname }}</b>
            <input
              v-else
              type="text"
              class="form-control"
              v-model="upd_user.user_nickname"
            />
          </b-col>
          <b-col md="4" cols="4" class="text-left">
            <b-button
              class="bg-white text-success text-center"
              @click="updateName()"
            >
              {{ editName == false ? "Edit" : "Update" }}</b-button
            >
            <b-button
              v-if="editName == true"
              class="ml-2 bg-white text-success text-center"
              @click="editName = false"
              >Cancel</b-button
            >
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col md="4" cols="4" class="text-left">
            <b>Password</b>
          </b-col>
          <b-col md="4" cols="4" class="text-left">
            <b v-if="editPassword == false">************</b>
            <input
              v-else
              type="password"
              class="form-control"
              v-model="upd_user.user_password"
            />
          </b-col>
          <b-col md="4" cols="4" class="text-left">
            <b-button
              class="bg-white text-success text-center"
              @click="updatePassword()"
              >{{ editPassword == false ? "Edit" : "Update" }}</b-button
            >
            <b-button
              v-if="editPassword == true"
              class="ml-2 bg-white text-success text-center"
              @click="editPassword = false"
              >Cancel</b-button
            >
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col md="4" cols="4" class="text-left">
            <b>Mobile Number</b>
          </b-col>
          <b-col md="4" cols="4" class="text-left">
            <b v-if="editPhone == false">{{ loggedUser.phoneno }}</b>
            <input
              v-else
              type="number"
              class="form-control"
              v-model="upd_user.user_phoneno"
            />
          </b-col>
          <b-col md="4" cols="4" class="text-left">
            <b-button
              class="bg-white text-success text-center"
              @click="updatePhone()"
              >{{ editPhone == false ? "Edit" : "Update" }}</b-button
            >
            <b-button
              v-if="editPhone == true"
              class="ml-2 bg-white text-success text-center"
              @click="editPhone = false"
              >Cancel</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PostCard from "../components/PostCard.vue";
import { RepositoryFactory } from "../Repository/RepositoryFactory";
const UserRepository = RepositoryFactory.get("user_repository");
import { uuid } from "vue-uuid"; // uuid object is also exported to things

import globalfuns from "../mixins/globalfuns";
import S3 from "aws-s3";

const config = {
  bucketName: "qurbaniimages",
  region: "us-east-1",
  accessKeyId: "AKIA44SH2DQ6PVOA366C",
  secretAccessKey: "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",
  s3Url: "https://qurbaniimages.s3.us-east-1.amazonaws.com/" /* optional */,
};
const S3Client = new S3(config);

export default {
  name: "CMyProfile",
  mixins: [globalfuns],
  components: {
    PostCard,
  },
  data() {
    return {
      imageLoad: false,
      editName: false,
      editPassword: false,
      editPhone: false,
      upd_user: {
        user_id: "39391",
        user_password: "",
        user_phoneno: "",
        user_image: "",
        user_nickname: "",
      },
    };
  },
  computed: {
    ...mapGetters(["user_resources", "loggedUser"]),
  },
  created() {
    localStorage.setItem("route", "Dashboard");
  },
  mounted() {
    localStorage.setItem("route", "Dashboard");
  },
  methods: {
    onPickFile() {
      console.log("o");
      this.$refs.FileInput.click();
    },
    async deleteImage() {
      if (this.loggedUser.image != "") {
        this.imageLoad = true;
        this.upd_user.user_id = this.loggedUser.id;
        this.upd_user.user_password = this.loggedUser.password;
        this.upd_user.user_phoneno = this.loggedUser.phoneno;
        this.upd_user.user_nickname = this.loggedUser.nickname;
        this.upd_user.user_image = "";
        let resp1 = await UserRepository.updateProfile(this.upd_user);
        console.log(resp1);
        let resp = await UserRepository.getuser({
          user_id: this.loggedUser.id,
        });
        console.log(resp);

        localStorage.removeItem("loggedUser");
        this.$store.commit("setLoggedUser", resp.data[0]);
        this.imageLoad = false;
      } else {
        this.$store.commit("setNotifications", {
          message: "Upload an image before deleting it",
          type: "error",
        });
      }
    },
    onFilePicked(event) {
      this.imageLoad = true;
      const files = event.target.files;
      let img_name =
        uuid.v1() + "." + files[0].name.split(".")[1].toLowerCase();
      console.log(img_name);
      this.upd_user.user_id = this.loggedUser.id;
      this.upd_user.user_password = this.loggedUser.password;
      this.upd_user.user_phoneno = this.loggedUser.phoneno;
      this.upd_user.user_nickname = this.loggedUser.nickname;
      let self = this;

      console.log(this.upd_user);

      S3Client.uploadFile(files[0], img_name)
        .then(async (data) => {
          console.log(data);
          self.upd_user.user_image = img_name;

          let resp1 = await UserRepository.updateProfile(self.upd_user);
          console.log(resp1);
          let resp = await UserRepository.getuser({
            user_id: self.loggedUser.id,
          });
          console.log(resp);

          localStorage.removeItem("loggedUser");
          self.$store.commit("setLoggedUser", resp.data[0]);
          self.imageLoad = false;
        })
        .catch((err) => console.error(err));
    },
    async updateName() {
      if (this.editName == true) {
        if (this.upd_user.user_nickname != "") {
          this.upd_user.user_id = this.loggedUser.id;
          this.upd_user.user_password = this.loggedUser.password;
          this.upd_user.user_phoneno = this.loggedUser.phoneno;
          this.upd_user.user_image = this.loggedUser.image;

          let { data } = await UserRepository.updateProfile(this.upd_user);
          let resp = await UserRepository.getuser({
            user_id: this.loggedUser.id,
          });
          localStorage.removeItem("loggedUser");
          this.$store.commit("setLoggedUser", resp.data[0]);
        } else {
          this.$store.commit("setNotifications", {
            message: "Make sure to fill in the fields",
            type: "error",
          });
        }
        this.editName = false;
      } else {
        this.editName = true;
      }
    },
    async updatePassword() {
      console.log(this.editPassword);
      if (this.editPassword == true) {
        if (this.upd_user.user_password != "") {
          this.upd_user.user_id = this.loggedUser.id;
          this.upd_user.user_nickname = this.loggedUser.nickname;
          this.upd_user.user_phoneno = this.loggedUser.phoneno;
          this.upd_user.user_image = this.loggedUser.image;
          console.log(this.upd_user);
          let { data } = await UserRepository.updateProfile(this.upd_user);
          let resp = await UserRepository.getuser({
            user_id: this.loggedUser.id,
          });
          localStorage.removeItem("loggedUser");
          this.$store.commit("setLoggedUser", resp.data[0]);
        } else {
          this.$store.commit("setNotifications", {
            message: "Make sure to fill in the fields",
            type: "error",
          });
        }
        this.editPassword = false;
      } else {
        this.editPassword = true;
      }
    },

    async updatePhone() {
      console.log(this.editPhone);
      if (this.editPhone == true) {
        if (this.upd_user.user_phoneno != "") {
          this.upd_user.user_id = this.loggedUser.id;
          this.upd_user.user_nickname = this.loggedUser.nickname;
          this.upd_user.user_password = this.loggedUser.password;
          this.upd_user.user_image = this.loggedUser.image;
          console.log(this.upd_user);
          let { data } = await UserRepository.updateProfile(this.upd_user);
          let resp = await UserRepository.getuser({
            user_id: this.loggedUser.id,
          });
          localStorage.removeItem("loggedUser");
          this.$store.commit("setLoggedUser", resp.data[0]);
        } else {
          this.$store.commit("setNotifications", {
            message: "Make sure to fill in the fields",
            type: "error",
          });
        }

        this.editPhone = false;
      } else {
        this.editPhone = true;
      }
    },
  },
};
</script>

<style>
</style>