<template>
  <b-container>
    <h3 class="mt-4">Cows Posts</h3>
    <b-spinner v-if="postLoad == true" variant="success"></b-spinner>

    <div v-if="posts.length > 0 && postLoad == false" class="mt-5 row">
      <div v-for="(post_item, i) in viewable_posts" :key="i" class="col-md-4">
        <PostCard :post_item="post_item" @toggle-item="setPost" />
      </div>
    </div>
    <a-pagination
      class="pages"
      :current="current"
      :default-current="5"
      @change="onChange"
      :total="rows"
    />
    <!-- <b-pagination
      v-model="current"
      :total-rows="rows"
      @change="onChange"
      per-page="12"
      aria-controls="my-table"
    ></b-pagination> -->
    <b-modal
      hide-footer
      id="cow-lg"
      ref="cow-lg"
      size="lg"
      :title="selected_post.category"
    >
      <DialogPost
        :selected_post="selected_post"
        @chat-show="$emit('chat-show', selected_post)"
      />
    </b-modal>
  </b-container>
</template>
<script>
import PostCard from "../components/PostCard.vue";
import DialogPost from "../components/DialogPost.vue";
import { RepositoryFactory } from "../Repository/RepositoryFactory";
const PostsRepository = RepositoryFactory.get("posts_repository");

import { mapGetters } from "vuex";
export default {
  name: "CowPosts",
  components: {
    PostCard,
    DialogPost,
  },
  computed: {
    ...mapGetters(["posts"]),
    rows() {
      if (this.posts.length > 0) {
        let lngth_posts = this.total_posts; //this.posts[2].length
        let temp_perpage = 12;
        let dividedresult = lngth_posts / temp_perpage;
        let parsed = parseInt(dividedresult);
        if (parsed * temp_perpage < lngth_posts) {
          parsed++;
          let sringer = parsed.toString() + "0";
          let parsedback = parseInt(sringer);
          return parsedback;
        } else {
          let sringer = parsed.toString() + "0";
          let parsedback = parseInt(sringer);
          return parsedback;
        }
      }
    },
    spliced_posts() {
      let arr2 = [];
      if (this.posts.length > 0) {
        arr2 = this.posts[2].slice(this.start_index, this.end_index);
        return arr2;
      } else {
        return [];
      }
    },
  },
  watch: {
    posts() {
      if (this.posts.length > 0) {
        this.end_index = this.per_page;
      }
    },
  },
  mounted() {
    localStorage.setItem("route", "AllPosts");
    // this.getCowPosts(1)
  },
  created() {
    localStorage.setItem("route", "AllPosts");
    if (this.posts.length > 0) {
      this.end_index = this.per_page;
    }
    this.getCowPosts(1);
  },
  methods: {
    async getCowPosts(page) {
      this.postLoad = true;
      let { data } = await PostsRepository.category_pagination_posts({
        page: page,
        category: "Cow",
      });
      console.log(data);
      if (page == 1) {
        this.total_posts = data.total_posts;
      }
      this.viewable_posts = data.posts;
      this.postLoad = false;
      window.scrollTo(50, 50);
    },
    setPost(post) {
      this.selected_post = post;
      this.$bvModal.show("cow-lg");
    },
    onChange(current, pkd) {
      console.log(current);
      // if(current<this.current){
      // this.end_index=this.per_page*current
      this.current = current;
      this.getCowPosts(current);
      // this.start_index=(this.current-1)*this.per_page
      // }
      // else{
      // this.end_index=this.per_page*current
      // this.current = current;
      // this.start_index=(this.current-1)*this.per_page
      // }
    },
  },
  data() {
    return {
      postLoad: false,
      viewable_posts: [],
      total_posts: 0,
      start_index: 0,
      end_index: 0,
      current: 1,
      per_page: 12,
      selected_post: "",
    };
  },
};
</script>