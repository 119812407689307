<template>
  <b-container>
    <h3 class="mt-4">Search Results</h3>
    <div v-if="search_results.length > 0" class="mt-5 row">
      <div v-for="(post_item, i) in viewable_posts" :key="i" class="col-md-4">
        <PostCard :post_item="post_item" @toggle-item="setPost" />
      </div>
    </div>
    <div v-else class="text-center">
      <b-spinner variant="success" label="Spinning"></b-spinner>
    </div>
    <a-pagination :current="current" @change="onChange" :total="rows" />
    <b-modal
      hide-footer
      id="sec-res"
      ref="sec-res"
      size="lg"
      :title="selected_post.category"
    >
      <DialogPost
        :selected_post="selected_post"
        @chat-show="$emit('chat-show', selected_post)"
      />
    </b-modal>
  </b-container>
</template>
<script>
import PostCard from "../components/PostCard.vue";
import DialogPost from "../components/DialogPost.vue";
import { RepositoryFactory } from "../Repository/RepositoryFactory";
const SearchRepository = RepositoryFactory.get("search_repository");

import { mapGetters } from "vuex";
export default {
  name: "SearchResultPosts",
  props: ["search_results", "data", "search_params"],
  components: {
    PostCard,
    DialogPost,
  },
  computed: {
    rows() {
      if (this.data.total_posts > 0) {
        let lngth_posts = this.data.total_posts; //this.posts[2].length
        let temp_perpage = 12;
        let dividedresult = lngth_posts / temp_perpage;
        let parsed = parseInt(dividedresult);
        if (parsed * temp_perpage < lngth_posts) {
          parsed++;
          let sringer = parsed.toString() + "0";
          let parsedback = parseInt(sringer);
          return parsedback;
        } else {
          let sringer = parsed.toString() + "0";
          let parsedback = parseInt(sringer);
          return parsedback;
        }
      }
    },

    spliced_posts() {
      let arr2 = [];
      if (this.search_results.length > 0) {
        arr2 = this.search_results.slice(this.start_index, this.end_index);
        return arr2;
      } else {
        return [];
      }
    },
  },
  watch: {
    search_results() {
      if (this.search_results.length > 0) {
        this.end_index = this.per_page;
      }
    },
  },
  mounted() {
    localStorage.setItem("route", "AllPosts");
  },
  created() {
    console.log(this.search_params);
    localStorage.setItem("route", "AllPosts");
    // if (this.search_results.length > 0) {
    //   this.end_index = this.per_page;
    // }
    this.viewable_posts = this.search_results;
  },
  methods: {
    setPost(post) {
      this.selected_post = post;
      this.$bvModal.show("sec-res");
    },
    onChange(current, pkd) {
      console.log(current);

      this.current = current;
      this.getNewSearchResults(current);
    },
    async getNewSearchResults(page) {
      // this.postLoad = true;
      //   if (this.type < 0) {
      //     let { data } = await SearchRepository.search_results({
      //       ...this.local_search_params,
      //       page: page,
      //     });
      //     console.log(data);
      //     this.viewable_posts = data.posts;
      //   } else {
      //     let tip = "";
      //     if (this.type == 2) {
      //       tip = "cow";
      //     } else if (this.type == 3) {
      //       tip = "goat";
      //     }
      //     if (this.type == 4) {
      //       tip = "sheep";
      //     }
      let { data } = await SearchRepository.search_results({
        ...this.search_params,
        page: page,
        //   category: tip,
      });
      console.log(data);
      this.viewable_posts = data.posts;
      //   }
      // this.postLoad = false;
      window.scrollTo(50, 50);
    },
  },
  data() {
    return {
      viewable_posts: [],
      start_index: 0,
      end_index: 0,
      current: 1,
      per_page: 12,
      selected_post: "",
    };
  },
};
</script>