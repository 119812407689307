<template>
<div>
 <b-card  tag="article" @click="emitDashboard(post_item)"  :style="[post_item.viewed==null || post_item.viewed==false ? 'background:#27A84430':'','cursor:pointer;']" class="shadow mt-1 mb-2">
   <div class="row"> 
      <div :class="['col-md-1', 'pr-0', isMobile==true ? '':'left-aligned' ]" >
          <img v-if="checkComments(post_item.commented_user,'liked')" src="img/notifications/emoji.png" width="40">
          <img v-if="checkComments(post_item.commented_user,'commented')" src="img/notifications/communication.png" width="40">
          <img v-if="checkComments(post_item.commented_user,'messaged')" src="img/notifications/communication.png" width="40">
          <img v-if="checkComments(post_item.commented_user,'updated')" src="img/notifications/speaker.png" width="40">
      </div>
      <div  :class="['col-md-5','pl-0', 'mt-2', isMobile==true ? '':'left-aligned' ]">
          <span > {{post_item.commented_user}}</span>
      </div>
      <div  :class="['col-md-6', 'mt-2', isMobile==true ? '':'right-aligned' ]" >
          <span >{{post_item.updated_at.split('T')[0]}}</span>
      </div>
    </div>

</b-card>
</div>
</template>
<script>
import globalfuns from '../mixins/globalfuns'
import { RepositoryFactory } from '../Repository/RepositoryFactory'
const PostsRepository = RepositoryFactory.get('posts_repository')
const UserRepository = RepositoryFactory.get('user_repository')

import {mapGetters} from 'vuex'
  export default {
    props:['post_item'],
    mixins:[globalfuns],
    data() {
      return {

      }
    },
    computed:{
      ...mapGetters(['loggedUser'])
    }, 
    methods:{
      checkComments(arg,arg_type){
        let resp=arg.includes(arg_type)
        return resp;
      },
      emitDashboard(post_item){
        if(post_item.commented_user.includes('messaged')){
          this.changeStatus(post_item.id)
          this.$emit("return-order",9)
        }
        else if(post_item.commented_user.includes('liked') || post_item.commented_user.includes('commented')){
          this.changeStatus(post_item.id)
          this.$emit("return-order",{type:'liked',value:2,obj:post_item})
        }


      },
      async changeStatus(id) {
        let resp=await PostsRepository.update_notification_status({id:id,viewed:true})
        var {data}= await UserRepository.get_user_notifications({user_id:this.loggedUser.id})
        this.$store.commit("setUserNotifications",data[0])
        
      }
    }
  }
</script>

<style>

</style>
