import Vue from "vue";
import firebase from "firebase";

const state = {
  loggedUser: null,
  notifications: {},
  user_resources: [],
  user_notifications: [],
  admin_orders: [],
  promo: {},
  FB: null,
  videos:[],
};

const getters = {
  admin_orders: (state) => {
    return state.admin_orders;
  },
  loggedUser: (state) => {
    return state.loggedUser;
  },
  promo: (state) => state.promo,
  notifications: (state) => {
    return state.notifications;
  },
  user_resources: (state) => {
    return state.user_resources;
  },
  user_notifications: (state) => {
    return state.user_notifications;
  },
  videos: state=>state.videos,
  FB: (state) => {
    return state.FB;
  },
};

const actions = {
  findAllVideos({ commit }) {
    console.log("Camesss")
    firebase
      .database()
      .ref("f_utube_v")
      .on("value", (snapshot) => {
        commit("setAllVIdeos", {
          ...snapshot.val(),
        });
      });
  },
};
const mutations = {
  setNotifications: (state, payload) => {
    state.notifications = payload;
  },
  setUserNotifications: (state, payload) => {
    state.user_notifications = payload;
  },
  setAllVIdeos:(state,payload)=>{
    console.log(payload)
    state.videos=payload
  },
  setLoggedUser: (state, payload) => {
    console.log("auth user set");
    if (localStorage.getItem("loggedUser") == null) {
      localStorage.setItem("loggedUser", JSON.stringify(payload));
    }
    state.loggedUser = payload;
  },
  setNewUserLikes(state, payload) {
    state.user_resources[2].push(payload);
    localStorage.removeItem("user_resources");
    localStorage.setItem(
      "user_resources",
      JSON.stringify(state.user_resources)
    );
  },
  removeOldLikes(state, payload) {
    const deleteappt = state.user_resources[2].find(
      (user_res_item) => user_res_item.post_id == payload.post_id
    );
    const index = state.user_resources[2].indexOf(deleteappt);
    state.user_resources[2].splice(index, 1);
    localStorage.removeItem("user_resources");
    localStorage.setItem(
      "user_resources",
      JSON.stringify(state.user_resources)
    );
  },
  setUserResources: (state, payload) => {
    console.log("user resources set");
    if (localStorage.getItem("user_resources") == null) {
      localStorage.setItem("user_resources", JSON.stringify(payload));
    }
    state.user_resources = payload;
  },
  setAdminResources: (state, payload) => {
    state.admin_orders = payload;
  },
  setFB(state, payload) {
    state.FB = payload;
    localStorage.setItem("FB", JSON.stringify(payload));
  },
  setPromo(state, payload) {
    state.promo = payload;
  },
  logout(state, payload) {
    state.loggedUser = payload;
    state.user_resources = [];
    localStorage.removeItem("loggedUser");
    localStorage.removeItem("user_resources");
    localStorage.removeItem("FB");
    localStorage.removeItem("route");
    localStorage.clear();
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
