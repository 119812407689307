<template>
    <div>
        <h3 class="left-aligned text-success"> <b> Notifications </b> </h3>
        <div v-if="user_notifications.length>0" class="row">
            <div class="col-md-12 col-12" v-for="(post_item,i) in user_notifications.slice(0,10)" :key="i">
                <NotificationItem @return-order="rereturns" :post_item="post_item"/>
            </div>
        </div>
        <div v-else>
            No Notifications to show
        </div>
    </div>

</template>

<script>
import {mapGetters} from 'vuex'
import PostCard from '../components/PostCard.vue'
import NotificationItem from '../components/NotificationItem.vue'

export default {
    components:{
        NotificationItem
    },
    computed:{
        ...mapGetters(['user_notifications'])
    },
    methods:{
        rereturns(id){
            this.$emit('return-order',id)
        }
    }

}
</script>

<style>

</style>