<template>
  <b-container
    fluid
    class="pr-0 pl-0"
    style="min-height: 500px; position: relative"
  >
    <b-container fluid class="mt-2 text-right">
      <!-- <h4 class="text-white">
        WE PROVIDE SADQAH AND AQIQA SERVICES <br />
        TO THOUSANDS OF USERS DAILY
      </h4> -->
    </b-container>

    <!-- -->
    <b-card>
      <b-row>
        <b-col
          md="3"
          cols="12"
          :class="i > 3 ? 'mt-3' : ''"
          v-for="(item, i) in Object.keys(videos).reverse()"
          :key="i"
        >
          <iframe
            width="560"
            height="315"
            :src="`https://www.youtube.com/embed/${videos[item].key}`"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>

          <!-- <span>12 comments</span> -->
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>
<script>
import testTopNav from "../components/testTopNav.vue";
import { RepositoryFactory } from "../Repository/RepositoryFactory";
import { mapGetters } from "vuex";

export default {
  components: {
    testTopNav,
  },
  computed: {
    ...mapGetters(["videos", "loggedUser"]),
  },
  data() {
    return {};
  },
  created() {
    if (this.videos.length < 1) {
      this.$store.dispatch("findAllVideos");
    }
  },
};
</script>

