<template>
   <b-container style="margin-top: 5rem !important;">
      <h3 class="bolds">Terms And Conditions</h3>
      <h6>PLEASE CAREFULLY READ THESE TERMS OF USE. BY ACCESSING AND USING THIS QURBANI APP YOU
      INDICATE YOUR UNDERSTANDING AND ACCEPTANCE OF THESE TERMS. IF YOU DO NOT AGREE TO THESE 
      TERMS YOU MAY NOT USE THIS QURBANI APP</h6>
      <ul class="left-aligned">
         <li>Any of the animals presented in this app are not owned by Qurbani App. We are a platform for livestock enthusiasts to buy, sell and share their animals with the community.</li>
         <li>We act as an online market place platform to allow our users who comply with these Terms to offer, sell, and buy products and services listed on the app. Although you may be able to conduct payment and other transactions through the Website, using third-party vendors such as Jazz Payment, Qurbani App is not in any way involved in such transactions.</li>
         <li>You acknowledge and agree that you are solely responsible for your own Content posted on, transmitted through, or linked from the Service and the consequences of posting, transmitting, linking or publishing it.</li>
         <li>Qurbani App does not permit copyright infringing activities and infringement of intellectual property rights on Qurbani App's web and mobile platforms, and Qurbani App may at its sole discretion, remove any infringing content</li>
         
         <li>Qurbani App does not endorse any Content or any opinion, statement, recommendation, or advice expressed therein, and Qurbani App expressly disclaims any and all liability in connection with user Content. Qurbani App does not permit copyright infringing activities and infringement of intellectual property rights on the Website, and Qurbani App may, at its sole discretion, remove any infringing Content.</li>
         <li>We are not responsible for the prices quoted by sellers on this app.</li>
         <li>Payments processed through Qurbani App are subject to 6% charge on top of the price of the animal.</li>
         <li>This 6% extra charge shall be paid by the buyer unless unless otherwise agreed.</li>
         <li>Any post (image, video, comment) that is not relevant for the livestock sector shall be immediately deleted without any warning.</li>
         <li>Qurbani App reserves the right to delete any user from the app or limit their access.</li>
         <li>If an animal, bought on Qurbani App is not in accordance with the requirements of the buyer, buyer my return that animals to the seller and refund the amount, unless agreed otherwise.</li>
         <li>In case of a dispute, you are requested to contact us via the Contact Us tab on the app and the website. Qurbani App’s verdict in case of dispute shall be final.</li>
         <li>Fee paid for marketing or promoting certain animals or farms is refundable within the 24 hours of payment only </li>
         <li>To post an animal on Featured section, user is requested to pay a certain amount in accordance with the current rate.</li>
         <li>Qurbani App has the right to permanently block any user found in violation of the above terms and conditions </li>
         <!-- <li>The price and description of animals added on Qurbani App are the prerogative of sellers. They have the right to disclose as much information as possible.</li>
         
         <li>We confirm not to share the data of our users with any third party</li>
         <li>Standard delivery times of animals shall be mutually agreed by the buyer and the seller before processing the transaction. Qurbani will not take any responsibility for the transportation time</li>
         <li>For animals bought and sold through the platform and payment processed through Qurbani App:</li>
         <li>Buyer is required to confirm the acceptance of the qurbani animal with in 24 hours of receiving the animal</li>
         <li>Buyer will be liable to pay 6% transaction processing fee on top of the agreed price of the animal</li>
         <li>Qurbani App will disburse the transaction money to the seller account after 3 days of the transaction</li>
         <li>The payments through Qurbani App shall only be processed in Pakistani Rupee</li>
       -->
      
      
      
      
      
      
      </ul>
   </b-container>
      
</template>
<script>
  export default {
   
   
  }
</script>

