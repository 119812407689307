import Repository from "./Repository";
const promo_Resource = "posts/create_promo_code";
const current_Promo = "posts/get_valid_promo_code";

export default {
  new_promo_code(payload) {
    return Repository.post(`${promo_Resource}`, payload);
  },
  get_current_promo() {
    return Repository.get(`${current_Promo}`);
  },
};
