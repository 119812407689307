<template>
   <b-container class="mt-5">
      <h3 style="font-weight:bold;">About Us</h3>
      <h6>We are on a goal to disrupt the livestock sector and bridge the gap between the stake holders of livestock sector. We believe that in Agro-based economy like Pakistan Qurbani App can substantially increase the income of farmers ultimately up lifting the economy of Pakistan. We believe that each and every tech company should build products that can sole the problem and have real impact on the lives of the people.</h6>
      <h3 style="font-weight:bold;" class="mt-5">Visit Us</h3>
      <div class="row">
        <div class="col-md-6">
             <gmap-map :center="center" :zoom="19"  style="width:100%;  height: 400px;" >
               <!-- <GmapMarker
                :position="center"
                :draggable="true"
                 @drag="updateCoordinates"
              /> -->
            </gmap-map>
        </div>
        <div class="col-md-6 mt-auto mb-auto">
            Address : National Incubation Center Street 6 H-9/1.Islamabad
            Contact : +92 306 540 1886
            Foreign contact : +44 7403 228448
            Email : Bakra@qurbaniapp.com
        </div>
      </div>
   </b-container>
</template>
<script>
  export default {
    methods:{
      updateCoordinates(long){
        // console.log(long)
                console.log(long.latLng.lng())
                console.log(long.latLng.lat())
      }
    },
    data(){
    return{
      // ,,16z
// ,,18z,,21z
        center: { lat: 33.666728931095555, lng: 73.0406235969416 },

    }
    },
    
   
  }
</script>

