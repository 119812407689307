const state = {
    posts: []
};

const getters = {
    posts: (state) => {
        return state.posts
    }
};

const actions = {
    removeAct({commit,rootState},payload) {
        const deletepost = rootState.user.user_resources[0].find(post_item=>post_item.id == payload.id);
        const index=rootState.user.user_resources[0].indexOf(deletepost)
        rootState.user.user_resources[0].splice(index, 1);     
        localStorage.removeItem("user_resources");
        localStorage.setItem("user_resources",JSON.stringify(rootState.user.user_resources))
    }
};
const mutations = {
    setPosts:(state,payload) =>{
        state.posts=payload
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}
