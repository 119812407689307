<template>
  <div>
    <b-container class="pr-0 pl-0 m-0">
      <div
        style="
          border-bottom-right-radius: 1rem;
          border-bottom-left-radius: 1rem;
          min-height: 200px;
          background: linear-gradient(180deg, #000000 0%, #309f5d 100%) 0% 0%
            no-repeat padding-box;
        "
        class="p-1"
      >
        <b-row>
          <b-col md="8" style="align-self: center">
            <h2 class="text-white">Get access to all your posts</h2>
          </b-col>
          <b-col md="4">
            <img
              class="float-md-left"
              style="width: 100px"
              src="img/myprofile/sheep.png"
              alt=""
            />
          </b-col>
        </b-row>
      </div>
    </b-container>
    <!-- <h3 class="left-aligned text-success"> <b> Get access to all your posts </b> </h3> -->
    <div class="row">
      <div class="col-md-4" v-for="(post_item, i) in spliced_posts" :key="i">
        <PostCard :post_item="post_item" />
      </div>
    </div>
    <a-pagination
      v-if="spliced_posts.length > 0"
      class="mb-4"
      :current="current"
      @change="onChange"
      :total="rows"
    />

    <div v-if="this.selected_post != null">
      <b-modal
        hide-footer
        id="modal-post"
        ref="my-lg"
        size="lg"
        :title="getPost.category"
      >
        <DialogPost
          :selected_post="getPost"
          @chat-show="$emit('chat-show', selected_post)"
        />
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DialogPost from "../components/DialogPost.vue";
import PostCard from "../components/PostCard.vue";
export default {
  props: ["selected_post"],
  components: {
    PostCard,
    DialogPost,
  },
  data() {
    return {
      start_index: 0,
      end_index: 12,
      current: 1,
      per_page: 12,
    };
  },
  methods: {
    onChange(current, pkd) {
      this.end_index = this.per_page * current;
      this.current = current;
      this.start_index = (this.current - 1) * this.per_page;
    },
  },
  computed: {
    ...mapGetters(["user_resources", "loggedUser"]),
    getPost() {
      let obj = this.user_resources[0].find(
        (item) => item.id == this.selected_post.post_id
      );
      return obj;
    },
    spliced_posts() {
      let arr2 = [];
      if (this.user_resources.length > 0) {
        arr2 = this.user_resources[0].slice(this.start_index, this.end_index);
        return arr2;
      } else {
        return [];
      }
    },
    rows() {
      if (this.user_resources.length > 0) {
        let lngth_posts = this.user_resources[0].length;
        let temp_perpage = 12;
        let dividedresult = lngth_posts / temp_perpage;
        let parsed = parseInt(dividedresult);
        if (parsed * temp_perpage < lngth_posts) {
          parsed++;
          let sringer = parsed.toString() + "0";
          let parsedback = parseInt(sringer);
          return parsedback;
        } else {
          let sringer = parsed.toString() + "0";
          let parsedback = parseInt(sringer);
          return parsedback;
        }
      }
    },
  },
  created() {
    if (this.selected_post != null) {
      console.log(this.selected_post);
      this.$bvModal.show("modal-post");
    }
    localStorage.setItem("route", "Dashboard");
  },
  mounted() {
    localStorage.setItem("route", "Dashboard");
  },
};
</script>

<style>
</style>