import Vue from "vue";
import Router from "vue-router";
import Landing from "@/pages/Landing";
import TermsAndConditions from "@/pages/TermsAndConditions";
import SadqahAndAqiqa from "@/pages/SadqahAndAqiqa";
import FAQs from "@/pages/FAQs";
import ContactUs from "@/pages/ContactUs";
import AboutUs from "@/pages/AboutUs";
import Login from "@/pages/Login";
import Register from "@/pages/Register";
import Dashboard from "@/pages/Dashboard";
import NewPost from "@/pages/NewPost";
import UpdatePost from "@/pages/UpdatePost";
import ForgetPassword from "@/pages/ForgetPassword";
import IjtmaeQurbani from "@/pages/IjtmaeQurbani";
import testLanding from "@/pages/test_Landing";
import testTermsAndConditions from "@/pages/testTermsAndConditions";
import testContactUs from "@/pages/testContactUs";
import testSadqahAndAqiqa from "@/pages/testSadqahAndAqiqa";
import testAllPosts from "@/pages/testAllPosts";
import testLogin from "@/pages/testLogin";
import testSearchResults from "@/pages/testSearchResults";
import testIjtmaeQurbani from "@/pages/testIjtmaeQurbani";
import testDiseases from "@/pages/testDiseases";
import testFarms from "@/pages/testFarms";
import testVideos from "@/pages/testVideos";
import testInfoVideos from "@/pages/testInfoVideos";
import testForums from "@/pages/testForums";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Landing",
      component: testLanding,
    },
    
    {
      path: "/farms",
      name: "testFarms",
      component: testFarms,
      props:true,
      
    },
    {
      path: "/videos",
      name: "testVideos",
      component: testVideos,
    },
    {
      path: "/forums",
      name: "testForums",
      component: testForums,
    },
    
    {
      path: "/testlanding",
      name: "TestLanding",
      component: testLanding,
    },

    {
      path: "/testlogin",
      name: "testLogin",
      component: testLogin,
    },
    {
      path: "/diseases",
      name: "Diseases",
      component: testDiseases,
    },

    {
      path: "/search-results",
      name: "SearchResults",
      component: testSearchResults,
      props: true,
    },

    {
      path: "/testallposts",
      name: "testAllPosts",
      component: testAllPosts,
    },
    {
      path: "/testallposts/:id",
      name: "testAllPosts",
      component: testAllPosts,
    },
    {
      path: "/testcontactus",
      name: "testContactUs",
      component: testContactUs,
    },
    {
      path: "/testsadqaqiqa",
      name: "testSadqahAndAqiqa",
      component: testSadqahAndAqiqa,
    },
    {
      path: "/informational-videos",
      name: "Informational VIdeos",
      component: testInfoVideos,
    },
    {
      path: "/testlanding",
      name: "TestLanding",
      component: testLanding,
    },

    {
      path: "/testterms",
      name: "testTermsAndConditions",
      component: testTermsAndConditions,
    },

    {
      path: "/update-post",
      name: "UpdatePost",
      component: UpdatePost,
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/ijtmae-qurbani",
      name: "IjtmaeQurbani",
      component: testIjtmaeQurbani,
      props: true,
    },
    {
      path: "/new-post",
      name: "NewPost",
      component: NewPost,
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/login",
      name: "Login",
      component: testLogin,
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: Dashboard,
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
    },
    {
      path: "/forget-password",
      name: "ForgetPassword",
      component: ForgetPassword,
    },
    {
      path: "/all_posts",
      name: "AllPosts",
      component: testAllPosts,
      props: true,
    },
    {
      path: "/all_posts/:id",
      name: "AllPosts",
      component: testAllPosts,
      props: true,
    },
    {
      path: "/terms-and-conditions",
      name: "terms-and-conditions",
      component: testTermsAndConditions,
    },
    {
      path: "/sadqah-and-aqiqa",
      name: "sadqah-and-aqiqa",
      component: testSadqahAndAqiqa,
    },
    {
      path: "/terms-and-conditions",
      name: "terms-and-conditions",
      component: testTermsAndConditions,
    },
    {
      path: "/contact-us",
      name: "contact-us",
      component: testContactUs,
    },
    {
      path: "/about-us",
      name: "about-us",
      component: AboutUs,
    },
    {
      path: "/faqs",
      name: "faqs",
      component: FAQs,
    },

    // {
    //   path:'/searchresults',
    //   name:'searchresults',
    //   component:SearchResults
    // },
    // {
    //   path:'/resultdetails',
    //   name:'resultdetails',
    //   component:ResultDetails
    // }
  ],
});
