var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"pr-0 pl-0",staticStyle:{"min-height":"500px","position":"relative"},attrs:{"fluid":""}},[_c('b-card',[_c('h4',{staticClass:"text-success text-center"},[_vm._v(" Post Discussions here and become an active community member ")]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-input',{staticClass:"mb-5 mt-3",attrs:{"placeholder":"Search here"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.loggedUser != null)?_c('b-col',{staticClass:"text-right"},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-1",modifiers:{"modal-1":true}}],attrs:{"variant":"success"}},[_vm._v(" New Discussion ")])],1):_vm._e()],1),_c('b-modal',{attrs:{"centered":"","id":"modal-1","ok-title":"Save","title":"New Discussion"},on:{"ok":_vm.saveDiss}},[_c('b-textarea',{staticClass:"mb-3",attrs:{"placeholder":"Enter forum details"},model:{value:(_vm.newDi.question),callback:function ($$v) {_vm.$set(_vm.newDi, "question", $$v)},expression:"newDi.question"}}),_vm._v(" "+_vm._s(_vm.file == null ? "Select Attachment" : _vm.file.name)+" "),_c('b-avatar',{attrs:{"button":"","variant":"success"},on:{"click":_vm.onPickFile}},[_c('i',{staticClass:"fa fa-paperclip",attrs:{"aria-hidden":"true"}})]),_c('input',{ref:"fils",staticStyle:{"display":"none"},attrs:{"type":"file"},on:{"change":_vm.onFilePicked}})],1),_c('b-tabs',{attrs:{"content-class":"mt-5"},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('b-tab',{attrs:{"title":"Recent","active":""}},_vm._l((_vm.lecdisc),function(item,l){return _c('div',{key:l},[_c('b-card',{staticClass:"mt-3",staticStyle:{"background":"lightgray"},attrs:{"shadow":""}},[_c('b-row',[_c('b-col',{staticClass:"text-left d-md-flex d-lg-flex d-block",attrs:{"md":"9","cols":"12"}},[_c('div',[(
                      item.file_types != null && item.file_types == 'video'
                    )?_c('video',{attrs:{"width":"320","height":"240","controls":""}},[_c('source',{attrs:{"src":item.queDownloadLink,"type":"video/mp4"}})]):(
                      item.file_types == 'image' ||
                      item.queDownloadLink != 'N/A'
                    )?_c('img',{staticStyle:{"min-width":"320px","max-width":"320px","max-height":"240px"},attrs:{"src":item.queDownloadLink,"alt":""}}):_vm._e()]),_c('div'),_c('div',{staticClass:"align-self-center mb-0 ml-4 mt-2"},[_c('b-avatar',{attrs:{"src":item.image != ''
                        ? ("https://qurbaniimages.s3.amazonaws.com/" + (_vm.replacer(
                            item.userImage
                          )))
                        : 'img/dashboard/avatar.png'}}),_c('h5',{staticClass:"mb-0 "},[_vm._v(" "+_vm._s(item.question)+" ")]),_c('p',{staticClass:"text-success "},[_vm._v("By "+_vm._s(item.username))]),_c('p',{staticClass:"text-success "},[_vm._v(_vm._s(item.date))])],1)]),_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"md":"3","cols":"12"}},[_c('div',[_c('h5',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getLen(_vm.getcoms(item.id)))+" ")]),_c('h5',{staticClass:"text-success"},[_vm._v("Replies")])])])],1),(_vm.getLen(_vm.getcoms(item.id)) > 0)?_c('p',{staticClass:"text-success text-right cursor-pointer",on:{"click":function($event){_vm.posts[l].show = !_vm.posts[l].show}}},[_vm._v(" "+_vm._s(item.show == true ? "Hide Comments" : "View Comments")+" ")]):_vm._e()],1),_vm._l((_vm.getcoms(item.id)),function(it,i){return (item.show == true)?_c('div',{key:i},_vm._l((Object.keys(it).filter(
                function (key) { return it[key].discussion != null; }
              )),function(is,j){return _c('b-row',{key:j,staticClass:"mt-3"},[_c('b-col',{staticClass:"text-left d-flex",attrs:{"md":"12","cols":"12"}},[_c('b-card',{staticClass:"w-100"},[_c('div',{staticClass:"d-flex"},[_c('b-avatar',{attrs:{"src":item.image != ''
                          ? ("https://qurbaniimages.s3.amazonaws.com/" + (_vm.replacer(
                              it[is].userImage
                            )))
                          : 'img/dashboard/avatar.png'}}),_c('h6',{staticClass:"mb-0 align-slf-center ml-2"},[_vm._v(" "+_vm._s(it[is].discussion)+" ")])],1),_c('div',{staticClass:"align-self-center mb-0 ml-2"},[_c('p',{staticClass:"text-success mb-0"},[_vm._v("By "+_vm._s(it[is].username))]),_c('p',{staticClass:"text-success mb-0"},[_vm._v(_vm._s(it[is].date))])])])],1)],1)}),1):_vm._e()}),_c('form'),_c('b-row',[_c('b-col',{staticClass:"text-left d-flex mt-2",attrs:{"md":"12","cols":"12"}},[_c('b-textarea',{attrs:{"placeholder":"Enter comment"},model:{value:(item.discussion),callback:function ($$v) {_vm.$set(item, "discussion", $$v)},expression:"item.discussion"}})],1),(_vm.loggedUser != null)?_c('b-col',{staticClass:"text-md-right mt-2 text-center align-self-center"},[_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){return _vm.submitcom(item.discussion, item.id, l)}}},[_vm._v(" Post ")])],1):_vm._e()],1)],2)}),0),_c('b-tab',{attrs:{"title":"Old"}},_vm._l((_vm.lecdisc),function(item,l){return _c('div',{key:l},[_c('b-card',{staticClass:"mt-3",staticStyle:{"background":"lightgray"},attrs:{"shadow":""}},[_c('b-row',[_c('b-col',{staticClass:"text-left d-md-flex d-lg-flex d-block",attrs:{"md":"9","cols":"12"}},[_c('div',[(
                      item.file_types != null && item.file_types == 'video'
                    )?_c('video',{attrs:{"width":"320","height":"240","controls":""}},[_c('source',{attrs:{"src":item.queDownloadLink,"type":"video/mp4"}})]):(
                      item.file_types == 'image' ||
                      item.queDownloadLink != 'N/A'
                    )?_c('img',{staticStyle:{"min-width":"320px","max-width":"320px","max-height":"240px"},attrs:{"src":item.queDownloadLink,"alt":""}}):_vm._e()]),_c('div'),_c('div',{staticClass:"align-self-center mb-0 ml-4 mt-2"},[_c('b-avatar',{attrs:{"src":item.image != ''
                        ? ("https://qurbaniimages.s3.amazonaws.com/" + (_vm.replacer(
                            item.userImage
                          )))
                        : 'img/dashboard/avatar.png'}}),_c('h5',{staticClass:"mb-0 "},[_vm._v(" "+_vm._s(item.question)+" ")]),_c('p',{staticClass:"text-success "},[_vm._v("By "+_vm._s(item.username))]),_c('p',{staticClass:"text-success "},[_vm._v(_vm._s(item.date))])],1)]),_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"md":"3","cols":"12"}},[_c('div',[_c('h5',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getLen(_vm.getcoms(item.id)))+" ")]),_c('h5',{staticClass:"text-success"},[_vm._v("Replies")])])])],1),(_vm.getLen(_vm.getcoms(item.id)) > 0)?_c('p',{staticClass:"text-success text-right cursor-pointer",on:{"click":function($event){_vm.posts[l].show = !_vm.posts[l].show}}},[_vm._v(" "+_vm._s(item.show == true ? "Hide Comments" : "View Comments")+" ")]):_vm._e()],1),_vm._l((_vm.getcoms(item.id)),function(it,i){return (item.show == true)?_c('div',{key:i},_vm._l((Object.keys(it).filter(
                function (key) { return it[key].discussion != null; }
              )),function(is,j){return _c('b-row',{key:j,staticClass:"mt-3"},[_c('b-col',{staticClass:"text-left d-flex",attrs:{"md":"12","cols":"12"}},[_c('b-card',{staticClass:"w-100"},[_c('div',{staticClass:"d-flex"},[_c('b-avatar',{attrs:{"src":item.image != ''
                          ? ("https://qurbaniimages.s3.amazonaws.com/" + (_vm.replacer(
                              it[is].userImage
                            )))
                          : 'img/dashboard/avatar.png'}}),_c('h6',{staticClass:"mb-0 align-slf-center ml-2"},[_vm._v(" "+_vm._s(it[is].discussion)+" ")])],1),_c('div',{staticClass:"align-self-center mb-0 ml-2"},[_c('p',{staticClass:"text-success mb-0"},[_vm._v("By "+_vm._s(it[is].username))]),_c('p',{staticClass:"text-success mb-0"},[_vm._v(_vm._s(it[is].date))])])])],1)],1)}),1):_vm._e()}),_c('form'),_c('b-row',[_c('b-col',{staticClass:"text-left d-flex mt-2",attrs:{"md":"12","cols":"12"}},[_c('b-textarea',{attrs:{"placeholder":"Enter comment"},model:{value:(item.discussion),callback:function ($$v) {_vm.$set(item, "discussion", $$v)},expression:"item.discussion"}})],1),(_vm.loggedUser != null)?_c('b-col',{staticClass:"text-md-right mt-2 text-center align-self-center"},[_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){return _vm.submitcom(item.discussion, item.id, l)}}},[_vm._v(" Post ")])],1):_vm._e()],1)],2)}),0)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }