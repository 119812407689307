import firebase from "firebase";
import Vue from "vue";

const state = {
  conversations: [],
  messages: [],
  real_messages: [],
  lock: 0,
  chatUser: "",
  allUsers: [],
};

const getters = {
  allUsers: (state) => {
    return state.allUsers;
  },
  conversations: (state) => {
    return state.conversations;
  },
  messages: (state) => {
    return state.messages;
  },
  chatUser: (state) => {
    return state.chatUser;
  },
};

const actions = {
  updateOnlineStatus({ commit }, payload) {
    firebase
      .database()
      .ref("Users")
      .child(payload.id)
      .update({ isOnline: payload.status })
      .then(() => {
        console.log("its done status");
      })
      .catch((error) => console.log(error.message));
  },
  updateNotifications({ commit }, payload) {
    firebase
      .database()
      .ref("Users")
      .child(payload.id)
      .child("notifications")
      .update({ viewed: payload.viewed, unviewed: payload.unviewed })
      .then(() => {
        console.log("its done notifications");
      })
      .catch((error) => console.log(error.message));
  },
  fetchOnlineStatus({ commit, state }, payload) {
    console.log("camers");
    console.log(payload);
    Object.keys(payload.chat_groups).forEach((item) => {
      firebase
        .database()
        .ref("Users")
        .child(payload.chat_groups[item].receiver_id)
        .on("value", (snapshot) => {
          let obj = state.allUsers.find((it) => it.id == snapshot.key);
          if (obj == null) {
            commit("setAllUsers", {
              ...snapshot.val(),
              id: snapshot.key,
            });
          }
        });
    });
  },
  findAllUsers({ commit }) {
    firebase
      .database()
      .ref("Users")
      .on("value", (snapshot) => {
        commit("setAllUsers", {
          ...snapshot.val(),
        });
      });
  },
  findChatUser({ commit }, payload) {
    firebase
      .database()
      .ref("/Users/" + payload)
      .once("value")
      .then((snapshot) => {
        commit("setChatUser", {
          ...snapshot.val(),
        });
      });
  },
  fetchMessages({ commit, getters }, payload) {
    firebase
      .database()
      .ref("Groups")
      .child(payload)
      .on("value", (snapshot) => {
        commit("setMessages", {
          result: snapshot.val(),
          chanel_key: snapshot.key,
        });
      });
  },
  sendMessage({ commit }, payload) {
    // console.log(payload)
    firebase
      .database()
      .ref("Conversations")
      .child(payload.chanel)
      .push(payload.message)
      .then((snapshot) => {
        console.log("camee here");
      })
      .catch((error) => console.log(error.message));
  },
  initialMessage({ commit }, payload) {
    // check if new or not new
    if (payload.isNew == true) {
      let unique_conv_key = firebase
        .database()
        .ref("Groups")
        .push()
        .getKey();

      //     // push new with key and default obj
      firebase
        .database()
        .ref("Groups")
        .child(unique_conv_key)
        .child("messages")
        .push(payload.new_chat_obj)
        .then((snapshot) => {
          console.log("camee here2");
        })
        .catch((error) => console.log(error.message));
      firebase
        .database()
        .ref("Users")
        .child(payload.contacts.sender_id)
        .update({ ...payload.new_user_obj, name: payload.contacts.sender_name })
        .then((snapshot) => {
          console.log("camee here4");
        })
        .catch((error) => console.log(error.message));

      firebase
        .database()
        .ref("Users")
        .child(payload.contacts.receiver_id)
        .update({
          ...payload.new_user_obj,
          name: payload.contacts.receiver_name,
        })
        .then((snapshot) => {
          console.log("camee here4");
        })
        .catch((error) => console.log(error.message));
      firebase
        .database()
        .ref("Users")
        .child(payload.contacts.receiver_id)
        .child("chat_groups")
        .push({
          id: unique_conv_key,
          name: payload.contacts.sender_name,
          receiver_id: payload.contacts.sender_id,
        })
        .then((snapshot) => {
          console.log("camee here4");
        })
        .catch((error) => console.log(error.message));
      firebase
        .database()
        .ref("Users")
        .child(payload.contacts.sender_id)
        .child("chat_groups")
        .push({
          id: unique_conv_key,
          name: payload.contacts.receiver_name,
          receiver_id: payload.contacts.receiver_id,
        })
        .then((snapshot) => {
          console.log("camee here4");
        })
        .catch((error) => console.log(error.message));
    } else {
      console.log("elses");
      console.log(payload);
      firebase
        .database()
        .ref("Groups")
        .child(payload.chatgroup_id)
        .child("messages")
        .push(payload.new_chat_obj)
        .then((snapshot) => {
          console.log("camee here2");
        })
        .catch((error) => console.log(error.message));

      firebase
        .database()
        .ref("Users")
        .child(payload.contacts.receiver_id)
        .update({ ...payload.new_user_obj })
        .then((snapshot) => {
          console.log("camee here4");
        })
        .catch((error) => console.log(error.message));

      firebase
        .database()
        .ref("Users")
        .child(payload.contacts.sender_id)
        .update({ ...payload.new_user_obj })
        .then((snapshot) => {
          console.log("camee here4");
        })
        .catch((error) => console.log(error.message));
    }
    // // push to existing with key if not NEW
  },
};
const mutations = {
  setChatUser: (state, obj) => {
    if (Object.keys(obj).length > 0) {
      state.chatUser = obj;
    }
    // let temp=[]
    // Object.keys(obj.chat_groups).forEach(item=>{
    //     temp.push({...obj[item],id:obj[item].chanelName})
    // })
    // state.conversations=temp
  },
  setAllUsers: (state, payload) => {
    // let users_Array = [];
    // for (let key in payload) {
    //   payload[key].id = key;
    //   users_Array.push(payload[key]);
    // }
    // state.allUsers = users_Array;
    state.allUsers.push(payload);
  },
  setMessages: (state, obj) => {
    let ind = state.messages.findIndex(
      (item) => item.chanel_key == obj.chanel_key
    );
    if (ind == -1) {
      // doesnt exists

      state.messages.push(obj);
    } else {
      // exists
      // state.messages[ind]=obj
      Vue.set(state.messages, ind, obj);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
