<template>
  <div>
    <b-container style="margin-top: 8rem">
      <h3 class="text-success" style="text-decoration: underline">
        IJTAMAE QURBANI BY QURBANI APP
      </h3>
      <p>
        Qurbani App is providing Ijtamae Qurbani this Eid. We have some of the
        best breeds from around the World. Our Ijtamai Qurbani Service will
        deliver meat to the most underprivileged and deserving communities of
        twin cities. Other than this this year COVID-19 pandemic has hit country
        badly and there are numbers of people who are jobless and hungry. This
        is your chance to help them, play your part for the well being of your
        people to the most underprivileged and deserving communities in twin
        cities. Covid-19 pandemic this year has severely affected the
        livelihoods of thousands of families in disadvantaged areas like Dhak
        Dalal, Dhok Hassu, Fauji Colony and Girja Road. We are working with a
        number of local social welfare organisation to maintain a database of
        widows, divorcees, orphans, daily wage earners, families living on rent
        and families of more than five with a single wage earner. Your Qurbani
        this year can be a source of nutritious meat for these communities. Help
        us, Help them! ORDER NOW! Call Now +44-7403228448 / +92-3065401886
      </p>
      <h3 class="text-success">Check Out Our Packages</h3>
      <b-container>
        <b-row>
          <b-col md="12" cols="12">
            <span>Convert to view in your currency</span> <br />
            <b-button
              pill
              :variant="order_currency == 'PKR' ? 'success' : 'outline-success'"
              @click="
                order_currency = 'PKR';
                currencyConversion();
              "
              >PKR</b-button
            >
            <b-button
              class="ml-2"
              pill
              :variant="order_currency == 'USD' ? 'success' : 'outline-success'"
              @click="
                order_currency = 'USD';
                currencyConversion();
              "
              >USD</b-button
            >
            <b-button
              class="ml-2"
              pill
              :variant="order_currency == 'GBP' ? 'success' : 'outline-success'"
              @click="
                order_currency = 'GBP';
                currencyConversion();
              "
              >GBP</b-button
            >
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card
              @click="total_price = packages[0]"
              tag="article"
              :class="[
                'points shadow mt-5 pb-4 col-md-12 mb-2 ml-auto mr-auto p-0',
                total_price == this.packages[0] ? 'selected_price' : '',
              ]"
            >
              <template v-slot:header>
                <div
                  class="col-md-12 col-11 ml-auto p-2 mr-auto bg-success"
                  style="
                    border-top-right-radius: 1rem;
                    border-top-left-radius: 1rem;
                  "
                >
                  <div class="p-2">
                    <b-img src="img/ijtmae/cow.png" width="80"></b-img>
                    <p style="font-size: 15px" class="text-white bolds">
                      Ijtamai Hissa With Meat Distributed To Poor People
                    </p>
                  </div>
                </div>
              </template>
              <div class="text-left text-success">
                <div v-if="currLoad == true" class="text-center">
                  <b-spinner variant="success"></b-spinner>
                </div>
                <h3 v-else class="text-success text-center">
                  {{ this.order_currency }} {{ this.packages[0] }}
                </h3>
                1) Animal slaughtered within the first two days of Eid. <br />
                2) Meat distributed within the first three days. <br />
                3) Receipt of Order confirmation. <br />
                4) Live updates on WhatsApp number <br />
                5) Images and videos of slaughtered animal with takbeer of your
                name
              </div>
            </b-card>
          </b-col>
          <b-col>
            <b-card
              @click="total_price = packages[1]"
              tag="article"
              :class="[
                'points shadow mt-5 pb-4 col-md-12 mb-2 ml-auto mr-auto p-0',
                total_price == this.packages[1] ? 'selected_price' : '',
              ]"
            >
              <template v-slot:header>
                <div
                  class="col-md-12 col-11 ml-auto p-2 mr-auto bg-success"
                  style="
                    border-top-right-radius: 1rem;
                    border-top-left-radius: 1rem;
                  "
                >
                  <div class="p-2">
                    <b-img src="img/ijtmae/cow_1.png" width="80"></b-img>
                    <p style="font-size: 15px" class="text-white bolds">
                      Ijtamae Hissa With Meat Delivery To Home
                    </p>
                  </div>
                </div>
              </template>
              <div class="text-left text-success">
                <div v-if="currLoad == true" class="text-center">
                  <b-spinner variant="success"></b-spinner>
                </div>
                <h3 v-else class="text-success text-center">
                  {{ this.order_currency }} {{ this.packages[1] }}
                </h3>
                1) Animal slaughtered within the first two days of Eid. <br />
                2) Meat distributed within the first three days. <br />
                3) Receipt of Order confirmation. <br />
                4) Live updates on WhatsApp number <br />
                5) Images and videos of slaughtered animal with takbeer of your
                name
              </div>
            </b-card>
          </b-col>
          <b-col>
            <!-- ,total_price==this.packages[2] ? 'selected_price':'' -->
            <b-card
              @click="total_price = packages[2]"
              tag="article"
              :class="[
                'points shadow mt-5 pb-4 col-md-12 mb-2 ml-auto mr-auto p-0',
                total_price == this.packages[2] ? 'selected_price' : '',
              ]"
            >
              <template v-slot:header>
                <div
                  class="col-md-12 col-11 ml-auto p-2 mr-auto bg-success"
                  style="
                    border-top-right-radius: 1rem;
                    border-top-left-radius: 1rem;
                  "
                >
                  <div class="p-2">
                    <b-img src="img/ijtmae/goat.png" width="80"></b-img>
                    <p style="font-size: 15px" class="text-white bolds">
                      Qurbani Bakra With Or Without Delivery
                    </p>
                  </div>
                </div>
              </template>
              <div class="text-left text-success">
                <div v-if="currLoad == true" class="text-center">
                  <b-spinner variant="success"></b-spinner>
                </div>
                <h3 v-else class="text-success text-center">
                  {{ this.order_currency }} {{ this.packages[2] }}
                </h3>
                1) Animal slaughtered within the first two days of Eid. <br />
                2) Meat distributed within the first three days. <br />
                3) Receipt of Order confirmation. <br />
                4) Live updates on WhatsApp number <br />
                5) Images and videos of slaughtered animal with takbeer of your
                name
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
      <h3 class="text-success mt-4">Payment Options For you</h3>
      <b-container class="mb-5">
        <b-row>
          <b-col md="6">
            <div class="card p-5" style="background: #feea63">
              <b-img
                class="ml-auto mr-auto"
                src="img/easy.png"
                width="200"
              ></b-img>
              <div class="text-success">
                You can transfer your payment through easypaisa. Just go to any
                easypaisa shop nearby and ask retailer to transfer specific
                amount to following number
              </div>
              <div class="text-success" style="margin-top: 2.25rem">
                <b> Number Details 0306 5401886</b>
              </div>
            </div>
          </b-col>
          <b-col md="6">
            <div class="card p-5" style="background: #81c784">
              <b-img
                class="ml-auto mr-auto"
                src="img/bank.png"
                width="100"
              ></b-img>
              <div>
                <h3 class="text-white">Bank Transfer</h3>
              </div>
              <div class="text-white">
                Account Number# 12787900142903 <br />
                IBAN Number# PK08 HABB <br />
                0012787900142903
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <h3 class="text-success">
        "WE PROMISE TO DELIVER YOU HIGH FIVE SERVICE"
      </h3>
      <b-container>
        <b-img width="800" src="img/featured_icon.png"> </b-img>
      </b-container>
      <h5 class="mt-5">Didn't find what you were looking for?</h5>
      <button
        class="col-md-4 btn btn-success"
        v-clipboard:copy="message"
        v-clipboard:success="onCopy"
        @click="show = true"
      >
        {{ show == false ? "Qurbani Helpline" : "+923065401886 Helpline" }}
      </button>
      <b-alert
        v-model="showTop"
        class="position-fixed fixed-top m-0 rounded-0"
        style="z-index: 2000"
        variant="success"
        dismissible
      >
        Help Line phone number copied !
      </b-alert>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "IjtmaeQurbani",
  data() {
    return {
      showTop: false,
      show: false,
      total_price: "15000",
      order_currency: "PKR",
      currLoad: false,
      packages: [15000, 18000, 30000],
      values: [15000, 18000, 30000],
    };
  },
  methods: {
    currencyConversion() {
      this.currLoad = true;
      if (this.order_currency != "PKR") {
        // this.packages=[15000,18000,30000]

        axios
          .get(
            `https://free.currconv.com/api/v7/convert?apiKey=93b58cb071fcca371f8d&q=${this.order_currency}_PKR&compact=y`
          )
          .then((response) => {
            console.log(response.data);
            // packages
            console.log(response.data);
            if (this.order_currency == "USD") {
              // this.packages[0]=Math.round(this.packages[0]/response.data.USD_PKR.val)
              this.$set(
                this.packages,
                0,
                Math.round(15000 / response.data.USD_PKR.val)
              );
              this.$set(
                this.packages,
                1,
                Math.round(18000 / response.data.USD_PKR.val)
              );
              this.$set(
                this.packages,
                2,
                Math.round(30000 / response.data.USD_PKR.val)
              );

              // this.packages[1]=Math.round(this.packages[1]/response.data.USD_PKR.val)
              //                     this.packages[2]=Math.round(this.packages[2]/response.data.USD_PKR.val)
            } else {
              this.$set(
                this.packages,
                0,
                Math.round(15000 / response.data.GBP_PKR.val)
              );
              this.$set(
                this.packages,
                1,
                Math.round(18000 / response.data.GBP_PKR.val)
              );
              this.$set(
                this.packages,
                2,
                Math.round(30000 / response.data.GBP_PKR.val)
              );

              // this.packages[0]=Math.round(this.packages[0]/response.data.GBP_PKR.val)
              // this.packages[1]=Math.round(this.packages[1]/response.data.GBP_PKR.val)
              // this.packages[2]=Math.round(this.packages[2]/response.data.GBP_PKR.val)
            }
            this.currLoad = false;

            // console.log(response.status);
            // console.log(response.statusText);
            // console.log(response.headers);
            // console.log(response.config);
          });
      } else {
        this.packages = this.values;
        this.currLoad = false;
      }
    },
  },
};
</script>

<style scoped>
.card .card-header {
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
}

.selected_price .card-header {
  background: #f5f5f5 !important;
}
.selected_price .card-body {
  background: #f5f5f5 !important;
}
.selected_price {
  background: #f5f5f5 !important;
}
</style>
