<template>
  <div class="mt-4">
    <b-container class="mt-3 mb-5" fluid>
      <div class="row">
        <div class="col-md-6 d-none d-md-block">
          <div
            class="col-md-12"
            style="
              background: url('img/section1.png');
              background-repeat: no-repeat;
              background-size: 100% 100%;
              padding-top: 4rem;
              padding-bottom: 4rem;
            "
          >
            <router-link :to="{ name: 'AllPosts', params: { types: 1 } }">
              <b-button
                variant="success"
                style="margin-top: 12rem"
                class="btn btn-lg pr-5 pl-5"
                >New Arrivals</b-button
              >
            </router-link>
          </div>
        </div>
        <div class="col-md-6 d-none d-block d-xl-none d-lg-none d-md-none">
          <div
            class="col-md-12"
            style="
              background: url('img/section1.png');
              background-repeat: no-repeat;
              background-size: 100% 100%;
            "
          >
            <router-link :to="{ name: 'AllPosts', params: { types: 1 } }">
              <b-button
                variant="success"
                style="margin-top: 6rem; margin-bottom: 4rem"
                class="btn btn-lg pr-5 pl-5"
                >New Arrivals</b-button
              >
            </router-link>
          </div>
        </div>
        <div class="col-md-6 d-none d-md-block">
          <div
            class="col-md-12"
            style="
              background: url('img/section2.png');
              background-repeat: no-repeat;
              background-size: 100% 100%;
              padding-top: 4rem;
              padding-bottom: 4rem;
            "
          >
            <router-link :to="{ name: 'AllPosts', params: { types: 7 } }">
              <b-button
                variant="success"
                style="margin-top: 12rem"
                class="btn btn-lg pr-5 pl-5"
                >View Stock</b-button
              >
            </router-link>
          </div>
        </div>
        <div class="col-md-6 d-none d-block d-xl-none d-lg-none d-md-none">
          <div
            class="col-md-12"
            style="
              background: url('img/section2.png');
              background-repeat: no-repeat;
              background-size: 100% 100%;
            "
          >
            <router-link :to="{ name: 'AllPosts', params: { types: 7 } }">
              <b-button
                variant="success"
                style="margin-top: 6rem; margin-bottom: 4rem"
                class="btn btn-lg pr-5 pl-5"
                >View Stock</b-button
              >
            </router-link>
          </div>
        </div>
      </div>
      <div class="row mt-4 mr-2">
        <div
          class="col-12 col-md-7"
          :style="isMobile == true ? 'text-align:center;' : 'text-align:right;'"
        >
          <h3 class="text-success"><b>Featured Posts</b></h3>
        </div>
        <div
          class="col-12 col-md-5"
          :style="isMobile == true ? 'text-align:center;' : 'text-align:right;'"
        >
          <router-link to="/all_posts">
            <b-button variant="success" class="pull-right btn btn-lg"
              >View All</b-button
            >
          </router-link>
        </div>
      </div>
      <b-container v-if="posts.length > 0" fluid class="d-none d-md-block">
        <carousel
          :per-page="4"
          :mouse-drag="false"
          :autoplay="true"
          :loop="true"
          :autoplayTimeout="3000"
          :paginationEnabled="false"
          :navigationEnabled="true"
        >
          <slide v-for="(post_item, i) in posts[0].slice(0, 7)" :key="i">
            <PostCard
              :landing="landing"
              :post_item="post_item"
              @toggle-item="setPost"
            />
          </slide>
        </carousel>
        <div class="row">
          <div class="col-md-2 ml-auto"></div>
        </div>
      </b-container>
      <b-container
        v-if="posts.length > 0"
        fluid
        class="d-none d-block d-xl-none d-lg-none d-md-none"
      >
        <carousel
          :per-page="1"
          :mouse-drag="false"
          :autoplay="true"
          :autoplayTimeout="3000"
          :paginationEnabled="false"
          :loop="true"
          :navigationEnabled="true"
        >
          <slide v-for="(post_item, i) in posts[0].slice(0, 7)" :key="i">
            <PostCard
              :landing="landing"
              :post_item="post_item"
              @toggle-item="setPost"
            />
          </slide>
        </carousel>
        <b-modal
          hide-footer
          id="secs-lg"
          ref="secs-lg"
          size="lg"
          :title="selected_post.category"
          no-stacking
        >
          <DialogPost :selected_post="selected_post" @chat-show="hideDialog" />
        </b-modal>
        <b-modal
          id="first-chat"
          title="Send Message"
          @ok="lc_sendMessage()"
          ok-title="Send"
        >
          <b-form-group id="fieldset-1" label="Write your message">
            <b-form-textarea
              id="textarea"
              v-model="new_chat_obj.message"
              placeholder="Type here..."
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>
        </b-modal>
        <div class="row">
          <div class="col-md-2 ml-auto">
            <router-link to="/all_posts">
              <h6 style="text-align: right; color: #007bff; cursor: pointer">
                view more..
              </h6>
            </router-link>
          </div>
        </div>
      </b-container>
      <div class="row">
        <div class="col-md-12">
          <h3 class="text-success"><b>Qurbani Categories</b></h3>
        </div>
      </div>
      <div class="row">
        <div class="grow col-md-4 custom_overlay">
          <div class="custom_overlay col-md-12 pl-0 pr-0">
            <router-link :to="{ name: 'AllPosts', params: { types: 3 } }">
              <b-img
                src="img/goat_Q.png"
                fluid
                alt="Responsive image"
                class="col-md-12 pl-0 pr-0 image"
              ></b-img>
              <!-- <div class="overlay">
                                <div class="text">Goats</div>
                            </div> -->
            </router-link>
          </div>
          <div
            class="pt-1 pb-1"
            style="background-color: #28a745 !important; color: white"
          >
            <p>GOATS</p>
          </div>
        </div>
        <div class="grow col-md-4">
          <div class="custom_overlay col-md-12 pl-0 pr-0">
            <router-link :to="{ name: 'AllPosts', params: { types: 2 } }">
              <b-img
                src="img/cow_Q.png"
                fluid
                alt="Responsive image"
                class="col-md-12 pl-0 pr-0 image"
              ></b-img>
              <!-- <div class="overlay">
                                <div class="text">Cows</div>
                            </div> -->
            </router-link>
          </div>
          <div
            class="pt-1 pb-1"
            style="background-color: #28a745 !important; color: white"
          >
            <p>COWS</p>
          </div>
        </div>
        <div class="grow col-md-4">
          <div class="custom_overlay col-md-12 pl-0 pr-0">
            <router-link :to="{ name: 'AllPosts', params: { types: 4 } }">
              <b-img
                src="img/sheep_Q.png"
                fluid
                alt="Responsive image"
                class="col-md-12 pl-0 pr-0 image"
              ></b-img>
              <!-- <div class="overlay">
                                <div class="text">Sheeps</div>
                            </div> -->
            </router-link>
          </div>
          <div
            class="pt-1"
            style="
              padding-bottom: 0.01rem;
              background-color: #28a745 !important;
              color: white;
            "
          >
            <p>SHEEP</p>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-12">
          <h3 class="text-success"><b>Our Partners</b></h3>
        </div>
      </div>
      <b-container class="mt-4">
        <carousel
          class="d-none d-md-block"
          :autoplayTimeout="3000"
          :per-page="3"
          :mouse-drag="true"
          :autoplay="true"
          :loop="true"
          :paginationEnabled="false"
          :navigationEnabled="false"
        >
          <slide style="cursor: grab" v-for="(item, i) in sponsors" :key="i">
            <!-- <div class="col-md-4"> -->
            <img :src="`img/sponsors/${item}`" alt="" width="150" />
            <!-- </div>   -->
          </slide>
        </carousel>
        <carousel
          class="d-none d-block d-xl-none d-lg-none d-md-none"
          :per-page="1"
          :mouse-drag="true"
          :autoplay="false"
          :loop="true"
          :paginationEnabled="false"
          :navigationEnabled="true"
        >
          <slide style="cursor: grab" v-for="(item, i) in sponsors" :key="i">
            <div class="col-md-4">
              <img :src="`img/sponsors/${item}`" alt="" />
            </div>
          </slide>
        </carousel>
      </b-container>
      <div class="content-center mt-5 pt-2 pb-5">
        <div class="container">
          <div class="row">
            <div class="col-md-12 text-center">
              <h3 class="text-success">
                <b>What people are saying about us</b>
              </h3>
            </div>
            <div class="col-md-12 text-center">
              <span
                class="text-muted"
                style="font-size: 23px; font-family: open sans"
              >
                Start using QurbaniApp today and share your experience with us
                <br />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="content-center pt-2 pb-5">
        <div class="container space-2 space-top-lg-3">
          <div class="card-deck d-block d-lg-flex card-lg-gutters-2">
            <carousel
              class="d-none d-md-block"
              :per-page="3"
              :mouse-drag="false"
              :autoplayTimeout="3000"
              :autoplay="true"
              :loop="true"
              :paginationEnabled="false"
              :navigationEnabled="true"
            >
              <slide v-for="(comment_item, i) in comments" :key="i">
                <div
                  class="shadow card border-class bg-white border-0"
                  style="border: 1px solid #dcdcdc !important"
                >
                  <div class="card-body p-5">
                    <ul class="list-inline text-warning small">
                      <img src="img/five-stars.png" width="80" alt="" />
                    </ul>
                    <div class="mb-auto">
                      <p class="mb-0">{{ comment_item.text }}</p>
                    </div>
                  </div>

                  <div class="card-footer border-0 bg-white pt-0 px-5 pb-5">
                    <div class="media">
                      <div class="media-body">
                        <h4 class="h6 mb-1 text-success">
                          {{ comment_item.user }}
                        </h4>
                        <small class="d-block text-secondary">{{
                          comment_item.city
                        }}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </slide>
            </carousel>
            <carousel
              class="d-none d-block d-xl-none d-lg-none d-md-none"
              :per-page="1"
              :mouse-drag="false"
              :autoplay="true"
              :loop="true"
              :navigationEnabled="true"
            >
              <slide v-for="(comment_item, i) in comments" :key="i">
                <div
                  class="card border-class bg-white border-0"
                  style="border: 1px solid #dcdcdc !important"
                >
                  <div class="card-body p-5">
                    <ul class="list-inline text-warning small">
                      <li class="list-inline-item mx-0">
                        <span class="fas fa-star"></span>
                      </li>
                      <li class="list-inline-item mx-0">
                        <span class="fas fa-star"></span>
                      </li>
                      <li class="list-inline-item mx-0">
                        <span class="fas fa-star"></span>
                      </li>
                      <li class="list-inline-item mx-0">
                        <span class="fas fa-star"></span>
                      </li>
                      <li class="list-inline-item mx-0">
                        <span class="fas fa-star"></span>
                      </li>
                    </ul>
                    <div class="mb-auto">
                      <p class="mb-0">{{ comment_item.text }}</p>
                    </div>
                  </div>

                  <div class="card-footer border-0 bg-white pt-0 px-5 pb-5">
                    <div class="media">
                      <div class="u-avatar mr-3">
                        <img
                          class="img-fluid rounded-circle"
                          width="30"
                          :src="img_src"
                          alt="Image Description"
                        />
                      </div>
                      <div class="media-body">
                        <h4 class="h6 mb-1">{{ comment_item.user }}</h4>
                        <small class="d-block text-secondary">{{
                          comment_item.city
                        }}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </slide>
            </carousel>
          </div>
        </div>
      </div>
      <div class="content-center mt-5 pt-2 pb-5">
        <div class="container">
          <div class="row">
            <div class="col-md-12 text-center">
              <h3 class="text-success"><b>Our Stories</b></h3>
            </div>
          </div>
        </div>
      </div>
      <div class="content-center pt-2 pb-5">
        <div class="container space-2 space-top-lg-3">
          <!-- Testimonials -->
          <div class="card-deck d-block d-lg-flex card-lg-gutters-2">
            <!-- Testimonials -->
            <carousel
              class="d-none d-md-block"
              :per-page="3"
              :mouse-drag="false"
              :autoplayTimeout="3000"
              :autoplay="true"
              :loop="true"
              :paginationEnabled="false"
              :navigationEnabled="true"
            >
              <slide v-for="(comment_item, i) in stories" :key="i">
                <b-card :img-src="comment_item.sourceimg" img-height="200">
                  <!-- <img class="mt-2" :src="comment_item.img" style="width:100%;max-height:280px" alt=""> -->

                  <div class="card-body pl-3 pr-3">
                    <!-- <ul class="list-inline text-warning small">
                                            <img :src="comment_item.img" width="100%" alt="">
                                           </ul> -->
                    <div class="mb-auto">
                      <a
                        class="text-success mb-0"
                        target="_blank"
                        :href="comment_item.url"
                        >{{ comment_item.text }}</a
                      >
                    </div>
                  </div>
                </b-card>
              </slide>
            </carousel>
            <carousel
              class="d-none d-block d-xl-none d-lg-none d-md-none"
              :per-page="1"
              :mouse-drag="false"
              :autoplay="true"
              :loop="true"
              :navigationEnabled="true"
            >
              <slide v-for="(comment_item, i) in stories" :key="i">
                <div
                  class="card border-class bg-white border-0"
                  style="border: 1px solid #dcdcdc !important"
                >
                  <b-card :img-src="comment_item.sourceimg" img-height="200">
                    <div class="card-body pl-3 pr-3">
                      <div class="mb-auto">
                        <a
                          target="_blank"
                          :href="comment_item.url"
                          class="mb-0"
                          >{{ comment_item.text }}</a
                        >
                      </div>
                    </div>
                  </b-card>
                </div>
              </slide>
            </carousel>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-right">
          <div
            class="col-md-12"
            style="
              background: url('img/landing-cover.png');
              background-repeat: no-repeat;
              background-size: 100% 100%;
              padding-top: 4rem;
              padding-bottom: 4rem;
            "
          >
            <router-link :to="{ name: 'AllPosts', params: { types: 1 } }">
              <b-button
                variant="success"
                style="margin-top: 8rem"
                class="btn btn-lg pr-5 pl-5"
                >See Qurbani App Stock</b-button
              >
            </router-link>
          </div>
        </div>
      </div>
      <div class="container mt-5">
        <div class="row">
          <div class="col-md-6" style="align-self: center">
            <h3
              class="text-success mt-5"
              style="
                font-family: tekton-pro, sans-serif;
                font-weight: 700;
                font-style: normal;
              "
            >
              Discover What the Buzz is All About Download App Now
            </h3>
            <a
              href="https://play.google.com/store/apps/details?id=com.plan9.qurbaniapps.qurbani"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="img/google-play.png" width="80" height="80" alt="" />
            </a>
            <a
              href="https://apps.apple.com/pk/app/qurbani-app/id1520463934"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="img/app-store.png" width="80" height="80" alt="" />
            </a>

            <h3
              class="text-success mt-5"
              style="
                font-family: tekton-pro, sans-serif;
                font-weight: 700;
                font-style: normal;
              "
            >
              Buy and Sell Your Animals on a flip of a finger Now !
            </h3>
          </div>
          <div class="col-md-6 text-center">
            <!-- width="400" height="450" -->
            <img
              src="img/group.png"
              class="d-none d-md-block"
              width="300"
              alt=""
            />
            <img
              src="img/group.png"
              class="ml-auto mr-auto d-none d-block d-xl-none d-lg-none d-md-none"
              width="150"
              alt=""
            />

            <!-- <img src="img/home-screen.png" class="overlapper d-none d-md-block" width="250" height="490"   alt="">
                        <img src="img/home-screen.png" class="overlapper d-none d-block d-xl-none d-lg-none d-md-none" width="190" height="390"   alt=""> -->
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>
<script>
import { Carousel, Slide } from "vue-carousel";
import { mapGetters } from "vuex";
import DialogPost from "../components/DialogPost.vue";
import { RepositoryFactory } from "../Repository/RepositoryFactory";
const PostsRepository = RepositoryFactory.get("posts_repository");
const UserRepository = RepositoryFactory.get("user_repository");
import unixTime from "unix-time";
import PostCard from "../components/PostCard.vue";
import globalfuns from "../mixins/globalfuns";
export default {
  name: "Section",
  mixins: [globalfuns],
  data() {
    return {
      landing: true,
      stories: [
        {
          sourceimg:
            "https://firebasestorage.googleapis.com/v0/b/qurbaniapp-24a10.appspot.com/o/new_resources%2Fdawn.jpg?alt=media&token=454b6d07-62f3-4d05-99d1-32ee50315374",
          img: "/img/stories/dawn.PNG",
          text: "Qurbani App : Install App And Make Digital Qurbani",
          url: "https://www.dawn.com/news/1478923 ",
        },
        {
          sourceimg:
            "https://firebasestorage.googleapis.com/v0/b/qurbaniapp-24a10.appspot.com/o/new_resources%2Fpw.png?alt=media&token=db231730-155e-4fc5-8d58-04d0d9344a40",
          img: "/img/stories/phoneworld.PNG",
          url:
            "https://www.phoneworld.com.pk/now-book-your-sacrificial-animal-via-qurbani-app/",
          text: "Now Book Your Sacrificial Animal Via ‘Qurbani App",
        },
        {
          sourceimg:
            "https://firebasestorage.googleapis.com/v0/b/qurbaniapp-24a10.appspot.com/o/new_resources%2F091157b13516ee5a5457649b91cbb9b5.jpeg?alt=media&token=d6ba67a8-5288-4e9b-9ee4-10004986f2c4",
          img: "/img/stories/whenwhere.PNG",
          text: "In Pakistan launched Qurbani App for livestock",
          url:
            "https://echonews.pk/index.php/2020/07/11/pakistani-developers-launched-qurbani-app-for-livestock-trade/",
        },
        {
          sourceimg:
            "https://firebasestorage.googleapis.com/v0/b/qurbaniapp-24a10.appspot.com/o/new_resources%2Ftribune.png?alt=media&token=316b00bc-c114-4445-be35-0d740664a7af",
          img: "/img/stories/tribune.PNG",
          text: "Qurbani App launched ahead of Eidul Azha",
          url:
            "https://tribune.com.pk/story/2254245/qurbani-app-launched-ahead-of-eidul-azha",
        },
        {
          sourceimg:
            "https://firebasestorage.googleapis.com/v0/b/qurbaniapp-24a10.appspot.com/o/new_resources%2Fmashable.jpg?alt=media&token=f570cad5-f403-4df7-84d7-3d9ce5aa01b9",
          img: "/img/stories/mashable.PNG",
          text: "Qurbani App : Digitizing the livestock sector in Pakistan",
          url:
            "https://pk.mashable.com/tech/4038/qurbani-app-digitizing-the-livestock-sector-in-pakistan",
        },
      ],
      sponsors: [
        "logo1.png",
        "logo2.png",
        "logo3.png",
        "logo4.png",
        "logo5.png",
        "logo6.png",
        "logo7.png",
        "logo8.png",
      ],
      likes: [],
      count: 0,
      comments: [
        {
          text:
            "I have placed two bulls in Qurbani app Farms throuh Bara service the way they take care of animals is simply awesome.",
          user: "Shafaqat Ali Habib",
          city: "Gujrat",
        },
        {
          text:
            "I ordered Sadqah from United Kingdom through Qurbani App and i must say their process is crystal clear. I am so satisfied High five stuff !.",
          user: "Ubaid Ahmed Kiyani",
          city: "London",
        },
        {
          text:
            "I have already five bakras for EId-ul-Adha 2019 through Qurbani App. Rates are so affordable i definately recommend you all.",
          user: "Asif Ali Khan",
          city: "Rawlpindi",
        },
        {
          text:
            "Best Service in the town, I have ordered Sadqah and Aqiqa for the first time online through an app and the result i got were magical.",
          user: "Usman Rahim",
          city: "Karachi",
        },
        {
          text:
            "This platform is awesome to get Mandi rates and have a go to the top notch livestock content all across the whole country.",
          user: "Umer Qabir Ahmed",
          city: "Jhelum",
        },
      ],
      selected_post: "",
      img_src: require("../assets/img/default-profile.png"),
      // noImage: require('../../public/sparcS.png'),
    };
  },
  components: {
    Carousel,
    Slide,
    DialogPost,
    PostCard,
  },
  methods: {
    async lc_sendMessage() {
      if (this.loggedUser != null) {
        let isNew = true;
        let sendername = this.loggedUser.nickname;
        this.new_chat_obj.post_id = this.selected_post.id;
        this.new_chat_obj.sender = this.loggedUser.id;
        this.new_chat_obj.timestamp = unixTime(new Date());
        this.new_chat_obj.image_key = this.selected_post.imagekey;

        this.new_user_obj = {
          last_date: this.getTodaysDate(),
          last_time: this.getCurrentTime(),
        };

        let { data } = await UserRepository.getuser({
          user_id: this.selected_post.user_id,
        });
        let receivername = data[0].nickname;
        let checks;
        if (this.chatUser.chat_groups != null) {
          checks = Object.keys(this.chatUser.chat_groups).find(
            (item) => this.chatUser.chat_groups[item].receiver_id == data[0].id
          );
        }
        let chatgroup_id;
        if (checks != null) {
          chatgroup_id = this.chatUser.chat_groups[checks].id;
        }
        isNew = checks != null ? false : true;

        let contacts = {
          sender_name: sendername,
          receiver_name: receivername,
          sender_id: this.loggedUser.id,
          receiver_id: data[0].id,
        };

        this.msg_notif_obj.sender_id = this.loggedUser.id;
        this.msg_notif_obj.post_owner_id = this.selected_post.user_id;
        this.msg_notif_obj.channel = this.loggedUser.id + "_" + data[0].id;
        this.msg_notif_obj.post_image = this.selected_post.imagekey;

        let resps = await UserRepository.sendmsgnotification(
          this.msg_notif_obj
        );

        this.$store.dispatch("initialMessage", {
          chatgroup_id: chatgroup_id,
          isNew: isNew,
          contacts: contacts,
          new_user_obj: this.new_user_obj,
          new_chat_obj: this.new_chat_obj,
        });
        this.$bvModal.hide("first-chat");
        this.$router.push({ name: "Dashboard", params: { active_prop: 9 } });
      } else {
        this.$store.commit("setNotifications", {
          message: "Login before messaging",
          type: "error",
        });
      }
    },
    hideDialog(item) {
      console.log(item);
      this.$bvModal.hide("secs-lg");
      this.$bvModal.show("first-chat");
      this.selected_post = item;
    },
    setPost(post) {
      this.selected_post = post;
      this.$bvModal.show("secs-lg");
    },
    callComments(id) {
      let obj = this.fetchComments(id);
    },
    async fetchComments(id) {
      let { data } = await PostsRepository.post_comments({ post_id: id });
      this.likes[this.count] = data[1].length;
      this.count++;
    },
    replacer(item) {
      let updater = item.replace("+", "%252B");
      return updater;
    },
    imgUrlAlt(event) {
      event.target.src = "img/section1.png";
    },
    dateSplitter(args) {
      var res = args.split("T");
      return res[0];
    },
  },
  computed: {
    ...mapGetters(["posts", "loggedUser", "FB", "chatUser"]),
  },
};
</script>
<style >
.btn-primary {
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}
.ant-pagination-item-active {
  border-color: #28a745 !important;
}
</style>

<style scoped>
.card-header {
  background-color: #28a745 !important;
  color: white;
  padding: 0rem !important;
}

.overlay {
  position: absolute;
  top: 30 !important;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20%;
  width: 100%;
  opacity: 1;
  transition: 0.5s ease;
  background-color: rgba(0, 0, 0, 0.2);
}

.custom_overlay:hover .overlay {
  opacity: 1;
  height: 30%;
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  .overlapper {
    position: absolute;
    top: 17px;
    left: 90px;
  }
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  .overlapper {
    position: absolute;
    top: 17px;
    left: 90px;
  }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .overlapper {
    position: absolute;
    top: 18px;
    left: 72px;
  }
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.card-img {
  height: 200px !important;
}
</style>
