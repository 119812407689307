<template>
  <div>
    <h3 class="left-aligned">
      <b> Get live Updates about order processing </b>
    </h3>

    <div>
      <div class="ml-auto mr-auto col-md-6 mb-5">
        <div class="row">
          <div class="col-md-12">
            <b-img
              :src="
                temp_order.imagekey != ''
                  ? 'https://s3.amazonaws.com/qurbaniimages/' +
                    replacer(temp_order.imagekey)
                  : 'img/section1.png'
              "
              fluid
              alt="Responsive image"
            ></b-img>
          </div>
          <div class="col-md-12">
            <h3>Sadqah Order {{ temp_order.no + 1 }}</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            Availibility : <span class="text-danger">Out of Stock</span>
          </div>
          <div class="col-md-6">
            <b>Rs:{{ temp_order.total_price }}</b>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">Ad Id {{ temp_order.id }}</div>
          <div class="col-md-6">
            {{ temp_order.sadqa_aqiqa }}
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-6">
            <span style="font-size: 12px">Delievery Charges: </span
            ><span><b>1000</b></span>
          </div>
          <div class="col-md-6">
            <span
              style="font-size: 12px"
              :class="
                temp_order.order_delivered == false ||
                temp_order.order_delivered == null
                  ? `text-danger`
                  : `text-success`
              "
            >
              {{
                temp_order.order_delivered == false ||
                temp_order.order_delivered == null
                  ? "IN PROCESS"
                  : "COMPLETED"
              }}
            </span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-6">
            <span style="font-size: 12px">Total Charges: </span
            ><span
              ><b>{{ 1000 + parseFloat(temp_order.total_price) }}</b></span
            >
          </div>
        </div>
        <div class="row mt-2" v-if="loggedUser.id == 21">
          <div class="col-md-6">
            <span style="font-size: 12px">Name: </span
            ><span style="font-size: 12px">{{ temp_order.user_name }}</span>
          </div>
        </div>
        <div class="row mt-2" v-if="loggedUser.id == 21">
          <div class="col-md-6">
            <span style="font-size: 12px">Phone no </span
            ><span style="font-size: 12px">{{ temp_order.user_phone }}</span>
          </div>
        </div>
        <div class="row mt-2" v-if="loggedUser.id == 21">
          <div class="col-md-6">
            <span style="font-size: 12px">Address: </span
            ><span style="font-size: 12px">{{ temp_order.user_address }}</span>
          </div>
        </div>
        <hr />
        <div class="row mt-2" v-if="loggedUser.id == 21">
          <div class="col-md-12">
            <h6 class="bolds">SEND UPDATES</h6>
          </div>
        </div>
        <div class="row mt-2" v-if="loggedUser.id == 21">
          <div
            :class="[
              temp_order.order_delivered == false
                ? 'col-md-3 pr-0 pl-0'
                : 'col-md-4',
              isMobile == false ? 'right-aligned' : '',
            ]"
          >
            <input
              style="display: none"
              ref="FileInput"
              @change="onFilePicked"
              type="file"
            />

            <b-button
              v-if="isUpload == false"
              variant="success"
              @click="visible = true"
              >Send Video
            </b-button>
            <b-spinner v-else variant="success" label="Spinning"></b-spinner>
          </div>
          <div
            :class="[
              temp_order.order_delivered == false ? 'col-md-3' : 'col-md-4',
              isMobile == false ? '' : 'mt-2',
            ]"
          >
            <b-button variant="success" v-b-modal.order-message
              >Send Text</b-button
            >
          </div>
          <div
            v-if="temp_order.order_delivered == false"
            :class="[
              temp_order.order_delivered == false ? 'col-md-3' : 'col-md-4',
              isMobile == false ? 'left-aligned' : 'mt-2',
            ]"
          >
            <b-button @click="completeOrder()" variant="success"
              >Complete</b-button
            >
          </div>
          <div
            :class="[
              temp_order.order_delivered == false
                ? 'col-md-3 pr-0 pl-0'
                : 'col-md-4',
              isMobile == false ? 'left-aligned' : 'mt-2',
            ]"
          >
            <input
              style="display: none"
              ref="ImageFileInput"
              @change="onImageFilePicked"
              type="file"
            />
            <b-button
              v-if="isImageUpload == false"
              variant="success"
              @click="imagevisible = true"
              >Send Image</b-button
            >
            <b-spinner v-else variant="success" label="Spinning"></b-spinner>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12">
            <span style="font-size: 12px" class="bolds">Live Updates</span>
          </div>
        </div>
        <div>
          <div v-for="(item, i) in temp_order.order_video.split(',')" :key="i">
            <div class="row mt-4">
              <div v-if="item != ''" class="col-md-12">
                <video
                  style="width: 233px !important; height: 178px !important"
                  controls
                >
                  <source
                    :src="
                      'https://s3.amazonaws.com/qurbaniimages/' + replacer(item)
                    "
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
            <div v-if="item != ''" class="row mt-2">
              <div class="col-md-12">
                <span class="bolds">{{
                  temp_order.order_video_message.split(",")[i]
                }}</span>
              </div>
            </div>
          </div>
          <div
            v-for="(item, i) in temp_order.order_image.split(',')"
            :key="i + 20"
          >
            <div class="row mt-4">
              <div v-if="item != ''" class="col-md-12">
                <b-img
                  style="width: 233px !important; height: 178px !important"
                  :src="
                    'https://s3.amazonaws.com/qurbaniimages/' + replacer(item)
                  "
                ></b-img>
              </div>
            </div>
            <div v-if="item != ''" class="row mt-2">
              <div class="col-md-12">
                <span class="bolds">{{
                  temp_order.order_image_message.split(",")[i]
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            class="row mt-2"
            v-for="(item, i) in temp_order.order_message.split(',')"
            :key="i"
          >
            <div
              v-if="item != ''"
              class="p-3 col-md-12 left-aligned"
              style="background: #f6f4f4"
            >
              <span class="bolds">{{ item }}</span>
            </div>
          </div>
        </div>
      </div>
      <a-modal
        v-model="imagevisible"
        title="Image Message"
        @ok="onImagePickFile"
      >
        <b-form-input
          v-model="edit_order_obj.order_image_message"
          placeholder="Enter message"
        ></b-form-input>
      </a-modal>
      <a-modal v-model="visible" title="Video Message" @ok="onPickFile">
        <b-form-input
          v-model="edit_order_obj.order_video_message"
          placeholder="Enter message"
        ></b-form-input>
      </a-modal>
      <b-modal
        id="order-message"
        @ok="saveOrderMessage"
        ok-title="Save"
        title="Order Message"
      >
        <b-form-input
          v-model="edit_order_obj.order_message"
          placeholder="Enter message"
        ></b-form-input>
      </b-modal>
    </div>
    <!-- <div >

                <b-container style="width:70%">
                    <b-card>

                    <b-row>
                        <b-col>
                        <h3 class="text-center">Check your Details Here</h3>
                        <h3>Order Id 123076</h3>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col  class="text-left text-muted">
                            NAME:Sibgat
                        </b-col>
                        <b-col class="text-right text-muted">
                            CNIC : 3730266882827
                        </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col  class="text-left text-muted">
                            PHONE NUMBER :83247837847
                        </b-col>
                        <b-col class="text-right text-muted">
                            ADDRESS : H-NO C-139 G10 Islamabad
                        </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col  class="text-left text-muted">
                            CITY : Islamabad
                        </b-col>
                        <b-col class="text-right text-muted">
                            COUNTRY: Pakistan
                        </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col  class="text-left text-muted">
                            CATEGORY PRICE : 13000
                        </b-col>
                        <b-col class="text-right text-muted">
                            STATUS : IN PROCESS
                        </b-col>
                    </b-row>
                     <div class="row mt-3" >
                        <div class="col-md-12">
                            <span style="font-size: 12px;" class="bolds">Live Updates</span>
                        </div>
                    </div>
                    </b-card>

                </b-container>
        </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import S3 from "aws-s3";
import PostCard from "../components/PostCard.vue";
import { RepositoryFactory } from "../Repository/RepositoryFactory";
const OrderRepository = RepositoryFactory.get("order_repository");
import { uuid } from "vue-uuid"; // uuid object is also exported to things
const config = {
  bucketName: "qurbaniimages",
  region: "us-east-1",
  accessKeyId: "AKIA44SH2DQ6PVOA366C",
  secretAccessKey: "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",
  s3Url: "https://qurbaniimages.s3.us-east-1.amazonaws.com/" /* optional */,
};
const S3Client = new S3(config);

export default {
  components: {
    PostCard,
  },
  data() {
    return {
      imagevisible: false,
      isUpload: false,
      isImageUpload: false,
      visible: false,
      temp_order: "",
      uuid: uuid.v1(),
      selected_video: {
        video_name: "",
        video: "",
      },
      selected_image: {
        image_name: "",
        image: "",
      },
      edit_order_obj: {
        sadqa_order_id: "",
        order_video: "", //TueDec31022357GMT+05002019_1577741037440
        order_video_message: "",
        order_message: "",
        order_delivered: true,
        order_image: "",
        order_image_message: "",
      },
      outline_success: "outline-success",
      success: "success",
    };
  },
  props: ["order"],
  methods: {
    async completeOrder() {
      this.temp_order.order_delivered = true;
      var { data } = await OrderRepository.edit_ijtmae_order({
        sadqa_order_id: this.order.id,
        order_delivered: true,
      });
      this.fetchAdminAllSadqahOrders();
      this.$emit("return-order", 4);
    },
    async fetchAdminAllSadqahOrders() {
      var { data } = await OrderRepository.get_admin_sadqa_orders();
      this.$store.commit("setAdminResources", data[0]);
    },

    async saveOrderMessage() {
      this.$bvModal.hide("order-message");
      if (this.edit_order_obj.order_message != "") {
        this.edit_order_obj.sadqa_order_id = this.temp_order.id;
        // delete this.edit_order_obj.order_video_message, delete this.edit_order_obj.order_video, delete this.edit_order_obj.order_delivered
        let { data } = await OrderRepository.edit_order(this.edit_order_obj);
        this.temp_order.order_message = data.order_message;
        this.edit_order_obj = {
          sadqa_order_id: "",
          order_video: "",
          order_video_message: "",
          order_message: "",
          order_delivered: false,
          order_image: "",
          order_image_message: "",
        };
        this.$store.commit("setNotifications", {
          message: "Order Message created successfully",
          type: "success",
        });
      } else {
        this.$store.commit("setNotifications", {
          message: "Order Message empty",
          type: "error",
        });
      }
    },

    async saveImage() {
      this.isImageUpload = true;
      // delete this.edit_order_obj.order_message, delete this.edit_order_obj.order_delivered
      this.edit_order_obj.sadqa_order_id = this.temp_order.id;
      this.edit_order_obj.order_image = this.selected_image.image_name;
      let self = this;
      S3Client.uploadFile(
        this.selected_image.image,
        this.selected_image.image_name
      )
        .then(async (resp) => {
          let { data } = await OrderRepository.edit_order(self.edit_order_obj);
          console.log(data);
          self.temp_order.order_image_message = data.order_image_message;
          self.edit_order_obj = {
            sadqa_order_id: "",
            order_video: "",
            order_video_message: "",
            order_message: "",
            order_delivered: false,
            order_image: "",
            order_image_message: "",
          };
          this.fetchAdminAllSadqahOrders();

          self.isImageUpload = false;
          self.$store.commit("setNotifications", {
            message: "Image Updated successfully",
            type: "success",
          });
          self.$emit("return-order", 4);
        })
        .catch((err) => console.error(err));
    },
    async saveVideo() {
      this.isUpload = true;
      // delete this.edit_order_obj.order_message, delete this.edit_order_obj.order_delivered
      this.edit_order_obj.sadqa_order_id = this.temp_order.id;
      this.edit_order_obj.order_video = this.selected_video.video_name;
      let self = this;
      S3Client.uploadFile(
        this.selected_video.video,
        this.selected_video.video_name
      )
        .then(async (resp) => {
          let { data } = await OrderRepository.edit_order(self.edit_order_obj);
          self.temp_order.video_message = data.video_message;
          self.edit_order_obj = {
            sadqa_order_id: "",
            order_video: "",
            order_video_message: "",
            order_message: "",
            order_delivered: false,
            order_image: "",
            order_image_message: "",
          };
          this.fetchAdminAllSadqahOrders();
          self.isUpload = false;
          self.$store.commit("setNotifications", {
            message: "Video Updated successfully",
            type: "success",
          });
          self.$emit("return-order", 4);
        })
        .catch((err) => console.error(err));
    },
    onImagePickFile() {
      this.$refs.ImageFileInput.click();
    },
    onPickFile() {
      this.$refs.FileInput.click();
    },
    onImageFilePicked(event) {
      const files = event.target.files;
      // if(files[0].name.split('.')[1]=='mp4') {
      let img_name = this.uuid + "." + files[0].name.split(".")[1];
      this.selected_image.image_name = img_name;
      this.selected_image.image = files[0];
      this.imagevisible = false;
      this.saveImage();

      //   }
      //   else {
      //     this.$store.commit('setNotifications',{message:'Make sure video format is mp4',type:'error'})

      //   }
    },
    onFilePicked(event) {
      const files = event.target.files;
      if (files[0].name.split(".")[1] == "mp4") {
        let img_name = this.uuid + "." + files[0].name.split(".")[1];
        this.selected_video.video_name = img_name;
        this.selected_video.video = files[0];
        this.visible = false;
        this.saveVideo();
      } else {
        this.$store.commit("setNotifications", {
          message: "Make sure video format is mp4",
          type: "error",
        });
      }

      // this.new_post.imagekey=this.selected_video.video_name
    },
    replacer(item) {
      if (item.includes("mp4")) {
        return item + ".mp4";
      }
      if (item.includes("cropped")) {
        let res = item.split(",");
        if (res.length == 1) {
          return res[0].replace("+", "%252B");
        } else if (res.length > 1) {
          return res[1].replace("+", "%252B");
        }
      } else if (item.includes("png")) {
        let res = item + ".png";
        return res;
      } else if (item.includes("jpg")) {
        let res = item + ".jpeg";
        return res;
      } else if (item.split(",").length > 0) {
        let res = item.split(",");
        if (res.length > 1) {
          let updater = res[1].replace("+", "%252B");
          return updater;
        } else {
          let updater = res[0].replace("+", "%252B");
          return updater;
        }
      } else {
        let updater = item.replace("+", "%252B");
        return updater;
      }
    },
  },
  computed: {
    ...mapGetters(["posts", "loggedUser"]),
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  created() {
    localStorage.setItem("route", "Sadqah");
    console.log(this.order);
    this.temp_order = this.order;
    this.edit_order_obj.order_delivered = this.order.order_delivered;
  },
  mounted() {
    localStorage.setItem("route", "Sadqah");
  },
};
</script>

<style scoped>
.text-muted {
  color: #8a8585 !important;
}
</style>