import Vue from "vue";
import App from "./App.vue";
import {BootstrapVue,BootstrapVueIcons} from "bootstrap-vue";
import VueRouter from "vue-router";
import router from "./router";
import VueClipboard from "vue-clipboard2";
import * as VueGoogleMaps from "vue2-google-maps";
import store from "./store";
import "native-toast/dist/native-toast.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";
import VueRecord from "@codekraft-studio/vue-record";
import VueAnalytics from "vue-analytics";
import VueSocialSharing from "vue-social-sharing";
import globalfuns from "../src/mixins/globalfuns";
Vue.mixin(globalfuns);
import firebase from "firebase";
Vue.use(VueMaterial);
Vue.use(VueClipboard);
Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Antd);
Vue.use(VueRecord);
Vue.use(VueSocialSharing);
Vue.use(VueAnalytics, {
  id: "UA-161739350-1",
  router,
});

// var firebaseConfig = {
//   apiKey: "AIzaSyAlqdRtL2bG_9feOEMwP84lfhhhdBzjKUU",
//   authDomain: "form-apis-43f14.firebaseapp.com",
//   databaseURL: "https://form-apis-43f14.firebaseio.com",
//   projectId: "form-apis-43f14",
//   storageBucket: "form-apis-43f14.appspot.com",
//   messagingSenderId: "576391803423",
//   appId: "1:576391803423:web:cee1cc6393bef4a8331b2a",
//   measurementId: "G-TFB4KXCVCD"
// };
var firebaseConfig = {
  apiKey: "AIzaSyBt5gxtWIa9--CjqKCp9MBjco_glSd4xPY",
  authDomain: "qurbaniapp-24a10.firebaseapp.com",
  databaseURL: "https://qurbaniapp-24a10.firebaseio.com",
  projectId: "qurbaniapp-24a10",
  storageBucket: "qurbaniapp-24a10.appspot.com",
  messagingSenderId: "527359831128",
  appId: "1:527359831128:web:e93cb69a1d27eba8842e2d",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  if (requiresAuth && !localStorage.getItem("loggedUser")) {
    next("/login");
  } else if (requiresAuth && localStorage.getItem("loggedUser")) {
    next();
  } else {
    if (to.name == "Login" && localStorage.getItem("loggedUser")) {
      next("/dashboard");
    } else {
      next();
    }
  }
});

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAOkWyGIdy3PNtv36rswAnB8vX_cU-_Pc0",
    libraries: "places", // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
});
// Vue.config.productionTip = false
// app.js
new Vue({
  render: (h) => h(App),
  store,
  router,
}).$mount("#app");
