export default {
  data() {
    return {
      msg_notif_obj: {
        sender_id: "",
        post_owner_id: "",
        post_image: "",
        channel: "",
      },
      new_chat_obj: {
        message: "",
        post_id: "",
        sender: "",
        timestamp: "",
        type: "text",
        image_key: "",
      },
      new_user_obj: {
        last_date: "",
        last_time: "",
      },
    };
  },
  computed: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    hiddenPhone(val, index) {
      if (val != "Call Now") {
        let temp = val;
        let templength = temp.length;
        let hash = "";
        let slicedstr = temp.slice(2, templength - 3);
        for (var i = 0; i < slicedstr.length; i++) {
          hash = hash + "*";
        }
        let s =
          temp.slice(0, 2) + hash + temp.slice(templength - 2, templength);
        return s;
      } else {
        return "Call Now";
      }
    },
    getCurrentTime() {
      var date = new Date();
      var ampm = hours >= 12 ? "pm" : "am";
      var hours = date.getHours();
      var minutes = date.getMinutes();
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;

      var times =
        ("0" + hours).slice(-2) +
        ":" +
        ("0" + minutes).slice(-2) +
        ":" +
        ("0" + date.getSeconds()).slice(-2);
      return times;
    },
    getTodaysDate() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      today = dd + "-" + mm + "-" + yyyy;
      return today;
    },
    replacer(item) {
      if (item != null) {
        if (item.split(",").length > 1 && !item.includes("+")) {
          let res = item.split(",");
          if (res[1].includes("png")) {
            let rip = res[1] + ".png";
            return rip;
          } else if (res[1].includes("jpg")) {
            let rip = res[1] + ".jpeg";
            return rip;
          }
        }
        if (item.includes("cropped")) {
          let res = item.split(",");
          if (res.length == 1) {
            return res[0].replace("+", "%252B");
          } else if (res.length > 1) {
            return res[1].replace("+", "%252B");
          }
        } else if (item.includes("jpg") && item.includes("GMT")) {
          let res = item.split(",");

          if (res.length > 1) {
            let updater = res[1].replaceAll("+", "%252B");
            return updater;
          } else {
            let updater = res[0].replaceAll("+", "%252B");
            return updater;
          }
          // return item
        } else if (item.includes("jpg")) {
          let res = item + ".jpeg";
          return res;
        } else if (item.includes("mp4")) {
          let res = item + ".mp4";
          return res;
        } else if (item.includes("png")) {
          let res = item + ".png";
          return res;
        } else if (item.split(",").length > 0) {
          let res = item.split(",");

          if (res.length > 1) {
            let updater = res[1].replaceAll("+", "%252B");
            return updater;
          } else {
            let updater = res[0].replaceAll("+", "%252B");
            return updater;
          }
        } else {
          let updater = item.replaceAll("+", "%252B");
          return updater;
        }
      }
    },
    trimValue(arg){
      if(arg){
      let splts=arg.split('.')
      if(splts.length>1){
        if(splts[1]=='png'){
          return splts[0]+'.'+splts[1]
        }
        else{
         return arg
        }
      
      }
      else{

      return arg
      }
    }
    },
    replacers(item) {
      if (item.split(",").length > 0 && !item.includes("+")) {
        let res = item.split(",");
        if (res[0].includes("png")) {
          let rip = res[0] + ".png";
          return rip;
        } else if (res[0].includes("jpg")) {
          let rip = res[0] + ".jpeg";
          return rip;
        }
      }
      // console.log(item.includes('+'))
      if (item.includes("cropped")) {
        let res = item.split(",");
        if (res.length == 1) {
          return res[0].replace("+", "%252B");
        } else if (res.length > 1) {
          return res[1].replace("+", "%252B");
        }
      } else if (item.includes("png")) {
        let res = item + ".png";
        return res;
      } else if (item.includes("jpg")) {
        let res = item + ".jpeg";
        return res;
      } else if (item.split(",").length > 0) {
        let res = item.split(",");

        if (res.length > 1) {
          let updater = res[1].replace("+", "%252B");
          return updater;
        } else {
          let updater = res[0].replace("+", "%252B");
          return updater;
        }
      } else {
        let updater = item.replace("+", "%252B");
        return updater;
      }
    },
    redirect(self, routename) {
      // console.log("yehh")
      // setTimeout(()=>{
      self.$router.push(`${routename}`);
      // },2000)
    },
    // isMobile() {
    //   if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    //     return true
    //   } else {
    //     return false
    //   }
    // }
  },
};
