<template>
  <b-container>
    <h3 class="mt-4 text-success text-left">{{mine==true && $route.name=='Dashboard' ? 'My Farms':'Explore All Farms Of Pakistan'}}</h3>
    <b-spinner v-if="postLoad == true" variant="success"></b-spinner>

    <div v-else class="mt-5 row">
      <div
        @click="checkClick(post_item)"
        
        v-for="(post_item, i) in farms"
        :key="i"
        class="col-md-3 col-12 mt-3"
      >
        <b-card
         
          style="background: #f7f7f7;box-shadow: 0px 3px 6px #00000029;border:none;cursor:pointer;"
        >
          <b-avatar
            
            :src="post_item.image!='' ?
              'https://s3.amazonaws.com/qurbaniimages/' +
              trimValue(replacer(post_item.image))
              :
              './img/farms/cow.png'
            "
            size="4rem"
          >
          </b-avatar>
          <p>{{ post_item.name}}</p>
           <b-button
            variant="success"
             @click="$emit('order-item', post_item)"
            v-if="loggedUser && $route.name=='Dashboard' && post_item.user_id == loggedUser.id"
            ><span class="fa fa-eye"></span></b-button
          >
          <b-button
            variant="danger"
            class="ml-2"
            @click="delFarm(post_item)"
            v-if="loggedUser && $route.name=='Dashboard' && post_item.user_id == loggedUser.id"
            ><span class="fa fa-trash"></span></b-button
          >
          <b-button
            variant="success"
            class="ml-2"
            @click="$emit('edit-farm',post_item)"
            v-if="loggedUser && $route.name=='Dashboard' && post_item.user_id == loggedUser.id"
            ><span class="fa fa-edit"></span></b-button
          >
        </b-card>
      </div>

    </div>
    <!-- <a-pagination
      class="pages"
      :current="current"
      :default-current="5"
      @change="onChange"
      :total="rows"
    /> -->

    <!-- <b-modal
      hide-footer
      id="modal-lg"
      ref="modal-lg"
      size="lg"
      :title="selected_post.category"
    >
      <DialogPost
        :selected_post="selected_post"
        @chat-show="$emit('chat-show', selected_post)"
      />
    </b-modal> -->
  </b-container>
</template>
<script>
import PostCard from "./PostCard.vue";
import DialogPost from "./DialogPost.vue";
import { RepositoryFactory } from "../Repository/RepositoryFactory";
const FarmsRepository = RepositoryFactory.get("farms_repository");
import globalfuns from "../mixins/globalfuns";

import { mapGetters } from "vuex";
export default {
  name: "Farms",
  mixins: [globalfuns],

  props: ["mine"],
  components: {
    PostCard,
    DialogPost,
  },
  computed: {
    ...mapGetters(["farms", "loggedUser"]),
    rows() {
      if (this.farms.length > 0) {
        let lngth_posts = this.total_posts; //this.farms[2].length
        let temp_perpage = 12;
        let dividedresult = lngth_posts / temp_perpage;
        let parsed = parseInt(dividedresult);
        if (parsed * temp_perpage < lngth_posts) {
          parsed++;
          let sringer = parsed.toString() + "0";
          let parsedback = parseInt(sringer);
          return parsedback;
        } else {
          let sringer = parsed.toString() + "0";
          let parsedback = parseInt(sringer);
          return parsedback;
        }
      }
    },
    spliced_posts() {
      let arr2 = [];
      if (this.farms.length > 0) {
        arr2 = this.farms[2].slice(this.start_index, this.end_index);
        return arr2;
      } else {
        return [];
      }
    },
  },
  watch: {
    farms() {
      if (this.farms.length > 0) {
        this.end_index = this.per_page;
      }
    },
  },
  mounted() {
    localStorage.setItem("route", "AllPosts");
    // this.getAllFarms(1)
  },
  created() {
    console.log(this.mine);
    localStorage.setItem("route", "AllPosts");
    if (this.farms.length > 0) {
      this.end_index = this.per_page;
    }
    if (this.mine) {
      this.getMyFarms();
    } else {
      this.getAllFarms();
    }
  },
  methods: {
    checkClick (post_item){
      console.log(this.mine)
      if(this.mine==true){

      }
      else{
        this.$emit('order-item', post_item)
      }
    },
    
    // selectFarm(item){
    //   console.log(item)
    //   this.$bvModal.show('shits-dialog')
    //   this.editFarm=item
    // },
    async delFarm(item) {
      console.log(item);
      let { data } = await FarmsRepository.deleteFarm({ farm_id: item.id });
      console.log(data);
      if (this.mine) {
        this.getMyFarms();
      } else {
        this.getAllFarms();
      }
    },
    //  onPickFile() {
    //   this.$refs.FileInput.click();
    // },
    // getBase64(file) {
    //   return new Promise((resolve, reject) => {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(file);
    //     reader.onload = () => resolve(reader.result);
    //     reader.onerror = (error) => reject(error);
    //   });
    // },
    // async onFilePicked(event) {
    //   this.file = event.target.files[0];
    //   var opd = await this.getBase64(event.target.files[0]);
    //   this.showurl = opd;
    // },
    async getAllFarms() {
      //  this.postLoad = true;
      let { data } = await FarmsRepository.getAllFarms();
      this.$store.commit("setFarms", data);
      // console.log(data);
      //  this.postLoad = false;
    },
    async getMyFarms() {
      this.postLoad = true;
      let { data } = await FarmsRepository.getUserFarms({
        user_id: this.loggedUser.id,
      });
      this.$store.commit("setFarms", data);

      window.scrollTo(50, 50);
      this.postLoad = false;
    },

    onChange(current, pkd) {
      this.current = current;
      this.getAllFarms(current);
    },
    imgUrlAlt(event) {
      event.target.src = "img/section1.png";
    },
  },
  data() {
    return {
      editFarm:{},
      isload:false,
      showurl:"",

      postLoad: false,
      viewable_posts: [],
      total_posts: 0,
      start_index: 0,
      end_index: 0,
      current: 1,
      per_page: 12,
      selected_post: "",
    };
  },
};
</script>