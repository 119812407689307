import firebase from "firebase";
import Vue from "vue";

const state = {
  discussions: [],
  comments:[]
};

const getters = {
  discussions: state => state.discussions,
  comments:state=> state.comments
};

const actions = {
  addComment(context,payload){
    let customkey = firebase
      .database()
      .ref()
      .child("Discussions")
      .push().key;
      console.log(payload)
      console.log(customkey)
      firebase
      .database()
      .ref("Discussions")
      .child(payload.id)
      .child(customkey)
      .set(payload.data)
      .then(() => {
        alert("Comment added succcessfully");
        // dispatch('user/setNotifications',{message:'Discussion created succcessfully',type:'success'})
      })
      .catch((error) => console.log(error.message));
  },
  async addDiscussion(context, payload) {

    if(payload.file!=null){

      
      let imgkey = firebase
        .database()
        .ref()
        .child("Questions")
        .push().key;
  
      let ext2 = payload.file.name.slice(payload.file.name.lastIndexOf("."));
  
  
      let snapshot = await firebase.storage()
        .ref("discImages/" + imgkey+ ext2.toLowerCase())
        .put(payload.file);
      let url = await snapshot.ref.getDownloadURL();
      let customkey = firebase
      .database()
      .ref()
      .child("Questions")
      .push().key;
    firebase
      .database()
      .ref("Questions")
      .child(customkey)
      .set({ ...payload.data, id: customkey,queDownloadLink:url,file_types:payload.file.type.split('/')[0]})
      .then(() => {
        alert("Forum post created succcessfully");
      })
      .catch((error) => console.log(error.message));
  
    }
    else{
      let customkey = firebase
      .database()
      .ref()
      .child("Questions")
      .push().key;
    firebase
      .database()
      .ref("Questions")
      .child(customkey)
      .set({ ...payload.data, id: customkey})
      .then(() => {
        alert("Forum post created succcessfully");
      })
      .catch((error) => console.log(error.message));
    }


   
        // dispatch('user/setNotifications',{message:'Discussion created succcessfully',type:'success'})
    
  },
  fetchDiscussions({ commit }, payload) {
    firebase
      .database()
      .ref("Questions")
      .on("value", (snapshot) => {
        
        commit("setDiscussions", {

          ...snapshot.val(),
        });
      });
  },
  fetchComments({ commit }, payload) {
    firebase
      .database()
      .ref("Discussions")
      .on("value", (snapshot) => {
        
        commit("setComments", {

          ...snapshot.val(),
        });
      });
  },
};
const mutations = {
  setDiscussions(state, payload) {
    let discussions_Array = [];
    for (let key in payload) {
      payload[key].id = key;
      discussions_Array.push(payload[key]);
    }
    state.discussions = discussions_Array.reverse();
  },
  setComments(state, payload) {
    let discussions_Array = [];
    for (let key in payload) {
      payload[key].id = key;
      discussions_Array.push(payload[key]);
    }
    state.comments = discussions_Array.reverse();
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
