<template>
   <b-container fluid class="mt-5">
      <h3 style="font-weight:bold;">Contact Us</h3>
      <h6>SEND US YOUR THOUGHTS AND WE WILL GET BACK TO YOU </h6>
     
      <b-card class="offset-md-3 col-md-6 text-center">
        <b-card-body >
          <form @submit.prevent="submitQuery">
           <b-form-group
            id="input-group-1"
            label="Name:"
            label-for="input-1">
            <b-form-input
              id="input-1"
              required
              v-model="name"
              placeholder="Enter your name"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-1"
            label="Email:"
            label-for="input-1">
            <b-form-input
              id="input-1"
              v-model="email"
              type="email"
              required
              placeholder="Enter your email"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-1"
            label="Phone:"
            label-for="input-1">
            <b-form-input
              id="input-1"
              v-model="phone"
              type="number"
              required
              placeholder="Enter your contact no"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-1"
            label="Your Message:"
            label-for="input-1">
            <b-form-textarea
              id="textarea"
              required
              v-model="message"
              placeholder="Enter your message..."
              rows="3"
              max-rows="6"
            ></b-form-textarea> 
          </b-form-group>
          <b-button variant="success" type="submit">Submit</b-button>
          </form>
        </b-card-body>
      </b-card>
      <!-- <div class="row ml-auto mr-auto">
        <div class="col-md-6">
            <b-form-group
            id="input-group-1"
            label="Email address:"
            label-for="input-1"
            description="We'll never share your email with anyone else.">
            <b-form-input
              id="input-1"
              type="email"
              required
              placeholder="Enter email"
            ></b-form-input>
          </b-form-group>

        </div>

      </div>
          <b-form-group
            id="input-group-1"
            label="Email address:"
            label-for="input-1"
            description="We'll never share your email with anyone else.">
            <b-form-input
              id="input-1"
              type="email"
              class="col-md-7 ml-auto mr-auto"
              required
              placeholder="Enter email"
            ></b-form-input>
          </b-form-group> -->

      <!-- <div class="row">
        <div clas="col-md-6">     
          <b-form >
              <b-form-group
                id="input-group-1"
                label="Email address:"
                label-for="input-1"
                description="We'll never share your email with anyone else.">
                <b-form-input
                  id="input-1"
                  type="email"
                  required
                  placeholder="Enter email"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-2" label="Your Name:" label-for="input-2">
                <b-form-input
                  id="input-2"
                  required
                  placeholder="Enter name"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-4">
                <b-form-checkbox-group  id="checkboxes-4">
                  <b-form-checkbox value="me">Check me out</b-form-checkbox>
                  <b-form-checkbox value="that">Check that out</b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>

              <b-button type="submit" variant="primary">Submit</b-button>
              <b-button type="reset" variant="danger">Reset</b-button>
          </b-form>
        </div>

      </div> -->
   </b-container>
   
      
</template>
<script>
  import { RepositoryFactory } from '../Repository/RepositoryFactory'
  const UserRepository = RepositoryFactory.get('user_repository')
  export default {
   data(){
      return{
        name:'',
        email:'',
        phone:'',
        message:''
      }
    },
    methods:{
      async submitQuery(){

        console.log({name:this.name,
        email:this.email,
        phone:this.phone,
        message:this.message})
        var {data}= await UserRepository.contactus({
          your_name:this.name,
          your_email:this.email,
          your_phone:this.phone,
          your_message:this.message
        })
        this.$store.commit('setNotifications',{message:'Query submitted successfully',type:'success'})


      }
    }
  }
</script>


<style  >
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
