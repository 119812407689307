import firebase from "firebase";
import Vue from "vue";

const state = {
  farms: [],
};

const getters = {
  farms: (state) => {
    return state.farms;
  },
};

const actions = {};
const mutations = {
  setFarms: (state, obj) => {
    state.farms = obj;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
