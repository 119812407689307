<template>
  <b-container>
    <div class="row" style="margin-top: 5rem">
      <div class="col-md-12">
        <b-card class="col-md-6 ml-auto mr-auto">
          <div class="row">
            <div class="col-md-12">
              <h4><b>UPDATE YOUR ADD</b></h4>
              <p class="left-aligned">Click to Upload Media</p>
              <input
                style="display: none"
                ref="FileInput"
                @change="onFilePicked"
                type="file"
              />
              <img
                v-if="selected_image.image_name == ''"
                @click="onPickFile"
                :src="
                  'https://s3.amazonaws.com/qurbaniimages/' +
                  replacer(post.imagekey)
                "
                width="90"
                align="left"
              />
              <p v-if="selected_image.image_name != ''" class="left-aligned">
                <span
                  class="fa fa-check fa-3x text-success left-aligned text-right"
                  aria-hidden="true"
                ></span>
              </p>

              <!-- <p v-else class="left-aligned"><span class="fa fa-check fa-3x text-success left-aligned text-right" aria-hidden="true"></span> </p> -->
            </div>
            <div style="text-align: left !important" class="col-md-12 mt-3">
              <p class="left-aligned">Add Type</p>
              <b-button
                :variant="post.sale == true ? success : outline_success"
                class="text-left rounded-pill"
                @click="post.sale = true"
                >For Sale</b-button
              >
              <b-button
                :variant="post.sale == null ? success : outline_success"
                class="rounded-pill ml-1"
                @click="post.sale = false"
                >Not For Sale</b-button
              >
            </div>
            <div style="text-align: left !important" class="col-md-12 mt-3">
              <p class="left-aligned">Select Category</p>
              <b-button
                :variant="post.category == 'cow' ? success : outline_success"
                class="text-left rounded-pill"
                @click="post.category = 'cow'"
                >Cow</b-button
              >
              <b-button
                :variant="post.category == 'goat' ? success : outline_success"
                class="ml-1 rounded-pill"
                @click="post.category = 'goat'"
                >Goat</b-button
              >
              <b-button
                :variant="post.category == 'sheep' ? success : outline_success"
                class="ml-1 rounded-pill"
                @click="post.category = 'sheep'"
                >Sheep</b-button
              >
            </div>
            <div style="text-align: left !important" class="col-md-12 mt-3">
              <p class="left-aligned">Animal Information</p>
              <b-form-input
                placeholder="City"
                v-model="post.city"
              ></b-form-input>
              <b-form-input
                class="mt-2"
                v-model="post.price"
                placeholder="Price"
              ></b-form-input>
              <b-form-input
                class="mt-2"
                v-model="post.contactno"
                placeholder="Contact Number"
              ></b-form-input>
              <b-input-group class="mt-2">
                <b-form-input
                  v-model="post.weight"
                  placeholder="Weight"
                ></b-form-input>

                <template v-slot:append>
                  <b-dropdown :text="post.weight_unit" variant="success">
                    <b-dropdown-item @click="post.weight_unit = 'KG'"
                      >KG</b-dropdown-item
                    >
                    <b-dropdown-item @click="post.weight_unit = 'Mann'"
                      >Mann</b-dropdown-item
                    >
                  </b-dropdown>
                </template>
              </b-input-group>
              <b-form-textarea
                class="mt-2"
                placeholder="Description..."
                rows="3"
                v-model="post.description"
                max-rows="6"
              ></b-form-textarea>
            </div>
            <div class="col-md-12 mt-4">
              <b-button
                class="text-center"
                variant="success"
                @click="updatePost"
                >UPDATE</b-button
              >
            </div>
          </div>
        </b-card>
      </div>
    </div>
  </b-container>

  <!-- </div> -->
</template>

<script>
import S3 from "aws-s3";
import { RepositoryFactory } from "../Repository/RepositoryFactory";
const PostsRepository = RepositoryFactory.get("posts_repository");

const config = {
  bucketName: "qurbaniimages",
  region: "us-east-1",
  accessKeyId: "AKIA44SH2DQ6PVOA366C",
  secretAccessKey: "szYhav2Z6/+VHYznh7kfa2mqmElvVsseg5/BvVZOO4edA4waS5",
  s3Url: "https://qurbaniimages.s3.us-east-1.amazonaws.com/" /* optional */,
};
const S3Client = new S3(config);
import { mapGetters } from "vuex";
import { uuid } from "vue-uuid"; // uuid object is also exported to things

export default {
  props: ["type", "post"],

  data() {
    return {
      uuid: uuid.v1(),
      selected_image: {
        image_name: "",
        image: "",
      },
      outline_success: "outline-success",
      success: "success",
    };
  },
  computed: {
    ...mapGetters(["loggedUser"]),
  },
  created() {
    console.log(this.post);
    if (this.type != null) {
      this.post.post_type = this.type;
    }
  },
  methods: {
    replacer(item) {
      if (item.includes("cropped")) {
        let res = item.split(",");
        if (res.length == 1) {
          return res[0].replace("+", "%252B");
        } else if (res.length > 1) {
          return res[1].replace("+", "%252B");
        }
      } else if (item.includes("png")) {
        let res = item + ".png";
        return res;
      } else if (item.includes("jpg")) {
        let res = item + ".jpeg";
        return res;
      } else if (item.split(",").length > 0) {
        let res = item.split(",");
        if (res.length > 1) {
          let updater = res[1].replace("+", "%252B");
          return updater;
        } else {
          let updater = res[0].replace("+", "%252B");
          return updater;
        }
      } else {
        let updater = item.replace("+", "%252B");
        return updater;
      }
    },
    onPickFile() {
      this.$refs.FileInput.click();
    },
    onFilePicked(event) {
      const files = event.target.files;
      let img_name = this.uuid + "." + files[0].name.split(".")[1];
      this.selected_image.image_name = img_name;
      this.selected_image.image = files[0];
      this.post.imagekey = this.selected_image.image_name;
    },
    async updatePost() {
      console.log(this.selected_image);
      this.post.post_id = this.post.id;
      // delete this.post.id
      console.log(this.post);
      if (
        this.post.imagekey != "" &&
        this.post.description != "" &&
        this.post.price != "" &&
        this.post.weight != "" &&
        this.post.contactno != ""
      ) {
        if (this.selected_image.image_name != "") {
          S3Client.uploadFile(
            this.selected_image.image,
            this.selected_image.image_name
          )
            .then((data) => {
              console.log(data);
            })
            .catch((err) => console.error(err));
        }

        this.post.user_id = this.loggedUser.id;
        var { data } = await PostsRepository.update_post(this.post);
        console.log(data);
        this.$store.commit("setNotifications", {
          message: "Your post has been updated",
          type: "success",
        });
        this.$router.push({ path: "/dashboard" });
      } else {
        this.$store.commit("setNotifications", {
          message: "Make sure to fill in all details",
          type: "error",
        });
      }
    },
  },
};
</script>

<style>
</style>