<template>
  <b-container
    fluid
    class="main-landing-back pr-0 pl-0"
    style="min-height: 500px; position: relative"
  >
    <!-- <testTopNav /> -->
    <b-container>
      <div class="row" >
        <div class="col-md-12">
          <b-card class="col-md-6 ml-auto mr-auto">
            <div class="row">
              <div class="col-md-12 text-left">
                  <b-button @click="goBACK" variant="success" pill>BACK</b-button>
              </div>
              <div class="col-md-12">
                <h4><b>POST YOUR ADD</b></h4>
                <p class="left-aligned">Click to Upload Media</p>
                <div class="d-flex">
                  <input
                    style="display: none"
                    single
                    ref="FileInput"
                    @change="onFilePicked"
                    type="file"
                  />
                  <img
                    v-if="total_files.length < 1"
                    @click="onPickFile"
                    src="img/sellmodal/camera.png"
                    width="50"
                    align="left"
                  />
                  <p v-else class="left-aligned">
                    <span
                      class="
                        fa fa-check fa-3x
                        text-success
                        left-aligned
                        text-right
                      "
                      aria-hidden="true"
                    ></span>
                  </p>
                  <b-avatar
                    variant="info"
                    size="4rem"
                    :src="showurl"
                    v-if="
                      total_files.length > 0 &&
                      this.new_post.post_type == 'AUDIO'
                    "
                    button
                  ></b-avatar>
                  <i
                    v-if="
                      total_files.length > 0 &&
                      this.new_post.post_type == 'AUDIO'
                    "
                    class="fa fa-crop"
                    v-b-modal.dopes
                    aria-hidden="true"
                  ></i>
                </div>

                <video
                  v-if="video != ''"
                  id="my-video"
                  style="height: 163px"
                  width="450"
                  controls
                  :src="video"
                ></video>
              </div>
              <div style="text-align: left !important" class="col-md-12 mt-3">
                <p class="left-aligned">Add Type</p>
                <b-button
                  :variant="new_post.sale == true ? success : outline_success"
                  class="text-left rounded-pill"
                  @click="new_post.sale = true"
                  >For Sale</b-button
                >
                <b-button
                  :variant="new_post.sale == false ? success : outline_success"
                  class="rounded-pill ml-1"
                  @click="new_post.sale = false"
                  >Not For Sale</b-button
                >
              </div>

              <div style="text-align: left !important" class="col-md-12 mt-3">
                <p class="left-aligned">Select Category</p>
                <b-button
                  :variant="
                    new_post.category == 'cow' ? success : outline_success
                  "
                  class="text-left rounded-pill"
                  @click="new_post.category = 'cow'"
                  >Cow</b-button
                >
                <b-button
                  :variant="
                    new_post.category == 'goat' ? success : outline_success
                  "
                  class="ml-1 rounded-pill"
                  @click="new_post.category = 'goat'"
                  >Goat</b-button
                >
                <b-button
                  :variant="
                    new_post.category == 'sheep' ? success : outline_success
                  "
                  class="ml-1 rounded-pill"
                  @click="new_post.category = 'sheep'"
                  >Sheep</b-button
                >
                <b-button
                  v-if="loggedUser.id == 21"
                  :variant="
                    new_post.category == 'Sadqa' ? success : outline_success
                  "
                  class="ml-1 rounded-pill"
                  @click="new_post.category = 'Sadqa'"
                  >Sadqa</b-button
                >
                <b-button
                  v-if="loggedUser.id == 21"
                  :variant="
                    new_post.category == 'Aqiqa' ? success : outline_success
                  "
                  class="ml-1 rounded-pill"
                  @click="new_post.category = 'Aqiqa'"
                  >Aqiqa</b-button
                >
              </div>
              <div style="text-align: left !important" class="col-md-12 mt-3">
                <p class="left-aligned">Select Country</p>
                <select
                  v-model="new_post.country"
                  id="country"
                  name="country"
                  class="form-control"
                >
                  <option value="Afghanistan">Afghanistan</option>
                  <option value="Åland Islands">Åland Islands</option>
                  <option value="Albania">Albania</option>
                  <option value="Algeria">Algeria</option>
                  <option value="American Samoa">American Samoa</option>
                  <option value="Andorra">Andorra</option>
                  <option value="Angola">Angola</option>
                  <option value="Anguilla">Anguilla</option>
                  <option value="Antarctica">Antarctica</option>
                  <option value="Antigua and Barbuda">
                    Antigua and Barbuda
                  </option>
                  <option value="Argentina">Argentina</option>
                  <option value="Armenia">Armenia</option>
                  <option value="Aruba">Aruba</option>
                  <option value="Australia">Australia</option>
                  <option value="Austria">Austria</option>
                  <option value="Azerbaijan">Azerbaijan</option>
                  <option value="Bahamas">Bahamas</option>
                  <option value="Bahrain">Bahrain</option>
                  <option value="Bangladesh">Bangladesh</option>
                  <option value="Barbados">Barbados</option>
                  <option value="Belarus">Belarus</option>
                  <option value="Belgium">Belgium</option>
                  <option value="Belize">Belize</option>
                  <option value="Benin">Benin</option>
                  <option value="Bermuda">Bermuda</option>
                  <option value="Bhutan">Bhutan</option>
                  <option value="Bolivia">Bolivia</option>
                  <option value="Bosnia and Herzegovina">
                    Bosnia and Herzegovina
                  </option>
                  <option value="Botswana">Botswana</option>
                  <option value="Bouvet Island">Bouvet Island</option>
                  <option value="Brazil">Brazil</option>
                  <option value="British Indian Ocean Territory">
                    British Indian Ocean Territory
                  </option>
                  <option value="Brunei Darussalam">Brunei Darussalam</option>
                  <option value="Bulgaria">Bulgaria</option>
                  <option value="Burkina Faso">Burkina Faso</option>
                  <option value="Burundi">Burundi</option>
                  <option value="Cambodia">Cambodia</option>
                  <option value="Cameroon">Cameroon</option>
                  <option value="Canada">Canada</option>
                  <option value="Cape Verde">Cape Verde</option>
                  <option value="Cayman Islands">Cayman Islands</option>
                  <option value="Central African Republic">
                    Central African Republic
                  </option>
                  <option value="Chad">Chad</option>
                  <option value="Chile">Chile</option>
                  <option value="China">China</option>
                  <option value="Christmas Island">Christmas Island</option>
                  <option value="Cocos (Keeling) Islands">
                    Cocos (Keeling) Islands
                  </option>
                  <option value="Colombia">Colombia</option>
                  <option value="Comoros">Comoros</option>
                  <option value="Congo">Congo</option>
                  <option value="Congo, The Democratic Republic of The">
                    Congo, The Democratic Republic of The
                  </option>
                  <option value="Cook Islands">Cook Islands</option>
                  <option value="Costa Rica">Costa Rica</option>
                  <option value="Cote D'ivoire">Cote D'ivoire</option>
                  <option value="Croatia">Croatia</option>
                  <option value="Cuba">Cuba</option>
                  <option value="Cyprus">Cyprus</option>
                  <option value="Czech Republic">Czech Republic</option>
                  <option value="Denmark">Denmark</option>
                  <option value="Djibouti">Djibouti</option>
                  <option value="Dominica">Dominica</option>
                  <option value="Dominican Republic">Dominican Republic</option>
                  <option value="Ecuador">Ecuador</option>
                  <option value="Egypt">Egypt</option>
                  <option value="El Salvador">El Salvador</option>
                  <option value="Equatorial Guinea">Equatorial Guinea</option>
                  <option value="Eritrea">Eritrea</option>
                  <option value="Estonia">Estonia</option>
                  <option value="Ethiopia">Ethiopia</option>
                  <option value="Falkland Islands (Malvinas)">
                    Falkland Islands (Malvinas)
                  </option>
                  <option value="Faroe Islands">Faroe Islands</option>
                  <option value="Fiji">Fiji</option>
                  <option value="Finland">Finland</option>
                  <option value="France">France</option>
                  <option value="French Guiana">French Guiana</option>
                  <option value="French Polynesia">French Polynesia</option>
                  <option value="French Southern Territories">
                    French Southern Territories
                  </option>
                  <option value="Gabon">Gabon</option>
                  <option value="Gambia">Gambia</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Germany">Germany</option>
                  <option value="Ghana">Ghana</option>
                  <option value="Gibraltar">Gibraltar</option>
                  <option value="Greece">Greece</option>
                  <option value="Greenland">Greenland</option>
                  <option value="Grenada">Grenada</option>
                  <option value="Guadeloupe">Guadeloupe</option>
                  <option value="Guam">Guam</option>
                  <option value="Guatemala">Guatemala</option>
                  <option value="Guernsey">Guernsey</option>
                  <option value="Guinea">Guinea</option>
                  <option value="Guinea-bissau">Guinea-bissau</option>
                  <option value="Guyana">Guyana</option>
                  <option value="Haiti">Haiti</option>
                  <option value="Heard Island and Mcdonald Islands">
                    Heard Island and Mcdonald Islands
                  </option>
                  <option value="Holy See (Vatican City State)">
                    Holy See (Vatican City State)
                  </option>
                  <option value="Honduras">Honduras</option>
                  <option value="Hong Kong">Hong Kong</option>
                  <option value="Hungary">Hungary</option>
                  <option value="Iceland">Iceland</option>
                  <option value="India">India</option>
                  <option value="Indonesia">Indonesia</option>
                  <option value="Iran, Islamic Republic of">
                    Iran, Islamic Republic of
                  </option>
                  <option value="Iraq">Iraq</option>
                  <option value="Ireland">Ireland</option>
                  <option value="Isle of Man">Isle of Man</option>
                  <option value="Israel">Israel</option>
                  <option value="Italy">Italy</option>
                  <option value="Jamaica">Jamaica</option>
                  <option value="Japan">Japan</option>
                  <option value="Jersey">Jersey</option>
                  <option value="Jordan">Jordan</option>
                  <option value="Kazakhstan">Kazakhstan</option>
                  <option value="Kenya">Kenya</option>
                  <option value="Kiribati">Kiribati</option>
                  <option value="Korea, Democratic People's Republic of">
                    Korea, Democratic People's Republic of
                  </option>
                  <option value="Korea, Republic of">Korea, Republic of</option>
                  <option value="Kuwait">Kuwait</option>
                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                  <option value="Lao People's Democratic Republic">
                    Lao People's Democratic Republic
                  </option>
                  <option value="Latvia">Latvia</option>
                  <option value="Lebanon">Lebanon</option>
                  <option value="Lesotho">Lesotho</option>
                  <option value="Liberia">Liberia</option>
                  <option value="Libyan Arab Jamahiriya">
                    Libyan Arab Jamahiriya
                  </option>
                  <option value="Liechtenstein">Liechtenstein</option>
                  <option value="Lithuania">Lithuania</option>
                  <option value="Luxembourg">Luxembourg</option>
                  <option value="Macao">Macao</option>
                  <option value="Macedonia, The Former Yugoslav Republic of">
                    Macedonia, The Former Yugoslav Republic of
                  </option>
                  <option value="Madagascar">Madagascar</option>
                  <option value="Malawi">Malawi</option>
                  <option value="Malaysia">Malaysia</option>
                  <option value="Maldives">Maldives</option>
                  <option value="Mali">Mali</option>
                  <option value="Malta">Malta</option>
                  <option value="Marshall Islands">Marshall Islands</option>
                  <option value="Martinique">Martinique</option>
                  <option value="Mauritania">Mauritania</option>
                  <option value="Mauritius">Mauritius</option>
                  <option value="Mayotte">Mayotte</option>
                  <option value="Mexico">Mexico</option>
                  <option value="Micronesia, Federated States of">
                    Micronesia, Federated States of
                  </option>
                  <option value="Moldova, Republic of">
                    Moldova, Republic of
                  </option>
                  <option value="Monaco">Monaco</option>
                  <option value="Mongolia">Mongolia</option>
                  <option value="Montenegro">Montenegro</option>
                  <option value="Montserrat">Montserrat</option>
                  <option value="Morocco">Morocco</option>
                  <option value="Mozambique">Mozambique</option>
                  <option value="Myanmar">Myanmar</option>
                  <option value="Namibia">Namibia</option>
                  <option value="Nauru">Nauru</option>
                  <option value="Nepal">Nepal</option>
                  <option value="Netherlands">Netherlands</option>
                  <option value="Netherlands Antilles">
                    Netherlands Antilles
                  </option>
                  <option value="New Caledonia">New Caledonia</option>
                  <option value="New Zealand">New Zealand</option>
                  <option value="Nicaragua">Nicaragua</option>
                  <option value="Niger">Niger</option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="Niue">Niue</option>
                  <option value="Norfolk Island">Norfolk Island</option>
                  <option value="Northern Mariana Islands">
                    Northern Mariana Islands
                  </option>
                  <option value="Norway">Norway</option>
                  <option value="Oman">Oman</option>
                  <option value="Pakistan">Pakistan</option>
                  <option value="Palau">Palau</option>
                  <option value="Palestinian Territory, Occupied">
                    Palestinian Territory, Occupied
                  </option>
                  <option value="Panama">Panama</option>
                  <option value="Papua New Guinea">Papua New Guinea</option>
                  <option value="Paraguay">Paraguay</option>
                  <option value="Peru">Peru</option>
                  <option value="Philippines">Philippines</option>
                  <option value="Pitcairn">Pitcairn</option>
                  <option value="Poland">Poland</option>
                  <option value="Portugal">Portugal</option>
                  <option value="Puerto Rico">Puerto Rico</option>
                  <option value="Qatar">Qatar</option>
                  <option value="Reunion">Reunion</option>
                  <option value="Romania">Romania</option>
                  <option value="Russian Federation">Russian Federation</option>
                  <option value="Rwanda">Rwanda</option>
                  <option value="Saint Helena">Saint Helena</option>
                  <option value="Saint Kitts and Nevis">
                    Saint Kitts and Nevis
                  </option>
                  <option value="Saint Lucia">Saint Lucia</option>
                  <option value="Saint Pierre and Miquelon">
                    Saint Pierre and Miquelon
                  </option>
                  <option value="Saint Vincent and The Grenadines">
                    Saint Vincent and The Grenadines
                  </option>
                  <option value="Samoa">Samoa</option>
                  <option value="San Marino">San Marino</option>
                  <option value="Sao Tome and Principe">
                    Sao Tome and Principe
                  </option>
                  <option value="Saudi Arabia">Saudi Arabia</option>
                  <option value="Senegal">Senegal</option>
                  <option value="Serbia">Serbia</option>
                  <option value="Seychelles">Seychelles</option>
                  <option value="Sierra Leone">Sierra Leone</option>
                  <option value="Singapore">Singapore</option>
                  <option value="Slovakia">Slovakia</option>
                  <option value="Slovenia">Slovenia</option>
                  <option value="Solomon Islands">Solomon Islands</option>
                  <option value="Somalia">Somalia</option>
                  <option value="South Africa">South Africa</option>
                  <option value="South Georgia and The South Sandwich Islands">
                    South Georgia and The South Sandwich Islands
                  </option>
                  <option value="Spain">Spain</option>
                  <option value="Sri Lanka">Sri Lanka</option>
                  <option value="Sudan">Sudan</option>
                  <option value="Suriname">Suriname</option>
                  <option value="Svalbard and Jan Mayen">
                    Svalbard and Jan Mayen
                  </option>
                  <option value="Swaziland">Swaziland</option>
                  <option value="Sweden">Sweden</option>
                  <option value="Switzerland">Switzerland</option>
                  <option value="Syrian Arab Republic">
                    Syrian Arab Republic
                  </option>
                  <option value="Taiwan">Taiwan</option>
                  <option value="Tajikistan">Tajikistan</option>
                  <option value="Tanzania, United Republic of">
                    Tanzania, United Republic of
                  </option>
                  <option value="Thailand">Thailand</option>
                  <option value="Timor-leste">Timor-leste</option>
                  <option value="Togo">Togo</option>
                  <option value="Tokelau">Tokelau</option>
                  <option value="Tonga">Tonga</option>
                  <option value="Trinidad and Tobago">
                    Trinidad and Tobago
                  </option>
                  <option value="Tunisia">Tunisia</option>
                  <option value="Turkey">Turkey</option>
                  <option value="Turkmenistan">Turkmenistan</option>
                  <option value="Turks and Caicos Islands">
                    Turks and Caicos Islands
                  </option>
                  <option value="Tuvalu">Tuvalu</option>
                  <option value="Uganda">Uganda</option>
                  <option value="Ukraine">Ukraine</option>
                  <option value="United Arab Emirates">
                    United Arab Emirates
                  </option>
                  <option value="United Kingdom">United Kingdom</option>
                  <option value="United States">United States</option>
                  <option value="United States Minor Outlying Islands">
                    United States Minor Outlying Islands
                  </option>
                  <option value="Uruguay">Uruguay</option>
                  <option value="Uzbekistan">Uzbekistan</option>
                  <option value="Vanuatu">Vanuatu</option>
                  <option value="Venezuela">Venezuela</option>
                  <option value="Viet Nam">Viet Nam</option>
                  <option value="Virgin Islands, British">
                    Virgin Islands, British
                  </option>
                  <option value="Virgin Islands, U.S.">
                    Virgin Islands, U.S.
                  </option>
                  <option value="Wallis and Futuna">Wallis and Futuna</option>
                  <option value="Western Sahara">Western Sahara</option>
                  <option value="Yemen">Yemen</option>
                  <option value="Zambia">Zambia</option>
                  <option value="Zimbabwe">Zimbabwe</option>
                </select>
              </div>
              
              <div  v-if="this.new_post.farm==true && farms.length>=1" style="background:yellow;text-align: left !important" class="p-3 col-md-12 mt-3">
              
                <p class="left-aligned">Select Your Farm</p>
                <select v-model="new_post.farm_name" class="form-control">
                    <option selected value="">None</option>
                    <option  v-for="(it,i) in farms" :key="i" :value="it.name">{{it.name}}</option>
                </select>
               </div> 
              <div style="text-align: left !important" class="col-md-12 mt-3">
                <p class="left-aligned">Animal Information</p>
                <select
                  v-if="new_post.country == 'Pakistan'"
                  v-model="new_post.city"
                  class="form-control"
                >
                  <option :value="item" v-for="(item, i) in cities" :key="i">
                    {{ item }}
                  </option>
                </select>
                <b-form-input
                  v-else
                  placeholder="City"
                  v-model="new_post.city"
                ></b-form-input>
                <b-form-input
                  class="mt-2"
                  v-model="new_post.price"
                  placeholder="Price"
                  min="1"
                  type="number"
                ></b-form-input>
                <b-form-input
                  class="mt-2"
                  v-model="new_post.contactno"
                  placeholder="Contact Number"
                ></b-form-input>
                <b-input-group class="mt-2">
                  <b-form-input
                    v-model="new_post.weight"
                    placeholder="Weight"
                    min="1"
                    type="number"
                  ></b-form-input>

                  <template v-slot:append>
                    <b-dropdown :text="new_post.weight_unit" variant="success">
                      <b-dropdown-item @click="new_post.weight_unit = 'KG'"
                        >KG</b-dropdown-item
                      >
                      <b-dropdown-item @click="new_post.weight_unit = 'Mann'"
                        >Mann</b-dropdown-item
                      >
                    </b-dropdown>
                  </template>
                </b-input-group>
                <b-form-textarea
                  class="mt-2"
                  placeholder="Description..."
                  rows="3"
                  v-model="new_post.description"
                  max-rows="6"
                ></b-form-textarea>
              </div>
              <div class="col-md-12 mt-4">
                <b-button
                  v-if="isload == false"
                  class="text-center"
                  variant="success"
                  @click="createNewPost"
                  >UPLOAD</b-button
                >
                <b-spinner
                  v-else
                  variant="success"
                  label="Spinning"
                ></b-spinner>
              </div>
            </div>
          </b-card>
        </div>
        <b-modal id="dopes" @ok="croppeds">
          <cropper
            class="cropper"
            :stencil-props="{
              aspectRatio: 1 / 1,
              minAspectRatio: 1 / 1,
              maxAspectRatio: 1 / 1,
            }"
            :src="showurl"
            @change="change"
          ></cropper>
        </b-modal>
      </div>
    </b-container>
  </b-container>

  <!-- </div> -->
</template>

<script>
import S3 from "aws-s3";
import { RepositoryFactory } from "../Repository/RepositoryFactory";
// import "cropperjs/dist/cropper.css";
import "vue-advanced-cropper/dist/style.css";

const PostsRepository = RepositoryFactory.get("posts_repository");
const UserRepository = RepositoryFactory.get("user_repository");
const OrderRepository = RepositoryFactory.get("order_repository");
const FarmsRepository = RepositoryFactory.get("farms_repository");

const config = {
  bucketName: "qurbaniimages",
  region: "us-east-1",
  accessKeyId: "AKIA44SH2DQ6PVOA366C",
  secretAccessKey: "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",
  s3Url: "https://qurbaniimages.s3.us-east-1.amazonaws.com/" /* optional */,
};
const S3Client = new S3(config);
import { mapGetters } from "vuex";
import { uuid } from "vue-uuid"; // uuid object is also exported to things
import captureVideoFrame from "capture-video-frame";
import { Cropper } from "vue-advanced-cropper";
import testTopNav from "../components/testTopNav.vue";
import cities from "../js/cities";
export default {
  props: ["type","farm"],
  name: "NewPost",
  components: {
    Cropper,
    testTopNav,
  },
  data() {
    return {
      isload: false,
      video: "",
      total_files: [],
      showurl: "",
      mycanvas: "",
      cities: cities,
      datums: "",
      uuid: uuid.v1(),
      new_post: {
        imagekey: "",
        farm:false,
        farm_name:"",
        description: "",
        country: "Pakistan",
        user_id: "",
        category: "cow",
        post_type: "AUDIO",
        sale: true,
        sadqa: false,
        city: "Islamabad",
        weight: "",
        weight_unit: "KG",
        price: "",
        contactno: "",
        thumbnail: "",
        featured: "false",
      },
      outline_success: "outline-success",
      success: "success",
    };
  },
  computed: {
    ...mapGetters(["loggedUser","farms"]),
  },
  async created() {
    if (this.type != null) {
      this.new_post.post_type = this.type;
    } else {
      this.new_post.post_type = "AUDIO";
    }

    this.new_post.farm=this.farm

    // console.log(this.new_post)
    console.log(this.type)

    let { data } = await FarmsRepository.getUserFarms({
        user_id: this.loggedUser.id,
      });
      console.log(data)
    this.$store.commit("setFarms", data);
  },
  methods: {
    goBACK(){
      this.$router.back()
    },
    croppeds() {
      let self = this;
      // console.log(canvas)
      this.mycanvas.toBlob(async function (blob) {
        console.log(blob);
        var thumbnail_file = new File([blob], "temps", {
          type: blob.type,
        });
        // console.log(thumbnail_file);
        // self.interpreterUser.file = thumbnail_file;
        var opd = await self.getBase64(thumbnail_file);
        self.showurl = opd;
        let newkey = uuid.v1();
        console.log(newkey);
        self.total_files.push({
          image: thumbnail_file,
          image_name: newkey + "." + thumbnail_file.type.split("/")[1],
        });

        let tem_img_key =
          "," + newkey + "." + thumbnail_file.type.split("/")[1];
        self.new_post.imagekey = tem_img_key;
        console.log(tem_img_key);
        // self.showurl = opd;
      });
    },
    change({ coordinates, canvas }) {
      console.log(canvas);
      this.mycanvas = canvas;

      //     var thumbnail_file = new File([frame.blob], 'temps', {
      //   type: "img/"+frame.format,
      //   });
      // console.log(coordinates, canvas)
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async getUserResources() {
      if (this.loggedUser.id == 21) {
        this.fetchAdminAllSadqahOrders();
      } else {
        localStorage.removeItem("user_resources");
        var { data } = await UserRepository.get_user_resources({
          user_id: this.loggedUser.id,
        });
        this.$store.commit("setUserResources", data);
      }
      this.$router.push({ name: "AllPosts", params: { types: 1 } });
    },
    async fetchAdminAllSadqahOrders() {
      var { data } = await OrderRepository.get_admin_sadqa_orders();
      this.$store.commit("setAdminResources", data[0]);
    },
    onPickFile() {
      this.$refs.FileInput.click();
    },
    // var video = document.querySelector('video');
    // video.src = window.URL.createObjectURL(files[0]);
    // URL.createObjectURL(this.files[0])
    async onFilePicked(event) {
      let tem_img_key = "";
      const files = event.target.files;
      console.log(this.new_post.post_type);
      if (this.new_post.post_type == "VIDEO") {
        if (files[0].type.split("/")[0] == "video") {
          this.video = URL.createObjectURL(files[0]);

          files.forEach((item, i) => {
            let newkey = uuid.v1();
            this.total_files.push({
              image: item,
              image_name: newkey + "." + item.name.split(".")[1],
            });

            if (i == 0) {
              tem_img_key = "," + newkey + "." + item.name.split(".")[1];
            } else {
              tem_img_key =
                tem_img_key + "," + newkey + "." + item.name.split(".")[1];
            }
          });
          this.new_post.imagekey = tem_img_key;
        } else {
          this.$store.commit("setNotifications", {
            message: "Select a video file ",
            type: "error",
          });
        }
      }
      if (this.new_post.post_type == "AUDIO") {
        console.log("camess");
        if (files[0].type.split("/")[0] == "image") {
          files.forEach((item, i) => {
            let newkey = uuid.v1();
            this.total_files.push({
              image: item,
              image_name: newkey + "." + item.name.split(".")[1],
            });

            if (i == 0) {
              tem_img_key = "," + newkey + "." + item.name.split(".")[1];
            } else {
              tem_img_key =
                tem_img_key + "," + newkey + "." + item.name.split(".")[1];
            }
          });
          this.new_post.imagekey = tem_img_key;
          var opd = await this.getBase64(event.target.files[0]);
          this.showurl = opd;
          console.log(this.new_post.imagekey);
        } else {
          this.$store.commit("setNotifications", {
            message: "Select an image file ",
            type: "error",
          });
        }
      }
    },
    async createNewPost() {
      if(this.new_post.farm_name!=""){
        this.new_post.farm=true
      }
      if (this.new_post.category == "Sadqa") {
        this.new_post.sadqa = true;
      } else {
        this.new_post.sadqa = false;
      }
      this.isload = true;
      if (this.type == "VIDEO") {
        var frame = captureVideoFrame("my-video", "png");
        var thumbnail_file = new File([frame.blob], "temps", {
          type: "img/" + frame.format,
        });
        console.log(frame);
        var thumbnail_name = uuid.v1() + "." + frame.format;
        this.new_post.thumbnail = thumbnail_name;
        S3Client.uploadFile(thumbnail_file, thumbnail_name)
          .then((data) => {
            console.log(data);
          })
          .catch((err) => console.error(err));
      }
      // })
      if (
        this.new_post.description != "" &&
        this.new_post.price != "" &&
        this.new_post.weight != "" &&
        this.new_post.contactno != ""
      ) {
        this.total_files.forEach((item, i) => {
          console.log("saffffes");
          let self = this;
          S3Client.uploadFile(item.image, item.image_name)
            .then((data) => {
              if (this.total_files.length == i + 1) {
                self.isload = false;
                this.$store.commit("setNotifications", {
                  message: "Your post has been created",
                  type: "success",
                });
                this.getUserResources();
              }
            })
            .catch((err) => console.error(err));
        });

        this.new_post.user_id = this.loggedUser.id;
        console.log(this.new_post);
        var { data } = await PostsRepository.new_post(this.new_post);
        // this.$store.commit('setNotifications',{message:'Your post has been created',type:'success'})
        // this.total_files=[]
        this.new_post = {
          imagekey: "",
          description: "",
          user_id: "",
          category: "cow",
          farm:false,
          farm_name:"",
          country: "Pakistan",
          post_type: "AUDIO",
          sale: true,
          sadqa: false,
          city: "Islamabad",
          weight: "",
          price: "",
          sold_status: "n",
          contactno: "",
          thumbnail: "",
          featured: "false",
        };
        this.video = "";
      } else {
        this.isload = false;
        this.$store.commit("setNotifications", {
          message: "Make sure to fill in all details",
          type: "error",
        });
      }
    },
  },
};
</script>

<style>
.cropper {
  height: 400px;
  background: #ddd;
}
</style>