import Repository from "./Repository";
const all_posts_resource = "posts/all_posts";
const new_post_resource = "posts/create_post";
const delete_post_resource = "posts/delete_post_of_user";
const edit_post_resource = "posts/edit_post";
const posts_comments_resource = "posts/comments_by_posts";
const post_sale_status = "posts/update_Sale_Status";
const notification_status = "posts/notification_viewed";
const post_search_resouce = "posts/posts_search_filter";
const category_pagination_posts_resource = "posts/all_posts_category";
const recent_pagination_posts_resource = "posts/all_posts_recent";
const other_pagination_resource = "posts/all_posts_official";
const post_views_resource = "posts/post_views";
const get_post_views_resource = "posts/get_post_views";
const get_sigle_post = "posts/get_post";
const get_info_vids = "posts/get_informational_videos";

const new_likes_resource = "likes/create_like";
const delete_likes_resource = "likes/delete_like";

const new_comments_resource = "comments/create_comment/";
const delete_comments_resource = "comments/delete_comment";
const create_comment_reply_resource = "comments/create_comment_reply";
const delete_comment_reply_resource = "comments/delete_comment_reply";

export default {
  // START PAGINATION APIS
  other_pagination_posts(payload) {
    return Repository.post(`${other_pagination_resource}`, payload);
  },
  recent_pagination_posts(payload) {
    return Repository.post(`${recent_pagination_posts_resource}`, payload);
  },
  category_pagination_posts(payload) {
    return Repository.post(`${category_pagination_posts_resource}`, payload);
  },
  // Post views apis
  post_views(payload) {
    return Repository.post(`${post_views_resource}`, payload);
  },
  get_post_views(payload) {
    return Repository.post(`${get_post_views_resource}`, payload);
  },
  //End Pagination APIS
  get_infovid_posts() {
    return Repository.get(`${get_info_vids}`);
  },
  all_posts() {
    return Repository.get(`${all_posts_resource}`);
  },
  search_post(payload) {
    return Repository.post(`${post_search_resouce}`, payload);
  },
  new_post(payload) {
    return Repository.post(`${new_post_resource}`, payload);
  },
  get_sigle_post(payload) {
    return Repository.post(`${get_sigle_post}`, payload);
  },

  update_notification_status(payload) {
    return Repository.post(`${notification_status}`, payload);
  },
  update_post(payload) {
    return Repository.post(`${edit_post_resource}`, payload);
  },
  remove_post(payload) {
    return Repository.post(`${delete_post_resource}`, payload);
  },
  //Comments API
  change_sale_status(payload) {
    return Repository.post(`${post_sale_status}`, payload);
  },
  new_comments(payload) {
    return Repository.post(`${new_comments_resource}`, payload);
  },
  delete_comments(payload) {
    return Repository.post(`${delete_comments_resource}`, payload);
  },
  //Likes API
  new_likes(payload) {
    return Repository.post(`${new_likes_resource}`, payload);
  },
  remove_likes(payload) {
    return Repository.post(`${delete_likes_resource}`, payload);
  },
  //Comments Posts API
  post_comments(payload) {
    return Repository.post(`${posts_comments_resource}`, payload);
  },
  createCommentReply(payload) {
    return Repository.post(`${create_comment_reply_resource}`, payload);
  },
  deleteCommentReply(payload) {
    return Repository.post(`${delete_comment_reply_resource}`, payload);
  },
};
