<template>
  <div>
    <!-- <b-container class="pr-0 pl-0">
      <div
        style="
          border-bottom-right-radius: 1rem;
          border-bottom-left-radius: 1rem;
          min-height: 200px;
          background: linear-gradient(180deg, #000000 0%, #309f5d 100%) 0% 0%
            no-repeat padding-box;
        "
        class="p-1"
      >
        <b-row>
          <b-col md="8" style="align-self: center">
            <h2 class="text-white">Welcome to your Dashboard</h2>
          </b-col>
          <b-col md="4">
            <img
              class="float-md-left"
              style="width: 100px"
              src="img/myprofile/sheep.png"
              alt=""
            />
          </b-col>
        </b-row>
      </div>
    </b-container> -->
    <div class="row">
      <div class="col-md-12 mb-4">
        <div style="position: relative">
          <b-img src="img/dashboards.jpg" fluid></b-img>
          <!-- <h1
            style="position: absolute; top: 8rem; left: 5rem"
            class="d-none d-md-block text-white"
          >
            Welcome to Your <br />Dashboard
          </h1>
          <h5
            style="position: absolute; top: 4rem; left: 2rem"
            class="d-none d-block d-xl-none d-lg-none d-md-none text-white"
          >
            Welcome to Your <br />Dashboard
          </h5> -->
        </div>
        <b-row>
          <b-col cols="12" md="9">
            <b-container fluid>
              <h3 class="left-aligned text-success mt-2">
                <b> Choose Category</b>
              </h3>
              <div class="row">
                <div class="grow col-md-2 mt-1">
                  <router-link :to="{ name: 'AllPosts', params: { types: 2 } }">
                    <div
                      class="col-md-12 shadow"
                      style="border-radius: 1em; background: #00000029"
                    >
                      <img src="img/dashboard/cow.png" alt="" />
                      <br />
                      <span class="dashboard-cols">Cows</span>
                    </div>
                  </router-link>
                </div>
                <div class="grow col-md-2 mt-1">
                  <router-link :to="{ name: 'AllPosts', params: { types: 3 } }">
                    <div
                      class="col-md-12 shadow"
                      style="border-radius: 1em; background: #00000029"
                    >
                      <img src="img/dashboard/goat.png" alt="" />
                      <br />
                      <span class="dashboard-cols">Goat</span>
                    </div>
                  </router-link>
                </div>
                <div class="grow col-md-2 mt-1">
                  <router-link :to="{ name: 'AllPosts', params: { types: 4 } }">
                    <div
                      class="col-md-12 shadow"
                      style="border-radius: 1em; background: #00000029"
                    >
                      <img src="img/dashboard/sheep.png" alt="" />
                      <br />
                      <span class="dashboard-cols">Sheep</span>
                    </div>
                  </router-link>
                </div>
                <div class="grow col-md-6 mt-1" style="cursor: pointer">
                  <div
                    @click="$emit('return-order', 5)"
                    class="col-md-12 shadow"
                    style="border-radius: 1em; background: #00000029"
                  >
                    <img src="img/dashboard/sadqah-aqiqa.png" alt="" />
                    <br />
                    <span class="dashboard-cols">Sadqah & Aqiqah stock</span>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="grow col-md-6">
                  <router-link to="/farms">

                  <!-- @click="$emit('return-order', 14)" -->
                  <div
                    class="col-md-12 shadow"
                    style="border-radius: 1em; background: #00000029"
                  >
                    <img
                      src="img/dashboard/farm.png"
                      width="125"
                      style="margin-top: 10px"
                      alt=""
                    />
                    <br />
                    <span class="dashboard-cols">Farms</span>
                  </div>
                  </router-link>
                </div>
                <div class="grow col-md-6 mt-1">
                  <router-link :to="{ name: 'AllPosts', params: { types: 1 } }">
                    <div
                      class="col-md-12 shadow"
                      style="border-radius: 1em; background: #00000029"
                    >
                      <img src="img/dashboard/new-posts.png" alt="" />
                      <br />
                      <span class="dashboard-cols">New Arrivals</span>
                    </div>
                  </router-link>
                </div>
              </div>
            </b-container>
          </b-col>
          <b-col cols="12" md="3" style="background: #00000029">
            <!-- <div class="col-md-3 mt-2" > -->
            <h5 class="text-success mt-3">Your Order Status</h5>
            <div v-if="user_resources.length > 0">
              <div v-if="loggedUser.id == 21">
                <b-card
                  style="cursor: pointer"
                  @click="returnItem(item, i)"
                  v-for="(item, i) in admin_orders.slice(0, 2)"
                  :key="i"
                  class="shadow mt-2 mr-2 pr-0 pl-0"
                >
                  <div class="left-aligned">
                    <span style="font-size: 0.7rem" class="text-success"
                      >Category :
                      {{ item.sadqa_aqiqa != "" ? item.sadqa_aqiqa : "" }}</span
                    >
                  </div>
                  <div class="row">
                    <div class="col-md-6 pr-0 pl-0">
                      <span>
                        {{ item.total_price != "" ? item.total_price : "NA" }}
                      </span>
                    </div>
                    <div class="col-md-6 pr-0 pl-0">
                      <span class="text-success">
                        <b>{{ item.breed }}</b>
                      </span>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-md-6 pr-0 pl-0">
                      <span>
                        {{ item.updated_at.split("T")[0] }}
                      </span>
                    </div>
                    <div class="col-md-6 pr-0 pl-0">
                      <span
                        :class="
                          item.order_delivered == true
                            ? `text-success`
                            : `text-danger`
                        "
                      >
                        <b>
                          {{
                            item.order_delivered == true
                              ? `Completed`
                              : `In Process`
                          }}
                        </b>
                      </span>
                    </div>
                  </div>
                </b-card>
              </div>
              <div v-else>
                <b-card
                  style="cursor: pointer"
                  @click="returnItem(item, i)"
                  v-for="(item, i) in user_resources[3].slice(0, 2)"
                  :key="i"
                  class="shadow mt-2 mr-2 pr-0 pl-0"
                >
                  <div class="left-aligned">
                    <span style="font-size: 0.7rem" class="text-success"
                      >Category :
                      {{ item.sadqa_aqiqa != "" ? item.sadqa_aqiqa : "" }}</span
                    >
                  </div>
                  <div class="row">
                    <div class="col-md-6 pr-0 pl-0">
                      <span>
                        {{ item.total_price != "" ? item.total_price : "NA" }}
                      </span>
                    </div>
                    <div class="col-md-6 pr-0 pl-0">
                      <span class="text-success">
                        <b>{{ item.breed }}</b>
                      </span>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-md-6 pr-0 pl-0">
                      <span>
                        {{ item.updated_at.split("T")[0] }}
                      </span>
                    </div>
                    <div class="col-md-6 pr-0 pl-0">
                      <span
                        :class="
                          item.order_delivered == true
                            ? `text-success`
                            : `text-danger`
                        "
                      >
                        <b>
                          {{
                            item.order_delivered == true
                              ? `Completed`
                              : `In Process`
                          }}
                        </b>
                      </span>
                    </div>
                  </div>
                </b-card>
              </div>
            </div>
            <span v-else class="text-danger">No Orders created yet </span>
            <!-- </div> -->
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["user_resources", "loggedUser", "admin_orders"]),
  },
  methods: {
    returnItem(item, i) {
      console.log(i);
      if (item.sadqa_aqiqa == "Ijtimai") {
        let obj = { item: { ...item, no: i }, id: 12 };
        // let obj={item:item,id:12}
        this.$emit("return-order", obj);
      } else {
        // let obj={item:item,id:7}
        let obj = { item: { ...item, no: i }, id: 7 };

        this.$emit("return-order", obj);
      }
    },
  },
};
</script>

<style>
.dashboard-cols {
  text-decoration: none;
  color: black;
}
</style>