<template>
  <div>
    <div class="row mr-0 mb-5">
      <div
        class="col-md-3"
        style="max-height: 600px; overflow-y: auto; overflow-x: hidden"
      >
        <div class="mb-4 text-left">My Chat List</div>
        <!-- @click="chanel_key=item.chanelName;objectName=item.objectName;selecteduserid=item.userId;selectedpostimg=item.postImageKey" -->
        <div v-if="chatUser.chat_groups != null">
          <div
            style="cursor: pointer"
            @click="loadingMessages(item.key)"
            v-for="(item, i) in sorted_groups"
            :key="i"
            class="mt-1 d-md-flex text-left chat-list p-3"
          >
            <img src="img/dashboard/man.png" width="35" alt="" />
            <span style="align-self: center" class="ml-2"
              >{{ item.name }}
            </span>
            <span
              v-if="onlineStatus(item.receiver_id) == true"
              class="mt-auto mb-auto fas fa-circle ml-auto text-success"
            >
              online</span
            >
            <!-- <p>{{ chatdate(chatUser.chat_groups[item].id) }}</p> -->
          </div>
        </div>
      </div>
      <div class="col-md-9">
        <div class="chat-list p-3">
          <div class="text-left">
            <img src="img/dashboard/man.png" width="35" alt="" />
            <span style="align-self: center"> {{ objectName }}</span>
          </div>
        </div>
        <div class="chat-list p-4">
          <div
            id="conts"
            style="
              height: 400px;
              max-height: 400px;
              overflow-y: auto;
              overflow-x: hidden;
            "
          >
            <div
              class="outgoing-msg d-md-flex mt-4"
              v-for="(item, i) in viewable_messages"
              :key="i"
            >
              <img src="img/dashboard/man.png" style="height: 43px" alt="" />
              <div
                class="text-left p-2 ml-2 chat-list"
                :style="
                  item.text.sender != loggedUser.id
                    ? `background-color:#00000029;`
                    : ``
                "
              >
                <div v-if="item.text.type == 'text'">
                  <div>
                    <img
                      v-if="item.text.image_key != ''"
                      :src="
                        `https://qurbaniimages.s3.amazonaws.com/` +
                        replacer(item.text.image_key)
                      "
                      width="440"
                      alt=""
                    />
                  </div>
                  <span style="font-size: 13px"> {{ item.text.message }}</span>
                </div>
                <div v-else>
                  <audio controls height="20">
                    <source :src="item.text.message" />
                    Your browser does not support the audio element.
                  </audio>
                </div>
              </div>
            </div>
            <div v-if="!(viewable_messages.length > 0)">No messages here</div>
          </div>
          <div v-if="chanel_key != ''" class="p-3" style="position: relative">
            <img
              v-if="isLoad == false"
              @click="lc_sendMessage()"
              src="img/send.png"
              width="22"
              alt=""
              style="position: absolute; right: 2rem; top: 1.95rem"
            />
            <b-spinner
              v-if="isLoad == true"
              variant="success"
              class="spins"
              label="Spinning"
            ></b-spinner>

            <VueRecordAudio
              v-if="isLoad == false"
              class="micro_phones"
              mode="press"
              @result="onResult"
            />
            <input
              @keyup.enter="lc_sendMessage()"
              v-model="message"
              style="background: #f5f5f5; padding-right: 6rem"
              type="text"
              placeholder="Say Something.."
              class="pt-4 pb-4 form-control"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PostCard from "../components/PostCard.vue";
import NotificationItem from "../components/NotificationItem.vue";
import globalfuns from "../mixins/globalfuns";
import UserRepository from "../Repository/UserRepository";
import unixTime from "unix-time";
import { uuid } from "vue-uuid"; // uuid object is also exported to things
import firebase from "firebase";
import moment from "moment";
export default {
  name: "CMyMessages",
  mixins: [globalfuns],
  components: {
    NotificationItem,
  },
  data() {
    return {
      isLoad: false,
      selectedpostimg: "",
      audio: "",
      moment: moment(),
      isAudio: false,
      chanel_key: "",
      audiomsg: "",
      message: "",
      objectName: "",
      selecteduserid: "",
    };
  },
  watch: {
    messages: {
      handler(value) {
        this.opd;
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["messages", "loggedUser", "chatUser", "allUsers"]),
    sorted_groups() {
      let demoChat = {};
      let arrs = Object.keys(this.chatUser.chat_groups).map((index) => {
        return {
          ...this.chatUser.chat_groups[index],
          key: index,
          date: this.chatdate(this.chatUser.chat_groups[index].id).date,
        };
      });
      //   console.log(arrs);
      arrs.sort(function (a, b) {
        var dateA = new Date(a.date),
          dateB = new Date(b.date);
        return dateA - dateB; //sort by date ascending
      });
      // console.log(arrs);
      //   arrs.sort((a, b) => {
      //     new Date(b.date) - new Date(a.date);
      //   });

      return arrs.reverse();

      //   return Object.keys(this.chatUser.chat_groups).sort(
      //     (a, b) =>
      //       new Date(this.chatUser.chat_groups[a].last_date) -
      //       new Date(this.chatUser.chat_groups[b].last_date)
      //   );
    },
    viewable_messages() {
      return this.opd.filter((item) => item.id == this.chanel_key);
    },

    opd() {
      let temp = [];
      this.messages.forEach((item) => {
        Object.keys(item.result.messages).forEach((per) => {
          var dateString = "";
          //   console.log(dateString);
          //   console.log("cs");
          let str = JSON.stringify(item.result.messages[per].timestamp);
          let len = str.length;
          if (len > 10) {
            let arr = str.match(/.{1,10}/g);
            let compiled = arr.join().split(",")[0];
            // console.log(compiled);
            dateString = moment.unix(compiled).format("DD-MM-YYYY");
          } else {
            dateString = moment
              .unix(item.result.messages[per].timestamp)
              .format("DD-MM-YYYY");
          }
          //   console.log(dateString);
          //   console.log(
          //     JSON.stringify(item.result.messages[per].timestamp).length
          //   );

          temp.push({
            text: item.result.messages[per],
            id: item.chanel_key,
            timestamp: dateString, //item.result.messages[per].timestamp,
            chanel_key: item.chanel_key,
          });
        });
      });
      return temp;
    },
  },
  mounted() {
    console.log(this.opd);
  },
  created() {
    console.log(this.chatUser);
    this.$store.dispatch("fetchOnlineStatus", this.chatUser);
  },
  methods: {
    chatdate(chanel) {
      let arrs = this.opd.filter(
        (messages_item) => messages_item.chanel_key == chanel
      );
      //   console.log(arrs);
      if (arrs.length > 0) {
        let dt = arrs[arrs.length - 1].timestamp;
        return { date: dt };
      } else {
        return { date: "NA" };
      }
    },
    onlineStatus(id) {
      let obj = this.allUsers.find((item) => item.id == id);
      if (obj != null && obj.isOnline != null) {
        if (obj.isOnline == true) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    onResult(data) {
      this.isLoad = true;
      this.audio = uuid.v1();
      var file = new File([data], this.audio, {
        type: "audio/mp3",
      });
      this.audiocomment(file);
    },
    async audiocomment(file) {
      let ext = file.type.split("/")[1];
      this.isAudio = true;
      let thisrefs = this;
      // var filename = file.name
      // let  ext2 = filename.slice(filename.lastIndexOf('.'))
      firebase
        .storage()
        .ref("audioMessages/" + file.name + "." + ext.toLowerCase())
        .put(file)
        .then((filedata) => {
          filedata.ref.getDownloadURL().then((URL) => {
            thisrefs.audiomsg = URL;
            thisrefs.lc_sendMessage();
          });
        });
    },
    loadingMessages(item) {
      this.selecteduserid = this.chatUser.chat_groups[item].receiver_id;
      this.chanel_key = this.chatUser.chat_groups[item].id;
      this.objectName = this.chatUser.chat_groups[item].name;
      var container = this.$el.querySelector("#conts");
      this.$nextTick(() => {
        container.scrollTop = container.scrollHeight;
      });
    },
    async lc_sendMessage() {
      this.isLoad = true;

      if (this.message != "" || this.audiomsg != "") {
        let isNew = true;
        let sendername = this.loggedUser.nickname;
        this.new_chat_obj.post_id = "";
        this.new_chat_obj.sender = this.loggedUser.id;
        this.new_chat_obj.timestamp = unixTime(new Date());
        this.new_chat_obj.image_key = "";
        this.new_chat_obj.message =
          this.audiomsg != "" ? this.audiomsg : this.message;
        this.new_chat_obj.type = this.audiomsg != "" ? "audio" : "text";

        this.message = "";
        this.audiomsg = "";
        this.isAudio = false;

        this.new_user_obj = {
          last_date: this.getTodaysDate(),
          last_time: this.getCurrentTime(),
        };

        let { data } = await UserRepository.getuser({
          user_id: this.selecteduserid,
        });
        let receivername = data[0].nickname;
        let checks = Object.keys(this.chatUser.chat_groups).find(
          (item) => this.chatUser.chat_groups[item].receiver_id == data[0].id
        );
        let chatgroup_id;
        if (checks != null) {
          chatgroup_id = this.chatUser.chat_groups[checks].id;
        }
        isNew = checks != null ? false : true;

        let contacts = {
          sender_name: sendername,
          receiver_name: receivername,
          sender_id: this.loggedUser.id,
          receiver_id: data[0].id,
        };

        this.msg_notif_obj.sender_id = this.loggedUser.id;
        this.msg_notif_obj.post_owner_id = this.selecteduserid;
        this.msg_notif_obj.channel = this.loggedUser.id + "_" + data[0].id;
        this.msg_notif_obj.post_image = "";

        let resps = await UserRepository.sendmsgnotification(
          this.msg_notif_obj
        );
        this.$store.dispatch("initialMessage", {
          chatgroup_id: chatgroup_id,
          isNew: isNew,
          contacts: contacts,
          new_user_obj: this.new_user_obj,
          new_chat_obj: this.new_chat_obj,
        });
        var container = this.$el.querySelector("#conts");
        this.$nextTick(() => {
          container.scrollTop = container.scrollHeight;
        });
        this.isLoad = false;
      } else {
        this.$store.commit("setNotifications", {
          message: "Write something...",
          type: "error",
        });
      }
    },
  },
};
</script>

<style scoped>
.spins {
  position: absolute !important;
  right: 4rem !important;
  top: 1.3rem !important;
}
.micro_phones {
  position: absolute !important;
  width: 30px !important;
  height: 31px !important;
  right: 4rem !important;
  top: 1.3rem !important;
  background-color: #27a844 !important;
}
.chat-list {
  box-shadow: 0px 3px 6px #00000029;
  background: white;
  border: 1px solid #dcdcdc;
  /* padding: 2rem; */
  border-radius: 0.5rem;
}
</style>