<template>
  <b-container
    fluid
    class="main-landing-back pr-0 pl-0"
    style="min-height: 500px; position: relative"
    @click="
      search_toggle.city = false;
      search_toggle.username = false;
    "
  >
    <!-- <testTopNav /> -->
    <b-card>
      <div class="row">
        <div class="col-md-3">
          <b-card>
            <b-tabs pills content-class="mt-3">
              <b-tab class="post-tab" title="Type" active>
                <b-card style="box-shadow: 0px 3px 6px #00000029" class="mb-2">
                  <template v-slot:header>
                    <h4 class="pt-3"><b>Browse Categories</b></h4>
                  </template>
                  <b-list-group style="cursor: pointer">
                    <!-- <b-list-group-item
                    @click="type = 0"
                    href="#"
                    :class="type == 0 ? active_class : 'deps'"
                  >
                    <div class="d-inline">
                      <img
                        class="float-left"
                        :src="
                          type == 0
                            ? 'img/posts_page/featured-white.png'
                            : 'img/posts_page/feature.png'
                        "
                        width="20"
                        height="20"
                        alt=""
                      />
                      <span
                        class="float-left"
                        style="text-decoration: none; margin-left: 0.45rem"
                        :class="type == 0 ? 'text-white' : 'text-dark'"
                        >Featured</span
                      >
                    </div>
                  </b-list-group-item> -->
                    <!-- <b-list-group-item
                    :class="type == 1 ? active_class : 'opds'"
                    @click="type = 1"
                  >
                    <div class="d-inline">
                      <img
                        class="float-left"
                        :src="
                          type == 1
                            ? 'img/posts_page/new-aals-white.png'
                            : 'img/posts_page/new-aals.png'
                        "
                        width="20"
                        height="20"
                        alt=""
                      />
                      <span
                        style="text-decoration: none; margin-left: 0.45rem"
                        :class="[type == 1 ? 'text-white' : '', 'float-left']"
                      >
                        New Arrivals</span
                      >
                    </div>
                  </b-list-group-item> -->
                    <b-list-group-item
                      :class="type == 2 ? active_class : 'opds'"
                      @click="type = 2"
                    >
                      <div class="d-inline">
                        <img
                          class="float-left"
                          :src="
                            type == 2
                              ? 'img/posts_page/cow-white.png'
                              : 'img/posts_page/cow_ico_web.png'
                          "
                          width="20"
                          height="20"
                          alt=""
                        />
                        <span
                          style="text-decoration: none; margin-left: 0.45rem"
                          :class="[type == 2 ? 'text-white' : '', 'float-left']"
                          >Cow</span
                        >
                      </div>
                    </b-list-group-item>
                    <b-list-group-item
                      :class="type == 3 ? active_class : 'opds'"
                      @click="type = 3"
                    >
                      <div class="d-inline">
                        <img
                          class="float-left"
                          :src="
                            type == 3
                              ? 'img/posts_page/goat-white.png'
                              : 'img/posts_page/goat_io_web.png'
                          "
                          width="20"
                          height="20"
                          alt=""
                        />
                        <span
                          style="text-decoration: none; margin-left: 0.45rem"
                          :class="[type == 3 ? 'text-white' : '', 'float-left']"
                          >Goats</span
                        >
                      </div>
                    </b-list-group-item>
                    <b-list-group-item
                      :class="type == 4 ? active_class : 'opds'"
                      @click="type = 4"
                    >
                      <div class="d-inline">
                        <img
                          class="float-left"
                          :src="
                            type == 4
                              ? 'img/posts_page/sheep-white.png'
                              : 'img/posts_page/sheep_ico_web.png'
                          "
                          width="20"
                          height="20"
                          alt=""
                        />
                        <span
                          :class="[type == 4 ? 'text-white' : '', 'float-left']"
                          style="text-decoration: none; margin-left: 0.45rem"
                          >Sheep</span
                        >
                      </div>
                    </b-list-group-item>
                    <!-- <b-list-group-item
                        :class="type == 5 ? active_class : 'opds'"
                        @click="type = 5"
                      >
                        <div class="d-inline">
                          <img
                            class="float-left"
                            :src="
                              type == 5
                                ? 'img/posts_page/store-white.png'
                                : 'img/posts_page/home-black.png'
                            "
                            width="20"
                            height="20"
                            alt=""
                          />
                          <span
                            class="ml-2"
                            :class="[
                              type == 5 ? 'text-white' : '',
                              'float-left',
                            ]"
                            style="text-decoration: none; margin-left: 0.45rem"
                            >Our Stock</span
                          >
                        </div>
                      </b-list-group-item> -->
                    <!-- <b-list-group-item
                    :class="[type == 6 ? active_class : 'opds', 'pr-1 ']"
                    @click="type = 6"
                  >
                    <div class="d-inline">
                      <img
                        class="float-left"
                        :src="
                          type == 6
                            ? 'img/posts_page/certified_white.png'
                            : 'img/posts_page/certified_black.png'
                        "
                        width="20"
                        height="20"
                        alt=""
                      />
                      <span
                        :class="[type == 6 ? 'text-white' : '', 'float-left']"
                        style="text-decoration: none; margin-left: 0.45rem"
                        >Sadqah Stock</span
                      >
                    </div>
                  </b-list-group-item> -->
                  </b-list-group>
                </b-card>
              </b-tab>
            </b-tabs>
          </b-card>

          <b-card>
            <b-tabs pills content-class="mt-3">
              <b-tab class="post-tab" title="Search">
                <b-card style="box-shadow: 0px 3px 6px #00000029">
                  <h4>Search</h4>
                  <!-- <div>
                  <b-button
                    @click="search_obj.sold_status = 's'"
                    :variant="
                      search_obj.sold_status == 's' ? 'success' : 'white'
                    "
                    :style="
                      search_obj.sold_status == 's'
                        ? 'border:1px solid #dcdcdc;border-bottom-left-radius: 1rem;border-top-left-radius: 1rem;'
                        : 'border:1px solid #dcdcdc;border-bottom-left-radius: 1rem;border-top-left-radius: 1rem;'
                    "
                    >Sold</b-button
                  >
                  <b-button
                    @click="search_obj.sold_status = 'n'"
                    :variant="
                      search_obj.sold_status == 'n' ? 'success' : 'white'
                    "
                    :style="
                      search_obj.sold_status == 'n'
                        ? 'border:1px solid #dcdcdc;border-bottom-right-radius: 1rem;border-top-right-radius: 1rem;'
                        : 'border:1px solid #dcdcdc;border-bottom-right-radius: 1rem;border-top-right-radius: 1rem;'
                    "
                    >Unsold</b-button
                  >
                </div> -->
                  <div class="mt-3">
                    <h6 class="text-left">Description</h6>
                    <b-input
                      class="rounded-0"
                      v-model="search_obj.search"
                      style="background-clip: unset !important"
                      placeholder="Search"
                      @focus="search_toggle.city = false"
                    >
                    </b-input>
                    <div
                      v-if="search_toggle.desc == true"
                      style="
                        min-height: 100px;
                        max-height: 120px;
                        overflow-y: auto;
                      "
                      class="p-2 bg-white"
                    >
                      <h6
                        v-for="(item, i) in descriptions"
                        :key="i"
                        @click="descriptionResult(item)"
                        class="text-left cursor-pointer"
                      >
                        {{
                          item.split(" ").length > 3
                            ? `${item.split(" ")[0]} ${item.split(" ")[1]}...`
                            : item
                        }}
                      </h6>
                    </div>
                    <!-- <b-input-group> -->
                    <!-- <template v-slot:prepend>
                        <b-button
                          style="
                            border-left: 1px solid #dcdcdc;
                            border-top: 1px solid #dcdcdc;
                            border-bottom: 1px solid #dcdcdc;
                          "
                          variant="white"
                          ><i class="fa fa-text-width" aria-hidden="true"></i>
                        </b-button>
                      </template>
                      <input
                        v-model="search_obj.search"
                        class="form-control"
                        style="border-left: none !important"
                        placeholder="Description"
                      />
                    </b-input-group> -->
                  </div>

                  <div class="mt-3">
                    <h6 class="text-left">City</h6>
                    <b-input
                      class="rounded-0"
                      style="background-clip: unset !important"
                      placeholder="City"
                      v-model="search_obj.city"
                      @focus="search_toggle.desc = false"
                    >
                    </b-input>
                    <div
                      v-if="search_toggle.city == true"
                      style="
                        min-height: 100px;
                        max-height: 120px;
                        overflow-y: auto;
                      "
                      class="p-2 bg-white"
                    >
                      <h6
                        v-for="(item, i) in cities"
                        :key="i"
                        @click="cityResult(item)"
                        class="text-left cursor-pointer"
                      >
                        {{
                          item.split(" ").length > 3
                            ? `${item.split(" ")[0]} ${item.split(" ")[1]}...`
                            : item
                        }}
                      </h6>
                    </div>

                    <!-- <b-input-group>
                      <template v-slot:prepend>
                        <b-button
                          style="
                            border-left: 1px solid #dcdcdc;
                            border-top: 1px solid #dcdcdc;
                            border-bottom: 1px solid #dcdcdc;
                          "
                          variant="white"
                          ><span class="fa fa-map-marker"></span
                        ></b-button>
                      </template>
                      <input
                        v-model="search_obj.city"
                        class="form-control"
                        style="border-left: none !important"
                        placeholder="Location"
                      /> -->
                    <!-- </b-input-group> -->
                  </div>
                  <div class="mt-3">
                    <h6 class="text-left">By Weight</h6>
                    <div
                      @click="search_toggle.weight = !search_toggle.weight"
                      style="background-clip: unset !important"
                      class="text-center rounded-0 form-control"
                    >
                      <span>{{
                        loweight != "" && highweight != ""
                          ? `${loweight}-${highweight} kg`
                          : "Weight"
                      }}</span>
                    </div>
                    <div
                      v-if="search_toggle.weight == true"
                      style="
                        max-height: 120px;
                        overflow-x: hidden;
                        overflow-y: auto;
                      "
                      class="p-2 bg-white"
                    >
                      <b-row>
                        <b-col md="6">
                          <b-input
                            v-model="loweight"
                            type="number"
                            placeholder="min"
                            max="900"
                          ></b-input>
                        </b-col>
                        <b-col md="6">
                          <b-input
                            v-model="highweight"
                            type="number"
                            placeholder="max"
                            max="900"
                          ></b-input>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col v-if="loweight == ''">
                          <p
                            v-for="(item, i) in [
                              0, 40, 160, 300, 500, 700, 1000, 1300,
                            ]"
                            :key="i"
                            @click="loweight = item"
                            class="fs-14 mb-0 text-left search-drop mt-1"
                          >
                            {{ item }}
                          </p>
                        </b-col>
                        <b-col v-else>
                          <p
                            @click="
                              highweight = item;
                              search_toggle.weight = false;
                            "
                            v-for="(item, i) in [
                              0, 40, 160, 300, 500, 700, 1000, 1300,
                            ].filter((its) => its > loweight)"
                            :key="i"
                            class="fs-14 mb-0 text-right search-drop mt-1"
                          >
                            {{ item }}
                          </p>
                        </b-col>
                      </b-row>
                    </div>

                    <!-- <b-input-group>
                      <template v-slot:prepend>
                        <b-button
                          style="
                            border-left: 1px solid #dcdcdc;
                            border-top: 1px solid #dcdcdc;
                            border-bottom: 1px solid #dcdcdc;
                          "
                          variant="white"
                          ><span class="fa fa-shopping-bag"></span
                        ></b-button>
                      </template>
                      <select
                        class="form-control"
                        v-model="search_obj.weight"
                        name=""
                        id=""
                      >
                        <option value="no" selected disabled>
                          Select weight
                        </option>
                        <option value="0-40">0-40 kg</option>
                        <option value="40-80">40-80 kg</option>
                        <option value="80-120">80-120 kg</option>
                        <option value="120-160">120-160 kg</option>
                        <option value="160-200">160-200 kg</option>
                      </select>
                    </b-input-group> -->
                  </div>

                  <div class="mt-3">
                    <h6 class="text-left">By Date</h6>
                    <a-range-picker v-model="tempdate" @change="changeDate" />
                  </div>
                  <div class="mt-3" @mouseleave="search_toggle.city = false">
                    <h6 class="text-left">By User</h6>

                    <b-input
                      class="rounded-0"
                      v-model="search_obj.username"
                      style="background-clip: unset !important"
                      placeholder="User"
                      @focus="search_toggle.city = false"
                    >
                    </b-input>
                    <div
                      v-if="search_toggle.username == true"
                      style="
                        min-height: 100px;
                        max-height: 120px;
                        overflow-y: auto;
                      "
                      class="p-2 bg-white"
                    >
                      <h6
                        v-for="(item, i) in users"
                        :key="i"
                        @click="usernameResult(item)"
                        class="text-left cursor-pointer"
                      >
                        {{
                          item.split(" ").length > 2
                            ? `${item.split(" ")[0]} ${item.split(" ")[1]}...`
                            : item
                        }}
                      </h6>
                    </div>
                  </div>
                  <div class="mt-3">
                    <h6 class="text-left">By Price</h6>
                    <div
                      @click="search_toggle.price = !search_toggle.price"
                      style="background-clip: unset !important"
                      class="text-center rounded-0 form-control pr-0 pl-0"
                    >
                      <span>{{
                        lowprice != "" && highprice != ""
                          ? `${lowprice}-${
                              highprice == 10000000 ? "1000000+" : highprice
                            }
                        Pkr`
                          : "Price"
                      }}</span>
                    </div>
                    <div
                      v-if="search_toggle.price == true"
                      style="
                        max-height: 120px;
                        overflow-x: hidden;
                        overflow-y: auto;
                      "
                      class="pt-2 pb-2 bg-white"
                    >
                      <b-row>
                        <b-col md="6">
                          <b-input
                            v-model="lowprice"
                            type="number"
                            placeholder="min"
                          ></b-input>
                        </b-col>
                        <b-col md="6">
                          <b-input
                            v-model="highprice"
                            type="number"
                            placeholder="max "
                          ></b-input>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col v-if="lowprice == ''">
                          <p
                            v-for="(item, i) in [
                              20000, 60000, 100000, 200000, 300000, 1000000,
                            ]"
                            :key="i"
                            @click="lowprice = item"
                            class="fs-14 mb-0 text-left search-drop mt-1"
                          >
                            {{ item }}
                          </p>
                        </b-col>
                        <b-col v-else>
                          <p
                            @click="
                              highprice = item;
                              search_toggle.price = false;
                            "
                            v-for="(item, i) in [
                              20000, 60000, 100000, 200000, 300000, 1000000,
                              10000000,
                            ].filter((its) => its > lowprice)"
                            :key="i"
                            class="fs-14 mb-0 text-right search-drop mt-1"
                          >
                            {{ item == 10000000 ? "1000000+" : item }}
                          </p>
                        </b-col>
                      </b-row>
                    </div>
                    <!-- <b-input-group>
                      <template v-slot:prepend>
                        <b-button
                          style="
                            border-left: 1px solid #dcdcdc;
                            border-top: 1px solid #dcdcdc;
                            border-bottom: 1px solid #dcdcdc;
                          "
                          variant="white"
                        >
                          <i class="fa fa-money" aria-hidden="true"></i>
                        </b-button>
                      </template>
                      <select
                        v-model="search_obj.price"
                        style="background-clip: unset !important"
                        class="rounded-0 form-control"
                      >
                        <option value="no" selected disabled>
                          Select price
                        </option>
                        <option value="20000 40000">20,000-40,000</option>
                        <option value="60000-80000">60,000-80,000</option>
                        <option value="100000-150000">100,000-150,000</option>
                        <option value="200000-250000">200,000 250,000</option>
                        <option value=" 300000-500000">300,000 500,000</option>
                        <option value="1000000-9000000">
                          1,000,000 and above
                        </option>
                      </select> -->
                    <!-- </b-input-group> -->
                    <!-- <b-input-group class="mt-2">
                      <template v-slot:prepend>
                        <b-button
                          style="
                            border-left: 1px solid #dcdcdc;
                            border-top: 1px solid #dcdcdc;
                            border-bottom: 1px solid #dcdcdc;
                          "
                          variant="white"
                        ></b-button>
                      </template>
                    </b-input-group> -->
                  </div>
                  <div class="mt-3">
                    <b-button
                      variant="success"
                      class="pr-5 pl-5"
                      @click="secondarySearchResults(1)"
                      >Apply</b-button
                    >
                  </div>
                </b-card>
              </b-tab>
            </b-tabs>
          </b-card>
        </div>
        <div class="col-md-9">
          <b-card>
            <h5 v-if="data == -1" class="text-success">No search results</h5>
            <h3 v-else>Search Results</h3>
            <div class="row">
              <div
                class="col-md-4"
                v-for="(post_item, i) in viewable_posts"
                :key="i"
              >
                <PostCard
                  :landing="`sadqahs`"
                  :post_item="post_item"
                  @toggle-item="setPost"
                />
              </div>
            </div>
            <div class="text-center">
              <a-pagination
                class="pages"
                :current="current"
                @change="onChange"
                :total="rows"
              />
            </div>
          </b-card>
        </div>
      </div>
    </b-card>

    <b-modal
      hide-footer
      id="modal-lg"
      ref="modal-lg"
      size="lg"
      :title="selected_post.category"
    >
      <DialogPost
        :selected_post="selected_post"
        @chat-show="$emit('chat-show', selected_post)"
      />
    </b-modal>
  </b-container>
</template>
<script>
import DialogPost from "../components/DialogPost";
import testTopNav from "../components/testTopNav.vue";
import PostCard from "../components/PostCard.vue";

import { RepositoryFactory } from "../Repository/RepositoryFactory";
const SearchRepository = RepositoryFactory.get("search_repository");

export default {
  components: {
    DialogPost,
    testTopNav,
    PostCard,
  },
  props: ["data", "search_params"],

  created() {
    if (this.data == null) {
      this.$router.push("/");
    }
    this.search_obj = {
      search:
        this.search_params.search_field == "no"
          ? ""
          : this.search_params.search_field,
      city: this.search_params.city == "no" ? "" : this.search_params.city,
      weight: this.search_params.weight,
      price: this.search_params.price,
      start_date: this.search_params.start_date,
      end_date: this.search_params.end_date,
      username: this.search_params.username,
    };
    this.tempdate = this.search_params.tempdate;
    this.local_search_params = this.search_params;
    this.viewable_posts = this.data.posts;
    let prices = this.search_params.price.split("-");
    let weights = this.search_params.weight.split("-");

    if (prices != "no") {
      this.lowprice = prices[0];
      this.highprice = prices[1];
    }
    if (weights != "no") {
      this.loweight = weights[0];
      this.highweight = weights[1];
    }
    this.search_toggle = {
      price: false,
      weight: false,
      city: false,
      desc: false,
      username: false,
    };
  },
  computed: {
    search() {
      return this.search_obj.search;
    },
    username() {
      return this.search_obj.username;
    },
    search_city() {
      return this.search_obj.city;
    },
    active_class() {
      return "active";
    },
    rows() {
      if (this.data.total_posts > 0) {
        let lngth_posts = this.data.total_posts; //this.posts[2].length
        let temp_perpage = 12;
        let dividedresult = lngth_posts / temp_perpage;
        let parsed = parseInt(dividedresult);
        if (parsed * temp_perpage < lngth_posts) {
          parsed++;
          let sringer = parsed.toString() + "0";
          let parsedback = parseInt(sringer);
          return parsedback;
        } else {
          let sringer = parsed.toString() + "0";
          let parsedback = parseInt(sringer);
          return parsedback;
        }
      }
    },
  },
  watch: {
    username(value) {
      console.log(value);
      if (value.length > 2) {
        this.usernameResults();
      } else {
        this.search_toggle.username = false;
      }
      // or generate/simulate a native events (not sure how, but its outside Vue's realm I think
    },
    type(val) {
      if (val > 0) {
        this.getNewSearchResults(1);
      }
    },
    search(value) {
      if (value.length > 2) {
        this.searchResults();
      } else {
        this.search_toggle.desc = false;
      }
      // or generate/simulate a native events (not sure how, but its outside Vue's realm I think
    },
    search_city(value) {
      if (value.length > 3) {
        this.searchCity();
      } else {
        this.search_toggle.city = false;
      }
      // or generate/simulate a native events (not sure how, but its outside Vue's realm I think
    },
  },
  data() {
    return {
      descriptions: [],
      users: [],
      cities: [],
      current: 1,
      tempdate: "",
      highprice: "",
      lowprice: "",
      highweight: "",
      loweight: "",
      local_search_params: {},
      viewable_posts: [],
      selected_post: {},
      search_toggle: {
        price: false,
        weight: false,
        city: false,
        desc: false,
        username: false,
      },
      search_results: [],
      search_obj: {
        price: "2000-100000",
        weight: "no",
        city: "",
        search: "",
        start_date: "",
        end_date: "",
        username: "",
      },
      order: "",

      selected_post: "",
      type: -1,
    };
  },
  methods: {
    usernameResult(item) {
      this.search_obj.username =
        item.split(" ").length > 3
          ? `${item.split(" ")[0]} ${item.split(" ")[1]}...`
          : item;
      console.log(this.search);
      this.local_search_params.username = this.search_obj.username;
      this.search_toggle.username = false;
    },
    async usernameResults() {
      console.log(this.search);
      let { data } = await SearchRepository.search_user({
        search_field: this.search_obj.username,
      });
      console.log(data);
      this.users = data;
      this.search_toggle.username = true;
    },
    changeDate(date, dateString) {
      this.local_search_params.start_date = dateString[0];
      this.local_search_params.end_date = dateString[1];
    },
    cityResult(item) {
      this.search_obj.city =
        item.split(" ").length > 3
          ? `${item.split(" ")[0]} ${item.split(" ")[1]}...`
          : item;
      this.search_toggle.city = false;
    },
    descriptionResult(item) {
      this.search_obj.search =
        item.split(" ").length > 3
          ? `${item.split(" ")[0]} ${item.split(" ")[1]}...`
          : item;
      this.search_toggle.desc = false;
    },
    async secondarySearchResults(page) {
      // this.local_search_params = this.search_params;

      this.local_search_params = {
        ...this.search_obj,
        ...this.local_search_params,
        user_name:
          this.search_obj.username == "" ? "no" : this.search_obj.username,
        city: this.search_city == "" ? "no" : this.search_city,
        price:
          this.lowprice != "" && this.highprice != ""
            ? this.lowprice + "-" + this.highprice
            : "no",
        weight:
          this.loweight != "" && this.highweight != ""
            ? this.loweight + "-" + this.highweight
            : "no",
        search_field:
          this.search_obj.search == "" ? "no" : this.search_obj.search,
      };
      this.getNewSearchResults(page);
    },
    async searchCity() {
      let { data } = await SearchRepository.search_city({
        city: this.search_city,
      });
      this.cities = data;
      this.search_toggle.city = true;
    },
    async searchResults() {
      let { data } = await SearchRepository.search_description({
        search_field: this.search,
      });
      this.descriptions = data;
      this.search_toggle.desc = true;
    },
    async getNewSearchResults(page) {
      // this.postLoad = true;
      if (this.type < 0) {
        let { data } = await SearchRepository.search_results({
          ...this.local_search_params,
          page: page,
        });
        this.viewable_posts = data.posts;
      } else {
        let tip = "";
        if (this.type == 2) {
          tip = "cow";
        } else if (this.type == 3) {
          tip = "goat";
        }
        if (this.type == 4) {
          tip = "sheep";
        }
        let { data } = await SearchRepository.search_results({
          ...this.local_search_params,
          page: page,
          category: tip,
        });
        this.viewable_posts = data.posts;
      }
      // this.postLoad = false;
      window.scrollTo(50, 50);
    },

    setPost(post) {
      this.selected_post = post;
      this.$bvModal.show("modal-lg");
    },
    onChange(current, pkd) {
      this.current = current;
      this.getNewSearchResults(current);
    },
  },
};
</script>
<style>
.btn-primary {
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}
</style>
<style scoped>
button:focus {
  outline: none !important;
  box-shadow: none !important;
  border-left: 1px solid #dcdcdc !important;
  border-top: 1px solid #dcdcdc !important;
  border-bottom: 1px solid #dcdcdc !important;
}
select {
  border-left: none !important;
}
input:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #dcdcdc !important;
}
.form-control:focus {
  /* border-color: green !important; */
  outline: none !important;
  border: 1px solid #dcdcdc !important;
  box-shadow: none !important;
}
.list-group-item:first-child {
  /* border-top-left-radius: 0.25rem; */
  border-top: none;
  border-left: none !important;
  border-right: none !important;
}
.list-group-item:first-child:hover {
  /* border-top-left-radius: 0.25rem; */
  text-decoration: none !important;
  border-top: none;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
}
.active {
  background-color: #28a745 !important;
  color: black !important;
  /* border-top:1px solid #0A0101 !important; */
  border-left: none;
  border-right: none;
  /* border-bottom: none !important */
}

.opds {
  /* border-top:1px solid #0A0101 !important; */
  border-left: none;
  border-right: none;
  /* border-bottom: none !important */
}
.card-header {
  background-color: white !important;
}
</style>
<style>
.nav-item .nav-link.active {
  background-color: #28a745 !important;
}
.nav-item .nav-link.active:hover {
  background-color: #28a745 !important;
  color: white !important;
}
</style>