import Repository from "./Repository";
const all_posts_resource = "/posts/posts_search_by_city";
const post_description = "/posts/posts_search_by_description";
const post_searchbar = "/posts/posts_search_bar";
const post_username = "/posts/posts_search_by_user_name";

export default {
  // START PAGINATION APIS
  search_city(payload) {
    return Repository.post(`${all_posts_resource}`, payload);
  },
  search_description(payload) {
    return Repository.post(`${post_description}`, payload);
  },
  search_results(payload) {
    return Repository.post(`${post_searchbar}`, payload);
  },

  search_user(payload) {
    return Repository.post(`${post_username}`, payload);
  },
};
