var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',[_c('b-container',{staticStyle:{"width":"60%"}},[_c('b-card',[_c('b-row',[_c('b-col',[_c('h3',{staticClass:"text-center"},[_vm._v("Check your Details Here")]),_c('h3',[_vm._v("Order Id "+_vm._s(_vm.temp_order.id))])])],1),_c('b-row',[_c('b-col',{staticClass:"text-left text-muted"},[_vm._v(" NAME:"+_vm._s(_vm.temp_order.user_name)+" ")]),_c('b-col',{staticClass:"text-right text-muted"},[_vm._v(" CNIC : "+_vm._s(_vm.temp_order.user_cnic)+" ")])],1),_c('b-row',{staticClass:"mt-3"},[_c('b-col',{staticClass:"text-left text-muted"},[_vm._v(" PHONE NUMBER :"+_vm._s(_vm.temp_order.user_phone)+" ")]),_c('b-col',{staticClass:"text-right text-muted"},[_vm._v(" ADDRESS : "+_vm._s(_vm.temp_order.user_address)+" ")])],1),_c('b-row',{staticClass:"mt-3"},[_c('b-col',{staticClass:"text-left text-muted"},[_vm._v(" CITY : "+_vm._s(_vm.temp_order.user_city)+" ")]),_c('b-col',{staticClass:"text-right text-muted"},[_vm._v(" COUNTRY: "+_vm._s(_vm.temp_order.user_country)+" ")])],1),_c('b-row',{staticClass:"mt-3"},[_c('b-col',{staticClass:"text-left text-muted"},[_vm._v(" CATEGORY PRICE : "+_vm._s(_vm.temp_order.order_currency != null ? _vm.temp_order.order_currency : "PKR")+" "+_vm._s(_vm.temp_order.total_price)+" ")]),_c('b-col',{staticClass:"text-right text-muted"},[_vm._v(" STATUS : "),_c('span',{class:_vm.temp_order.order_delivered == false
                  ? "text-danger"
                  : "text-success",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.temp_order.order_delivered == false ? "IN PROCESS" : "COMPLETED")+" ")])])],1),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-md-12"},[_c('span',{staticClass:"bolds",staticStyle:{"font-size":"12px"}},[_vm._v("Live Updates")])])]),(_vm.loggedUser.id == 21)?_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-md-12"},[_c('h6',{staticClass:"bolds"},[_vm._v("SEND UPDATES")])])]):_vm._e(),(_vm.loggedUser.id == 21)?_c('div',{staticClass:"row mt-2"},[_c('div',{class:[
              _vm.temp_order.order_delivered == false ||
              _vm.temp_order.order_delivered == null
                ? 'col-md-3 pr-0 pl-0'
                : 'col-md-4',
              _vm.isMobile == false ? 'right-aligned' : '' ]},[_c('input',{ref:"FileInput",staticStyle:{"display":"none"},attrs:{"type":"file"},on:{"change":_vm.onFilePicked}}),(_vm.isUpload == false)?_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){_vm.visible = true}}},[_vm._v("Send Video ")]):_c('b-spinner',{attrs:{"variant":"success","label":"Spinning"}})],1),_c('div',{class:[
              _vm.temp_order.order_delivered == false ||
              _vm.temp_order.order_delivered == null
                ? 'col-md-3'
                : 'col-md-4',
              _vm.isMobile == false ? '' : 'mt-2' ]},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.order-message",modifiers:{"order-message":true}}],attrs:{"variant":"success"}},[_vm._v("Send Text")])],1),(
              _vm.temp_order.order_delivered == false ||
              _vm.temp_order.order_delivered == null
            )?_c('div',{class:[
              _vm.temp_order.order_delivered == false ||
              _vm.temp_order.order_delivered == null
                ? 'col-md-3'
                : 'col-md-4',
              _vm.isMobile == false ? 'left-aligned' : 'mt-2' ]},[_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){return _vm.completeOrder()}}},[_vm._v("Complete")])],1):_vm._e(),_c('div',{class:[
              _vm.temp_order.order_delivered == false ||
              _vm.temp_order.order_delivered == null
                ? 'col-md-3 pr-0 pl-0'
                : 'col-md-4',
              _vm.isMobile == false ? 'left-aligned' : 'mt-2' ]},[_c('input',{ref:"ImageFileInput",staticStyle:{"display":"none"},attrs:{"type":"file"},on:{"change":_vm.onImageFilePicked}}),(_vm.isImageUpload == false)?_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){_vm.imagevisible = true}}},[_vm._v("Send Image")]):_c('b-spinner',{attrs:{"variant":"success","label":"Spinning"}})],1)]):_vm._e(),_c('b-container',[_c('div',_vm._l((_vm.temp_order.order_video.split(',')),function(item,i){return _c('div',{key:i},[_c('div',{staticClass:"row mt-4"},[(item != '')?_c('div',{staticClass:"col-md-12"},[_c('video',{staticStyle:{"width":"233px !important","height":"178px !important"},attrs:{"controls":""}},[_c('source',{attrs:{"src":'https://s3.amazonaws.com/qurbaniimages/' +
                        _vm.replacer(item)}}),_vm._v(" Your browser does not support the video tag. ")])]):_vm._e()]),(item != '')?_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-md-12"},[_c('span',{staticClass:"bolds"},[_vm._v(_vm._s(_vm.temp_order.order_video_message.split(",")[i]))])])]):_vm._e()])}),0),_c('div',_vm._l((_vm.temp_order.order_image.split(',')),function(item,i){return _c('div',{key:i + 20},[_c('div',{staticClass:"row mt-4"},[(item != '')?_c('div',{staticClass:"col-md-12"},[_c('b-img',{staticStyle:{"width":"233px !important","height":"178px !important"},attrs:{"src":'https://s3.amazonaws.com/qurbaniimages/' +
                      _vm.replacer(item)}})],1):_vm._e()]),(item != '')?_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-md-12"},[_c('span',{staticClass:"bolds"},[_vm._v(_vm._s(_vm.temp_order.order_image_message.split(",")[i]))])])]):_vm._e()])}),0),_c('div',_vm._l((_vm.temp_order.order_message.split(',')),function(item,i){return _c('div',{key:i,staticClass:"row mt-2"},[(item != '')?_c('div',{staticClass:"p-3 col-md-12 left-aligned",staticStyle:{"background":"#f6f4f4"}},[_c('span',{staticClass:"bolds"},[_vm._v(_vm._s(item))])]):_vm._e()])}),0)])],1)],1),_c('a-modal',{attrs:{"title":"Image Message"},on:{"ok":_vm.onImagePickFile},model:{value:(_vm.imagevisible),callback:function ($$v) {_vm.imagevisible=$$v},expression:"imagevisible"}},[_c('b-form-input',{attrs:{"placeholder":"Enter message"},model:{value:(_vm.edit_order_obj.order_image_message),callback:function ($$v) {_vm.$set(_vm.edit_order_obj, "order_image_message", $$v)},expression:"edit_order_obj.order_image_message"}})],1),_c('a-modal',{attrs:{"title":"Video Message"},on:{"ok":_vm.onPickFile},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('b-form-input',{attrs:{"placeholder":"Enter message"},model:{value:(_vm.edit_order_obj.order_video_message),callback:function ($$v) {_vm.$set(_vm.edit_order_obj, "order_video_message", $$v)},expression:"edit_order_obj.order_video_message"}})],1),_c('b-modal',{attrs:{"id":"order-message","ok-title":"Save","title":"Order Message"},on:{"ok":_vm.saveOrderMessage}},[_c('b-form-input',{attrs:{"placeholder":"Enter message"},model:{value:(_vm.edit_order_obj.order_message),callback:function ($$v) {_vm.$set(_vm.edit_order_obj, "order_message", $$v)},expression:"edit_order_obj.order_message"}})],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',{staticClass:"left-aligned"},[_c('b',[_vm._v(" Get live Updates about order processing ")])])}]

export { render, staticRenderFns }