<template>
  <div>
    <div class="row d-none d-flex d-xl-none d-lg-none d-md-none">
      <div class="col-md-12 d-none d-block d-xl-none d-lg-none d-md-none">
        <div class="page-container md-layout-column">
          <md-toolbar class="md-primary">
            <md-button class="md-icon-button" @click="showNavigation = true">
              <md-icon>menu</md-icon>
            </md-button>
          </md-toolbar>

          <md-drawer :md-active.sync="showNavigation" md-swipeable>
            <md-toolbar class="md-transparent" md-elevation="0">
              <div class="p-4">
                <div class="row">
                  <div class="col-md-12">
                    <img
                      v-if="loggedUser.isFb == true"
                      align="left"
                      class="ml-1"
                      width="50"
                      style="height: 55px"
                      :src="loggedUser.image"
                      alt=""
                    />

                    <img
                      v-if="loggedUser.isFb == false || loggedUser.isFb == null"
                      align="left"
                      class="ml-1"
                      width="50"
                      style="height: 55px"
                      :src="
                        loggedUser.image != ''
                          ? `https://qurbaniimages.s3.amazonaws.com/${replacer(
                              loggedUser.image
                            )}`
                          : 'img/dashboard/avatar.png'
                      "
                      alt=""
                    />
                  </div>
                  <div class="col-md-12 mt-2">
                    <span
                      class="left-aligned ml-1 text-white"
                      style="font-size: 12px; display: block"
                      >{{ loggedUser.nickname }}</span
                    >
                  </div>
                  <div class="col-md-12">
                    <span
                      class="left-aligned ml-1 text-white text-mute"
                      style="font-size: 8px; display: block"
                      >{{
                        loggedUser.email != null
                          ? loggedUser.email
                          : loggedUser.phoneno
                      }}
                    </span>
                  </div>
                </div>
              </div>
              <!-- <span class="md-title">My App name</span> -->
            </md-toolbar>

            <md-list>
              <md-list-item
                v-if="loggedUser.isFb == false"
                @click="active = 10"
              >
                <md-icon
                  ><img src="img/dashboard/user.png" height="20" alt=""
                /></md-icon>
                <span class="md-list-item-text text-white">Profile</span>
              </md-list-item>
              <md-list-item>
                <md-icon
                  ><img src="img/dashboard/home.png" height="20" alt=""
                /></md-icon>
                <span class="md-list-item-text text-white">Home</span>
              </md-list-item>
              <md-list-item @click="redirect(self, '/')">
                <md-icon
                  ><img
                    src="img/dashboard/dashboard_side.png"
                    height="20"
                    alt=""
                /></md-icon>
                <span class="md-list-item-text text-white">Dashboard</span>
              </md-list-item>
              <md-list-item
                @click="
                  active = 2;
                  showNavigation = false;
                "
              >
                <md-icon
                  ><img src="img/dashboard/post-it.png" height="20" alt=""
                /></md-icon>
                <span class="md-list-item-text text-white">My Ads</span>
              </md-list-item>
              <md-list-item
                @click="
                  active = 14;
                  showNavigation = false;
                "
              >
                <md-icon
                  ><img src="img/dashboard/ijtmae.png" height="20" alt=""
                /></md-icon>
                <span class="md-list-item-text text-white">My Farms</span>
              </md-list-item>

              <!-- <md-list-item >
                      <md-icon><img src="img/dashboard/langs.png" height="20" alt=""></md-icon>
                      <span class="md-list-item-text text-white">Change Language</span>
                    </md-list-item> -->

              <md-list-item
                @click="
                  active = 4;
                  showNavigation = false;
                "
              >
                <md-icon
                  ><img src="img/dashboard/choices.png" height="20" alt=""
                /></md-icon>
                <span class="md-list-item-text text-white">My Order</span>
              </md-list-item>

              <md-list-item
                @click="
                  active = 5;
                  showNavigation = false;
                "
              >
                <md-icon
                  ><img src="img/dashboard/side-goat.png" height="20" alt=""
                /></md-icon>
                <span class="md-list-item-text text-white">Sadqah Stock</span>
              </md-list-item>
              <!-- <md-list-item
                @click="
                  active = 11;
                  showNavigation = false;
                "
              >
                <md-icon
                  ><img src="img/dashboard/ijtmae.png" height="20" alt=""
                /></md-icon>
                <span class="md-list-item-text text-white">Ijtmae Qurbani</span>
              </md-list-item> -->
              <md-list-item @click="showModal()">
                <md-icon
                  ><img
                    src="img/dashboard/dslr-camera-white.png"
                    height="20"
                    alt=""
                /></md-icon>
                <span class="md-list-item-text text-white">Post Ad</span>
              </md-list-item>
              <md-list-item @click="showModal()">
                <md-icon
                  ><img
                    src="img/dashboard/dslr-camera-white.png"
                    height="20"
                    alt=""
                /></md-icon>
                <span class="md-list-item-text text-white">Register Farm</span>
              </md-list-item>
              <md-list-item @click="active = 13" v-if="loggedUser.id == 21">
                <md-icon
                  ><img
                    src="img/dashboard/dslr-camera-white.png"
                    height="20"
                    alt=""
                /></md-icon>
                <span class="md-list-item-text text-white">Discount</span>
              </md-list-item>

              <!-- <md-list-item
                @click="
                  notificationCall();
                  showNavigation = false;
                "
              >
                <md-icon
                  ><img src="img/dashboard/bell.png" height="20" alt=""
                /></md-icon> -->
              <!-- <md-badge v-else-if="chatUser.notifications.unviewed!=0 && chatUser.notifications!=null && hide==false" class="md-error" :md-content="chatUser.notifications.unviewed" /> -->
              <!-- </md-list-item> -->
              <!-- <md-list-item @click="active = 9">
                <md-icon
                  ><img
                    src="img/dashboard/message.png"
                    @click="
                      active = 9;
                      showNavigation = false;
                    "
                    height="20"
                    alt=""
                /></md-icon>
                <span class="md-list-item-text text-white">Messages</span>
              </md-list-item> -->
              <!-- <md-list-item @click="logout">
                <md-icon
                  ><img src="img/dashboard/logout.png" height="20" alt=""
                /></md-icon>
                <span class="md-list-item-text text-white">Logout</span>
              </md-list-item> -->
            </md-list>
          </md-drawer>
          <md-content>
            <CDashboard v-if="active == 1" @return-order="returnOrder" />
            <CMyPosts
              :selected_post="selected_post"
              class="mt-5"
              v-if="active == 2"
            />
            <CMyOrders
              :order="order"
              class="mt-5"
              v-if="active == 4"
              @return-order="returnOrder"
            />
            <CSadqahStock
              class="mt-5"
              v-if="active == 5"
              @order-item="orderItem"
            />
            <CSadqahOrder
              :order="order"
              class="mt-5"
              v-if="active == 6"
              @return-order="returnOrder"
            />
            <CSadqahOrderDetails
              :order="order"
              v-if="active == 7"
              class="mt-5"
            />
            <CMyNotifications
              v-if="active == 8"
              class="mt-5"
              @return-order="returnOrder"
            />
            <CMyMessages v-if="active == 9" class="mt-5" />
            <CMyProfile v-if="active == 10" class="mt-5" />
            <IjtmaeQurbaniOrder
              v-if="active == 11"
              @return-order="returnOrder"
            />
            <CIJtmaeOrderDetail
              :order="order"
              v-if="active == 12"
              class="mt-5"
              @return-order="returnOrder"
            />
            <CDIscounts v-if="active == 13" />
            <Farms
              @edit-farm="selectedFarm"
              @order-item="forwardFarm"
              v-if="active == 14"
              :mine="true"
            />
            <FarmDetails v-if="active == 15" :selectedFarm="editFarm" />
          </md-content>
        </div>
      </div>
    </div>
    <div class="row d-none d-md-flex">
      <div class="col-md-2">
        <div class="sidebar">
          <div class="pl-4 pr-4 pt-2 pb-2">
            <div class="row">
              <div class="col-md-12">
                <img
                  v-if="loggedUser.isFb == true"
                  align="left"
                  class="ml-1"
                  width="50"
                  style="height: 55px"
                  :src="loggedUser.image"
                  alt=""
                />

                <img
                  v-if="loggedUser.isFb == false || loggedUser.isFb == null"
                  align="left"
                  class="ml-1"
                  width="50"
                  style="height: 55px"
                  :src="
                    loggedUser.image != ''
                      ? `https://qurbaniimages.s3.amazonaws.com/${replacer(
                          loggedUser.image
                        )}`
                      : 'img/dashboard/avatar.png'
                  "
                  alt=""
                />
              </div>
              <div class="col-md-12 mt-2">
                <span
                  class="left-aligned ml-1 text-white"
                  style="font-size: 12px; display: block"
                  >{{ loggedUser.nickname }}</span
                >
              </div>
              <div class="col-md-12">
                <span
                  class="left-aligned ml-1 text-white text-mute"
                  style="font-size: 8px; display: block"
                  >{{
                    loggedUser.email != null
                      ? loggedUser.email
                      : loggedUser.phoneno
                  }}
                </span>
              </div>
            </div>
          </div>
          <div
            v-if="loggedUser.isFb == null || loggedUser.isFb == false"
            style="display: flex"
            class="side-items"
            @click="active = 10"
          >
            <img
              src="img/dashboard/user.png"
              class="mt-3 ml-3"
              style="height: 23px !important"
              alt=""
            />
            <a class="ml-1 pl-0 pr-0" href="JavaScript:void(0)">
              <span>Profile </span>
            </a>
          </div>

          <div
            style="display: flex"
            class="side-items"
            @click="redirect(self, '/')"
          >
            <img
              src="img/dashboard/home.png"
              class="mt-3 ml-3"
              style="height: 23px !important"
              alt=""
            />
            <a class="ml-1 pl-0 pr-0" href="JavaScript:void(0)">
              <span>Home </span>
            </a>
          </div>
          <div style="display: flex" class="side-items" @click="active = 1">
            <img
              src="img/dashboard/dashboard_side.png"
              class="mt-3 ml-3"
              style="height: 23px !important"
              alt=""
            />
            <a class="ml-1 pl-0 pr-0" href="JavaScript:void(0)">
              <span>Dashboard </span>
            </a>
          </div>
          <div style="display: flex" class="side-items" @click="active = 2">
            <img
              src="img/dashboard/post-it.png"
              class="mt-3 ml-3"
              style="height: 23px !important"
              alt=""
            />
            <a class="ml-1 pl-0 pr-0" href="JavaScript:void(0)">
              <span>My Ads </span>
            </a>
          </div>
          <div style="display: flex" class="side-items" @click="active = 14">
            <img
              src="img/dashboard/ijtmae.png"
              class="mt-3 ml-3"
              style="height: 23px !important"
              alt=""
            />
            <a class="ml-1 pl-0 pr-0" href="JavaScript:void(0)">
              <span>My Farms </span>
            </a>
          </div>
          <div style="display: flex" @click="sendFarm" class="side-items">
            <img
              src="img/dashboard/ijtmae.png"
              class="mt-3 ml-3"
              style="height: 23px !important"
              alt=""
            />
            <a class="ml-1 pl-0 pr-0" href="JavaScript:void(0)">
              <span>Add New Farm</span>
            </a>
          </div>
          <!-- <div style="display:flex;" class="side-items">
                      <img src="img/dashboard/langs.png" class="mt-3 ml-3" style="height:23px !important;" alt="">
                      <a class="ml-1 pl-0 pr-0" href="JavaScript:void(0)"> <span>Change Language </span> </a>
                  </div> -->
          <div style="display: flex" class="side-items" @click="active = 4">
            <img
              src="img/dashboard/choices.png"
              class="mt-3 ml-3"
              style="height: 23px !important"
              alt=""
            />
            <a class="ml-1 pl-0 pr-0" href="JavaScript:void(0)">
              <span>My Orders </span>
            </a>
          </div>
          <div style="display: flex" class="side-items" @click="active = 5">
            <img
              src="img/dashboard/side-goat.png"
              class="mt-3 ml-3"
              style="height: 23px !important"
              alt=""
            />
            <a class="ml-1 pl-0 pr-0">
              <span>Sadqah Stock </span>
            </a>
          </div>
          <!-- <div style="display:flex" class="side-items"  @click="$router.push('/ijtmae-qurbani')" >
            <img
              src="img/dashboard/ijtmae.png"
              class="mt-3 ml-3"
              style="height: 23px !important"
              alt=""
            />
            <a class="ml-1 pl-0 pr-0">
              <span>Ijtmae Qurbani </span>
            </a>
          </div> -->
          <div style="display: flex" @click="showModal()" class="side-items">
            <img
              src="img/dashboard/dslr-camera-white.png"
              class="mt-3 ml-3"
              style="height: 23px !important"
              alt=""
            />
            <a class="ml-1 pl-0 pr-0" href="JavaScript:void(0)">
              <span>Post Ad </span>
              <b-badge variant="warning">Sell NOW</b-badge>
            </a>
          </div>

          <div
            style="display: flex"
            @click="$router.push('forums')"
            class="side-items"
          >
            <img
              src="img/dashboard/discfor.png"
              class="mt-3 ml-3"
              style="height: 23px !important"
              alt=""
            />
            <a class="ml-1 pl-0 pr-0" href="JavaScript:void(0)">
              <span>Discussion Forum</span>
            </a>
          </div>
          <div
            style="display: flex"
            @click="$router.push('informational-videos')"
            class="side-items"
          >
            <img
              src="img/dashboard/info-vid.png"
              class="mt-3 ml-3"
              style="height: 23px !important"
              alt=""
            />
            <a class="ml-1 pl-0 pr-0" href="JavaScript:void(0)">
              <span>Informational Videos</span>
            </a>
          </div>
          <div
            style="display: flex"
            @click="$router.push('videos')"
            class="side-items"
          >
            <img
              src="img/dashboard/feat-video.png"
              class="mt-3 ml-3"
              style="height: 23px !important"
              alt=""
            />
            <a class="ml-1 pl-0 pr-0" href="JavaScript:void(0)">
              <span>Featured Videos</span>
            </a>
          </div>
          <div
            style="display: flex"
            @click="$router.push('all_posts')"
            class="side-items"
          >
            <img
              src="img/dashboard/livestock.png"
              class="mt-3 ml-3"
              style="height: 23px !important"
              alt=""
            />
            <a class="ml-1 pl-0 pr-0" href="JavaScript:void(0)">
              <span>Livestock</span>
            </a>
          </div>
          <div
            style="display: flex"
            v-if="loggedUser.id == 21"
            @click="active = 13"
            class="side-items"
          >
            <img
              src="img/dashboard/dslr-camera-white.png"
              class="mt-3 ml-3"
              style="height: 23px !important"
              alt=""
            />
            <a class="ml-1 pl-0 pr-0"> <span>Discount </span> </a>
          </div>
          <!-- <div
            style="display: flex; position: relative"
            @click="notificationCall"
            class="side-items"
          >
            <img
              src="img/dashboard/bell.png"
              class="mt-3 ml-3"
              style="height: 21px !important"
              alt=""
            />
            <a class="ml-1 pl-0 pr-0" href="JavaScript:void(0)">
              <span
                >Notifications
                <span v-if="chatUser.notifications != null && hide == false">
                  <b-badge
                    v-if="chatUser.notifications.unviewed != 0"
                    style="position: absolute; top: 0px; left: 1rem"
                    pill
                    variant="danger"
                    >{{ this.chatUser.notifications.unviewed }}
                  </b-badge>
                </span>
              </span>
            </a>
          </div> -->
          <!-- <div style="display: flex" @click="active = 9" class="side-items">
            <img
              src="img/dashboard/message.png"
              class="mt-3 ml-3"
              style="height: 21px !important"
              alt=""
            />
            <a class="ml-1 pl-0 pr-0" href="JavaScript:void(0)"> <span>Messages </span> </a>
          </div> -->
          <!-- <div style="display: flex" class="side-items" @click="logout">
            <img
              src="img/dashboard/logout.png"
              class="mt-3 ml-3"
              style="height: 23px !important"
              alt=""
            />
            <a class="ml-1 pl-0 pr-0" href="JavaScript:void(0)"> <span>Logout </span> </a>
          </div> -->
        </div>
      </div>
      <div class="col-md-10 pl-0 pr-0">
        <b-navbar toggleable="lg" type="light" variant="dark">
          <b-collapse id="nav-collapse" is-nav>
            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
              <b-nav-item
                style="position: relative"
                @click="
                  notificationCall();
                  showNavigation = false;
                "
              >
                <img src="img/dashboard/bell.png" width="25" alt="" />
                <span v-if="chatUser.notifications != null && hide == false">
                  <md-badge
                    style="top: 0"
                    v-if="chatUser.notifications.unviewed != 0"
                    class="md-error"
                    :md-content="chatUser.notifications.unviewed"
                  />
                </span>
              </b-nav-item>
              <b-nav-item
                @click="
                  active = 9;
                  showNavigation = false;
                "
                ><img src="img/dashboard/message.png" width="20" alt=""
              /></b-nav-item>
              <b-nav-item @click="logout"
                ><img src="img/dashboard/logout.png" width="20" alt=""
              /></b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>

        <CDashboard v-if="active == 1" @return-order="returnOrder" />
        <CMyPosts :selected_post="selected_post" v-if="active == 2" />
        <CMyOrders
          :order="order"
          v-if="active == 4"
          @return-order="returnOrder"
        />
        <CSadqahStock v-if="active == 5" @order-item="orderItem" />
        <CSadqahOrder
          :order="order"
          class="mt-5"
          v-if="active == 6"
          @return-order="returnOrder"
        />
        <CSadqahOrderDetails
          :order="order"
          v-if="active == 7"
          class="mt-5"
          @return-order="returnOrder"
        />
        <CMyNotifications
          v-if="active == 8"
          class="mt-5"
          @return-order="returnOrder"
        />
        <CMyMessages v-if="active == 9" class="mt-5" />
        <CMyProfile v-if="active == 10" />
        <IjtmaeQurbaniOrder v-if="active == 11" @return-order="returnOrder" />
        <CIJtmaeOrderDetail
          :order="order"
          v-if="active == 12"
          class="mt-5"
          @return-order="returnOrder"
        />
        <CDIscounts v-if="active == 13" />
        <Farms
          @edit-farm="selectedFarm"
          @order-item="forwardFarm"
          v-if="active == 14"
          :mine="true"
        />
        <FarmDetails v-if="active == 15" :selectedFarm="editFarm" />
      </div>
    </div>
    <b-modal id="sell-dialog" hide-footer="" centered>
      <template>
        <h5>Edit your Farm</h5>
      </template>
      <div>
        <form @submit.prevent="updateFarm">
          <!-- {{ editFarm }} -->
          <b-avatar
            variant="light"
            style="box-shadow: 0px 3px 6px #00000029"
            class="p-2"
            @click="onPickFiles"
            size="6rem"
          >
            <!-- <b-icon
              @click="onPickFiles"
              v-if="showurl == ''"
              font-scale="3"
              icon="camera-fill"
              variant="success"
            ></b-icon> -->
            <b-img
              @click="onPickFiles"
              :src="
                showurl == ''
                  ? 'https://s3.amazonaws.com/qurbaniimages/' +
                    trimValue(replacer(editFarm.image))
                  : showurl
              "
            ></b-img>
          </b-avatar>
          <input
            type="file"
            ref="FileInput"
            style="display: none"
            @change="onFilePicked"
          />
          <b-input
            style="background: #f7f7f8"
            v-model="editFarm.name"
            required
            class="mt-2"
            placeholder="Enter farm name"
          ></b-input>
          <b-input
            style="background: #f7f7f8"
            class="mt-2"
            v-model="editFarm.city"
            required
            placeholder="Enter City"
          ></b-input>
          <b-textarea
            style="background: #f7f7f8"
            class="mt-2"
            required
            v-model="editFarm.address"
            placeholder="Farm Address"
          ></b-textarea>
          <b-button
            v-if="isload == false"
            type="submit"
            class="mt-2 float-right"
            variant="success"
            >Save</b-button
          >
          <b-spinner
            v-else
            variant="success"
            class="mt-2 float-right"
          ></b-spinner>
        </form>
      </div>
    </b-modal>

    <b-modal id="bell-dialog" hide-footer="" centered>
      <template v-slot:modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h5>Upload your Ad</h5>
        <b-button size="sm" variant="outline-success" @click="close()">
          Cancel
        </b-button>
      </template>
      <div
        style="display: flex; cursor: pointer"
        @click="routeit('AUDIO', false)"
      >
        <img src="img/sellmodal/dslr-camera.png" width="60" alt="" />
        <h5 class="mt-3 ml-3">Upload Image</h5>
      </div>
      <div
        style="display: flex; cursor: pointer"
        @click="routeit('VIDEO', false)"
        class="mb-4"
      >
        <img src="img/sellmodal/video-camera.png" width="60" alt="" />
        <h5 class="mt-3 ml-3">Upload Video</h5>
      </div>
      <div
        style="display: flex; cursor: pointer"
        @click="routeit('AUDIO', true)"
      >
        <img src="img/dashboard/cow.png" width="60" alt="" />
        <h5 class="mt-3 ml-3">Upload Animal to your farm</h5>
      </div>
    </b-modal>
  </div>
</template>
<script>
import CDashboard from "../components/CDashboard.vue";
import CMyPosts from "../components/CMyPosts.vue";
import CSadqahStock from "../components/CSadqahStock.vue";
import CSadqahOrder from "../components/CSadqahOrder.vue";
import CMyOrders from "../components/CMyOrders.vue";
import CMyNotifications from "../components/CMyNotifications.vue";
import CDIscounts from "../components/CDiscounts.vue";
import CSadqahOrderDetails from "../components/CSadqahOrderDetails.vue";
import CMyMessages from "../components/CMyMessages.vue";
import CMyProfile from "../components/CMyProfile.vue";
import IjtmaeQurbaniOrder from "../components/IjtmaeQurbaniOrder.vue";
import CIJtmaeOrderDetail from "../components/CIJtmaeOrderDetail.vue";
import Farms from "../components/Farms.vue";
import FarmDetails from "../components/FarmDetails.vue";

import facebookLogin from "facebook-login-vuejs";
import { mapGetters } from "vuex";
import globalfuns from "../mixins/globalfuns";
import { RepositoryFactory } from "../Repository/RepositoryFactory";
const OrderRepository = RepositoryFactory.get("order_repository");
const FarmsRepository = RepositoryFactory.get("farms_repository");
import { uuid } from "vue-uuid"; // uuid object is also exported to things

import S3 from "aws-s3";
const config = {
  bucketName: "qurbaniimages",
  region: "us-east-1",
  accessKeyId: "AKIA44SH2DQ6PVOA366C",
  secretAccessKey: "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",
  s3Url: "https://qurbaniimages.s3.us-east-1.amazonaws.com/" /* optional */,
  /* optional */
};
const S3Client = new S3(config);
export default {
  name: "Dashboard",
  mixins: [globalfuns],
  components: {
    CDashboard,
    CMyPosts,
    CSadqahStock,
    Farms,
    FarmDetails,
    CDIscounts,
    CSadqahOrder,
    CMyOrders,
    CSadqahOrderDetails,
    facebookLogin,
    CMyNotifications,
    CMyMessages,
    CMyProfile,
    IjtmaeQurbaniOrder,
    CIJtmaeOrderDetail,
  },
  props: ["active_prop"],
  computed: {
    ...mapGetters(["user_notifications", "chatUser", "loggedUser", "FB"]),
  },
  watch: {
    loggedUser() {
      // Login for image to render on image upload

      this.$forceUpdate();
    },
  },

  data() {
    return {
      editFarm: {},
      uuid: uuid.v1(),
      file: "",
      showurl: "",
      isload: false,
      selected_post: null,
      active: 1,
      hide: false,
      order: "",
      showNavigation: false,
      self: "",
    };
  },
  created() {
    if (this.active_prop != null) {
      this.active = this.active_prop;
    }
    this.self = this;
  },
  methods: {
    sendFarm() {
      this.$router.push({ name: "testFarms", params: { fromdashboard: true } });
    },
    onPickFiles() {
      this.$refs.FileInput.click();
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async onFilePicked(event) {
      this.file = event.target.files[0];
      var opd = await this.getBase64(event.target.files[0]);
      this.showurl = opd;
    },
    selectedFarm(post) {
      this.$bvModal.show("sell-dialog");
      this.editFarm = JSON.parse(JSON.stringify(post));
    },
    forwardFarm(post) {
      this.active = 15;
      this.editFarm = JSON.parse(JSON.stringify(post));
    },
    async updateFarm() {
      this.isload = true;
      if (this.showurl != "") {
        let newkey = uuid.v1();
        // let tem_img_key = newkey + "." + item.name.split(".")[1];

        let self = this;
        S3Client.uploadFile(this.file, newkey)
          .then(async (da) => {
            console.log(da);
            console.log(newkey);
            console.log(self.editFarm);
            // let obj={
            //   ...self.editFarm                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              ,
            //   image: da.key
            // }
            let { data } = await FarmsRepository.editFarm({
              ...self.editFarm,
              image: "," + da.key,
              farm_id: self.editFarm.id,
            });
            console.log(data);
            self.isload = false;
            self.$bvModal.hide("sell-dialog");
            self.getMyFarms();
          })
          .catch((err) => console.error(err));
      } else {
        let { data } = await FarmsRepository.editFarm({
          ...this.editFarm,
          farm_id: this.editFarm.id,
        });
        this.getMyFarms();
        this.$bvModal.hide("sell-dialog");
        this.isload = false;
      }
    },
    async getMyFarms() {
      let { data } = await FarmsRepository.getUserFarms({
        user_id: this.loggedUser.id,
      });
      this.$store.commit("setFarms", data);

      window.scrollTo(50, 50);
    },
    notificationCall() {
      this.active = 8;
      this.hide = true;
      // this.$store.dispatch("updateNotifications",{id:this.loggedUser.id,viewed:this.user_notifications.length,unviewed:0})
    },
    orderItem(item) {
      this.active = 6;
      this.order = item;
    },
    returnOrder(arg) {
      if (arg.type != null) {
        if (arg.type == "liked") {
          this.active = arg.value;
          this.selected_post = arg.obj;
        }
      } else {
        if (arg.item != null) {
          this.active = arg.id;
          this.order = arg.item;
        } else {
          this.active = arg;
        }
      }
    },
    logout() {
      this.$store.dispatch("updateOnlineStatus", {
        id: this.loggedUser.id,
        status: false,
      });

      this.$store.commit("logout", null);
      // if(this.loggedUser.isFb==true){
      this.FB.logout();
      // }
    },
    showModal() {
      this.$bvModal.show("bell-dialog");
    },
    routeit(arg, bools) {
      this.type = arg;
      this.$bvModal.hide("bell-dialog");
      this.$router.push({
        name: "NewPost",
        params: { type: arg, farm: bools },
      });
    },
  },
};
</script>

<!-- <style lang="scss"  >
.page-container {
  min-height: 300px;
  overflow: hidden;
  position: relative;
  border: 1px solid rgba(#000, 0.12);
}

// Demo purposes only
.md-drawer {
  width: 230px;
  max-width: calc(100vw - 125px);
}

.md-content {
  padding: 16px;
}
</style> -->
<style scoped>
.card {
  border-radius: 1rem !important;
}
.card-body {
  padding: 0.3rem !important;
}
.sidebar {
  margin: 0;
  padding: 0;
  width: 200px;
  background: transparent
    linear-gradient(180deg, #000000 0%, #309f5d 100%, #18502f 100%) 0% 0%
    no-repeat padding-box;
  /* position: fixed; */
  height: 100vh;
  overflow: auto;
}
.sidebar a {
  display: block;
  color: white;
  padding: 16px;
  text-decoration: none;
}

.sidebar a.active {
  background-color: #388e3c;
  color: white;
}
.side-items:hover {
  background-color: #388e3c;
  color: white;
}
/* .sidebar a:hover:not(.active) {
  background-color: #388E3C;
  color: white; */
/* } */
div.content {
  margin-left: 200px;
  padding: 1px 16px;
  height: 150%;
}
@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .sidebar a {
    float: left;
  }
  div.content {
    margin-left: 0;
  }
}
@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }
}
.md-toolbar.md-theme-default.md-transparent {
  padding: 0rem !important;
}

.md-toolbar.md-theme-default.md-primary {
  background: transparent
    linear-gradient(180deg, #000000 0%, #309f5d 100%, #18502f 100%) 0% 0%
    no-repeat padding-box !important;
}
.md-drawer.md-theme-default {
  background: transparent
    linear-gradient(180deg, #000000 0%, #309f5d 100%, #18502f 100%) 0% 0%
    no-repeat padding-box !important;
}
.md-drawer {
  background: transparent
    linear-gradient(180deg, #000000 0%, #309f5d 100%, #18502f 100%) 0% 0%
    no-repeat padding-box !important;
}
.md-list {
  /* background: #27a844 !important; */
  background: transparent
    linear-gradient(180deg, #000000 0%, #309f5d 100%, #18502f 100%) 0% 0%
    no-repeat padding-box !important;
}
.md-list.md-theme-default {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}

a {
  color: white !important;
}

.bg-dark {
  background: black !important;
}
</style>
