import Vuex from 'vuex';
import Vue from 'vue';
import user from './modules/user';
import posts from './modules/posts';
import chats from './modules/chats';
import farms from './modules/farms';
import discussions from './modules/discussions';

// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
    modules: {
        user,   
        posts,
        chats,
        farms,
        discussions
    },
    
});