// LANDING
<template>
  <div>
    <Slider />
    <Section />
    <!-- <b-modal id="modal-video" hide-header hide-footer  >
         <video style="width:100%" height="240"  muted ref="opd">
            <source src="/qurbani.mp4" >
         </video>
      </b-modal> -->
  </div>
</template>
<script>
import Slider from "../components/Slider.vue";
import Section from "../components/Section.vue";
// import TopNav from "../components/TopNav.vue";

// import TermsAndConditions from './TermsAndConditions.vue'
export default {
  components: {
    Slider,
    Section,
    // TopNav,
  },
  mounted() {
    // this.$bvModal.show('modal-video')
    // setTimeout(() => {
    //    console.log("plays")
    //    this.$refs['opd'].play()
    // }, 2000);
    // setTimeout(() => {
    //  this.$bvModal.hide('modal-video')
    // }, 6000);
  },
  created() {
    //  this.$bvModal.show('modal-video')
  },
};
</script>



<style >
#modal-video .modal-dialog .modal-content .modal-body {
  padding: 0px !important;
}
.main-landing-back {
  background: transparent
    linear-gradient(180deg, #000000 0%, #000000 21%, #1b5934 65%, #309f5d 100%)
    0% 0% no-repeat padding-box;
}
.top-notification-bar {
  border-bottom: 1px solid #a9aaa9;
}
</style>