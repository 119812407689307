<template>
  <b-container
    fluid
    class="main-landing-back pr-0 pl-0"
    style="min-height: 500px; position: relative"
  >
    <!-- <testTopNav /> -->
      <b-container class="mb-5">
      <h4 class="text-white">
        Order Sadqa/Aqiqah Now and Get Live Hourly Updates on your Order  <br />
        Processed and Distributed Within 24 Hours
      </h4>
    </b-container>
    <b-card>
      <CSadqahStock v-if="type == 6" @order-item="orderItem" />
      <CSadqahOrder
        :AllPostFlag="true"
        :order="order"
        class="mt-5"
        v-if="type == 7"
      />
    </b-card>

    <!-- <div class="row mt-5">
         <div class="col-md-6" style="padding-top:14rem;padding-bottom:14rem;background:url('img/sadqaqiqah.png');background-repeat: no-repeat;background-size: 100% 100%;">
         </div>
         <div class="col-md-6">
             <h3 style="font-weight:bold;">Have a look at our Healthy & Fresh Stock!   </h3>
             <router-link :to="{name:'AllPosts',params:{types:6}}">
             <button class="col-md-12 btn btn-success">VIEW STOCK</button>
            </router-link> 

             <h6 class="mt-4">Qurbani app brings variety of Animals for you. You can now order Sadqa anytime. Just let us know the product id and we will deliver the live Bakra or its fresh meat at your doorstep. Try Us!</h6>
             <h6 class="mt-4" style="font-weight:bold;"> When you order you can choose among the following three options:</h6>
             <ul class="left-aligned">
               <li>We dleiver the live Bakra at your doorstep</li>
               <li>We sacrifice the Bakra for you and deliver meet at your doorstep</li>
               <li>We sacrifice the bakra for you and distribute its meat among the deserving families</li>
            </ul>
             <router-link :to="{ name: 'Dashboard', params: { active_prop: 5 }}">
                  <button class="col-md-12 btn btn-success" @click="toggleOrder()">Order</button>
            </router-link>  -->
    <!-- <router-link to="faqs">
             <button class="mt-2 col-md-12 btn btn-success">FAQs</button>
            </router-link> -->

    <!-- Call Now to Order: +92-3065401886 -->

    <!-- </div>

      </div> -->
    <!-- <b-container class="mt-5">
      <h3 style="font-weight: bold">FAQs Sadqah & Aqiqah Service</h3>
      <h6>VIEW COMPLETE DETAILS ON HOW WE DELIEVER SADQAH & AQIQAH SERVICES</h6>
      <ul style="list-style: none" class="mt-3 left-aligned">
        <li class="bolds">Q:How do I know your service is authentic?</li>
        <li>
          A: We have taken a number of measures to make sure that all our Sadqa
          service users in Pakistan and abroad are fully satisfied. Here are
          some some of those measures:
        </li>
        <li>
          1) You will receive a video of your Bakra being weighed, to calculate
          its final price
        </li>
        <li>
          2) You will receive a video of your Bakra being sacrficed with your
          nametag around its neck clearly visible while being slaughtered
        </li>
        <li>
          3) Depending on the service you choose, you will receive a video of
          your meat being distributed or a call when it is about to be delivered
        </li>
        <li class="bolds mt-3">
          Q: How much time does it take for an order to be processed?
        </li>
        <li>
          A: For all the three services stated above, we complete them within 48
          hours of receiving the payment
        </li>
        <li class="bolds mt-3">
          Q: Are there any extra charges on top of the Bakra price?
        </li>
        <li>
          A: Ans: Yes, there is Rs 1000 Serivce charge for all the three
          services mentioned above. This covers the cost of acrificing the
          Bakra. There is an additional Rs 1000 Delivery and Distribution
          Charge, this covers the cost of delivery or distribution in the three
          services above.
        </li>
        <li class="bolds mt-3">
          Q: If I want the meat to be distributed, who will receive it and how
          do I know that its reaching the derserving people?
        </li>
        <li>
          A: We partner with a welfare organisation named
          <a href="http://renaissancepakistan.com/" target="_black"
            >Renaissance</a
          >
          to distribute meat among orphans and widows. On days when we have more
          than hundred Sadqa Bakras, we distribute their meat in villages around
          Rawat. You will receive real-time updtaes with pictures and videos of
          your Sadqa meat being distributed among the under-privileged.
        </li>
        <li class="bolds mt-3">
          Q: What do you do with Bakra's skin, head and feet if we get meat
          delivered?
        </li>
        <li>
          We distribute the head and feet among families living below poverty
          line. We sell the skin and its proceeds are donated to
          <a href="http://renaissancepakistan.com/" target="_black"
            >Renaissance</a
          >
          , a charity providing quality eduaction in disadvantaged communities
        </li>
      </ul>
      <h5 class="mt-5">Didn't find what you were looking for?</h5>
      <button
        class="col-md-4 btn btn-success"
        v-clipboard:copy="message"
        v-clipboard:success="onCopy"
        @click="show = true"
      >
        {{ show == false ? "Qurbani Helpline" : "+923065401886 Helpline" }}
      </button>
      <b-alert
        v-model="showTop"
        class="position-fixed fixed-top m-0 rounded-0"
        style="z-index: 2000"
        variant="success"
        dismissible
      >
        Help Line phone number copied !
      </b-alert>
    </b-container> -->

    <b-container>
      <!-- <TermsAndConditions/> -->
    </b-container>

    <!-- <ul class="left-aligned">
         <li>Any of the animals presented in this app are not owned by Qurbani App. We are a platform for livestock enthusiasts to buy, sell and share their animals with the community.</li>
         <li>We act as an online market place platform to allow our users who comply with these Terms to offer, sell, and buy products and services listed on the app. Although you may be able to conduct payment and other transactions through the Website, using third-party vendors such as Jazz Payment, Qurbani App is not in any way involved in such transactions.</li>
         <li>You acknowledge and agree that you are solely responsible for your own Content posted on, transmitted through, or linked from the Service and the consequences of posting, transmitting, linking or publishing it.</li>
         <li>Qurbani App does not endorse any Content or any opinion, statement, recommendation, or advice expressed therein, and Qurbani App expressly disclaims any and all liability in connection with user Content. Qurbani App does not permit copyright infringing activities and infringement of intellectual property rights on the Website, and Qurbani App may, at its sole discretion, remove any infringing Content.</li>
         <li>We are not responsible for the prices quoted by sellers on this app.</li>
         <li>Payments processed through Qurbani App are subject to 6% charge on top of the price of the animal.</li>
         <li>This 6% extra charge shall be paid by the buyer unless unless otherwise agreed.</li>
         <li>Any post (image, video, comment) that is not relevant for the livestock sector shall be immediately deleted without any warning.</li>
         <li>Qurbani App reserves the right to delete any user from the app or limit their access.</li>
         <li>If an animal, bought on Qurbani App is not in accordance with the requirements of the buyer, buyer my return that animals to the seller and refund the amount, unless agreed otherwise.</li>
         <li>In case of a dispute, you are requested to contact us via the Contact Us tab on the app and the website. Qurbani App’s verdict in case of dispute shall be final.</li>
         <li>To post an animal on Featured section, user is requested to pay a certain amount in accordance with the current rate.</li>
         <li>The price and description of animals added on Qurbani App are the prerogative of sellers. They have the right to disclose as much information as possible.</li>
         <li>We confirm not to share the data of our users with any third party</li>
         <li>Standard delivery times of animals shall be mutually agreed by the buyer and the seller before processing the transaction. Qurbani will not take any responsibility for the transportation time</li>
         <li>For animals bought and sold through the platform and payment processed through Qurbani App:</li>
         <li>Buyer is required to confirm the acceptance of the qurbani animal with in 24 hours of receiving the animal</li>
         <li>Buyer will be liable to pay 6% transaction processing fee on top of the agreed price of the animal</li>
         <li>Qurbani App will disburse the transaction money to the seller account after 3 days of the transaction</li>
         <li>The payments through Qurbani App shall only be processed in Pakistani Rupee</li>
      
      
      
      
      
      
      
      </ul> -->
  </b-container>
</template>
<script>
import TermsAndConditions from "./TermsAndConditions.vue";
import CSadqahStock from "../components/CSadqahStock";
import CSadqahOrder from "../components/CSadqahOrder";
import testTopNav from "../components/testTopNav.vue";

export default {
  components: {
    TermsAndConditions,
    CSadqahStock,
    CSadqahOrder,
    testTopNav,
  },
  data() {
    return {
      type: 6,
      showTop: false,
      show: true,
      order: "",
      message: "+923065401886",
    };
  },
  methods: {
    orderItem(item) {
      this.type = 7;
      this.order = item;
    },
    onCopy: function (e) {
      this.showTop = !this.showTop;
    },
    toggleOrder() {
      this.order.flag = !this.order.flag;
      this.order.text = "Call Now to Order +923058597560";
    },
  },
};
</script>

