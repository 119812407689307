import UserRepository from "./UserRepository";
import PostsRepository from "./PostsRepository";
import OrderRepository from "./OrderRepository";
import PromoRepository from "./PromoRepository";
import SearchRepository from "./SearchRepository";
import FarmRepository from "./FarmRepository";

const repositories = {
  user_repository: UserRepository,
  posts_repository: PostsRepository,
  order_repository: OrderRepository,
  promo_repository: PromoRepository,
  search_repository: SearchRepository,
  farms_repository:FarmRepository
};

// eslint-disable-next-line import/prefer-default-export
export const RepositoryFactory = {
  get: (name) => repositories[name],
};
