<template>
  <div id="conts" class="row">
    <div class="col-md-8">
      <article class="card mb-2">
        <video
          v-if="selected_post.post_type == 'VIDEO'"
          style="width: 100%; height: 240px"
          @click="openanothermodal(selected_post.image_key)"
          controls
        >
          <source
            :src="`https://qurbaniimages.s3.amazonaws.com/${replacer(
              selected_post.imagekey
            )}`"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <!-- <img v-if="selected_post.post_type=='VIDEO'" @error="imgUrlAlt" :src="selected_post!='' ? 'https://s3.amazonaws.com/qurbaniimages/'+replacer(selected_post.thumbnail) :'img/section1.png'" alt="Image" class="card-img-top"> -->
        <div v-else>
          <carousel
            v-if="getImgDialogArr.size > 0"
            :per-page="1"
            :mouse-drag="false"
            :autoplay="true"
            :loop="true"
            :autoplayTimeout="3000"
            :paginationEnabled="false"
            :navigationEnabled="true"
          >
            <slide v-for="(post_item, i) in getImgDialogArr.imgs" :key="i">
              <img
                @click="openanothermodal(post_item)"
                @error="imgUrlAlt"
                :src="
                  selected_post != ''
                    ? 'https://qurbaniimages.s3.amazonaws.com/' +
                      replacers(post_item)
                    : 'img/section1.png'
                "
                alt="Image"
                class="card-img-top"
                style="height: 240px !important"
              />
            </slide>
          </carousel>
          <img
            style="height: 240px !important"
            v-else
            @error="imgUrlAlt"
            @click="openanothermodal(selected_post.imagekey)"
            :src="
              selected_post != ''
                ? 'https://qurbaniimages.s3.amazonaws.com/' +
                  replacer(selected_post.imagekey)
                : 'img/section1.png'
            "
            alt="Image"
            class="card-img-top"
          />
        </div>

        <div class="mb-5 ml-1 mr-1 mt-2">
          <div>
            <b-row>
              <b-col class="align-self-center text-left">
                <span style="font-size: 20px" class="text-success">
                  {{ comments.length }} Comments</span
                >
              </b-col>
              <b-col class="text-right">
                <b-spinner
                  v-if="isload == true"
                  variant="success"
                  label="Spinning"
                ></b-spinner>

                <span
                  v-else
                  @click="likePost(selected_post.id)"
                  :class="
                    analyzeLikes(selected_post.id) == true
                      ? 'fa fa-heart mt-2'
                      : 'fa fa-heart-o mt-2'
                  "
                  style="color: #a83f39; font-size: 20px"
                >
                  {{ likes.length }}
                </span>
              </b-col>
            </b-row>
          </div>
          <div>
            <div v-if="isload == false && comments.length > 0" class="row">
              <div class="col-md-12">
                <div v-for="(item, i) in comments" :key="i">
                  <b-card class="shadow mt-1" style="border-radius: 22px">
                    <div class="row">
                      <div class="col-md-6">
                        <span>
                          <img :src="default_img" width="40" alt="" />
                          {{
                            item.nickname != null ? item.nickname : "NA"
                          }}</span
                        >
                      </div>
                      <div class="col-md-6 text-right">
                        <i
                          v-if="
                            loggedUser != null && item.user_id == loggedUser.id
                          "
                          class="fa fa-trash mr-2"
                          @click="deletecomment(item, 'main')"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div class="col-md-12">
                        <span
                          v-if="
                            item.audio_file == '' || item.audio_file == null
                          "
                          class="ml-5"
                          >{{ item.commentbody }}</span
                        >
                        <audio v-else controls height="20">
                          <source :src="replaceaudio(item.audio_file)" />
                          Your browser does not support the audio element.
                        </audio>
                      </div>

                      <div class="col-md-6">
                        <span
                          class="left-aligned text-success fa fa-reply"
                          style="cursor: pointer"
                          @click="replyItem(item)"
                          >Reply</span
                        >
                      </div>
                      <div class="col-md-6">
                        <p class="right-aligned">
                          {{ dateSplitter(item.created_at) }}
                        </p>
                      </div>
                    </div>
                  </b-card>
                  <b-card
                    class="ml-4 shadow mt-1"
                    style="border-radius: 18px"
                    v-for="(item, i) in getReplies(item.id)"
                    :key="i"
                  >
                    <div class="row">
                      <div class="col-md-6">
                        <span>
                          <img :src="default_img" width="40" alt="" />
                          {{
                            item.nickname != null ? item.nickname : "NA"
                          }}</span
                        >
                      </div>
                      <div class="col-md-6 text-right">
                        <i
                          v-if="
                            loggedUser != null && item.user_id == loggedUser.id
                          "
                          class="fa fa-trash mr-2"
                          @click="deletecomment(item, 'reply')"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div class="col-md-12">
                        <span
                          v-if="
                            item.audio_file == '' || item.audio_file == null
                          "
                          class="ml-5"
                          >{{ item.commentreplybody }}</span
                        >
                        <audio v-else controls height="20">
                          <source :src="replaceaudio(item.audio_file)" />
                          Your browser does not support the audio element.
                        </audio>
                      </div>

                      <div class="col-md-6"></div>
                      <div class="col-md-6">
                        <p class="right-aligned">
                          {{ dateSplitter(item.created_at) }}
                        </p>
                      </div>
                    </div>
                  </b-card>
                </div>
              </div>
            </div>
            <div v-else-if="isload == true" class="text-center mt-2">
              <b-spinner variant="success" label="Spinning"></b-spinner>
            </div>
            <div class="row mt-2">
              <div class="col-md-12">
                <b-input-group>
                  <textarea
                    v-model="comment"
                    :placeholder="replier"
                    class="col-md-12"
                    style="
                      outline: none !important;
                      color: black;
                      padding-top:18px;
                      border-radius: 20px;
                      background-color: #f5f5f5;
                      padding-right: 7.5rem;
                    "
                    rows="2"
                    cols="50"
                  >
                  </textarea>
                  <VueRecordAudio
                    class="micro_phones"
                    mode="press"
                    @result="onResult"
                  />

                  <img
                    v-if="comment_flag == true"
                    @click="postcomment"
                    :src="img_src"
                    width="20"
                    height="20"
                    alt=""
                    style="position: absolute; top: 18px; right: 31px"
                  />
                </b-input-group>
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
    <div class="col-md-4">
      <b-card tag="article" class="mb-2">
        <div class="row">
          <div class="col-md-12">
            <img width="30" :src="default_img" alt="" />
            <span>{{
              selected_post.nickname != null ? selected_post.nickname : "NA"
            }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <span>{{
              selected_post.category != null ? selected_post.category : "NA"
            }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <span>Availability:</span
            ><span class="text-success">
              {{
                selected_post.sold_status == "s" ? "Sold Out" : "In Stock"
              }}</span
            >
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <span>Price:</span
            ><span class="font-weight-bold text-success">
              {{ selected_post.price != "" ? selected_post.price : "NA" }}</span
            >
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <span>City:</span
            ><span style="text-transform: capitalize">
              {{ selected_post.city != "" ? selected_post.city : "NA" }}</span
            >
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <span>Post Id:</span
            ><span style="text-transform: capitalize">
              {{ selected_post.id }}</span
            >
          </div>
          <div class="text-success col-md-12">
            <span>Post Views:</span
            ><span style="text-transform: capitalize"> {{ post_views }}</span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12 text-center">
            <span>Description:</span> <br />
            <span>{{
              selected_post.description != "" ? selected_post.description : "NA"
            }}</span>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12 text-center">
            <!-- v-if="buy_now == false"
              @click="buy_now = true" -->
            <!--  -->
            <!-- :description="`*Post Id* ${selected_post.id} *Location* ${
                selected_post.city != '' ? selected_post.city : 'NA'
              } *Price* ${
                selected_post.price != '' ? selected_post.price : 'NA'
              }`" -->
            <div>
              <ShareNetwork
                :url="`${origin}/all_posts/${selected_post.id}`"
                network="whatsapp"
                title="Check amazing animals at Qurbani App"
                class="p-2 text-white"
                :style="{ backgroundColor: '#25d366', borderRadius: '0.2rem' }"
                :description="`Posted By ${selected_post.nickname}`"
                quote="Find amazing animals at reasonable prices at Qurbani App"
                hashtags="qurbaniapp"
              >
                <i class="fab fah fa-lg fa-whatsapp mr-2"></i>
                <span>Share on WhatsApp</span>
              </ShareNetwork>
            </div>
            <div class="mt-3">
              <ShareNetwork
                :url="`${origin}/all_posts/${selected_post.id}`"
                network="facebook"
                title="Check amazing animals at Qurbani App"
                class="p-2 text-white"
                :style="{ backgroundColor: '#1877f2', borderRadius: '0.2rem',width:'200px' }"
                :description="`Posted By ${selected_post.nickname}`"
                quote="Find amazing animals at reasonable prices at Qurbani App"
                hashtags="qurbaniapp"
              >
                <i class="fab fah fa-lg fa-facebook-f mr-2"></i>
                <span>Share on Facebook</span>
              </ShareNetwork>
            </div>
            <!-- <b-button variant="success" style="width: 100%">Share</b-button> -->
            <!-- v-else-if="buy_now == true && paypal_flag == false" -->

            <!-- <b-spinner variant="success" label="Spinning"></b-spinner> -->
            <!-- <Paypal
              v-if="paypal_flag == true && buy_now == true"
              env="sandbox"
              amount="10.00"
              currency="USD"
              @payment-authorized="ok"
              v-on:payment-completed="sok"
              v-on:payment-cancelled="dok"
              v-bind:client="paypal"
            >
            </Paypal> -->
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12 text-center">
            <span>Call the Seller Now</span>
            <b-button
              variant="success"
              style="width: 80%"
              @click="contact = selected_post.contactno"
            >
              {{
                contact != ""
                  ? loggedUser != null
                    ? contact
                    : hiddenPhone(contact, 3)
                  : "No Number"
              }}</b-button
            >
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12 text-center">
            <span>Would You like to chat ?</span>
            <b-button
              variant="success"
              style="width: 80%"
              @click="$emit('chat-show', selected_post)"
              >Chat Now</b-button
            >
          </div>
        </div>

        <b-card-body> </b-card-body>
      </b-card>
    </div>
    <b-modal id="image-mods" hide-footer ref="image-mods">
      <video
        v-if="selected_post.post_type == 'VIDEO'"
        style="width: 100%; height: 240px"
        controls
      >
        <source
          :src="`https://qurbaniimages.s3.amazonaws.com/${replacer(
            selected_post.imagekey
          )}`"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <img v-else :src="selected_image" class="w-100" alt="" />
    </b-modal>
  </div>
</template>
<script>
import { RepositoryFactory } from "../Repository/RepositoryFactory";
const PostsRepository = RepositoryFactory.get("posts_repository");
import { Carousel, Slide } from "vue-carousel";
// import Paypal from "vue-paypal-checkout";
import { mapGetters } from "vuex";
import axios from "axios";
import globalfuns from "../mixins/globalfuns";
import { uuid } from "vue-uuid"; // uuid object is also exported to things

import S3 from "aws-s3";
const config = {
  bucketName: "qurbaniaudios",
  region: "us-east-1",
  accessKeyId: "AKIA44SH2DQ6PVOA366C",
  secretAccessKey: "szYhav2Z6/+VHYznh7kfa2mqmElvVsseg5/BvVZOO4edA4waS5",
  s3Url: "https://qurbaniaudios.s3.us-east-1.amazonaws.com/",
  /* optional */
};
const S3Client = new S3(config);
String.prototype.replaceAt = function (index, replacement) {
  return (
    this.substr(0, index) +
    replacement +
    this.substr(index + replacement.length)
  );
};
export default {
  name: "DialogPost",
  props: ["selected_post"],
  mixins: [globalfuns],
  data() {
    return {
      selected_image: "",
      origin: "",
      post_views: 0,
      comments: [],
      isReply: false,
      img_src: require("../assets/img/send.png"),

      reply_obj: "",
      replier: "Write Something",
      audio: "",
      comment: "",
      paypal_flag: false,
      comment_flag: false,
      buy_now: false,
      contact: "Call Now",
      myItems: [
        {
          name: "hat",
          description: "Brown hat.",
          quantity: "1",
          price: "5",
          currency: "USD",
        },
      ],
      paypal: {
        sandbox:
          "AaonszINBNF9xiFPLxpONw5RWO72KYPZKdqvU0MpvJKyt1Q7ut70e4g38pLWREDWWZ9E5i_3aqzR_liV",
        production:
          "EF1EvqZ6ESVa3JjPTZ00uIUCCEBwoH5eqYN_VnEj4tEhYH0PlMbJro6S1cXfTmrslKqZV6w5bztCPeKV",
      },
      likes: [],
      replies: [],
      isload: true,
      default_img: require("../assets/img/default-profile.png"),
    };
  },
  created() {
    this.origin = window.location.origin;
    this.fetchComments();
    // this.paypalDefer();
    this.commentDefer();
    this.incrementView();
  },
  components: {
    Carousel,
    Slide,
    // Paypal,
  },
  computed: {
    ...mapGetters(["loggedUser", "user_resources"]),
    getImgDialogArr() {
      let arr = this.selected_post.imagekey.split(",");
      let sl_Arr = arr.slice(1, arr.length);
      return {
        size: sl_Arr.length,
        imgs: sl_Arr,
      };
    },
  },
  methods: {
    openanothermodal(arg) {
      this.selected_image =
        this.selected_post != ""
          ? "https://qurbaniimages.s3.amazonaws.com/" + this.replacer(arg)
          : "img/section1.png";
      this.$bvModal.show("image-mods");
    },
    async incrementView() {
      let { data } = await PostsRepository.post_views({
        post_id: this.selected_post.id,
      });

      this.post_views = data;
    },
    replaceaudio(item) {
      if (item.includes("-")) {
        let sez = item.split("-");
        if (sez.length == 5) {
          let newcop = item + ".mp3";
          return newcop;
        } else {
          return item;
        }
      } else {
        return item;
      }

      return item;
    },
    async likePost(id) {
      this.isload = true;
      if (this.loggedUser != null) {
        if (this.analyzeLikes(id) == false) {
          let like_obj = {
            post_id: this.selected_post.id,
            user_id: this.loggedUser.id,
          };
          let { data } = await PostsRepository.new_likes(like_obj);
          this.$store.commit("setNewUserLikes", data);
          this.fetchComments();
        } else {
          let like_obj = {
            post_id: this.selected_post.id,
            user_id: this.loggedUser.id,
          };
          let { data } = await PostsRepository.remove_likes(like_obj);
          this.$store.commit("removeOldLikes", like_obj);
          this.fetchComments();
        }
      } else {
        this.$store.commit("setNotifications", {
          message: "Log in before liking a post",
          type: "error",
        });
      }
      this.isload = false;
    },
    ok(ev) {
      this.$store.commit("setNotifications", {
        message: "Your payment has been successfully completed",
        type: "success",
      });
      // const headers = {
      //     'Content-Type': 'application/json',
      //     'Authorization': 'Basic AaonszINBNF9xiFPLxpONw5RWO72KYPZKdqvU0MpvJKyt1Q7ut70e4g38pLWREDWWZ9E5i_3aqzR_liV:EF1EvqZ6ESVa3JjPTZ00uIUCCEBwoH5eqYN_VnEj4tEhYH0PlMbJro6S1cXfTmrslKqZV6w5bztCPeKV'
      // }
      // let url=`https://api.sandbox.paypal.com/v2/payments/authorizations/${ev.paymentID}/capture`
      //     axios.post(url, {"amount": {
      //         "value": "10.00",
      //         "currency_code": "USD"
      //     }
      //     },
      //     {
      //         headers: headers
      //     })
      //     .then((response) => {
      //     })
      //     .catch((error) => {
      //     })
    },
    dok(ev) {},
    sok(ev) {},
    async deletecomment(item, type) {
      this.isload = true;
      if (type == "main") {
        let { data } = await PostsRepository.delete_comments({
          id: item.id,
        });
      } else {
        let { data } = await PostsRepository.deleteCommentReply({
          comment_reply_id: item.id,
        });
      }
      this.isload = true;
      this.fetchComments();
    },
    onResult(data) {
      this.audio = uuid.v1();
      var file = new File([data], this.audio, {
        type: "audio/mp3",
      });
      this.audiocomment(file);
    },
    async replyItem(item) {
      this.isReply = true;
      this.reply_obj = item;
      this.replier = "Replying to" + " " + item.nickname;
    },
    async audiocomment(file) {
      if (this.loggedUser == null) {
        this.$store.commit("setNotifications", {
          message: "Make sure your logged in before commenting",
          type: "error",
        });
      } else {
        if (this.isReply == false) {
          S3Client.uploadFile(file, this.audio)
            .then((data) => {})
            .catch((err) => console.error(err));

          let comment_obj = {
            commentbody: "",
            user_id: this.loggedUser.id,
            audio_file: this.audio,
            post_id: this.selected_post.id,
          };

          let { data } = await PostsRepository.new_comments(comment_obj);
        } else {
          S3Client.uploadFile(file, this.audio)
            .then((data) => {})
            .catch((err) => console.error(err));

          let comment_obj = {
            commentreplybody: "",
            comment_id: this.reply_obj.id,
            user_id: this.loggedUser.id,
            audio_file: this.audio,
            post_id: this.selected_post.id,
          };
          let { data } = await PostsRepository.createCommentReply(comment_obj);
        }

        this.isload = true;
        this.fetchComments();
        this.comment = "";
      }
      this.replier = "";
      this.isReply = false;
    },
    analyzeLikes(id) {
      if (this.user_resources.length > 0) {
        let obj = this.user_resources[2].find(
          (user_res_item) => user_res_item.post_id == id
        );
        if (obj != null) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    async postcomment() {
      if (this.loggedUser == null) {
        this.$store.commit("setNotifications", {
          message: "Make sure your logged in before commenting",
          type: "error",
        });
      } else if (this.comment != "") {
        if (this.isReply == false) {
          let comment_obj = {
            commentbody: this.comment,
            user_id: this.loggedUser.id,
            audio_file: "",
            post_id: this.selected_post.id,
          };
          let { data } = await PostsRepository.new_comments(comment_obj);
        } else {
          let comment_obj = {
            commentreplybody: this.comment,
            comment_id: this.reply_obj.id,
            user_id: this.loggedUser.id,
            audio_file: "",
            post_id: this.selected_post.id,
          };
          let { data } = await PostsRepository.createCommentReply(comment_obj);
        }
        this.isload = true;
        this.fetchComments();
        this.comment = "";
      } else {
        this.$store.commit("setNotifications", {
          message: "Make sure to write someting before commenting",
          type: "error",
        });
      }
      this.replier = "";
      this.isReply = false;
    },
    getReplies(comment_id) {
      return this.replies.filter((item) => item.comment_id == comment_id);
    },
    paypalDefer() {
      setTimeout(() => {
        this.paypal_flag = true;
      }, 7000);
    },
    commentDefer() {
      setTimeout(() => {
        this.comment_flag = true;
      }, 3000);
    },
    async fetchComments() {
      let { data } = await PostsRepository.post_comments({
        post_id: this.selected_post.id,
      });
      this.comments = data[0];
      this.likes = data[1];
      this.replies = data[2];
      this.isload = false;
    },
    imgUrlAlt(event) {
      event.target.src = "img/section1.png";
    },
    dateSplitter(args) {
      var res = args.split("T");
      return res[0];
    },
  },
};
</script>

<style scoped>
.micro_phones {
  position: absolute !important;
  width: 30px !important;
  height: 31px !important;
  right: 4rem !important;
  top: 0.8rem !important;
  background-color: #27a844 !important;
}

.card-body {
  padding-bottom: 0rem !important;
}
</style>