<template>
  <div id="app position-relative ">
    <div ref="myNav" id="myNav" class="bgoverlay">
      <div class="overlay-content">
        <img src="/goats.gif" width="150" alt="" />
      </div>
    </div>
    <!-- <Discounts v-if="isDashboard != true" /> -->
    <b-container fluid>
      <b-row
        v-if="isDashboard != true"
        class="p-2 top-notification-bar main-landing-black pb-2"
      >
        <b-col class="col-md-6 text-white text-md-left text-center">
          <i class="fas fa-mobile-alt text-success"></i> Download App from
          <a
            target="_blank"
            class="text-white"
            href="https://apps.apple.com/pk/app/qurbani-app/id1520463934"
            >App</a
          >
          /
          <a
            target="_blank"
            class="text-white"
            href="https://play.google.com/store/apps/details?id=com.plan9.qurbaniapps.qurbani&hl=en"
            >Play
          </a>
          store
        </b-col>
        <b-col class="text-center text-md-right d-flex justify-content-end">
          <span class="text-success"
            ><div id="google_translate_element"></div>
          </span>
          <router-link v-if="loggedUser == null" to="/register">
            <span class="text-white ml-3">Register Now?</span>
          </router-link>
        </b-col>
      </b-row>
    </b-container>

    <TopNav v-if="isDashboard != true && hide == false" />
    <router-view />
    <Footer v-if="isDashboard != true" />
  </div>
</template>

<script>
import TopNav from "@/components/testTopNav.vue";
import Footer from "@/components/Footer.vue";
import Discounts from "@/components/Discounts.vue";
import { mapGetters } from "vuex";
import nativeToast from "native-toast";
import { RepositoryFactory } from "./Repository/RepositoryFactory";
const PostsRepository = RepositoryFactory.get("posts_repository");
const OrderRepository = RepositoryFactory.get("order_repository");
const UserRepository = RepositoryFactory.get("user_repository");
const PromoRepository = RepositoryFactory.get("promo_repository");

export default {
  name: "app",
  components: {
    TopNav,
    Footer,
    Discounts,
  },
  data() {
    return {
      isDashboard: false,
      hide: true,
    };
  },
  computed: {
    ...mapGetters([
      "user_notifications",
      "loggedUser",
      "notifications",
      "chatUser",
      "FB",
    ]),
  },
  created() {
    this.fetchAllPosts();
    this.fetchPromo();
    setTimeout(() => {
      this.hide = false;
      this.$refs.myNav.style.display = "none";
    }, 3000);

    // this.$store.dispatch("findAllUsers");
    if (localStorage.getItem("loggedUser") != null) {
      this.$store.commit(
        "setLoggedUser",
        JSON.parse(localStorage.getItem("loggedUser"))
      );
      this.fetchUserNotifications(
        JSON.parse(localStorage.getItem("loggedUser"))
      );
      if (localStorage.getItem("FB") != null) {
        this.$store.commit("setFB", JSON.parse(localStorage.getItem("FB")));
      }
    }
    if (localStorage.getItem("user_resources") != null) {
      if (JSON.parse(localStorage.getItem("loggedUser").id != 21)) {
        this.$store.commit(
          "setUserResources",
          JSON.parse(localStorage.getItem("user_resources"))
        );
      }
    }
    if (JSON.parse(localStorage.getItem("loggedUser")).id == 21) {
      this.fetchAdminAllSadqahOrders();
    }
  },
  methods: {
    async fetchPromo() {
      let { data } = await PromoRepository.get_current_promo();
      console.log(data);
      this.$store.commit("setPromo", data[0]);
    },
    async fetchAdminAllSadqahOrders() {
      var { data } = await OrderRepository.get_admin_sadqa_orders();
      this.$store.commit("setAdminResources", data[0]);
    },
    async fetchAllPosts() {
      var { data } = await PostsRepository.all_posts();
      this.$store.commit("setPosts", data);
    },
    async fetchUserNotifications(user) {
      // user.id
      var { data } = await UserRepository.get_user_notifications({
        user_id: user.id,
      });
      this.$store.commit("setUserNotifications", data[0]);
    },
    checkboth(as) {
      if (this.user_notifications.length > 0 && this.chatUser != "") {
        let temp_viewed =
          this.chatUser.notifications != null
            ? this.chatUser.notifications.viewed
            : 0;
        this.$store.dispatch("updateNotifications", {
          id: this.loggedUser.id,
          viewed: temp_viewed,
          unviewed: this.user_notifications.length - temp_viewed,
        });
      }
    },
  },
  watch: {
    "$route.name": {
      handler: function (route) {
        if (route == "Dashboard") {
          this.isDashboard = true;
          localStorage.setItem("route", "Dashboard");
        } else {
          this.isDashboard = false;
          localStorage.removeItem("route");
          localStorage.setItem("route", route);
        }
      },
      deep: true,
      immediate: true,
    },
    user_notifications() {
      if (this.user_notifications.length > 0) {
        this.checkboth("notif");
      }
    },
    chatUser(val) {
      if (this.chatUser != "") {
        this.checkboth("chat");
        if (this.chatUser.chat_groups != null) {
          Object.keys(this.chatUser.chat_groups).forEach((item) => {
            this.$store.dispatch(
              "fetchMessages",
              this.chatUser.chat_groups[item].id
            );
          });
        }
      }
    },
    loggedUser() {
      if (this.loggedUser != null) {
        this.$store.dispatch("updateOnlineStatus", {
          id: this.loggedUser.id,
          status: true,
        });
        if (this.$route.name == "Login") {
          let odr = localStorage.getItem("order");
          console.log(odr);
          if (odr != null) {
            this.$router.push({ name: "AllPosts", params: { types: 6 } });
          } else {
            this.$router.push({ path: "/dashboard" });
          }
        }
        this.$store.dispatch("findChatUser", this.loggedUser.id);
        if (this.loggedUser.id == 21) {
          this.fetchAdminAllSadqahOrders();
        }
      } else {
        this.$router.push({ path: "/" });
        location.reload();
      }
    },
    notifications() {
      if (this.notifications.message != null) {
        nativeToast({
          message: this.notifications.message,
          position: "north-east",
          timeout: 5000,
          type: this.notifications.type,
        });
      }
    },
  },
};
</script>

<style>
audio:not(.md-image) {
  height: 8vh !important;
}
html.md-theme-default {
  background: white !important;
}
#app {
  overflow: hidden !important;
  font-family: "Montserrat", "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
.bolds {
  font-weight: bold !important;
}

.left-aligned {
  text-align: left !important;
}
.right-aligned {
  text-align: right !important;
}
.carousel-inner {
  /* height: 85vh; */
}

.VueCarousel-dot {
  background-color: #28a745 !important;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: white !important;
  /* border:1px solid #dcdcdc; */
  background-color: #28a745 !important;
  border-radius: 8px !important;
}
/* .navbar-dark .navbar-nav .nav-link {
    color: white !important;
}
.navbar-dark .navbar-nav .nav-link:hover {
    color: black !important;
} */
/* .dropdown{
      border:1px solid #dcdcdc;
      border-radius: 8px !important;
}
.dropdown:hover{
      border:1px solid #dcdcdc;
      background-color: #28a745;
      color:black !important;
      border-radius: 8px !important;
}

.dropdown-item{
  color:white !important
}
.dropdown-menu{
  background-color: #9E9E9E !important
} */

.grow {
  transition: all 0.2s ease-in-out;
}
.grow:hover {
  transform: scale(1.1);
}
.pages li:nth-last-child(2) {
  display: none !important;
}
.pages li:first-child {
  display: none !important;
}
.pages li:nth-last-child(1) {
  display: none !important;
}
.ant-pagination-item-container {
  border: 1px solid #dcdcdc !important;
}
.search-drop:hover {
  background: #28a745;
  color: white;
}
.ant-calendar-picker-input {
  min-height: 38px !important;
  border-radius: 0px !important;
}
.ant-calendar-picker span i {
  display: none !important;
}
.ant-input {
  padding: 0px !important;
}

.post .card-header {
  background-color: #28a745 !important;
  color: white;
  padding: 0rem !important;
}
@media (min-width: 1200px) {
  .temp-cont {
    max-width: 1280px !important;
  }
}

body {
  overflow-x: hidden !important;
  text-align: center !important;
}

.bgoverlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: white;
  overflow-y: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}
.cursor-pointer {
  cursor: pointer;
}
/* #myNav{width :100%} */
</style>
