<template>
  <b-container
    fluid
    class="main-landing-back pr-0 pl-0"
    style="min-height: 500px; position: relative"
  >
    <!-- <testTopNav /> -->
    <b-container class="mb-5">
      <h4 class="text-white">DISEASE DIAGNOSTICS BY QURBANI APP</h4>
    </b-container>
    <b-container>
      <b-card>
        <b-img src="/img/diseases/cover.png" fluid></b-img>
        <b-row>
          <b-col cols="12" md="4" class="text-md-right text-center">
            <b-img
              @click="active='sheep'"
              style="box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px"
              src="/img/diseases/sheep.png"
              class="cursor-pointer grow"
              fluid
            ></b-img>
          </b-col>

          <b-col cols="12" md="4">
            <b-img
              @click="active='goat'"

              style="box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px"
              src="/img/diseases/goat.png"
              class="cursor-pointer grow"
              fluid
            ></b-img>
          </b-col>

          <b-col cols="12" md="4" class="text-md-left text-center">
            <b-img
              @click="active='cow'"

              style="box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px"
              src="/img/diseases/cow.png"
              class="cursor-pointer grow"
              fluid
            ></b-img>
          </b-col>
        </b-row>
        <!-- <b-card> -->
        <div class="accordion mt-3" role="tablist text-left">
          <div v-if="active=='sheep'">
          <b-card no-body class="p-2 mb-1 text-left">
            <b-card-header
              style="
                background: #fdffb0 !important;
                border-top-right-radius: 1rem;
                border-top-left-radius: 1rem;
              "
              header-tag="header"
              class="disease p-1"
              role="tab"
            >
              <b-button
                block
                variant="info"
                @click="visible = !visible"
                style="background: #fdffb0 !important"
                class="text-left"
                >Hypocalcaemia

                <span class="float-right">
                  <i
                    v-if="visible == false"
                    class="fa fa-plus"
                    aria-hidden="true"
                  ></i>
                  <i v-else class="fa fa-minus" aria-hidden="true"></i>
                </span>
              </b-button>
            </b-card-header>
            <b-collapse
              id="accordion-1"
              v-model="visible"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body style="background: #eeeded !important">
                <b-tabs content-class="mt-3">
                  <b-tab title="Species" active>
                    <p>All</p>
                  </b-tab>
                  <b-tab title="Description" active>
                    <p>
                      Hepatic encephalopathy refers to the nervous disease that
                      follows severe liver damage. The liver has a large reserve
                      capacity meaning that loss of three-quarters of liver
                      capacity generally must occur before signs of liver
                      dysfunction appear. As liver function reduces following
                      extensive liver damage, toxic metabolites including
                      ammonia that are normally detoxified by the liver, will
                      accumulate in blood and tissues. Accumulation of ammonia
                      and other toxins may then reach levels that cause
                      deterioration of brain function. Liver injury may occur in
                      animals ingesting toxic plants that contain pyrrolizidine
                      alkaloids such as heliotrope (Heliotropeum europeum) and
                      Paterson’s curse (Echium plantagineum), or fungal-infected
                      lupin stubbles that produce phomopsin. The liver damage
                      may be exacerbated in the export process if animals
                      consume feed (pasture, hay or pellets) contaminated with
                      hepatotoxic plants, or drink from water sources containing
                      blue-green algae.
                    </p>
                  </b-tab>

                  <b-tab title="Other Names"><p>Milk Fever</p></b-tab>
                  <b-tab title="Diagnosis"
                    ><p>
                      Affected animals will be dull, appear to be blind,
                      stagger, and exhibit head pressing before becoming
                      comatose and dying.
                    </p></b-tab
                  >
                  <b-tab title="Treatment"
                    ><p>
                      There is no effective treatment. Regeneration of liver can
                      occur, but only after a long delay. Severely affected
                      animals should be humanely euthanased.
                    </p></b-tab
                  >
                  <b-tab title="Prevention"
                    ><p>
                      Hepatic encephalopathy refers to the nervous disease that
                      follows severe liver damage. The liver has a large reserve
                      capacity meaning that loss of three-quarters of liver
                      capacity generally must occur before signs of liver
                      dysfunction appear. As liver function reduces following
                      extensive liver damage, toxic metabolites including
                      ammonia that are normally detoxified by the liver, will
                      accumulate in blood and tissues. Accumulation of ammonia
                      and other toxins may then reach levels that cause
                      deterioration of brain function. Liver injury may occur in
                      animals ingesting toxic plants that contain pyrrolizidine
                      alkaloids such as heliotrope (Heliotropeum europeum) and
                      Paterson’s curse (Echium plantagineum), or fungal-infected
                      lupin stubbles that produce phomopsin. The liver damage
                      may be exacerbated in the export process if animals
                      consume feed (pasture, hay or pellets) contaminated with
                      hepatotoxic plants, or drink from water sources containing
                      blue-green algae.
                    </p></b-tab
                  >
                  <b-tab title="Syndrome"> Nervous Disease </b-tab>
                </b-tabs>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="p-2 mb-1 text-left">
            <b-card-header
              style="
                background: #fdffb0 !important;
                border-top-right-radius: 1rem;
                border-top-left-radius: 1rem;
              "
              header-tag="header"
              class="disease p-1"
              role="tab"
            >
              <b-button
                block
                variant="info"
                @click="visible1 = !visible1"
                style="background: #fdffb0 !important"
                class="text-left"
                >Hepatic Encephalopathy

                <span class="float-right">
                  <i
                    v-if="visible1 == false"
                    class="fa fa-plus"
                    aria-hidden="true"
                  ></i>
                  <i v-else class="fa fa-minus" aria-hidden="true"></i>
                </span>
              </b-button>
            </b-card-header>
            <b-collapse
              v-model="visible1"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body style="background: #eeeded !important">
                <b-tabs content-class="mt-3">
                  <b-tab title="Species" active>
                    <p>All</p>
                  </b-tab>
                  <b-tab title="Description" active>
                    <p>
                      Hypocalcaemia is low blood calcium. Calcium is essential
                      for many body functions especially muscle contraction,
                      nerve conductivity and bone strength. Maintenance of
                      normal blood concentrations is achieved by a combination
                      of release from bone stores and absorption of dietary
                      calcium from the gut.
                    </p>
                  </b-tab>

                  <b-tab title="Other Names"><p>Milk Fever</p></b-tab>
                  <b-tab title="Diagnosis"
                    ><p>
                      Early signs include excitement and muscle tremors followed
                      by staggering gait, recumbency, paralysis, coma and death.
                      There is no fever.
                    </p></b-tab
                  >
                  <b-tab title="Treatment"
                    ><p>
                      Administer injectable calcium and magnesium solutions
                      under the skin over the ribs. Massage well to speed
                      absorption. Commercial solutions of calcium borogluconate
                      are commonly used and should be warmed to body temperature
                      before injection. A response is expected in a few minutes
                      (burping, muscle tremors, voluntary movements) with full
                      recovery usually within an hour. Treatment occasionally
                      needs to be repeated. It is fatal if left untreated.
                      Careful intravenous administration of calcium solutions
                      may be considered for severe cases.
                    </p></b-tab
                  >
                  <b-tab title="Prevention"
                    ><p>
                      Avoid selecting old, over-fat and pregnant females for
                      export. Avoid interruptions to food and water
                      availability. Feed roughage as hay, chaff or pellets high
                      in fibre (to increase dietary intake and gut absorption of
                      calcium) in high risk animals or conditions.
                    </p></b-tab
                  >
                  <b-tab title="Syndrome"> Nervous Disease </b-tab>
                </b-tabs>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="p-2 mb-1 text-left">
            <b-card-header
              style="
                background: #fdffb0 !important;
                border-top-right-radius: 1rem;
                border-top-left-radius: 1rem;
              "
              header-tag="header"
              class="disease p-1"
              role="tab"
            >
              <b-button
                block
                variant="info"
                @click="visible3 = !visible3"
                style="background: #fdffb0 !important"
                class="text-left"
                >Illthrift
                <span class="float-right">
                  <i
                    v-if="visible3 == false"
                    class="fa fa-plus"
                    aria-hidden="true"
                  ></i>
                  <i v-else class="fa fa-minus" aria-hidden="true"></i>
                </span>
              </b-button>
            </b-card-header>
            <b-collapse
              v-model="visible3"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body style="background: #eeeded !important">
                <b-tabs content-class="mt-3">
                  <b-tab title="Species" active>
                    <p>All</p>
                  </b-tab>
                  <b-tab title="Description" active>
                    <p>
                      Hypocalcaemia is low blood calcium. Calcium is essential
                      for many body functions especially muscle contraction,
                      nerve conductivity and bone strength. Maintenance of
                      normal blood concentrations is achieved by a combination
                      of release from bone stores and absorption of dietary
                      calcium from the gut.
                    </p>
                  </b-tab>

                  <b-tab title="Other Names"><p>Milk Fever</p></b-tab>
                  <b-tab title="Diagnosis"
                    ><p>
                      Early signs include excitement and muscle tremors followed
                      by staggering gait, recumbency, paralysis, coma and death.
                      There is no fever.
                    </p></b-tab
                  >
                  <b-tab title="Treatment"
                    ><p>
                      Administer injectable calcium and magnesium solutions
                      under the skin over the ribs. Massage well to speed
                      absorption. Commercial solutions of calcium borogluconate
                      are commonly used and should be warmed to body temperature
                      before injection. A response is expected in a few minutes
                      (burping, muscle tremors, voluntary movements) with full
                      recovery usually within an hour. Treatment occasionally
                      needs to be repeated. It is fatal if left untreated.
                      Careful intravenous administration of calcium solutions
                      may be considered for severe cases.
                    </p></b-tab
                  >
                  <b-tab title="Prevention"
                    ><p>
                      Avoid selecting old, over-fat and pregnant females for
                      export. Avoid interruptions to food and water
                      availability. Feed roughage as hay, chaff or pellets high
                      in fibre (to increase dietary intake and gut absorption of
                      calcium) in high risk animals or conditions.
                    </p></b-tab
                  >
                  <b-tab title="Syndrome"> Nervous Disease </b-tab>
                </b-tabs>
              </b-card-body>
            </b-collapse>
          </b-card>
            
          </div>

        <div v-if="active=='goat'">

         <b-card no-body class="p-2 mb-1 text-left">
            <b-card-header
              style="
                background: #fdffb0 !important;
                border-top-right-radius: 1rem;
                border-top-left-radius: 1rem;
              "
              header-tag="header"
              class="disease p-1"
              role="tab"
            >
              <b-button
                block
                variant="info"
                @click="visible4 = !visible4"
                style="background: #fdffb0 !important"
                class="text-left"
                >Indigestion
                <span class="float-right">
                  <i
                    v-if="visible4 == false"
                    class="fa fa-plus"
                    aria-hidden="true"
                  ></i>
                  <i v-else class="fa fa-minus" aria-hidden="true"></i>
                </span>
              </b-button>
            </b-card-header>
            <b-collapse
              v-model="visible4"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body style="background: #eeeded !important">
                <b-tabs content-class="mt-3">
                  <b-tab title="Species" active>
                    <p>All. Sheep are the main species of interest.</p>
                  </b-tab>
                  <b-tab title="Description" active>
                    <p>
                      Inappetence refers to a reduction in appetite and feed
                      intake (synonymous with shy feeders).
                    </p>
                  </b-tab>

                  <b-tab title="Other Names"><p>Milk Fever</p></b-tab>
                  <b-tab title="Diagnosis"
                    ><p>
                      Early signs include excitement and muscle tremors followed
                      by staggering gait, recumbency, paralysis, coma and death.
                      There is no fever.
                    </p></b-tab
                  >
                  <b-tab title="Treatment"
                    ><p>
                      Administer injectable calcium and magnesium solutions
                      under the skin over the ribs. Massage well to speed
                      absorption. Commercial solutions of calcium borogluconate
                      are commonly used and should be warmed to body temperature
                      before injection. A response is expected in a few minutes
                      (burping, muscle tremors, voluntary movements) with full
                      recovery usually within an hour. Treatment occasionally
                      needs to be repeated. It is fatal if left untreated.
                      Careful intravenous administration of calcium solutions
                      may be considered for severe cases.
                    </p></b-tab
                  >
                  <b-tab title="Prevention"
                    ><p>
                      Avoid selecting old, over-fat and pregnant females for
                      export. Avoid interruptions to food and water
                      availability. Feed roughage as hay, chaff or pellets high
                      in fibre (to increase dietary intake and gut absorption of
                      calcium) in high risk animals or conditions.
                    </p></b-tab
                  >
                  <b-tab title="Syndrome"> Nervous Disease </b-tab>
                </b-tabs>
              </b-card-body>
            </b-collapse>
          </b-card> 
        <b-card no-body class="p-2 mb-1 text-left">
            <b-card-header
              style="
                background: #fdffb0 !important;
                border-top-right-radius: 1rem;
                border-top-left-radius: 1rem;
              "
              header-tag="header"
              class="disease p-1"
              role="tab"
            >
              <b-button
                block
                variant="info"
                @click="visible5 = !visible5"
                style="background: #fdffb0 !important"
                class="text-left"
                >Pinkeye
                <span class="float-right">
                  <i
                    v-if="visible5 == false"
                    class="fa fa-plus"
                    aria-hidden="true"
                  ></i>
                  <i v-else class="fa fa-minus" aria-hidden="true"></i>
                </span>
              </b-button>
            </b-card-header>
            <b-collapse
              v-model="visible5"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body style="background: #eeeded !important">
                <b-tabs content-class="mt-3">
                  <b-tab title="Species" active>
                    <p>All. Sheep are the main species of interest.</p>
                  </b-tab>
                  <b-tab title="Description" active>
                    <p>
                      Inappetence refers to a reduction in appetite and feed
                      intake (synonymous with shy feeders).
                    </p>
                  </b-tab>

                  <b-tab title="Other Names"><p>Milk Fever</p></b-tab>
                  <b-tab title="Diagnosis"
                    ><p>
                      Early signs include excitement and muscle tremors followed
                      by staggering gait, recumbency, paralysis, coma and death.
                      There is no fever.
                    </p></b-tab
                  >
                  <b-tab title="Treatment"
                    ><p>
                      Administer injectable calcium and magnesium solutions
                      under the skin over the ribs. Massage well to speed
                      absorption. Commercial solutions of calcium borogluconate
                      are commonly used and should be warmed to body temperature
                      before injection. A response is expected in a few minutes
                      (burping, muscle tremors, voluntary movements) with full
                      recovery usually within an hour. Treatment occasionally
                      needs to be repeated. It is fatal if left untreated.
                      Careful intravenous administration of calcium solutions
                      may be considered for severe cases.
                    </p></b-tab
                  >
                  <b-tab title="Prevention"
                    ><p>
                      Avoid selecting old, over-fat and pregnant females for
                      export. Avoid interruptions to food and water
                      availability. Feed roughage as hay, chaff or pellets high
                      in fibre (to increase dietary intake and gut absorption of
                      calcium) in high risk animals or conditions.
                    </p></b-tab
                  >
                  <b-tab title="Syndrome"> Nervous Disease </b-tab>
                </b-tabs>
              </b-card-body>
            </b-collapse>
          </b-card> 
        <b-card no-body class="p-2 mb-1 text-left">
            <b-card-header
              style="
                background: #fdffb0 !important;
                border-top-right-radius: 1rem;
                border-top-left-radius: 1rem;
              "
              header-tag="header"
              class="disease p-1"
              role="tab"
            >
              <b-button
                block
                variant="info"
                @click="visible6 = !visible6"
                style="background: #fdffb0 !important"
                class="text-left"
                >Monensin Poisoning
                <span class="float-right">
                  <i
                    v-if="visible6 == false"
                    class="fa fa-plus"
                    aria-hidden="true"
                  ></i>
                  <i v-else class="fa fa-minus" aria-hidden="true"></i>
                </span>
              </b-button>
            </b-card-header>
            <b-collapse
              v-model="visible6"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body style="background: #eeeded !important">
                <b-tabs content-class="mt-3">
                  <b-tab title="Species" active>
                    <p>All. Sheep are the main species of interest.</p>
                  </b-tab>
                  <b-tab title="Description" active>
                    <p>
                      Inappetence refers to a reduction in appetite and feed
                      intake (synonymous with shy feeders).
                    </p>
                  </b-tab>

                  <b-tab title="Other Names"><p>Milk Fever</p></b-tab>
                  <b-tab title="Diagnosis"
                    ><p>
                      Early signs include excitement and muscle tremors followed
                      by staggering gait, recumbency, paralysis, coma and death.
                      There is no fever.
                    </p></b-tab
                  >
                  <b-tab title="Treatment"
                    ><p>
                      Administer injectable calcium and magnesium solutions
                      under the skin over the ribs. Massage well to speed
                      absorption. Commercial solutions of calcium borogluconate
                      are commonly used and should be warmed to body temperature
                      before injection. A response is expected in a few minutes
                      (burping, muscle tremors, voluntary movements) with full
                      recovery usually within an hour. Treatment occasionally
                      needs to be repeated. It is fatal if left untreated.
                      Careful intravenous administration of calcium solutions
                      may be considered for severe cases.
                    </p></b-tab
                  >
                  <b-tab title="Prevention"
                    ><p>
                      Avoid selecting old, over-fat and pregnant females for
                      export. Avoid interruptions to food and water
                      availability. Feed roughage as hay, chaff or pellets high
                      in fibre (to increase dietary intake and gut absorption of
                      calcium) in high risk animals or conditions.
                    </p></b-tab
                  >
                  <b-tab title="Syndrome"> Nervous Disease </b-tab>
                </b-tabs>
              </b-card-body>
            </b-collapse>
          </b-card> 
        </div>

        <div v-if="active=='cow'">

        <b-card no-body class="p-2 mb-1 text-left">
            <b-card-header
              style="
                background: #fdffb0 !important;
                border-top-right-radius: 1rem;
                border-top-left-radius: 1rem;
              "
              header-tag="header"
              class="disease p-1"
              role="tab"
            >
              <b-button
                block
                variant="info"
                @click="visible7 = !visible7"
                style="background: #fdffb0 !important"
                class="text-left"
                >Johne's Disease
                <span class="float-right">
                  <i
                    v-if="visible7 == false"
                    class="fa fa-plus"
                    aria-hidden="true"
                  ></i>
                  <i v-else class="fa fa-minus" aria-hidden="true"></i>
                </span>
              </b-button>
            </b-card-header>
            <b-collapse
              v-model="visible7"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body style="background: #eeeded !important">
                <b-tabs content-class="mt-3">
                  <b-tab title="Species" active>
                    <p>All. Sheep are the main species of interest.</p>
                  </b-tab>
                  <b-tab title="Description" active>
                    <p>
                      Inappetence refers to a reduction in appetite and feed
                      intake (synonymous with shy feeders).
                    </p>
                  </b-tab>

                  <b-tab title="Other Names"><p>Milk Fever</p></b-tab>
                  <b-tab title="Diagnosis"
                    ><p>
                      Early signs include excitement and muscle tremors followed
                      by staggering gait, recumbency, paralysis, coma and death.
                      There is no fever.
                    </p></b-tab
                  >
                  <b-tab title="Treatment"
                    ><p>
                      Administer injectable calcium and magnesium solutions
                      under the skin over the ribs. Massage well to speed
                      absorption. Commercial solutions of calcium borogluconate
                      are commonly used and should be warmed to body temperature
                      before injection. A response is expected in a few minutes
                      (burping, muscle tremors, voluntary movements) with full
                      recovery usually within an hour. Treatment occasionally
                      needs to be repeated. It is fatal if left untreated.
                      Careful intravenous administration of calcium solutions
                      may be considered for severe cases.
                    </p></b-tab
                  >
                  <b-tab title="Prevention"
                    ><p>
                      Avoid selecting old, over-fat and pregnant females for
                      export. Avoid interruptions to food and water
                      availability. Feed roughage as hay, chaff or pellets high
                      in fibre (to increase dietary intake and gut absorption of
                      calcium) in high risk animals or conditions.
                    </p></b-tab
                  >
                  <b-tab title="Syndrome"> Nervous Disease </b-tab>
                </b-tabs>
              </b-card-body>
            </b-collapse>
          </b-card> 
        <b-card no-body class="p-2 mb-1 text-left">
            <b-card-header
              style="
                background: #fdffb0 !important;
                border-top-right-radius: 1rem;
                border-top-left-radius: 1rem;
              "
              header-tag="header"
              class="disease p-1"
              role="tab"
            >
              <b-button
                block
                variant="info"
                @click="visible8 = !visible8"
                style="background: #fdffb0 !important"
                class="text-left"
                >Gastrocnemius Muscle Rupture
                <span class="float-right">
                  <i
                    v-if="visible8 == false"
                    class="fa fa-plus"
                    aria-hidden="true"
                  ></i>
                  <i v-else class="fa fa-minus" aria-hidden="true"></i>
                </span>
              </b-button>
            </b-card-header>
            <b-collapse
              v-model="visible8"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body style="background: #eeeded !important">
                <b-tabs content-class="mt-3">
                  <b-tab title="Species" active>
                    <p>All. Sheep are the main species of interest.</p>
                  </b-tab>
                  <b-tab title="Description" active>
                    <p>
                      Inappetence refers to a reduction in appetite and feed
                      intake (synonymous with shy feeders).
                    </p>
                  </b-tab>

                  <b-tab title="Other Names"><p>Milk Fever</p></b-tab>
                  <b-tab title="Diagnosis"
                    ><p>
                      Early signs include excitement and muscle tremors followed
                      by staggering gait, recumbency, paralysis, coma and death.
                      There is no fever.
                    </p></b-tab
                  >
                  <b-tab title="Treatment"
                    ><p>
                      Administer injectable calcium and magnesium solutions
                      under the skin over the ribs. Massage well to speed
                      absorption. Commercial solutions of calcium borogluconate
                      are commonly used and should be warmed to body temperature
                      before injection. A response is expected in a few minutes
                      (burping, muscle tremors, voluntary movements) with full
                      recovery usually within an hour. Treatment occasionally
                      needs to be repeated. It is fatal if left untreated.
                      Careful intravenous administration of calcium solutions
                      may be considered for severe cases.
                    </p></b-tab
                  >
                  <b-tab title="Prevention"
                    ><p>
                      Avoid selecting old, over-fat and pregnant females for
                      export. Avoid interruptions to food and water
                      availability. Feed roughage as hay, chaff or pellets high
                      in fibre (to increase dietary intake and gut absorption of
                      calcium) in high risk animals or conditions.
                    </p></b-tab
                  >
                  <b-tab title="Syndrome"> Nervous Disease </b-tab>
                </b-tabs>
              </b-card-body>
            </b-collapse>
          </b-card> 
        <b-card no-body class="p-2 mb-1 text-left">
            <b-card-header
              style="
                background: #fdffb0 !important;
                border-top-right-radius: 1rem;
                border-top-left-radius: 1rem;
              "
              header-tag="header"
              class="disease p-1"
              role="tab"
            >
              <b-button
                block
                variant="info"
                @click="visible9 = !visible9"
                style="background: #fdffb0 !important"
                class="text-left"
                >Knuckling
                <span class="float-right">
                  <i
                    v-if="visible9 == false"
                    class="fa fa-plus"
                    aria-hidden="true"
                  ></i>
                  <i v-else class="fa fa-minus" aria-hidden="true"></i>
                </span>
              </b-button>
            </b-card-header>
            <b-collapse
              v-model="visible9"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body style="background: #eeeded !important">
                <b-tabs content-class="mt-3">
                  <b-tab title="Species" active>
                    <p>All. Sheep are the main species of interest.</p>
                  </b-tab>
                  <b-tab title="Description" active>
                    <p>
                      Inappetence refers to a reduction in appetite and feed
                      intake (synonymous with shy feeders).
                    </p>
                  </b-tab>

                  <b-tab title="Other Names"><p>Milk Fever</p></b-tab>
                  <b-tab title="Diagnosis"
                    ><p>
                      Early signs include excitement and muscle tremors followed
                      by staggering gait, recumbency, paralysis, coma and death.
                      There is no fever.
                    </p></b-tab
                  >
                  <b-tab title="Treatment"
                    ><p>
                      Administer injectable calcium and magnesium solutions
                      under the skin over the ribs. Massage well to speed
                      absorption. Commercial solutions of calcium borogluconate
                      are commonly used and should be warmed to body temperature
                      before injection. A response is expected in a few minutes
                      (burping, muscle tremors, voluntary movements) with full
                      recovery usually within an hour. Treatment occasionally
                      needs to be repeated. It is fatal if left untreated.
                      Careful intravenous administration of calcium solutions
                      may be considered for severe cases.
                    </p></b-tab
                  >
                  <b-tab title="Prevention"
                    ><p>
                      Avoid selecting old, over-fat and pregnant females for
                      export. Avoid interruptions to food and water
                      availability. Feed roughage as hay, chaff or pellets high
                      in fibre (to increase dietary intake and gut absorption of
                      calcium) in high risk animals or conditions.
                    </p></b-tab
                  >
                  <b-tab title="Syndrome"> Nervous Disease </b-tab>
                </b-tabs>
              </b-card-body>
            </b-collapse>
          </b-card> 

        </div>
          <!-- <b-card no-body class="p-2 mb-1 text-left">
            <b-card-header
              style="
                background: #fdffb0 !important;
                border-top-right-radius: 1rem;
                border-top-left-radius: 1rem;
              "
              header-tag="header"
              class="disease p-1"
              role="tab"
            >
              <b-button
                block
                variant="info"
                @click="visible5 = !visible5"
                style="background: #fdffb0 !important"
                class="text-left"
                >Inappetence
                <span class="float-right">
                  <i
                    v-if="visible5 == false"
                    class="fa fa-plus"
                    aria-hidden="true"
                  ></i>
                  <i v-else class="fa fa-minus" aria-hidden="true"></i>
                </span>
              </b-button>
            </b-card-header>
            <b-collapse
              v-model="visible5"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body style="background: #eeeded !important">
                <b-tabs content-class="mt-3">
                  <b-tab title="Species" active>
                    <p>All. Sheep are the main species of interest.</p>
                  </b-tab>
                  <b-tab title="Description" active>
                    <p>
                      Inappetence refers to a reduction in appetite and feed
                      intake (synonymous with shy feeders).
                    </p>
                  </b-tab>

                  <b-tab title="Other Names"><p>Milk Fever</p></b-tab>
                  <b-tab title="Diagnosis"
                    ><p>
                      Early signs include excitement and muscle tremors followed
                      by staggering gait, recumbency, paralysis, coma and death.
                      There is no fever.
                    </p></b-tab
                  >
                  <b-tab title="Treatment"
                    ><p>
                      Administer injectable calcium and magnesium solutions
                      under the skin over the ribs. Massage well to speed
                      absorption. Commercial solutions of calcium borogluconate
                      are commonly used and should be warmed to body temperature
                      before injection. A response is expected in a few minutes
                      (burping, muscle tremors, voluntary movements) with full
                      recovery usually within an hour. Treatment occasionally
                      needs to be repeated. It is fatal if left untreated.
                      Careful intravenous administration of calcium solutions
                      may be considered for severe cases.
                    </p></b-tab
                  >
                  <b-tab title="Prevention"
                    ><p>
                      Avoid selecting old, over-fat and pregnant females for
                      export. Avoid interruptions to food and water
                      availability. Feed roughage as hay, chaff or pellets high
                      in fibre (to increase dietary intake and gut absorption of
                      calcium) in high risk animals or conditions.
                    </p></b-tab
                  >
                  <b-tab title="Syndrome"> Nervous Disease </b-tab>
                </b-tabs>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="p-2 mb-1 text-left">
            <b-card-header
              style="
                background: #fdffb0 !important;
                border-top-right-radius: 1rem;
                border-top-left-radius: 1rem;
              "
              header-tag="header"
              class="disease p-1"
              role="tab"
            >
              <b-button
                block
                variant="info"
                @click="visible5 = !visible5"
                style="background: #fdffb0 !important"
                class="text-left"
                >Inappetence
                <span class="float-right">
                  <i
                    v-if="visible5 == false"
                    class="fa fa-plus"
                    aria-hidden="true"
                  ></i>
                  <i v-else class="fa fa-minus" aria-hidden="true"></i>
                </span>
              </b-button>
            </b-card-header>
            <b-collapse
              v-model="visible5"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body style="background: #eeeded !important">
                <b-tabs content-class="mt-3">
                  <b-tab title="Species" active>
                    <p>All. Sheep are the main species of interest.</p>
                  </b-tab>
                  <b-tab title="Description" active>
                    <p>
                      Inappetence refers to a reduction in appetite and feed
                      intake (synonymous with shy feeders).
                    </p>
                  </b-tab>

                  <b-tab title="Other Names"><p>Milk Fever</p></b-tab>
                  <b-tab title="Diagnosis"
                    ><p>
                      Early signs include excitement and muscle tremors followed
                      by staggering gait, recumbency, paralysis, coma and death.
                      There is no fever.
                    </p></b-tab
                  >
                  <b-tab title="Treatment"
                    ><p>
                      Administer injectable calcium and magnesium solutions
                      under the skin over the ribs. Massage well to speed
                      absorption. Commercial solutions of calcium borogluconate
                      are commonly used and should be warmed to body temperature
                      before injection. A response is expected in a few minutes
                      (burping, muscle tremors, voluntary movements) with full
                      recovery usually within an hour. Treatment occasionally
                      needs to be repeated. It is fatal if left untreated.
                      Careful intravenous administration of calcium solutions
                      may be considered for severe cases.
                    </p></b-tab
                  >
                  <b-tab title="Prevention"
                    ><p>
                      Avoid selecting old, over-fat and pregnant females for
                      export. Avoid interruptions to food and water
                      availability. Feed roughage as hay, chaff or pellets high
                      in fibre (to increase dietary intake and gut absorption of
                      calcium) in high risk animals or conditions.
                    </p></b-tab
                  >
                  <b-tab title="Syndrome"> Nervous Disease </b-tab>
                </b-tabs>
              </b-card-body>
            </b-collapse>
          </b-card> -->







          <!-- <b-card no-body class="p-2 mb-1 text-left">
            <b-card-header header-tag="header" class="p-1 disease" role="tab">
              <b-button
                block
                v-b-toggle.accordion-2
                variant="info"
                class="text-left"
                >Hypocalcaemia</b-button
              >
            </b-card-header>
            <b-collapse
              id="accordion-2"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>
                  <span class="">Other Names</span> <br />
                  <br />
                  Milk Fever<br />
                  <br />
                  <span class="">Species</span> <br />
                  <br />
                  All.<br />
                  <br />
                  <span class="">Description</span> <br />
                  <br />
                  Hypocalcaemia is low blood calcium. Calcium is essential for
                  many body functions especially muscle contraction, nerve
                  conductivity and bone strength. Maintenance of normal blood
                  concentrations is achieved by a combination of release from
                  bone stores and absorption of dietary calcium from the gut.<br />
                  <br />
                  <br />
                  <br />
                  Animals are at risk of hypocalcaemia when:<br />
                  <br />
                  Bone stores are depleted - may occur with long term grain
                  feeding or vitamin D deficiency (seen in low sunlight, vitamin
                  A-rich grazing conditions of southern Australian winters).<br />
                  <br />
                  Sudden reduction in intake and absorption from the gut -
                  fasting associated with transport or yarding, sudden dietary
                  changes, sudden climatic changes (hot or cold), and ingestion
                  of plants containing oxalate.<br />
                  <br />
                  Mobilisation from bones is too slow to meet an increase in
                  demand - may occur in older, fatter animals.<br />
                  <br />
                  Animals have high demand for calcium such as in early
                  lactation and pregnant animals especially those with multiple
                  foetuses.<br />
                  <br />
                  The first three situations might occur in assembly points, the
                  fourth at destination.<br />
                  <br />
                  <span class="">Diagnosis</span> <br />
                  <br />
                  Early signs include excitement and muscle tremors followed by
                  staggering gait, recumbency, paralysis, coma and death. There
                  is no fever.<br />
                  <br />
                  <br />
                  <br />
                  Diagnosis is based on clinical signs, presence of risk factors
                  and response to treatment. Fragility of bones and sometimes
                  healing rib fractures may be present in sheep with prolonged
                  dietary deficiency. Blood samples will have low calcium
                  concentrations.<br />
                  <br />
                  <br />
                  <br />
                  Before kidding/lambing/calving, pregnancy toxaemia is the main
                  differential diagnosis. Some animals have both disease. In
                  pregnancy toxaemia, the onset is gradual over a few days and
                  response to calcium is slight and temporary.<br />
                  <br />
                  <br />
                  <br />
                  Laboratory confirmation requires 2 mL of serum submitted
                  chilled for calcium and magnesium estimation. In dead animals
                  submit aqueous humor for calcium estimation, taking care to
                  minimise cellular contamination from the iris.<br />
                  <br />
                  <span class="">Treatment</span> <br />
                  <br />
                  Administer injectable calcium and magnesium solutions under
                  the skin over the ribs. Massage well to speed absorption.
                  Commercial solutions of calcium borogluconate are commonly
                  used and should be warmed to body temperature before
                  injection. A response is expected in a few minutes (burping,
                  muscle tremors, voluntary movements) with full recovery
                  usually within an hour. Treatment occasionally needs to be
                  repeated. It is fatal if left untreated. Careful intravenous
                  administration of calcium solutions may be considered for
                  severe cases.<br />
                  <br />
                  <span class=""> Prevention</span><br />
                  <br />
                  Avoid selecting old, over-fat and pregnant females for export.
                  Avoid interruptions to food and water availability. Feed
                  roughage as hay, chaff or pellets high in fibre (to increase
                  dietary intake and gut absorption of calcium) in high risk
                  animals or conditions.<br />
                  <br />
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="p-2 mb-1 text-left">
            <b-card-header header-tag="header" class="p-1 disease" role="tab">
              <b-button
                block
                v-b-toggle.accordion-3
                variant="info"
                class="text-left"
                >Illthrift</b-button
              >
            </b-card-header>
            <b-collapse
              id="accordion-3"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Other Names</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Poor Doers, Wasting, Weight Loss</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Species</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">All.</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Description</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Illthrift is the loss of body condition in the presence of
                    ample feed. Illthrift may be the end result of a wide range
                    of different causes including parasitism (of blood, skin or
                    gut), infectious disease, specific nutrient deficiencies
                    such as copper, chronic conditions causing organ dysfunction
                    or toxaemia, and conditions causing pain. Contributing
                    causes may be difficult to diagnose such as indigestion,
                    mild ruminal acidosis, depletion of rumen microflora,
                    abomasal ulcer, pneumonia, liver abscess, local peritonitis,
                    and mild laminitis in all four feet. Single or multiple
                    animals may be affected. It is common for some cattle under
                    shipboard conditions to have greater difficulty than pen
                    mates in adapting to the unfamiliar environment, the type of
                    feed and water and the way it is offered. Animals with
                    persistent infection with pestivirus are usually excluded
                    from the export process by testing or visual inspection or
                    both.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Diagnosis</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    During pen inspection, a flank scan may detect affected
                    animals due to a reduction in size of the abdomen (i.e.
                    hollow flanks and a tucked up posture), and wasting of
                    muscles of the back and rump regions. Different hair length,
                    lustre, density and colour may stand out. Cattle may still
                    appear healthy and bright.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >The most likely causes of illthrift in shipboard cattle
                    include:</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Shy feeders.</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >Chronic toxemia or pain associated with conditions such as
                    pneumonia, pleurisy, rumenitis, liver abscessation and
                    abomasal ulceration.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Necropsy of unresponsive cases may reveal muscle wasting,
                    empty rumen, depleted fat reserves and serous atrophy
                    (jellying) of fat deposits especially mesenteric, renal and
                    bone marrow fat. The pancreas, liver and gall bladder may be
                    atrophied.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    The choice of specimens for laboratory differentiation will
                    be dictated by field observations. They may include faeces
                    for parasitology, blood samples to test for trace element
                    deficiencies, and samples from a range of body organs,
                    including fresh sections for bacteriology and virology, and
                    formalin-fixed sections for histology.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Treatment</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Treatment will depend on the diagnosis. Animals with
                    suspected infectious or painful conditions may be treated
                    with antibiotics (procaine penicillin, erythromycin,
                    ceftiofur, or oxytetracycline) and non-steroidal
                    anti-inflammatory drugs (flunixin meglumine, ketoprofen,
                    meloxicam, or tolfenamic acid). Response to initial therapy
                    may be used to guide further treatment. It is possible that
                    long and costly courses of treatment may be required to
                    achieve improvement, and relapse may occur upon withdrawal
                    of treatment.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Prevention</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >Preventative measures depend on a good understanding of and
                    anticipation of risk factors likely to affect a
                    consignment.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Syndromes</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Illthrift</span>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="p-2 mb-1 text-left">
            <b-card-header header-tag="header" class="disease p-1" role="tab">
              <b-button
                block
                v-b-toggle.accordion-4
                variant="info"
                class="text-left"
                >Inappetence</b-button
              >
            </b-card-header>
            <b-collapse
              id="accordion-4"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>
                  <br />
                  Other Names<br />
                  <br />
                  Reduced Appetite, Starvation<br />
                  <br />
                  Species<br />
                  <br />
                  All. Sheep are the main species of interest.<br />
                  <br />
                  Description<br />
                  <br />
                  Inappetence refers to a reduction in appetite and feed intake
                  (synonymous with shy feeders).<br />
                  <br />
                  <br />
                  <br />
                  For more information regarding inappetence in cattle see Shy
                  Feeders.<br />
                  <br />
                  <br />
                  <br />
                  Inanition is defined as the state of exhaustion resulting from
                  lack of food and water. Inanition is used to describe deaths
                  in the export industry due to prolonged refusal of food or
                  inability to eat. Starvation refers similarly to the prolonged
                  effects of deprivation of food or food refusal. Prolonged
                  inappetence may progress to inanition and also results in
                  increased risk of other conditions, such as salmonellosis
                  causing severe and sometimes fatal infections in compromised
                  animals.<br />
                  <br />
                  <br />
                  <br />
                  Inanition and salmonellosis are the most common causes of
                  death in exported sheep and goats and may occur separately
                  (one disease resulting in death without evidence of the other)
                  or together in the same animal. The first week of a sea voyage
                  appears to present an elevated risk of salmonellosis, while
                  inanition tends to occur more in later stages of a voyage.<br />
                  <br />
                  <br />
                  <br />
                  Causal factors contributing to inappetence in sheep and goats
                  are not well described. Adult sheep that are fat, exported
                  during the second half of the year and coming from a high
                  plane of nutrition are at a higher risk. In addition, feral
                  goats appear to be at an elevated risk. Inappetence may appear
                  in individual sheep or in lines of sheep. There also appear to
                  be farm-level factors contributing to risk of inappetence such
                  that some farms may be at higher risk than others.<br />
                  <br />
                  <br />
                  <br />
                  It is presumed the stresses of transition from pasture to
                  feedlot conditions may contribute to the risk of inappetence,
                  including transport, curfew periods without feed or water, and
                  transition to water troughs and pelleted feed. The greater
                  appetite of younger, growing sheep, and sheep coming from a
                  lower plane of nutrition is thought to protect animals from
                  inappetence.<br />
                  <br />
                  <br />
                  <br />
                  Most sheep that are inappetent in the assembly feedlot will
                  start feeding during the early part of a voyage. However,
                  inappetence in the feedlot is considered to indicate a higher
                  risk of prolonged inappetence continuing during the voyage and
                  an elevated likelihood of inanition and death.<br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  Image<br />
                  <br />
                  Figure 4.2: Unified causal web describing factors influencing
                  risk of inappetence, salmonellosis and inanition in sheep
                  (W.LIVE.0132)<br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  Mortality studies in the late 1980s and early 1990s
                  (LIVE.0112, 2002) suggested that persistent inappetence was
                  the single major initiating condition that then led to
                  elevated risk of subsequent occurrence of either inanition or
                  salmonellosis (each associated with high mortality risk).
                  Further studies (LIVE.0123, 2008; W.LIV.0132, 2009) have
                  suggested that inappetence and salmonellosis may occur
                  separately or together and the initial occurrence of either
                  condition can then lead to increased risk of the other. The
                  unified causal web presented in W.LIV.0132 (2009) and depicted
                  in Figure 4.2, describes a complex pattern of causal factors
                  that may lead to one or the other condition or both. The point
                  of this is that control and prevention efforts need to be
                  directed at both inappetence and salmonellosis and not just at
                  inappetence.<br />
                  <br />
                  Diagnosis<br />
                  <br />
                  Affected animals can be difficult to identify except by
                  extended or careful observation. Animals may be noticed as not
                  pushing forward to eat at feeding time, so called &lsquo;non
                  competitors&rsquo;, and may show varying signs from hollowing
                  of the left flanks to obvious emaciation.<br />
                  <br />
                  <br />
                  <br />
                  At post-mortem the rumen is shrunken, containing light
                  grey-coloured fluid (due to the loss of rumen microflora and
                  lack of ingesta), and very little food material. The lining,
                  normally papillated, will be smooth. The intestines are empty.
                  The gall bladder may be enlarged, and there may be
                  accumulation of fat in the liver, especially in fatter
                  sheep.<br />
                  <br />
                  <br />
                  <br />
                  Specimens for laboratory determination include serum for beta
                  hydroxybutyrate estimation and heart, liver, kidney, pancreas,
                  bone marrow and long bone diaphysis in buffered formalin for
                  histology.<br />
                  <br />
                  Treatment<br />
                  <br />
                  Treatment is symptomatic and may not always be successful.
                  Early recognition, separation, provision of adequate trough
                  and floor space, and feeding of high quality chaff or fresh
                  hay may encourage eating. Where multiple animals in a mob
                  appear to be inappetent during the assembly feedlot, adding or
                  resuming addition of chaff or hay to a pelleted diet may
                  encourage animals to eat and facilitate transition to eating
                  pellets in preparation for loading onto an export vessel.<br />
                  <br />
                  Prevention<br />
                  <br />
                  Ensure healthy livestock are sourced for export. Avoid stress
                  or prolonged periods of feed and water curfew during
                  transportation into the assembly feedlot, and while managing
                  animals in the feedlot. Provide shelter so animals can avoid
                  extreme weather during feedlotting.<br />
                  <br />
                  <br />
                  <br />
                  Provide high quality feed and water, and observe reasonable
                  standards of hygiene. Avoid pellet dust when feeding pellets,
                  particularly on board ship when pellets may be delivered
                  through automatic feeding lines. Consider adding additional
                  trough space and providing troughs in the middle of feedlot
                  paddocks and on the inside of pens on board ship, in an
                  attempt to allow shy feeders increased opportunity to access
                  feed.<br />
                  <br />
                  <br />
                  <br />
                  There is conflicting evidence over the benefits of feeding
                  sheep hay in the assembly feedlot including some evidence that
                  feeding hay may actually contribute to inappetence, or delay
                  the transition to pellets in some animals. Alternatives
                  include feeding chaff with pellets as an initial introductory
                  strategy, and introducing sheep to pellets that contain lower
                  concentrations of starch in an attempt to avoid the risk of
                  acidosis which may exacerbate inappetence.<br />
                  <br />
                  <br />
                  <br />
                  Lines of sheep that have high levels of inappetence in the
                  assembly feedlot may be pulled from the voyage because of the
                  increased risk of mortality from subsequent inanition and
                  possibly other conditions such as salmonellosis.<br />
                  <br />
                  Syndromes<br />
                  <br />
                  Illthrift</b-card-text
                >
              </b-card-body>
            </b-collapse>
          </b-card> -->
          <!-- <b-card no-body class="p-2 mb-1 text-left">
            <b-card-header header-tag="header" class="p-1 disease" role="tab">
              <b-button
                block
                v-b-toggle.accordion-5
                variant="info"
                class="text-left"
                >Indigestion</b-button
              >
            </b-card-header>
            <b-collapse
              id="accordion-5"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Species</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">All.</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Description</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    The term indigestion is used to refer to alteration in the
                    rumen environment as a result of dietary changes. The change
                    in quantity and quality of feed disrupts rumen microflora,
                    pH is altered and the result is temporary ruminal atony,
                    inappetence and change of faecal consistency.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    It is the most common cause of diarrhoea in shipboard cattle
                    and is usually associated with the sudden feed change, from
                    hay in assembly points to pellets at sea. Indigestion may
                    also result in constipation in assembly points when cattle
                    come from lush grass pastures on to coarse hay. It may also
                    occur when a new batch of hay or pellets with a different
                    concentration of nitrogen, starch or fibre are introduced
                    into the diet.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Diagnosis</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Cattle typically have fluid to watery faeces but can
                    otherwise appear healthy with a normal to slightly depressed
                    appetite. Multiple animals in more than one pen will be
                    affected. The diarrhoea usually resolves in a few to several
                    days as rumen flora adapt. The main differential diagnoses
                    are ruminal acidosis, salmonellosis or vagal indigestion.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Treatment</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >Substituting chaff or hay for part of the pellet ration
                    speeds recovery.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Prevention</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >Avoid uneven feeding regimes and introduce new diets
                    gradually over several days. It is recommended that cattle
                    be adapted to the shipboard ration before the voyage.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Syndromes</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Diarrhoea</span>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="p-2 mb-1 text-left">
            <b-card-header header-tag="header" class="p-1 disease" role="tab">
              <b-button
                block
                v-b-toggle.accordion-6
                variant="info"
                class="text-left"
                >Pinkeye</b-button
              >
            </b-card-header>
            <b-collapse
              id="accordion-6"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Other Names</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >Infectious Keratoconjunctivitis, Infectious Ophthalmia,
                    Infectious Ovine Keratoconjunctivitis</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Species</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">All.</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Description</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Pinkeye in sheep and goats is an infection of the eye caused
                    by bacteria (Mycoplasma conjunctivae, Chlamydia organisms,
                    and other Mycoplasma sp.), targeting the conjunctiva and
                    cornea. In cattle, pinkeye is mainly caused by Moraxella
                    bovis, but may be associated with other bacteria including
                    Mycoplasma and Neisseria. Note that the bacteria primarily
                    responsible for pinkeye in cattle (Moraxella bovis), has no
                    role in pinkeye in sheep and goats.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >Pinkeye generally requires a combination of causative
                    bacteria in the eye and some form of irritation or trauma to
                    the corneal epithelium from things such as dust, dryness or
                    ultraviolet light.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >The condition is contagious and can spread rapidly in
                    susceptible groups of animals. Alternatively, it may present
                    in individual animals in groups where most animals are
                    immune.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    The condition is painful and weight gains can be negatively
                    affected. Animals left with corneal scars or blindness may
                    have reduced sale value and suitability for purpose. Most
                    cases, whether treated or not, recover within 4 to 6 weeks
                    (cattle) or 2 weeks (sheep and goats). Recovered animals may
                    have small white areas of residual scarring on the cornea.
                    Occasionally the scarring is extensive, seriously reducing
                    vision. Rarely the cornea ruptures and the eye collapses,
                    causing permanent blindness.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >British and European cattle breeds are affected more
                    severely than tropically adapted cattle breeds. Goats are
                    mildly affected compared to sheep.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Predisposing factors include:</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >Crowding allowing close contact and transmission of
                    infection.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >Hot, dry, dusty conditions and presence of flies.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >Access to feedstuffs containing material that readily
                    enters the eye such as grass seeds, chaff, fibrous stems or
                    stalks, and fodder fines from disintegrating pellets at
                    sea.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >Stressors such as excessive handling, undernutrition,
                    crowding, and climatic changes that reduce resistance.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    In any export consignment, multiple animals are usually
                    affected and pink eye may be cause for rejection of badly
                    affected individuals. Most infections occur early in the
                    export process. They are often acquired at the assembly
                    point and are expressed in greatest numbers in the first
                    week or two at sea. If infections are not severe, most will
                    have healed by the end of a three week voyage. Animals still
                    affected at destination, may have reduced vision and be
                    difficult to move.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Diagnosis</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >One or both eyes can be affected. Signs include bloodshot
                    eyes (hyperaemia), eye discharge staining the face, and
                    closed eyelids.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    The cornea may be red initially and then go through cloudy
                    hues of red, blue, grey, and white during sequential stages
                    of healing. Animals with unilateral lesions may be missed at
                    pen inspection since they are likely to stand so their good
                    eye is facing the observer.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Differential diagnoses include foreign body in the eye, salt
                    water in the eye from high pressure hosing, and infectious
                    bovine rhinotracheitis (IBR) in cattle. IBR can cause a
                    severe conjunctivitis and oedema of the cornea at the
                    corneoscleral junction, but corneal ulceration is absent.
                    Trauma to the eye from ear tags of pen mates during trough
                    feeding has been suspected to cause occasional eye problems
                    at sea. Some cases of malignant catarrhal fever and ocular
                    squamous cell carcinoma may be mistaken for pinkeye in
                    cattle.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    It is important to examine multiple affected animals to
                    check for the presence of grass seeds or other foreign
                    material in the eye. Outbreaks of grass seeds or chaff in
                    the eye can behave like pinkeye, even though pinkeye
                    bacteria may not be present. Exposing foreign material
                    requires everting the eyelids, including the third eye lid.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Treatment</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Most cases of pinkeye will heal without treatment and this
                    will typically occur over the same time period as if
                    treatment was administered. This must be considered when
                    there are large numbers of animals affected, where catching
                    and restraining animals is difficult, and where handling may
                    stress animals and may predispose to other problems or
                    increase the spread of pinkeye infection. There is a small
                    risk of severely affected animals suffering long term
                    effects or even permanent loss of eyesight.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    However, correct treatment applied early in the disease when
                    there is just epiphora and tear staining below the eye, may
                    shorten the duration of disease and prevent the development
                    of complications including permanent blindness and corneal
                    scarring.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    If there are concerns that grass seeds, chaff or other
                    foreign bodies may be involved, then each affected animal
                    should be restrained and undergo close examination of the
                    eye, especially of the conjunctival sacs. Grass seeds and
                    other foreign bodies are easier to remove if topical
                    anaesthetic and forceps are used.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    The treatment of choice for pinkeye is a parenterally
                    administered course of antibiotics (oxytetracycline or
                    trimethoprim sulpha) which should be repeated as necessary
                    if signs persist. Injectable antibiotics are probably more
                    effective than topical treatments because they are better
                    able to achieve sustained therapeutic levels of antibiotic
                    in the eye. Note that Mycoplasma are often involved in
                    pinkeye in sheep and goats. Mycoplasma do not have a cell
                    wall and therefore antibiotics that target bacterial cell
                    wall synthesis (beta-lactam antibiotics such as penicillin
                    and cloxacillin), are less likely to be effective.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    A single topical application of long-acting, antibiotic eye
                    ointment is commonly used in cattle. Both eyes should be
                    treated even if only one eye is showing signs of disease.
                    Animals may be re-treated at 48 hour intervals. These
                    products often contain synthetic penicillins so may not be
                    effective against Mycoplasma infections in pinkeye in sheep
                    and goats.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Subconjunctival injection of antibiotic and
                    anti-inflammatory drugs may be useful for individual animals
                    with more severe disease and where good restraint is
                    available to ensure safe administration of the treatment.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Severe cases must be handled carefully to prevent bumping
                    the eye and the risk of corneal rupture. They may also
                    benefit from applying an eye patch or suturing the eyelids
                    closed for a period to provide protection while healing
                    occurs.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Topical treatments with sprays and powders are not
                    beneficial and may in fact be detrimental. The use of
                    irritant chemicals in the eyes will cause damage to the
                    cornea and will not help to treat or prevent infection. In
                    large outbreaks, mass medication of drinking water with
                    antibiotics may be attempted, but effectiveness is
                    uncertain. This practice requires the installation of header
                    tanks to achieve correct concentrations, the intake is
                    variable with some animals not drinking enough, and it
                    carries the risk of disrupting rumen function and feed
                    intake.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >Separate penning of severely affected animals with easy
                    access to food and water may be warranted.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Prevention</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Management changes can help to reduce the rate of spread and
                    also to prevent cases. Reducing exposure to grass seeds and
                    airborne feed particles, controlling flies in the local
                    environment, and reducing dust, are all measures that can
                    aid in preventing and controlling pinkeye. Source batches of
                    feed pellets that do not disintegrate easily and produce
                    dust. Minimise stressors such as excessive handling,
                    crowding, and undernutrition.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >At sea, avoid feeding chaff and hay in windy conditions,
                    minimise splash into eyes during high pressure hosing of
                    decks, and spread sawdust in pens carefully to prevent it
                    getting into eyes.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    A vaccine is available for cattle, but does not cover all
                    strains of Moraxella bovis that are involved in causing
                    pinkeye. It should be administered 3-6 weeks before the
                    onset of a high risk period to provide protection. There is
                    no vaccine available for sheep and goats.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Syndromes</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Eye Disease</span>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="p-2 mb-1 text-left">
            <b-card-header header-tag="header" class="p-1 disease" role="tab">
              <b-button
                block
                v-b-toggle.accordion-7
                variant="info"
                class="text-left"
                >Monensin Poisoning</b-button
              >
            </b-card-header>
            <b-collapse
              id="accordion-7"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Other Names</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >Ionophore Poisoning, Lasalocid Poisoning</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Species</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >All. Cattle are the main species of interest.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Description</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Ionophore antibiotics such as monensin and lasalocid are
                    commonly used feed additives for controlling coccidiosis,
                    bloat and improving efficiency of weight gain in cattle.
                    They are sometimes incorporated into pellets fed on ships.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Toxicity may result from mixing errors and consequent
                    inadvertent over exposure of animals to these compounds.
                    Affected animals suffer muscle damage leading to acute heart
                    failure and possibly skeletal muscle effects (paresis and
                    paralysis). Deaths in cattle can be expected to commence at
                    intakes of 10 mg/kg bodyweight.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Diagnosis</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    There is usually sudden onset of feed refusal, recumbency,
                    tachypnoea (fast breathing), ruminal atony, diarrhoea and
                    brown urine. In the subsequent days to weeks, there may be
                    development of illthrift and signs of congestive heart
                    failure including submandibular and brisket oedema, and
                    distended and pulsating jugular veins.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Necropsies performed on animals dying acutely may not reveal
                    any gross lesions. In longer surviving animals there may be
                    pallor of the myocardium, especially of the left ventricle
                    reflecting areas of myocardial necrosis. There may also be
                    signs related to congestive heart failure: pulmonary oedema,
                    hydrothorax, ascites and hepatomegaly (enlarged liver).
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Laboratory confirmation is based on specimens of myocardium
                    and skeletal muscle submitted in buffered formalin for
                    histology, and identification of toxic concentrations of an
                    ionophore in samples of feed.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Treatment</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    No antidote or specific drug treatment is available. Nursing
                    care and careful handling is required. Survivors should be
                    marked for salvage slaughter once residue periods have
                    passed. In an outbreak, decisive measures to replace, reduce
                    or dilute toxic feed are required.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Prevention</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >Prevention relies on preventing mixing errors in formulated
                    rations.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Syndromes</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Diarrhoea</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Downer</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Illthrift</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Sudden Death</span>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="mb-1 p-2 text-left">
            <b-card-header header-tag="header" class="p-1 disease" role="tab">
              <b-button
                block
                v-b-toggle.accordion-8
                variant="info"
                class="text-left"
                >Johne&#39;s Disease</b-button
              >
            </b-card-header>
            <b-collapse
              id="accordion-8"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Other Names</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >Mycobacterium paratuberculosis, Ovine Johne&#39;s Disease,
                    Paratuberculosis</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Species</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >All. Goats are the main species of interest. Sheep are the
                    main species of interest.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Description</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    This is a fatal mycobacterial infection affecting the
                    intestines. Multiplication of bacteria and chronic
                    inflammation slowly thicken the intestinal wall, preventing
                    absorption of nutrients, and leading to wasting and
                    diarrhoea. Affected animals show no signs for months or
                    years after becoming infected until the extent of intestinal
                    compromise causes progressive wasting. Eating and drinking
                    continue normally until animals are too weak to move.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    There are separate sheep and cattle strains of Mycobacterium
                    paratuberculosis. Goats may be infected with either strain.
                    Bovine Johne&rsquo;s disease (BJD) is endemic in south
                    eastern Australia. There are control and eradication
                    programs in Australia for both Ovine Johne&#39;s disease
                    (OJD) and BJD. The disease is widespread in sheep and goats
                    in Australia. Prevalence is lower in drier, lightly stocked
                    areas.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Problems may occur in the export process if infected animals
                    are sourced in the belief they have easily remedied problems
                    such as internal parasites or undernutrition. Also,
                    stressors within the export process may accelerate
                    progression of the disease and onset of clinical signs. It
                    takes six weeks to 3 months from the onset of signs to death
                    so there is the potential for animals to die during export.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Diagnosis</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Suspicions are often first raised when there is unresponsive
                    or unexplained wasting and death in yearling and adult
                    animals. Flocks may have a distinct tail of clinically
                    affected animals. At necropsy, the important diagnostic
                    lesions are found in the intestines which have thickened
                    walls, enlarged lymph nodes and prominent lymphatics.
                    Differential diagnoses include internal parasites,
                    undernutrition, poor dentition, and internal abscesses from
                    cheesy gland or following rumenitis. Caprine arthritis and
                    encephalitis (CAE) is an additional differential diagnosis
                    in goats. With many of these differentials there is often
                    some level of inappetence.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Laboratory confirmation in live animals requires serum and
                    faeces submitted chilled. In dead animals, recommended
                    specimens include thickened gut, enlarged lymph nodes and
                    liver chilled for microbiology and in buffered formalin for
                    histology. In the absence of obvious gross lesions submit
                    specimens of ileum, liver and ileal and caudal jejunal lymph
                    nodes.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Treatment</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >It is untreatable, including with antibiotics.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Prevention</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Prevention of infection occurs on farm by vaccination and
                    management to avoid exposure of susceptible animals to
                    infected stock. Prevention of onset of clinical signs in the
                    export process is by sourcing younger, vaccinated animals
                    from drier regions, or from flocks and herds in the
                    Johne&rsquo;s disease market assurance program. Minimising
                    environmental and management stress will also reduce the
                    likelihood of early infected animals developing clinical
                    signs.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Syndromes</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Illthrift</span>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="mb-1 p-2 text-left">
            <b-card-header header-tag="header" class="p-1 disease" role="tab">
              <b-button
                block
                v-b-toggle.accordion-9
                variant="info"
                class="text-left"
                >Gastrocnemius Muscle Rupture</b-button
              >
            </b-card-header>
            <b-collapse
              id="accordion-9"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Other Names</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Kangaroo Leg, Tendon Rupture</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Species</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >All. Cattle are the main species of interest.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Description</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >This injury usually follows attempts to rise on a slippery
                    floor in an animal already weakened by other disease.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Diagnosis</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    It presents as a markedly dropped (flexed) hock joint,
                    sometimes reaching the ground, and a markedly flexed
                    fetlock. It is particularly obvious in the standing animal.
                    It is usually unilateral, and may become bilateral if the
                    animal continues to struggle.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Treatment</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >These animals cannot walk and have major difficulty rising
                    therefore prompt euthanasia is recommended once the
                    diagnosis is confirmed.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Prevention</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >Low stress animal handling and non-slip flooring may reduce
                    incidence of this and other traumatic injuries.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Syndromes</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Knuckling</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Lameness</span>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="mb-1 p-2 text-left">
            <b-card-header header-tag="header" class="p-1 disease" role="tab">
              <b-button
                block
                v-b-toggle.accordion-10
                variant="info"
                class="text-left"
                >Knuckling</b-button
              >
            </b-card-header>
            <b-collapse
              id="accordion-10"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Other Names</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Knuckling Over At The Fetlock</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Species</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >All. Cattle are the main species of interest.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Description</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Knuckling refers to flexion of the fetlock joint caused by
                    damage to spinal cord, nerves, muscle or tendons. There may
                    be partial flexion where the soles of the hooves are bearing
                    weight, or extreme flexion where the front of the pastern is
                    bearing weight.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Many injuries or conditions may cause knuckling. In the
                    export process the main cause of knuckling in the hind leg
                    is injury to the spinal cord, the sciatic nerve and its
                    branches, or the gastrocnemius muscle or tendon. Knuckling
                    in the forelegs is from injury to the radial nerve.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Diagnosis</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Spinal cord injury may occur from being ridden by or riding
                    other animals, or pulling and twisting the head or neck when
                    it is stuck under a pen rail or over a gate chain. These
                    animals stand weakly on the front and hind limbs with both
                    hind fetlocks knuckling equally. The tail may have reduced
                    muscle tone or be flaccid, and anal reflex may be absent.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Cattle with mild calving paralysis knuckle bilaterally in
                    the hind limbs as a result of compression injury to nerves
                    in the pelvic canal suffered during parturition. A pelvic
                    fracture may cause similar signs.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    In animals recumbent for a long time on hard or uneven
                    surfaces, compression injury to a branch of the sciatic
                    nerve (the peroneal nerve) as it crosses the lateral stifle
                    can cause knuckling. Sometimes both hind limbs are affected,
                    and this resembles calving paresis.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Unilateral knuckling in a hind leg may also be the result of
                    injection site paresis affecting the sciatic nerve.
                    Injection site paresis is where needle puncture or irritant
                    injections in the gluteal region or caudal thigh result in
                    injury to the sciatic nerve and branches. The risk is
                    greater in animals with small muscle mass (young or very
                    thin animals), and in any animal where large volumes of
                    irritant medications or long large gauge needles are
                    injected intramuscularly.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Knuckling in the forelimb is usually from injury to the
                    radial nerve but can be due to rupture or severe trauma to
                    the extensor tendon or its associated musculature. With
                    these injuries, the elbow is dropped, the fetlock is
                    hyperflexed, and the limb appears unable to bear weight.
                    Radial nerve injury is typically seen in animals that have
                    been stuck in lateral recumbency for long periods following
                    blunt trauma to the shoulder area (striking a gate) or
                    following avulsion injury, such as from struggling to free a
                    stuck front leg.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >A severely dropped hock in conjunction with knuckling
                    indicates rupture of the gastrocnemius muscle or tendon
                    (Achilles tendon).</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >Note that knuckling may also be caused by systemic diseases
                    such as rabies, Aspergillus clavatus poisoning, cerebral
                    babesiosis and Histophilus somni meningitis.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Treatment</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Provide rest and confinement and administer non-steroidal
                    anti-inflammatory drugs (flunixin meglumine, ketoprofen,
                    meloxicam, or tolfenamic acid). Recovery from nerve damage
                    may take weeks or months, and may not occur at all.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Bandage or splint fetlocks in the extended position to
                    assist standing and walking, and to protect the anterior
                    pastern. Provide non-slip flooring. Treat any underlying
                    conditions leading to the injuries causing knuckling.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Prevention</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Injections into the neck and subcutis are preferred if
                    manufacturers&rsquo; directions allow. Roll downer animals
                    from side to side every few hours to reduce compression
                    damage to limb muscles and nerves.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Syndromes</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Knuckling</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Lameness</span>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="mb-1 p-2 text-left">
            <b-card-header header-tag="header" class="p-1 disease" role="tab">
              <b-button
                block
                v-b-toggle.accordion-11
                variant="info"
                class="text-left"
                >Lacerations</b-button
              >
            </b-card-header>
            <b-collapse
              id="accordion-11"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Other Names</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Skin Wounds, Wounds</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Species</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >All. Cattle are the main species of interest.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Description</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >Lacerations generally involve wounds of the skin and
                    possibly underlying tissues.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Lacerations may occur in animals striking sharp projections
                    in ramps, gates or laneways during loading or unloading, or
                    when moving between yards or pens. Lacerations due to
                    shearing cuts may be detected in sheep shorn just prior to
                    loading.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    If lameness is present it is especially concerning, because
                    more serious underlying damage to leg muscles, tendons,
                    joints and ligaments carries a poor prognosis, particularly
                    if flexor tendons and penetration of joints are involved.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >Laceration and bruising may result in damage to local blood
                    supply and further necrosis and sloughing of tissue.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Diagnosis</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >Diagnosis is based on clinical examination.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Treatment</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >Apply a pressure bandage if there is severe
                    haemorrhage.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >Relocate the affected animal to a hospital pen and provide
                    a deep layer of dry absorbent sawdust to prevent decubitus
                    ulcers should the animal spend a lot of time lying down
                    during recovery.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >The principles of treatment are to apply lavage,
                    debridement and where appropriate, suturing and
                    bandaging.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >Manage grossly contaminated wounds as open (unsutured)
                    wounds using lavage, debridement, and bandaging where
                    appropriate.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >An inexpensive, easily applied waterproof outer conforming
                    bandage suitable for shipboard conditions can be made using
                    rolls of plastic clingwrap, foam rubber and electrical or
                    duct tape.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Apply topical antibiotic ointments or powders to help
                    control surface infection, parenteral antibiotics (procaine
                    penicillin, oxytetracycline, ceftiofur, erythromycin, or
                    tylosin) to control deep infection, and non-steroidal
                    anti-inflammatory drugs (flunixin meglumine, ketoprofen,
                    meloxicam, or tolfenamic acid) to control pain and swelling.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >Encourage affected animals to stand as much as
                    possible.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Prevention</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >Use low stress animal handling methods to minimise the
                    speed at which animals might hit sharp projections when
                    being moved.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >Identify and remove hazards from ramps, gateways and
                    laneways before moving animals through them.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Syndromes</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Lameness</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Skin Lesions</span>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="mb-1 p-2 text-left">
            <b-card-header header-tag="header" class="p-1 disease" role="tab">
              <b-button
                block
                v-b-toggle.accordion-12
                variant="info"
                class="text-left"
                >Lameness</b-button
              >
            </b-card-header>
            <b-collapse
              id="accordion-12"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Species</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >All. Cattle are the main species of interest.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Description</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Lameness refers to changes in posture, stance or gait to
                    relieve pain in the limbs or trunk. It must be
                    differentiated from ataxia resulting from peripheral or
                    central nervous system disease or muscular weakness from
                    systemic conditions.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Lameness can be difficult to detect in crowded pen
                    conditions. It is easier to diagnose when there are
                    swellings, abrasions and non-weight bearing in single limbs.
                    Floor design and management of pens greatly influence the
                    number and severity of lameness cases.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Early detection and management of lameness is important. Any
                    condition may become life threatening if it worsens to the
                    point where an animal can no longer move effectively or
                    becomes a downer. Most lameness in export cattle involves
                    the foot (especially the lateral claws of the hind limbs)
                    because they are subjected to the most wear from twisting
                    and turning.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Diagnosis</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Lame cattle can be identified by swollen and/or
                    non-weight-bearing limbs, reluctance to move or prolonged
                    recumbency. One or more feet and legs may be affected.
                    Systemic signs occur when pain is severe or access to feed
                    and water is affected. Inspect the limbs of recumbent cattle
                    carefully because animals may spend more time lying down if
                    they are lame, and particularly if multiple limbs are
                    affected. Decubital ulcers and a tail with a depleted brush
                    may indicate the animal has been down for prolonged periods.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >The most likely causes of lameness in export cattle and
                    other ruminants include:</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >Solar abscesses, bruised soles and decubitus ulcers from
                    excessive wear on abrasive flooring</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >Traumatic injuries, from slipping, trampling or being
                    mounted.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >Footrot, when conditions underfoot are constantly wet and
                    damaging to interdigital skin</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >Infectious arthritis caused by organisms such as Mycoplasma
                    bovis, Histophilus somni and Chlamydiosis - joint swelling
                    and lameness are part of the disease complex caused by these
                    pathogens.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >Other conditions such as scabby mouth (orf virus) and
                    selenium deficiency may be unlikely causes of lameness in
                    sheep and goats.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Treatment</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Relocate the affected animal to a hospital pen and provide
                    bedding and uninhibited trough access. Administer
                    non-steroidal anti-inflammatory drugs (flunixin meglumine,
                    ketoprofen, meloxicam, or tolfenamic acid) and antibiotics
                    if appropriate (procaine penicillin, oxytetracycline,
                    erythromycin, ceftiofur, trimethoprim sulpha, or tylosin).
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Prevention</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Lameness can often be prevented by quiet handling of
                    livestock and matching pen mates on sex and size. Check
                    flooring for hazards such as broken anti-slip mesh or holes,
                    and abrasiveness. Avoid forced walking for long distances on
                    abrasive surfaces. Design laneways in yards and on ships
                    with minimal cornering.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Syndromes</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Lameness</span>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="mb-1 p-2 text-left">
            <b-card-header header-tag="header" class="p-1 disease" role="tab">
              <b-button
                block
                v-b-toggle.accordion-13
                variant="info"
                class="text-left"
                >Swollen Legs</b-button
              >
            </b-card-header>
            <b-collapse
              id="accordion-13"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Other Names</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Lameness</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Species</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >All. Cattle are the main species of interest.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Description</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >See related information under Foot abscess and
                    Lameness.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    This is a loosely defined term given to swelling of one or
                    more legs seen in cattle at sea. Swollen legs are generally
                    the result of accidental injury with or without secondary
                    bacterial infection. Swollen legs and lameness represent one
                    of the most common conditions affecting cattle during export
                    voyages.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Animals may be injured as a result of accidents during
                    transport or loading, being stepped on by a pen mate, or
                    from abrasion of the feet or lower limbs from contact with
                    the deck or other hard surfaces. Anatomical areas that are
                    commonly affected, and where initial swelling will be seen
                    if detected early, are the fetlock, pastern or coronet
                    region of the lower legs.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Deck surfaces can be rough and abrasive. As the voyage
                    progresses and there is increased accumulation of faecal
                    matter on the floor, abrasions and minor wounds, or injuries
                    of the feet or lower limbs may easily become infected.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Cattle recumbent in crowded pens are always at risk of
                    injury from being stepped on, especially the legs. All legs
                    are at risk, but especially the hind legs, as they may be
                    less likely to be tucked under a recumbent animal than the
                    front legs. The number of swollen legs from this cause
                    increases when pens are crowded with nervous, tired cattle.
                    Cattle on slippery flooring or pens of cattle that push for
                    feed are more at risk of developing skin abrasions or worn
                    hooves creating an entry point for bacterial infection.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Initial injuries may be small and innocuous, but over time
                    there is a risk of mild injuries being exacerbated by
                    repeated insult, and contaminated from exposure to faecal
                    matter on the floor. A localised cellulitis may develop and
                    result in swelling and lameness. Infection may involve
                    distal joints, depending on the site and extent of the
                    initial abrasion or injury. If the cellulitis progresses,
                    the swelling becomes extensive, there is incapacitating
                    lameness or toxaemia, and the animal dies or must be
                    euthanised.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >Lame animals may have difficulty standing and moving and
                    this, in turn, can lead to dehydration and inappetence, and
                    may predispose animals to a range of other conditions,
                    including heat stress.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >Swollen legs may also be due to joint infection secondary
                    to bovine respiratory disease (BRD).</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Complications from swollen legs are one of the major causes
                    of direct and indirect loss of animals at sea. The condition
                    can result in death directly or lead to euthanasia. It can
                    indirectly predispose animals to the development of other
                    conditions. Leg complications can also result in rejection
                    during health inspections at destination.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Diagnosis</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    During pen inspections, inspection of lower legs will detect
                    enlargement of the coronet, pastern and fetlock regions when
                    compared to other legs. Lameness may be mild or absent
                    unless swelling is severe, although animals may be reluctant
                    to stand on pen inspection. Lacerations are often not
                    visible unless the skin is scrubbed clean, and then they may
                    be very small.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Swelling of the coronary band and pastern may occur with
                    diseases of the claw, such as excessive wearing of the sole,
                    sole bruising, toe abscess, sole abscess, and white line
                    disease; or diseases of the interdigital space such as foot
                    rot. However, these conditions are accompanied by moderate
                    to severe lameness and the claws are spread.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >At necropsy, there is oedema and dark, mixed discolouration
                    of subcutaneous tissues in the area of the swelling. A
                    discharging skin wound may be present.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Treatment</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Minor lacerations or swellings affecting legs of export
                    cattle should not be ignored or treated lightly. Recovery
                    without treatment is possible but unpredictable as the
                    likelihood of wound infection is high for animals managed at
                    sea. Without early treatment, uncontainable or irreversible
                    cellulitis may occur.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >Move affected animals to a hospital pen with fresh bedding
                    to reduce environmental contamination.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Administer a course of antibiotics (procaine penicillin,
                    oxytetracycline, or ceftiofur) and non-steroidal
                    anti-inflammatory drugs (flunixin meglumine, ketoprofen,
                    meloxicam, or tolfenamic acid) and once finished, recheck
                    the animal for relapse.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >Avoid long-acting penicillins and
                    glucocorticosteroids.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Prevention</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Avoid overcrowding during transport or in pens. Avoid
                    situations where penned animals may rush and step on
                    recumbent animals. Move quietly around the cattle decks when
                    animals are settling into life on board, and make sure all
                    cattle in a pen are standing before feed is put out into
                    troughs.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1"
                    >Low stress handling practices during loading and discharge
                    will reduce the incidence of injury.</span
                  >
                  <span class="c0">
                    <br />
                    <br />
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">
                    Because early intervention saves lives, inspect cattle as
                    soon as possible after loading and isolate and treat any
                    animals that have lower leg wounds or swelling. During the
                    voyage, all lower legs should be systematically scanned
                    twice daily. This is best done immediately before or soon
                    after feeding has commenced when most cattle will be
                    standing up. Swollen legs are most easily detected after
                    deck washing &ndash; ensure a scan of legs occurs
                    immediately after deck washing and before spreading of
                    sawdust when cattle will start to lie down.
                  </span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Syndromes</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Downer</span>
                  <span class="c0">
                    <br />
                    <br />
                  </span>
                  <span class="c0 c1">Lameness</span>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card> -->
        </div>
        <!-- </b-card> -->
      </b-card>
    </b-container>
  </b-container>
</template>

<script>
import axios from "axios";
import testTopNav from "../components/testTopNav.vue";

export default {
  name: "Diseases",
  components: {
    testTopNav,
  },
  data() {
    return {
      active:'sheep',
      visible: false,
      visible1: false,
      visible2: false,
      visible3: false,
      visible4: false,
      visible5: false,
      visible6: false,
      visible7: false,
      visible8: false,
      visible9: false,

      text: `
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
          richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
          brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
          tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
          assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore
          wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
          vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic
          synth nesciunt you probably haven't heard of them accusamus labore VHS.
        `,
    };
  },
};
</script>

<style scoped>
.disease .btn-info {
  background: #f8f7f7 0% 0% no-repeat padding-box;
  color: black !important;
  border-color: white !important;
}
.disease .btn-info:active {
  background: #f8f7f7 0% 0% no-repeat padding-box;
  color: black !important;
  border-color: white !important;
}
.disease .btn-info:focus {
  background: #f8f7f7 0% 0% no-repeat padding-box;
  color: black !important;
  border-color: white !important;
}

.disease .btn-info:not(:disabled):not(.disabled):active {
  background: #f8f7f7 0% 0% no-repeat padding-box;
  color: black !important;
  border-color: white !important;
}
</style>