import Repository from "./Repository";

const user_login_resource = "users/user_login";
const user_register_resource = "users/create_user";
const update_user_resource = "users/update_user";

const user_forgetpassword_resource = "users/forgetpassword";
const user_qurabani_resource = "posts/user_posts_comments_likes";
const user_notification_resource = "posts/user_notifications";
const admin_notification_resource = "posts/user_admin_notifications";

const single_user_resource = "users/getuser";
const send_message_notification = "posts/send_message_notification";

const contact_us_resource = "users/contact_email";

export default {
  contactus(payload) {
    return Repository.post(`${contact_us_resource}`, payload);
  },
  authenticatelogin(payload) {
    return Repository.post(`${user_login_resource}`, payload);
  },
  getuser(payload) {
    return Repository.post(`${single_user_resource}`, payload);
  },
  register(payload) {
    return Repository.post(`${user_register_resource}`, payload);
  },
  updateProfile(payload) {
    return Repository.post(`${update_user_resource}`, payload);
  },
  get_user_resources(payload) {
    return Repository.post(`${user_qurabani_resource}`, payload);
  },
  get_user_notifications(payload) {
    console.log("wayyss");
    console.log(payload);
    if (payload.user_id == 21) {
      return Repository.get(`${admin_notification_resource}`);
    } else {
      return Repository.post(`${user_notification_resource}`, payload);
    }
  },

  forgetpassword(payload) {
    return Repository.post(`${user_forgetpassword_resource}`, payload);
  },
  sendmsgnotification(payload) {
    return Repository.post(`${send_message_notification}`, payload);
  },
};
