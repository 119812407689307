<template>
<div id="op">
   <div class="content  pl-0 pr-0">
    <div class="container-fluid" style="height:100vh;">
           <div class="row mt-5">
               
               <div class="col-md-12">
                  <div class="mt-5" v-if="isVerified==false" id="firebaseui-auth-container"></div>
                   <b-card
                   v-if="isVerified==true"
                    tag="article"
                    class="shadow-lg mt-5 pb-4 col-md-4 mb-2 p-0 ml-auto mr-auto"
                    style="max-width: 28rem;">
                    <img src="img/head.png" style="width:4rem;" alt="">
                    <template v-slot:header>
                      <div v-if="isVerified==true" class="col-md-8 col-11 ml-auto  mr-auto p-3 bg-success" style="border-top-right-radius:1rem;border-top-left-radius:1rem" >
                            <h4 class="text-white bolds">Forget Password</h4>
                      </div>

                    </template>
                    <b-card-body>
                              <b-input-group class="mt-3 mb-3">
                          <b-input-group-prepend>
                            <b-button style="border:1px solid #dcdcdc" variant="outline-white">
                              <img width="25" src="img/landing/password.png" alt="">
                            </b-button>
                          </b-input-group-prepend>
                                <b-form-input id="input-5" v-model="forget.password" type="password" placeholder="Enter new password" trim></b-form-input>
                        </b-input-group>
                            
                        <div class="row mt-2">
                          <div class="col-md-12 text-center">
                              <button @click="forget_password" class="text-center pr-5 pt-2 pb-2 pl-5   btn btn-outline-success"  >CONFIRM</button>

                          </div>
                        </div>
                    </b-card-body>

                  </b-card>
               </div>

           </div>
    </div>
</div>
</div>
</template>
<script>
import  * as firebaseui from 'firebaseui'
import firebase from 'firebase'
import { RepositoryFactory } from '../Repository/RepositoryFactory'
const UserRepository = RepositoryFactory.get('user_repository')
import globalfuns from '../mixins/globalfuns'
export default {
  name:'ForgetPassword',
  mixins:[globalfuns],
    mounted(){
      this.renderFirebaseVerifications()

    },
    data() {
      return {
        forget:{
          phoneno:'',
          password:''
        },
      isVerified:false

        
      }
    },
   
    methods:{

      renderFirebaseVerifications(){
              let self=this;
               var uiConfig = {
        callbacks: {
        signInSuccessWithAuthResult: function(authResult, redirectUrl) {
            self.isVerified=true
            let updated_phone=authResult.user.phoneNumber.replace("+92","0")
            self.forget.phoneno=updated_phone
            // Do something with the returned AuthResult.
            // Return type determines whether we continue the redirect automatically
            // or whether we leave that to developer to handle.
            return true;
          },
          signInFailure: function(error) {
            console.log(error)
            // Some unrecoverable error occurred during sign-in.
            // Return a promise when error handling is completed and FirebaseUI
            // will reset, clearing any UI. This commonly occurs for error code
            // 'firebaseui/anonymous-upgrade-merge-conflict' when merge conflict
            // occurs. Check below for more details on this.
            return handleUIError(error);
          },
      },
        signInOptions: [{
          // Leave the lines as is for the providers you want to offer your users.
          provider:firebase.auth.PhoneAuthProvider.PROVIDER_ID,
          defaultCountry: 'PK',
        }
        ],
        // tosUrl and privacyPolicyUrl accept either url string or a callback
        // function.
        // Terms of service url/callback.
        tosUrl:function() {
          self.$router.push({path:'/terms-and-conditions'})
          // window.location.assign('/terms-and-conditions');
        },
        // Privacy policy url/callback.
        privacyPolicyUrl: function() {
          self.$router.push({path:'/terms-and-conditions'})
          // window.location.assign('/terms-and-conditions');
        }
      };

      // Initialize the FirebaseUI Widget using Firebase.
      var ui =  firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());//new firebaseui.auth.AuthUI(firebase.auth());
      // The start method will wait until the DOM is loaded.
      ui.start('#firebaseui-auth-container', uiConfig);

      },
            // signInSuccessUrl: 'https://localhost:8085/#/terms-and-conditions',

       async forget_password() {

        if(this.forget.phoneno!='' && this.forget.password!='') {
          var {data}= await UserRepository.forgetpassword(this.forget)
          console.log(Object.keys(data).length)
          if(Object.keys(data).length>0){
          this.$store.commit('setNotifications',{message:'Password updated succesfully',type:'success'})
          this.forget.phoneno=''
          this.forget.password=''
          this.redirect(this,'/')
          }
          else{
          this.$store.commit('setNotifications',{message:'Make sure to provide valid credentials',type:'error'})
          }

        }
        else {
          this.$store.commit('setNotifications',{message:'Make sure to fill in the fields',type:'error'})
        }
      },
    }
}
</script>
<style  scoped>
.card-header {
  border-top-right-radius:1rem !important;
  border-top-left-radius:1rem !important
}
</style>

