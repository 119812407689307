<template>
  <b-container
    fluid
    class="pr-0 pl-0"
    style="min-height: 500px; position: relative"
  >
    <b-card>
      <h4 class="text-success text-center">
        Post Discussions here and become an active community member
      </h4>
      <b-row>
        <b-col cols="12" md="4">
          <b-input
            v-model="search"
            placeholder="Search here"
            class="mb-5 mt-3"
          ></b-input>
        </b-col>

        <b-col v-if="loggedUser != null" class="text-right">
          <b-button v-b-modal.modal-1 variant="success">
            New Discussion
          </b-button>
        </b-col>
      </b-row>
      <b-modal
        centered
        id="modal-1"
        ok-title="Save"
        @ok="saveDiss"
        title="New Discussion"
      >
        <b-textarea
          class="mb-3"
          v-model="newDi.question"
          placeholder="Enter forum details"
        >
        </b-textarea>
        {{ file == null ? "Select Attachment" : file.name }}
        <b-avatar button variant="success" @click="onPickFile">
          <i class="fa fa-paperclip" aria-hidden="true"></i>
        </b-avatar>
        <input
          @change="onFilePicked"
          type="file"
          ref="fils"
          style="display: none"
        />
      </b-modal>
      <b-tabs content-class="mt-5" v-model="tabIndex">
        <b-tab title="Recent" active>
          <div v-for="(item, l) in lecdisc" :key="l">
            <b-card shadow class="mt-3" style="background: lightgray">
              <b-row>
                <b-col
                  md="9"
                  cols="12"
                  class="text-left d-md-flex d-lg-flex d-block"
                >
                  <div>
                    <video
                      v-if="
                        item.file_types != null && item.file_types == 'video'
                      "
                      width="320"
                      height="240"
                      controls
                    >
                      <source :src="item.queDownloadLink" type="video/mp4" />
                    </video>
                    <img
                      v-else-if="
                        item.file_types == 'image' ||
                        item.queDownloadLink != 'N/A'
                      "
                      style="
                        min-width: 320px;
                        max-width: 320px;
                        max-height: 240px;
                      "
                      :src="item.queDownloadLink"
                      alt=""
                    />
                  </div>
                  <div></div>

                  <div class="align-self-center mb-0 ml-4 mt-2">
                    <b-avatar
                      :src="
                        item.image != ''
                          ? `https://qurbaniimages.s3.amazonaws.com/${replacer(
                              item.userImage
                            )}`
                          : 'img/dashboard/avatar.png'
                      "
                    >
                    </b-avatar>
                    <h5 class="mb-0 ">
                      {{ item.question }}
                    </h5>
                    <p class="text-success ">By {{ item.username }}</p>
                    <p class="text-success ">{{ item.date }}</p>
                  </div>
                </b-col>
                <b-col md="3" cols="12" class="d-flex justify-content-end">
                  <div>
                    <h5 class="font-weight-bold">
                      {{ getLen(getcoms(item.id)) }}
                    </h5>
                    <h5 class="text-success">Replies</h5>
                  </div>
                  <!-- <div>
                  <h5 class="font-weight-bold">15</h5>
                  <h5 class="text-success">Views</h5>
                </div> -->
                </b-col>
              </b-row>
              <p
                v-if="getLen(getcoms(item.id)) > 0"
                class="text-success text-right cursor-pointer"
                @click="posts[l].show = !posts[l].show"
              >
                {{ item.show == true ? "Hide Comments" : "View Comments" }}
              </p>
            </b-card>
            <div
              v-if="item.show == true"
              v-for="(it, i) in getcoms(item.id)"
              :key="i"
            >
              <b-row
                class="mt-3"
                v-for="(is, j) in Object.keys(it).filter(
                  (key) => it[key].discussion != null
                )"
                :key="j"
              >
                <b-col md="12" cols="12" class="text-left d-flex">
                  <b-card class="w-100">
                    <div class="d-flex">
                      <b-avatar
                        :src="
                          item.image != ''
                            ? `https://qurbaniimages.s3.amazonaws.com/${replacer(
                                it[is].userImage
                              )}`
                            : 'img/dashboard/avatar.png'
                        "
                      >
                      </b-avatar>
                      <h6 class="mb-0 align-slf-center ml-2">
                        {{ it[is].discussion }}
                      </h6>
                    </div>

                    <div class="align-self-center mb-0 ml-2">
                      <p class="text-success mb-0">By {{ it[is].username }}</p>
                      <p class="text-success mb-0">{{ it[is].date }}</p>
                    </div>
                  </b-card>
                </b-col>
              </b-row>
            </div>
            <form></form>
            <b-row>
              <b-col md="12" cols="12" class="text-left d-flex mt-2">
                <b-textarea
                  v-model="item.discussion"
                  placeholder="Enter comment"
                ></b-textarea>
              </b-col>
              <b-col
                v-if="loggedUser != null"
                class="text-md-right mt-2 text-center align-self-center"
              >
                <b-button
                  variant="success"
                  @click="submitcom(item.discussion, item.id, l)"
                >
                  Post
                </b-button>
              </b-col>
            </b-row>
          </div>

          <!-- <b-card>
            <div class="d-flex">
              <b-avatar>

              </b-avatar>
              <h4 class="align-self-center mb-0 ml-2">Hello</h4>
            </div>
            <p class="text-left">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum facilisis turpis eros, vitae ullamcorper nunc laoreet in. Fusce sit amet lacus ac metus porta mollis. Nulla nec mi nulla. Ut orci velit, molestie sed ipsum vel, scelerisque dignissim libero. Etiam a semper purus, et tempor arcu. Cras eu urna at nulla convallis tristique. Etiam faucibus varius ipsum, vitae sollicitudin leo aliquam volutpat. Curabitur porta tempor tellus, ultrices pellentesque massa.</p>
       
          </b-card>   -->
        </b-tab>
        <b-tab title="Old">
           <div v-for="(item, l) in lecdisc" :key="l">
            <b-card shadow class="mt-3" style="background: lightgray">
              <b-row>
                <b-col
                  md="9"
                  cols="12"
                  class="text-left d-md-flex d-lg-flex d-block"
                >
                  <div>
                    <video
                      v-if="
                        item.file_types != null && item.file_types == 'video'
                      "
                      width="320"
                      height="240"
                      controls
                    >
                      <source :src="item.queDownloadLink" type="video/mp4" />
                    </video>
                    <img
                      v-else-if="
                        item.file_types == 'image' ||
                        item.queDownloadLink != 'N/A'
                      "
                      style="
                        min-width: 320px;
                        max-width: 320px;
                        max-height: 240px;
                      "
                      :src="item.queDownloadLink"
                      alt=""
                    />
                  </div>
                  <div></div>

                  <div class="align-self-center mb-0 ml-4 mt-2">
                    <b-avatar
                      :src="
                        item.image != ''
                          ? `https://qurbaniimages.s3.amazonaws.com/${replacer(
                              item.userImage
                            )}`
                          : 'img/dashboard/avatar.png'
                      "
                    >
                    </b-avatar>
                    <h5 class="mb-0 ">
                      {{ item.question }}
                    </h5>
                    <p class="text-success ">By {{ item.username }}</p>
                    <p class="text-success ">{{ item.date }}</p>
                  </div>
                </b-col>
                <b-col md="3" cols="12" class="d-flex justify-content-end">
                  <div>
                    <h5 class="font-weight-bold">
                      {{ getLen(getcoms(item.id)) }}
                    </h5>
                    <h5 class="text-success">Replies</h5>
                  </div>
                  <!-- <div>
                  <h5 class="font-weight-bold">15</h5>
                  <h5 class="text-success">Views</h5>
                </div> -->
                </b-col>
              </b-row>
              <p
                v-if="getLen(getcoms(item.id)) > 0"
                class="text-success text-right cursor-pointer"
                @click="posts[l].show = !posts[l].show"
              >
                {{ item.show == true ? "Hide Comments" : "View Comments" }}
              </p>
            </b-card>
            <div
              v-if="item.show == true"
              v-for="(it, i) in getcoms(item.id)"
              :key="i"
            >
              <b-row
                class="mt-3"
                v-for="(is, j) in Object.keys(it).filter(
                  (key) => it[key].discussion != null
                )"
                :key="j"
              >
                <b-col md="12" cols="12" class="text-left d-flex">
                  <b-card class="w-100">
                    <div class="d-flex">
                      <b-avatar
                        :src="
                          item.image != ''
                            ? `https://qurbaniimages.s3.amazonaws.com/${replacer(
                                it[is].userImage
                              )}`
                            : 'img/dashboard/avatar.png'
                        "
                      >
                      </b-avatar>
                      <h6 class="mb-0 align-slf-center ml-2">
                        {{ it[is].discussion }}
                      </h6>
                    </div>

                    <div class="align-self-center mb-0 ml-2">
                      <p class="text-success mb-0">By {{ it[is].username }}</p>
                      <p class="text-success mb-0">{{ it[is].date }}</p>
                    </div>
                  </b-card>
                </b-col>
              </b-row>
            </div>
            <form></form>
            <b-row>
              <b-col md="12" cols="12" class="text-left d-flex mt-2">
                <b-textarea
                  v-model="item.discussion"
                  placeholder="Enter comment"
                ></b-textarea>
              </b-col>
              <b-col
                v-if="loggedUser != null"
                class="text-md-right mt-2 text-center align-self-center"
              >
                <b-button
                  variant="success"
                  @click="submitcom(item.discussion, item.id, l)"
                >
                  Post
                </b-button>
              </b-col>
            </b-row>
          </div>

        </b-tab>
      </b-tabs>
    </b-card>
  </b-container>
</template>
<script>
import testTopNav from "../components/testTopNav.vue";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import { uuid } from "vue-uuid"; // uuid object is also exported to things
export default {
  components: {
    testTopNav,
  },
  computed: {
    ...mapGetters(["loggedUser", "discussions", "comments"]),
    lecdisc() {
      if (this.search != "") {
        let posts= this.posts
          .filter(
            (item) =>
              item.question.toLowerCase().includes(this.search.toLowerCase()) ||
              item.username.toLowerCase().includes(this.search.toLowerCase())
          )
          .map((item) => {
            return { ...item, discussion: "",
            date:this.moment(item.date+'-2021').format('YYYY-MM-DD') };
          }).sort((a,b)=>{
            return a.date-b.date
          });
        if(this.tabIndex==0){
        let showdate = this.moment()
        .subtract(6 ,"days")
        .format("YYYY-MM-DD");

          return posts
        .filter(
          (item) =>
            this.moment(item.date).isBetween(
              showdate,
              this.moment().format("YYYY-MM-DD")
            ) ||
            this.moment(item.date).isSame(
              showdate)
            
            ||
            this.moment(item.date).isSame(
              this.moment().format("YYYY-MM-DD")
            )
        )
       

        }
        else{
        let showdate = this.moment()
        .subtract(6 ,"days")
        .format("YYYY-MM-DD");

        let prevshowdate = this.moment()
        .subtract(20 ,"days")
        .format("YYYY-MM-DD");
        return posts
        .filter(
          
          (item) =>
            this.moment(item.date).isBetween(
              prevshowdate,
              showdate
            ) 
            ||
            this.moment(item.date).isSame(
              showdate)
            
            ||
            this.moment(item.date).isSame(
            prevshowdate
            )
        )
       

        }

        

      } 
      else {
       let posts= this.posts
          
          .map((item) => {
            return { ...item, discussion: "",
            date:this.moment(item.date+'-2021').format('YYYY-MM-DD') };
          }).sort((a,b)=>{
            return a.date-b.date
          });
        if(this.tabIndex==0){
        let showdate = this.moment()
        .subtract(6 ,"days")
        .format("YYYY-MM-DD");

          return posts
        .filter(
          (item) =>
            this.moment(item.date).isBetween(
              showdate,
              this.moment().format("YYYY-MM-DD")
            ) ||
            this.moment(item.date).isSame(
              showdate)
            
            ||
            this.moment(item.date).isSame(
              this.moment().format("YYYY-MM-DD")
            )
        )
       

        }
        else{
        let showdate = this.moment()
        .subtract(6 ,"days")
        .format("YYYY-MM-DD");

        let prevshowdate = this.moment()
        .subtract(20 ,"days")
        .format("YYYY-MM-DD");
        return posts
        .filter(
          
          (item) =>
            this.moment(item.date).isBetween(
              prevshowdate,
              showdate
            ) 
            ||
            this.moment(item.date).isSame(
              showdate)
            
            ||
            this.moment(item.date).isSame(
            prevshowdate
            )
        )
       

        }

             }
    },
  },
  data() {
    return {
      moment: moment,
      tabIndex:0,
      forums: [1],
      posts: [],
      file: null,
      search: "",
      newDi: {
        ansDate: "N/A",
        ansDownloadLink: "N/A",
        ansDuration: "N/A",
        ansType: 0,
        ansUserImage: "N/A",
        ansUserName: "N/A",
        answer: "N/A",
        date: "",
        id: "",
        queDownloadLink: "N/A",
        question: "",
        questionType: 4,
        thumbnailLink: "N/A",
        tokens: [],
        userImage: "",
        username: "",
      },
      newComment: {
        date: "",
        discussion: "",
        discussionType: 4,
        id: "",
        queDownloadLink: "N/A",
        questionDuration: "N/A",
        uploadFileName: "N/A",
        uploadFileUri: "N/A",
        uploaded: false,
        userImage: "",
        username: "",
      },
    };
  },
  watch: {
    discussions() {
      this.posts = this.discussions.map((item) => {
        return { ...item, show: false };
      });
    },
  },
  created() {
    if (this.discussions.length < 1) {
      this.$store.dispatch("fetchDiscussions");
      this.$store.dispatch("fetchComments");
    }
    if (this.discussions.length > 0) {
      this.posts = this.discussions.map((item) => {
        return { ...item, show: false };
      });
    }
  },
  methods: {
    ...mapActions([
      "addDiscussion",
      "fetchDiscussions",
      "fetchComments",
      "addComment",
    ]),
    onPickFile() {
      console.log("camee");
      this.$refs.fils.click();
    },
    onFilePicked(event) {
      console.log(event.target.files[0]);
      let tip = event.target.files[0].type.split("/")[0];
      console.log(tip);
      if (tip == "image" || tip == "video") {
        this.file = event.target.files[0];
      } else {
        alert("FIle should be an image or video");
      }
    },
    async submitcom(dis, id, l) {
      if (dis == "") {
        this.$store.commit("setNotifications", {
          message: "Empty comment not allowed",
          type: "error",
        });
        return;
      }
      let obj = {
        ...this.newComment,
        date: moment().format("DD-MMM"),
        discussion: dis,
        username: this.loggedUser.nickname,
        userImage: this.loggedUser.image,
      };
      console.log({ data: obj, id: id });
      await this.addComment({ data: obj, id: id });
      this.lecdisc[l].discussion = "";
    },
    getLen(arr) {
      let cnt = 0;
      arr.forEach((it) => {
        cnt = Object.keys(it).filter(
          (key) => it[key].discussion != null
        ).length;
      });
      return cnt;
    },
    removeComs(arr) {
      arr.splice(arr.length, 1);
      return arr; //.splice(2,1)
    },
    getcoms(id) {
      return this.comments.filter((item) => item.id == id);
    },
    saveDiss() {
      if (this.newDi.question == "") {
        this.$store.commit("setNotifications", {
          message: "Empty post not allowed",
          type: "error",
        });
        return;
      }
      let newkey = uuid.v1();
      this.tokens = [];
      let obj = {
        ...this.newDi,
        date: moment().format("DD-MMM"),
        tokens: this.tokens,
        username: this.loggedUser.nickname,
        userImage: this.loggedUser.image,
      };
      obj.tokens.push(newkey);
      this.addDiscussion({ file: this.file, data: obj });
    },
  },
};
</script>

